import { BaseComponent } from './../basecomponents/base.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from '../shared/common.service';
import { Constants } from '../shared/constants';
import { CRAData } from '.././shared/cRAData';
import { BaseService } from '../basecomponents/base.service';
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-categorymanager',
  templateUrl: './categorymanager.component.html',
  styleUrls: ['./categorymanager.component.css']
})

export class CategoryManagerComponent extends BaseComponent {
  public categories: CRAData[] = [];
  public filteredData: CRAData[] = [];
  public  filteredName: string;
  public filteredUnits: string;
  public filteredDefinition: string;
  public filteredOrder: string;
  public filteredComment: string;

  public panelHeight = '500px';
  public originalValue: any;
  public isDataChanged = false;
  public hideMessage = false;
  @ViewChild('dvCategories') dvCategories: ElementRef;

  constructor(private _commonService: CommonService, private _confirmation: ConfirmationService,
    baseService: BaseService, _messageService: MessageService,
    sharedservice: SharedService) {
      super(baseService, _messageService, sharedservice);
  }

  buildForm() {
    if (this.isROVAdmin) {
      this.hideMessage = true;
    } 
    else {
      this.hideMessage = false;
    }

    this.SelectMenuOption('CategoryManager');
  }

  override ngOnInit() {
    this.setupRoles();
    this.getCategories();
    this.buildForm();
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const offsetHeight = this.dvCategories.nativeElement.getBoundingClientRect().top;
    this.panelHeight = ((viewPortHeight) - (offsetHeight)).toString() + 'px';
  }

  getCategories() {
    this._commonService.getCategories().subscribe({
      next:data => {
        this.filteredData = this.categories = data.slice();
        this.isDataChanged = false;
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  onEditInit(event: any) {
    this.originalValue = event.data[event.field];
  }

  dataTableOnBlur(event: any, col: string, rowData: any, rowIndex: any, dt: { onEditComplete: { emit: (arg0: { column: any; data: any; index: any; }) => void; }; }) {
    dt.onEditComplete.emit({ column: col, data: rowData, index: rowIndex });
  }

  onEditComplete(event: any) {
    let value = event.data[event.column];

    if (!(event.column === 'IsActive' || event.column === 'IsIncluded')) {
      if (event.column === 'Name') {
        if (!value || value.toString().trim() === '') {
          this.DisplayErrorMessage('Error', `${event.column.header} cannot be empty.`);
          event.data[event.column] = this.originalValue;
          return;
        }

        if (value.toString().length > 75) {
          this.DisplayErrorMessage('Error', 'Allowed max length for Title is 75 characters.');
          event.data[event.column] = this.originalValue;
          return;
        }

        const rowIndex = this.categories.findIndex(s => s.Name.trim().toLowerCase() === value.toString().trim().toLowerCase()
          && s.GUID.toString() !== event.data.GUID.toString());

        if (rowIndex !== -1) {
          this.DisplayErrorMessage('Error', `Title you provided has already been used in Row Number ${(rowIndex + 1)}.`);
          event.data[event.column] = this.originalValue;
          return;
        }
      }
      else if (event.column === 'Definition') {
        if (value.toString().length > 255) {
          this.DisplayErrorMessage('Error', 'Allowed max length for Definition is 255 characters.');
          event.data[event.column] = this.originalValue;
          return;
        }
      }
      else if (event.column === 'Units') {
        if (value.toString().length > 255) {
          this.DisplayErrorMessage('Error', 'Allowed max length for Units is 255 characters.');
          event.data[event.column] = this.originalValue;
          return;
        }
      }
      else if (event.column === 'Order') {
        if (!value || value.toString().trim() === '') {
          this.DisplayErrorMessage('Error', `${event.column.header} cannot be empty.`);
          event.data[event.column] = this.originalValue;
          return;
        }

        if (!this._commonService.IsValidInteger(value.toString())) {
          this.DisplayErrorMessage('Error', 'Order value is not a valid integer.');
          event.data[event.column] = this.originalValue;
          return;
        }
        const order = parseInt(value.toString(), 10);
        if (order === 0) {
          this.DisplayErrorMessage('Error', 'Order cannot be zero (0).');
          event.data[event.column] = this.originalValue;
          return;
        }

        if (order > 32767) {
          this.DisplayErrorMessage('Error', 'Order cannot be bigger than 32767.');
          event.data[event.column] = this.originalValue;
          return;
        }

        if (order > this.categories.length) {
          this.DisplayErrorMessage('Error', `Allowed max value for Order is ${this.categories.length}.`);
          event.data[event.column] = this.originalValue;
          return;
        }

        value = order;
        this.manageCatetoryOrder(order);
      }
    }

    if (value !== this.originalValue) {
      this.isDataChanged = true;
    }
  }

  manageCatetoryOrder(order: any) {
    let cats = this.categories;
    const originalValue = parseInt(this.originalValue, 10);

    if (order < originalValue) {
      for (let i = order; i < originalValue; i++) {
        cats[i - 1].Order = i + 1;
      }
    }
    else if (order > originalValue) {
      for (let i = originalValue; i < order; i++) {
        cats[i].Order = i;
      }
    }

    cats = cats.sort((a, b) => {
      if (a.Order < b.Order) {
        return -1;
      } 
      else if (a.Order > b.Order) {
        return 1;
      } 
      else {
        return 0;
      };
    });

    this.filteredData = this.categories = cats.slice();
  }

  filterCategoryDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  randomString(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  addNewCategory(dt: any) {
    if (this.filteredData.length !== this.categories.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    let catName = 'New Category';

    const rowIndex = this.categories.findIndex(s => s.Name.trim().toLowerCase() === catName.toLowerCase());

    if (rowIndex !== -1) {
      catName = `${catName}_${(this.categories.length + 1)}`;
    }

    const category = new CRAData();
    category.Definition = '';
    category.GUID = catName;
    category.IsActive = true;
    category.IsIncluded = true;
    category.Name = catName;
    category.Units = '';
    category.Comment = '';
    category.Order = this.categories.length + 1;
    this.categories.push(category);
    this.filteredData = this.categories = this.categories.slice();

    const catCount = this.categories.length;

    setTimeout(function () {
      const newRow = document.querySelector(
        `#dtCategories table > tbody > tr:nth-child(${catCount})`
      );
      // newRow.scrollIntoView();
      (newRow as HTMLElement).style.background = 'lightgrey';

      const firstCell = document.querySelector(
        `#dtCategories table > tbody > tr:nth-child(${catCount}) > td:nth-child(2)`
      );
      (firstCell as HTMLElement).click();
    }, 100);
    this.isDataChanged = true;
  }

  saveCategories() {
    if (this.isDataChanged) {
      this._confirmation.confirm({
        message: Constants.CONFIRMSAVECATEGORIES,
        accept: () => {
          this._commonService.saveCategories(JSON.stringify(this.categories)).subscribe({
            next: Result => {
              if (Result.Status === 'SUCCESS') {
                this.DisplaySuccessMessage('Success', Result.Message);
                this.getCategories();
              } else if (Result.Status === 'INFO') {
                this.DisplayInfoMessage('Info', Result.Message);
              } else {
                this.DisplayErrorMessage('Error', Result.Message);
              }
            },
           error: error => {
              if (error === '') {
                this.DisplayErrorMessage('Error', Constants.NOSERVICE);
              } else {
                this.DisplayErrorMessage('Error', error);
              }
            }
        });
        }
      });
    } else {
      this.DisplayInfoMessage('Info', 'Please make changes in the category data first.');
    }
  }

  exportCSVForCategoryManager() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._commonService.exportCSVForCategoryManager(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel' });
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'CategoryData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
       error:  error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
  });
  }
}
