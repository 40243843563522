import { BaseComponent } from './../basecomponents/base.component';
import { Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from '../basecomponents/base.service';
import { MessageService } from 'primeng/api';
import { SharedService } from './../services/shared.service';
import { CommonService } from '../shared/common.service';
import { Constants } from '../shared';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent extends BaseComponent {
  public selectedRequestID = '';
  public selectedTabIndex: number = -1;
  public hideHomeTab = false;
  public index: number = -1;
  public cleanCache = true;
  public isDownloading = false;
  public publishedFile = '';

  public constructor(_viewContainerRef: ViewContainerRef, private route: ActivatedRoute,
    public override _baseService: BaseService, _messageService: MessageService,
    sharedservice: SharedService, private _commonService: CommonService,
    private router: Router) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.publishedFile = '';
    this.isDownloading = false;

    this.route.queryParams.subscribe(p => {
      if(p['ProgramID']) {
        this.selectedRequestID = p['ProgramID'];
        this.selectedTabIndex = this.index = 0;
      }
      else if(p['StationID']) {
        this.selectedRequestID = p['StationID'];
        this.selectedTabIndex = this.index = 1;
        this.SelectMenuOption('Home');
      }
      else if(p['SeedStorageLocationID']) {
        this.selectedRequestID = p['SeedStorageLocationID'];
        this.selectedTabIndex = this.index = 2;
        this.SelectMenuOption('Home');
      }
      else if(p['EvaluationZoneID']) {
        this.selectedRequestID = p['EvaluationZoneID'];
        this.selectedTabIndex = this.index = 3;
        this.SelectMenuOption('Home');
      }
      else if(p['RegionID']) {
        this.selectedRequestID = p['RegionID'];
        this.selectedTabIndex = this.index = 4;
        this.SelectMenuOption('Home');
      }
      else if(p['BreedingZoneID']) {
        this.selectedRequestID = p['BreedingZoneID'];
        this.selectedTabIndex = this.index = 5;
        this.SelectMenuOption('Home');
      }
      else if(p['CPFieldScientistID']) {
        this.selectedRequestID = p['CPFieldScientistID'];
        this.selectedTabIndex = this.index = 6;
        this.SelectMenuOption('Home');
      }
      else if (p['publishedFileID'] && p['publishedFileName']) {
          this.selectedRequestID = p['publishedFileID'];
          this.publishedFile = p['publishedFileName'];
          this.selectedTabIndex = this.index = 8;

          this.isDownloading = true;
          this._commonService.generateResourceFileFromEmailLink(JSON.stringify(p['publishedFileID'])).subscribe({
            next: data => {
              const blob = new Blob([data], { type: 'application/ms-excel'});
              const link = document.createElement('a');
              link.style.display = 'none';
              link.href = window.URL.createObjectURL(blob);
              link.download = p['publishedFileName'] + '.xlsx';
              document.body.appendChild(link);
              link.click();

              setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
              }, 100);
            },
            error: error => {
              this.isDownloading = false;
            },
            complete: () => { 
              this.publishedFile += ' DONE!';
              this.isDownloading = false;
            }
          });

          this.router.navigate([Constants.Nav_Home]);
        }
    });
  }

  public onTabChange(event: any) {
    this.index = event.index;
    this.selectedTabIndex = event.index;
    this.hideHomeTab = true;
    this.publishedFile = '';
    this.isDownloading = false;
  }

  public onCleanUpCache(cleanCache: boolean) {
    this.cleanCache = cleanCache;
  }
}