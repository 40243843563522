import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SeedStorageLocationModel } from '../shared/seedStorageLocationModel';
import { GeoPoliticalUnitModel } from '../shared/geoPoliticalUnitModel';
import { LoadingService } from "../services/loadingService";
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SeedStorageLocationService {
    protected urlConfig: UrlConfig;
    protected http: HttpService;

    constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
        this.urlConfig = environment.getEnvironment();
        this.http = _http;
    }

    getSSLResult(): Observable<SeedStorageLocationModel[]> {
       this.loader.setMessage('Loading...');
       return this.http.get(this.urlConfig.ssl)
       //.catch((error: any) => Observable.throw(error.statusText))
       //.finally(() => this.loader.clearMessage());
    }

    GetAllCountriesFomPRISM(): Observable<any> {
        this.loader.setMessage('Loading...');
        return this.http.get(this.urlConfig.countries)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    /* GetNextlevelAreaFromPRISM(aoi_id: string): Observable<GeoPoliticalUnitModel[]> {
        this.loader.setMessage('Loading...');
        return this.http.get(this.urlConfig.getNextlevelAreaFromPRISM + '?aoi_id=' + aoi_id)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    } */

   saveSSL(ssl: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(this.urlConfig.saveSSL, ssl, httpOptions)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    saveSSLComment(sslComment: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(this.urlConfig.saveSSLComment, sslComment, httpOptions)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }


    exportCSVForSSL(ssl: string) {
        this.loader.setMessage('Loading...');
        const httpHeaders = new HttpHeaders({
            'Content-Type' : 'application/json'
          });
        return this.http.post(this.urlConfig.exportCSVForSSL, ssl, { headers: httpHeaders, responseType: 'arraybuffer' })
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }
}
