import { ProgramBreedingZoneAssoc } from './programBreedingZoneAssoc';
import { ApprovalComments } from './approvalComments';
import { ProgramTypeModel } from './programTypeModel';
import { SeedEvaluationZoneComboModel } from './seedEvaluationZoneComboModel';
import { ProgramPurposeModel } from './programPurposeModel';
import { SeedStorageLocationComboModel } from './seedStorageLocationComboModel';
import { StationResultModel } from './stationResultModel';
import { ProgramPrincipalInvestigator } from './programPrincipalInvestigator';
import { Person } from './person';
import { LookupType } from './lookupType';

export class ProgramForm {
  constructor(
    public ProgramID: string,
    public ProgramName: string,
    public Description: string,
    public ProgramCode: string,
    public ProgramSize: number,
    public CropID: string,
    public CropName: string,
    public CropDescription: string,
    public ProgramTypeID: string,
    public ProgramType: ProgramTypeModel,
    public ProgramPrincipalInvestigatorNames: string,
    public ProgramPrincipalInvestigators: ProgramPrincipalInvestigator[] = [],
    public StationID: string,
    public Station: any,
    public CRMLower: number,
    public CRMUpper: number,
    public LEID: string,
    public EvaluationZoneID: string,
    public EvaluationZone: SeedEvaluationZoneComboModel,
    public Region: string,
    public BreedingZones: string,
    public ShipToStationID: string,
    public ShipToStation: any,
    public SeedStorageLocationID: string,
    public SeedStorageLocation: SeedStorageLocationComboModel,
    public ProgramPurposeID: string,
    public ProgramPurpose: ProgramPurposeModel,
    public RequestStatus: string,
    public ApprovalComments: ApprovalComments[] = [],
    public CreatedBy: Person,
    public CreatedByUserName: string,
    public CreatedOn: Date,
    public ModifiedByUserName: string,
    public ModifiedBy: Person,
    public ModifiedOn: Date,
    public ApprovedByUserName: string,
    public ApprovedBy: Person,
    public ApprovedOn: Date,
    public RetiredByUserName: string,
    public RetiredBy: Person,
    public RetiredOn: Date,
    public BaseProgramID: string,
    public ProgramBreedingZoneAssoc: ProgramBreedingZoneAssoc[] = [], 
    public PrimaryResponsibleSubFunction: LookupType, 
    public PrimaryResponsibleSubFunctionId: string,
  ) {  }
}
