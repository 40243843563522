import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegionResultModel } from '../shared/regionResultModel';
import { LoadingService } from '../services/loadingService';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  bindRegionDropdownLists(): Observable<any> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.regionDropDownLists)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

  getRegionResult(): Observable<RegionResultModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.region)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

  saveRegion(region: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveRegion, region, httpOptions)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

  saveRegionComment(regionComment: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveRegionComment, regionComment, httpOptions)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

  exportCSVForRegions(region: string) {
    this.loader.setMessage('Loading...');
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForRegions, region, { headers: httpHeaders, responseType: 'arraybuffer' })
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

  saveAOIDataToPrismRegion(regionAOIData: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveAOIDataToPrismRegion, regionAOIData, httpOptions)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }
}
