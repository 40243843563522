import { ApprovalComments } from './approvalComments';
import { BreedingZoneLead } from './breedingZoneLead';
import { Person } from './person';

export class BreedingZoneResultModel {
    public BreedingZoneID: string;
    public BreedingZoneName: string;
    public Description: string;
    public BreedingZoneCode: string;
    public Color: string;
    public CropID: string;
    public CropName: string;
    public CropDescription: string;
    public Region: string;
    public BreedingZoneTypeID: string;
    public BreedingZoneTypeName: string;
    public BreedingZoneTypeCode: string;
    public CRMLower: number;
    public CRMUpper: number;
    public BreedingZoneLeadNames: string;
    public BreedingZoneLeads: BreedingZoneLead[] = [];
    public PRISM_AOI_ID: string;
    public RequestStatus: string;
    public ApprovalComments: ApprovalComments[] = [];
    public CreatedBy: Person;
    public CreatedByUserName: string;
    public CreatedOn: Date;
    public ModifiedByUserName: string;
    public ModifiedBy: Person;
    public ModifiedOn: Date;
    public ApprovedByUserName: string;
    public ApprovedBy: Person;
    public ApprovedOn: Date;
    public RetiredByUserName: string;
    public RetiredBy: Person;
    public RetiredOn: Date;
    public BaseBreedingZoneID: string;
}
