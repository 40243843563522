import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Message } from "primeng/api";
import { Person, UserFormModel, UserRequestedData } from "../shared";
import { CommonService } from "../shared/common.service";

@Component({
  selector: 'app-peoplepicker',
  templateUrl: './peoplepicker.component.html',
  styleUrls: ['./peoplepicker.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PeoplepickerComponent implements OnInit {
  public peoplePickerForm: FormGroup;
  public lastName: string;
  public firstName: string;
  public loginName: string;
  people: Person[] = [];
  public Height: string;
  selPeopleData: Person[] = [];
  public DisplayName = '';
  public userData = new UserFormModel(
    'Overwrite',
    ''
  );

  msgs: Message[] = [];
  peopleCols: any[];
  adUserCols: any[]; 

  public userRequestedData: UserRequestedData;
  public ParentComponent = '';
  public existingPeople: Person[] = [];
  public userSpecificData: Person[] = [];

  @Input()
  public existingPeopleString: string;

  @Output()
  public addUserDataClick: EventEmitter<UserRequestedData> = new EventEmitter<UserRequestedData>();

  constructor(private _commonService: CommonService) {
  }

  ngOnInit() {
    this.peopleCols = [
      { field: 'DisplayName', header: 'Name' },
      { field: 'FirstName', header: 'First Name' },
      { field: 'LastName', header: 'Last Name' },
      { field: 'Email', header: 'Email' },
      { field: 'LoginName', header: 'Login Name' }
    ];

    this.adUserCols = [
      { field: 'DisplayName', header: 'Name', isSortable: true }
    ]; 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.existingPeopleString) {
      this.userRequestedData = JSON.parse(this.existingPeopleString);

      this.ParentComponent = this.userRequestedData.ParentComponent;
      this.existingPeople = this.userRequestedData.person;

      this.selPeopleData = this.existingPeople;
      this.selPeopleData = this.selPeopleData.slice();
    }
  }

  valueChangeFName(event: any) {
    if (event.key === 'Enter') {
      this.getLDAPUsers();
    }
  }

  valueChangeLName(event: any) {
    if (event.key === 'Enter') {
      this.getLDAPUsers();
    }
  }

  getLDAPUsers() {
    let FirstName: string = "";
    let LastName: string = "";
    let LoginName: string = "";

    if (this.firstName && this.firstName.trim().length >= 2) {
      FirstName = this.firstName;
    }
    
    if (this.lastName && this.lastName.trim().length >= 2) {
      LastName = this.lastName;
    }

    if(this.loginName && this.loginName.trim()) {
      LoginName = this.loginName.trim();
    }

    const queryString = `firstName=${FirstName}&lastName=${LastName}&loginName=${LoginName}`;
    this.people = [];
    this._commonService.getLDAPUsers(queryString)
      .subscribe(response => {
          this.people = response.filter(p=>p.LEID !== null);
        }
      );
  }

  SendPeopleData() {
    this.firstName = '';
    this.lastName = '';
    this.loginName = '';
    const personData = new Person();
    this.people = [];
    this.people = this.people.slice();

    this.userRequestedData = {
      person: this.selPeopleData, ParentComponent: this.ParentComponent,
      singleLead: this.userRequestedData.singleLead, ResponsibilityCode: this.userRequestedData.ResponsibilityCode
    };
    this.addUserDataClick.emit(this.userRequestedData);
    this.selPeopleData = [];
    this.userRequestedData = new UserRequestedData();
  }

  deleteUser(user: Person) {
    let idx = -1;

    for (let i = 0; i < this.selPeopleData.length; i++) {
      idx++;
      if (this.selPeopleData[i].LoginName === user.LoginName) {
        break;
      }
    }

    if (idx !== -1) {
      this.selPeopleData.splice(idx, 1);
      this.selPeopleData = this.selPeopleData.slice();
    }
  }
}