<div class="grid" #dvCategories [hidden]="!(isROVAdmin)" style="width:100%">

  <p-panel id="pnlCategories" class="margin titleHide" appendTo="body">

    <p-header [style]="{'width':'100%'}">
      <div class="grid">
        <div class="col-10">
          <b>Manage Categories</b>
        </div>
        <div class="col-2">
          <button id="btnMgrExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
            label="Export" (click)="exportCSVForCategoryManager()" style="float: right"></button>
        </div>
      </div>
    </p-header>

    <p-table id="dtCategories" #dt [value]="categories" sortMode="single" [scrollable]="true"
      scrollHeight="530px" [loading]="loading"
      [totalRecords]="categories.length" (onFilter)="filterCategoryDataTable($event)" (onEditInit)="onEditInit($event)"
      (onEditComplete)="onEditComplete($event)" columnResizeMode="expand" [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th hidden>GUID</th>
          <th>Heading / Record</th>
          <th>Record Active</th>
          <th>Included in File</th>
          <th>Units</th>
          <th>Definition</th>
          <th>Order</th>
          <th>Comment</th>
        </tr>
        <tr>
          <th hidden>GUID</th>
          <th>
            <input id="txtName" [(ngModel)]="filteredName" pInputText type="text"
              (input)="dt.filter($any($event.target).value, 'Name', 'contains')" style="width:100%"
              placeholder="Search">
          </th>
          <th></th>
          <th></th>
          <th>
            <input id="txtUnits" [(ngModel)]="filteredUnits" pInputText type="text"
              (input)="dt.filter($any($event.target).value, 'Units', 'contains')" style="width:100%"
              placeholder="Search">
          </th>
          <th>
            <input id="txtDefinition" [(ngModel)]="filteredDefinition" pInputText type="text"
              (input)="dt.filter($any($event.target).value, 'Definition', 'contains')" style="width:100%"
              placeholder="Search">
          </th>
          <th>
            <input id="txtOrder" [(ngModel)]="filteredOrder" pInputText type="text"
              (input)="dt.filter($any($event.target).value, 'Order', 'contains')" style="width:100%"
              placeholder="Search">
          </th>
          <th>
            <input id="txtComment" [(ngModel)]="filteredComment" pInputText type="text"
              (input)="dt.filter($any($event.target).value, 'Comment', 'contains')" style="width:100%"
              placeholder="Search">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
        <tr>
          <td hidden>{{category.GUID}}</td>
          <td [pEditableColumn]="category" [pEditableColumnField]="'Name'" [pEditableColumnRowIndex]="rowIndex">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input id="txtMgrRecord" pInputText type="text" maxlength="75" [(ngModel)]="category.Name" 
                  (blur)="dataTableOnBlur($event, 'Name', category, rowIndex, dt)"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{category.Name}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td [pEditableColumn]="category" [pEditableColumnField]="'IsActive'" [pEditableColumnRowIndex]="rowIndex">
            <input id="cbMgrRecordActive2" type="checkbox" [(ngModel)]="category.IsActive"
              (blur)="dataTableOnBlur($event, 'IsActive', category, rowIndex, dt)" />
          </td>
          <td [pEditableColumn]="category" [pEditableColumnField]="'IsIncluded'" [pEditableColumnRowIndex]="rowIndex">
            <input id="cbMgrIncluded2" type="checkbox" [(ngModel)]="category.IsIncluded"
              (blur)="dataTableOnBlur($event, 'IsIncluded', category, rowIndex, dt)" />
          </td> 

          <td [pEditableColumn]="category" [pEditableColumnField]="'Units'" [pEditableColumnRowIndex]="rowIndex">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input id="txtMgrUnits" pInputText type="text" maxlength="75" [(ngModel)]="category.Units" 
                  (blur)="dataTableOnBlur($event, 'Units', category, rowIndex, dt)"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{category.Units}}
              </ng-template>
            </p-cellEditor>
          </td> 

          <td [pEditableColumn]="category" [pEditableColumnField]="'Definition'" [pEditableColumnRowIndex]="rowIndex">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input id="txtMgrDefinition" pInputText type="text" maxlength="75" [(ngModel)]="category.Definition" 
                  (blur)="dataTableOnBlur($event, 'Definition', category, rowIndex, dt)"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{category.Definition}}
              </ng-template>
            </p-cellEditor>
          </td>
         
          <td [pEditableColumn]="category" [pEditableColumnField]="'Order'" [pEditableColumnRowIndex]="rowIndex">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input id="txtMgrOrder" pInputText type="text" maxlength="75" [(ngModel)]="category.Order" 
                  (blur)="dataTableOnBlur($event, 'Order', category, rowIndex, dt)"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{category.Order}}
              </ng-template>
            </p-cellEditor>
          </td> 

          <td [pEditableColumn]="category" [pEditableColumnField]="'Comment'" [pEditableColumnRowIndex]="rowIndex">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input id="txtMgrComment" pInputText type="text" maxlength="75" [(ngModel)]="category.Comment" 
                  (blur)="dataTableOnBlur($event, 'Comment', category, rowIndex, dt)"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{category.Comment}}
              </ng-template>
            </p-cellEditor>
          </td> 
        </tr>
      </ng-template>
    </p-table>

    <p-footer>
      <div class="grid">
        <div class="col-8"></div>
        <div class="col-2">
          <button id="btnMgrAdd" type="button" pButton icon="fa fa-plus-circle" class="ui-button-primary"
            (click)="addNewCategory(dt)" iconPos="left" label="Add New" title="Add New Category"></button>
        </div>
        <div class="col-2">
          <button id="btnMgrSave" type="button" pButton icon="fa fa-save" class="p-button-success"
            (click)="saveCategories()" iconPos="left" label="Save Categories" title="Save Categories"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</div>

<div class="grid" [hidden]="hideMessage">
  <div class="col-3"></div>
  <div class="col-6">
    <h3>
      <span [hidden]="hideMessage">
        You don't have access to the
        <b>Category Manager</b> screen.
        <br />
        <a href="/">Click Here</a> to go to Home Screen.
      </span>
    </h3>
  </div>
  <div class="co-3"></div>
</div>