<div id="dvRegionGrid">
  <p-table #dt [columns]="cols" id="dtRegions" [value]="regionAllData" dataKey="RegionID" sortMode="single"
      [reorderableColumns]="true" [scrollable]="true" scrollHeight="530px"
      columnResizeMode="fit" exportFilename="ResearchCommercialZone" (onFilter)="filterRegionDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="regionAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption" id="dtRegionHeader">
      <div class="ui-helper-clearfix align-center">
        <p-button label="Research Commercial Zone Count" icon="fa fa-globe" styleClass="p-button-outlined p-button-secondary" badge="{{regionAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
        <button id="btnRegionExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
          label="Export" (click)="exportCSVForRegions()" style="float:right"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 1.25em"></th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <p-multiSelect id="msFilterRegionCode" *ngSwitchCase="'RegionCode'" [(ngModel)]="filteredRegionCode"
            [resetFilterOnHide]="true" [options]="distinctCodes" defaultLabel="All RCZs"
            (click)="$event.stopPropagation();" appendTo="body"
            (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterRegionName" *ngSwitchCase="'RegionName'" [(ngModel)]="filteredRegionName" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterRegionCropName" *ngSwitchCase="'CropDescription'"
            [(ngModel)]="filteredRegionCropDescription" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterRegionDescription" *ngSwitchCase="'Description'" [(ngModel)]="filteredRegionDescription"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterRegionLead" *ngSwitchCase="'RegionLeadNames'" [(ngModel)]="filteredRegionLeadNames"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterRegionStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredRegionRequestStatus"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
      <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
        <td style="width: 1.25em">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'"
              style="font-size: 1.25em"></i>
          </a>
        </td>
        <ng-container *ngFor="let col of columns">
          <td *ngIf="!col.hidden">
            {{rowData[col.field]}}
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template let-region pTemplate="rowexpansion" id="rowRegionexpand" let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="grid">
            <div class="col">
              <p-panel class="height" id="editRegionPanel1">
                <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label id="lblRegionName">RCZ Name :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valRegionName">{{region.RegionName}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblRegionCode">RCZ Code :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valRegionCode">{{region.RegionCode}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblRegionLeadName">RCZ Lead :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valRegionLeadName">{{region.RegionLeadNames ? region.RegionLeadNames : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label style="word-wrap: break-word" id="lblDescription">Description : </label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valDescription">{{region.Description}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblCrop">Crop :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valCropDescription">{{region.CropDescription}}</p>
                    </div>
                    <div class="col-6">
                      <label id="lblStatus">Status :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap" id="valStatus">{{region.RequestStatus}}</p>
                    </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height" id="editRegionPanel2">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label id="lblColor">Color :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valColor">{{region.Color ? region.Color : '-'}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height" id="editRegionPanel3">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label id="lblPrismAOIID">PRISM AOI ID :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valPrismAOIID">{{region.PRISM_AOI_ID ? region.PRISM_AOI_ID : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblCreatedBy">Created By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valCreatedBy">{{region.CreatedByUserName}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateCreated">Date Created :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateCreated">{{ConvertDateToString(region.CreatedOn)}}
                    </p>
                  </div>
                  <div class="col-6">
                    <label id="lblApprovedBy">Approved By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valApprovedBy">{{region.ApprovedByUserName ? region.ApprovedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateApproved">Date Approved :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="dateApproved">{{ConvertDateToString(region.ApprovedOn)}}
                    </p>
                  </div>
                  <div class="col-6">
                    <label id="lblRetiredBy">Retired By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valRetiredBy">{{region.RetiredByUserName ? region.RetiredByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateRetired">Date Retired :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateRetired">{{ConvertDateToString(region.RetiredOn)}}
                    </p>
                  </div>
                  <div class="col-6">
                    <label id="lblLastModified">Last Modified By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valLastModified">{{region.ModifiedByUserName ? region.ModifiedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label id="lblDateLastModified">Date Last Modified :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap" id="valDateLastModified">
                      {{ConvertDateToString(region.ModifiedOn)}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-2">
              <button pButton id="btnRegionComments" type="button" style="width: 100%"
                (click)="viewComments(region)"
                label="{{region.ApprovalComments === null || region.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                iconPos="left"
                [icon]="region.ApprovalComments === null || region.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                class="p-button-info"></button>
            </div>
            <div class="col-2">
              <div
                [hidden]="!((region.CreatedBy !== null && region.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                <button pButton id="btnRegionEdit" type="button" style="width: 100%" (click)="editRegions(region)"
                  label="Edit" iconPos="left" icon="fa fa-fw fa-edit"></button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="6">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
  showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
   [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<div>
  <p-dialog id="dlgRegionEdit" [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible" [breakpoints]="{'640px':'100vw'}"
    showEffect="fade" [modal]="true" (onHide)="onCloseEditRegion($event, frm)"
    [closeOnEscape]="false" [closable]="false" [showHeader]="true" header="Research Commercial Zone Details">
    <p-panel id="editRegionPanel4" [style]="{'height': panelHeight, 'overflow': 'auto', 'width':'850px'}" appendTo="body">
      <form #frm="ngForm" id="frmRegion" [formGroup]="regionFormGroup">
        <div class="grid">
          <div class="col-6">
            <div class="grid">
              <div class="col-5 controlLabel">
                <label id="lblEditCrop">Crop</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddRegionCrop" [style]="{'width':'100%'}" name="Crop" [filter]=true
                  [options]="regionCropName" [(ngModel)]="regionForm.CropID" formControlName="CropID"
                  placeholder="Select a Crop" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label id="lblEditRegionName">RCZ Name</label>
              </div>
              <div class="col-7">
                <input pInputText id="txtRegionName" [(ngModel)]="regionForm.RegionName"
                  formControlName="RegionName" minlength="1" maxlength="25" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label id="lblEditRegionCode">RCZ Code</label>
              </div>
              <div class="col-7">
                <input pInputText id="txtRegionCode" minlength="2" maxlength="4"
                  [(ngModel)]="regionForm.RegionCode" formControlName="RegionCode" styleClass="width100"
                  (keydown)="regionForm.PRISM_AOI_ID = ''" />
              </div>
              <div class="col-5 controlLabel">
                <label id="lblEditDescription">Description</label>
              </div>
              <div class="col-7">
                <textarea id="txtRegionDescription" [cols]="30" [rows]="3" [(ngModel)]="regionForm.Description"
                  formControlName="Description" pInputTextarea styleClass="width100" class="p-inputtext"></textarea>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="grid">
              <div class="col-5 controlLabel">
                <label id="lblEditColor">Color</label>
              </div>
              <div class="col-7">
                <input type="number" pInputText id="txtRegionColor" [(ngModel)]="regionForm.Color"
                  formControlName="Color" maxlength="9" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label id="lblEditRegionlead">RCZ Lead</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstRegionLead" [options]="regionLead" optionLabel="label" [(ngModel)]="regionForm.RegionLeadNames"
                        formControlName="RegionLeadNames" styleClass="width100" [style]="{'min-height':'35px'}"
                        [listStyle]="{'max-height':'100px'}">
                      </p-listbox>
                  </div>
                  <div class="col-2">
                    <button type="button" id="btnRegionAddLead" pButton icon="fa fa-user-plus"
                      class="p-button-info" style="width: 30px" (click)="addLeads()"
                      title="Add RCZ Lead"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label id="lblEditStatus">Status</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddRegionStatus" [style]="{'width':'100%'}" name="Status" [filter]=true
                  [options]="status" [(ngModel)]="regionForm.RequestStatus" formControlName="RequestStatus"
                  placeholder="Select a Status" (onChange)="statusChange(regionForm.RequestStatus,true)"
                  appendTo="body"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label id="lblPRISM_AOI_ID">PRISM AOI ID</label>
              </div>
              <div class="col-7">
                <input type="number" pInputText id="txtRegionPRISM_AOI_ID" [(ngModel)]="regionForm.PRISM_AOI_ID"
                  formControlName="PRISM_AOI_ID" maxlength="9" styleClass="width100" readonly="readonly" />
              </div>
              <div class="col-5">
              </div>
              <div class="col-7">
                <button id="btnRegionCheckAOI" type="button" pButton icon="fa fa-eye" class="p-button-info" (click)="GetAOIID(regionForm)"
                  label="Check AOI" title="Check AOI"></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </p-panel>
    <ng-template pTemplate="footer">
        <div [hidden]="!isROVUser" style="display: inline;">
          <button id="btnRegionCancel" type="button" pButton icon="fa fa-close" class="p-button-warning"
            (click)="close(frm)" label="Cancel" style='width: 200px;'>
          </button>
        </div>
        <button id="btnRegionSave" type="submit" pButton [icon]="saveIcon" [ngClass]="saveIconClass"
          (click)="saveRegion(frm, regionForm.RequestStatus)" label="{{saveLabelText}}"
          style='width: 200px;'></button>
    </ng-template>
  </p-dialog>
</div>

<p-dialog id="dlgRegionLead" header="Select RCZ Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayRegionLead"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker id="appRegionPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgRegionAOIID" header="Select AOI ID" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
  [(visible)]="displayLEIDWindow" showEffect="fade" [modal]="true" [closeOnEscape]="true"
  [closable]="true" [resizable]="false">
  <app-leidpicker id="appRegionLeidPicker" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
</p-dialog>
