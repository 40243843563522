import { SeedStorageLocationTypeModel } from './seedStorageLocationTypeModel';
import { ApprovalComments } from './approvalComments';
import { SeedStorageLocationKeyContact } from './keyContactPerson';
import { Person } from './person';

export class SeedStorageLocationModel {
    public SeedStorageLocationID: string;
    public Name: string;
    public StationId: string;
    public StationCode: string;
    public StationName: string;
    public SeedStorageLocationTypeId: string;
    public SeedStorageLocationTypeCode: string;
    public SeedStorageLocationTypeName: string;
    public CropID: string;
    public CropName: string;
    public CropDescription: string;

    //public CityId: number;
    //public City: string;
    //public LocalCityId: number;
    //public LocalCity: string;

    //public CountyId: number;
    // public County: string;
    //public LocalCountyId: number;
    //public LocalCounty: string;

    //public StateId: number;
    public State: string;
    //public LocalStateId: number;
    //public LocalState: string;

    //public CountryId: number;
    public Country: string;
    //public LocalCountryId: number;
    //public LocalCountry: string;
    public SeedStorageLocationKeyContactNames: string;
    public SeedStorageLocationKeyContacts: SeedStorageLocationKeyContact[] = [];
    public Description: string;
    public StatusId: number;
    public RequestStatus: string;
    public IsVirtual = false;
    public R3BuildingCode: string;
    public WWSAddessId: string;
    public WWSShippingProject: string;
    public PrismLeId: string;
    public Station: any;
    public SeedStorageLocationType: any;
    public PRISM_AOI_ID: number;
    public ApprovalComments: ApprovalComments[] = [];
    public CreatedBy: Person;
    public CreatedByUserName: string;
    public CreatedOn: Date;
    public ModifiedBy: Person;
    public ModifiedByUserName: string;
    public ModifiedOn: Date;
    public ApprovedBy: Person;
    public ApprovedByUserName: string;
    public ApprovedOn: Date;
    public RetiredByUserName: string;
    public RetiredBy: Person;
    public RetiredOn: Date;
    public BaseSeedStorageLocationID: string;
    //public GeographicLocale:string|null;
}
