
import { CropModel } from '.././shared/cropModel';
import { RegionModel } from '.././shared/regionModel';
import { EvaluationModel } from './evaluationModel';
import { ProgramsModel } from './programsModel';
import { CountriesModel } from './countriesModel';
import { StationsModel } from './stationsModel';

export class CreateFileModel {
  public GUID: string;
  public Title: string;
  public Crops: CropModel[] = [];
  public CropNames: string[] = [];
  public Countries: CountriesModel[] = [];
  public CountryNames: string[] = [];
  public Regions: RegionModel[] = [];
  public RegionNames: string[] = [];
  public Programs: ProgramsModel[] = [];
  public ProgramNames: string[] = [];
  public EvaluationZones: EvaluationModel[] = [];
  public EvaluationZoneNames: string[] = [];
  public Stations: StationsModel[] = [];
  public StationNames: string[] = [];
  public CreatedBy: string;
  public CreatedOn: string;
}
