import { BaseComponent } from './../basecomponents/base.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, SimpleChanges } from '@angular/core';
import { AllocationEZProgramForm } from './../shared/allocationEZProgramFormControls';
import { AllocationEZProgramModel } from './../shared/allocationEZProgramModel';
import { AllocationEZProgramTypeModel } from './../shared/allocationEZProgramTypeModel';
import { ProgramModel } from './../shared/programModel';
import { EvaluationZoneModel } from './../shared/evaluationZoneModel';
import { AllocationEZProgramService } from '../allocation-ezprogram/allocation-ezprogram.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Constants } from './../shared/constants';
import { BaseService } from '../basecomponents/base.service';
import {SharedService} from './../services/shared.service';

@Component({
  selector: 'app-allocation-ezprogram',
  templateUrl: './allocation-ezprogram.component.html',
  styleUrls: ['./allocation-ezprogram.component.css']
})
export class AllocationEZProgramComponent extends BaseComponent {
  public allocationEZProgramData: AllocationEZProgramModel[] = [];
  public filteredData: AllocationEZProgramModel[] = [];
  public selectedData: AllocationEZProgramModel[] = [];
  public selectedRow: AllocationEZProgramModel;
  public panelHeight = '500px';
  dialogVisible: boolean;
  IsCropListDisabled: boolean;

  public allocationEZProgramFormGroup: FormGroup;
  public allocationEZProgramForm = new AllocationEZProgramForm('', '', '', '', false);
  public allPrograms: ProgramModel[] = [];
  public program: ProgramModel[] = [];
  public allEvalutionZones: EvaluationZoneModel[] = [];
  public evalutionZones: EvaluationZoneModel[] = [];
  public allocationEZProgramTypes: AllocationEZProgramTypeModel[] = [];

  public filteredCrop: string; 
  public filteredEvalZoneCode: string; 
  public filteredProgramCode: string; 
  public filteredProgramPurpose: string; 
  public filteredAllocation: string;

  constructor(private _allocationEZProgramService: AllocationEZProgramService, private _fb: FormBuilder, 
    public override _baseService: BaseService, private _confirmation: ConfirmationService,
    _messageService: MessageService, sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.getAllocationEZProgramData();
    this.buildForm();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    //no roles needed here
    let i = 0;
  }

  buildForm(): void {
    this.allocationEZProgramFormGroup = this._fb.group({
      'CropID': [this.allocationEZProgramForm.CropID, Validators.required],
      'EvaluationZodeId': [this.allocationEZProgramForm.EvaluationZodeId, Validators.required],
      'ProgramId': [this.allocationEZProgramForm.ProgramId, Validators.required],
      'AllocationEZProgramTypeId': [this.allocationEZProgramForm.AllocationEZProgramTypeId, Validators.required],
      'IsExcluded': [this.allocationEZProgramForm.IsExcluded, Validators.required]
    });
  }

  getAllocationEZProgramData() {
    this.loading = true;
    this._allocationEZProgramService.getAllocationEZProgramData().subscribe({
      next: Data => {
        this.filteredData = this.allocationEZProgramData = Data.AllocationEZProgramData;

        if (Data.Crops && Data.Crops.length > 0) {
          this.crops = Data.Crops;
          this.crops.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (Data.Programs && Data.Programs.length > 0) {
          this.allPrograms = Data.Programs;
          this.allPrograms.forEach(element => {
            element.value = element.ProgramId;
            element.label = element.ProgramCode;
          });
        }

        if (Data.EvalutionZones && Data.EvalutionZones.length > 0) {
          this.allEvalutionZones = Data.EvalutionZones;
          this.allEvalutionZones.forEach(element => {
            element.value = element.EvaluationZodeId;
            element.label = element.EvaluationZodeCode;
          });
        }

        if (Data.AllocationEZProgramType && Data.AllocationEZProgramType.length > 0) {
          this.allocationEZProgramTypes = Data.AllocationEZProgramType;
          this.allocationEZProgramTypes.forEach(element => {
            element.value = element.AllocationEZProgramTypeId;
            element.label = element.Name;
          });
        }

        this.loading = false;
      },
     error: error => {
        this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        this.loading = false;
      }
  });
    this.SelectMenuOption('Allocate');
  }

  filterDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  editAllocationEZProgram(row: AllocationEZProgramModel) {
    this.selectedRow = row;
    this.buildForm();
    this.allocationEZProgramForm = Object.assign({ CropID: row.CropID },
      { EvaluationZodeId: row.EvaluationZodeId },
      { ProgramId: row.ProgramId },
      { AllocationEZProgramTypeId: row.AllocationEZProgramTypeId },
      { IsExcluded: row.IsExcluded });

    this.program = this.allPrograms.filter(s => s.CropID === row.CropID);
    this.evalutionZones = this.allEvalutionZones.filter(s => s.CropID === row.CropID);
    this.IsCropListDisabled = true;
    this.dialogVisible = true;
  }

  addAllocationEZProgram() {
    this.buildForm();
    this.allocationEZProgramForm = Object.assign({ CropID: '' },
      { EvaluationZodeId: '' },
      { ProgramId: '' },
      { AllocationEZProgramTypeId: '' },
      { IsExcluded: false });

    this.program = [];
    this.evalutionZones = [];
    this.IsCropListDisabled = false;
    this.dialogVisible = true;
  }

  getProgramAndEvaluationByCropID() {
    this.program = this.allPrograms.filter(s => s.CropID === this.allocationEZProgramForm.CropID);
    this.evalutionZones = this.allEvalutionZones.filter(s => s.CropID === this.allocationEZProgramForm.CropID);
  }

  close(frm: any) {
    this.dialogVisible = false;
  }

  checked(row: any) {
    if (row) {
      const index = this.selectedData.findIndex(s => s.AllocationEZProgramId === row.AllocationEZProgramId);
      if (index === -1) {
        this.selectedData.push(row);
      } else {
        this.selectedData.splice(index, 1);
      }
    }
  }

  rowSelected(event: any) {
  }

  saveAllocationEZPrograms() {
    this._allocationEZProgramService.saveAllocationEZPrograms(JSON.stringify(this.selectedData)).subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            this.DisplaySuccessMessage('Success', Result.Message);
            this.dialogVisible = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
  });
  }

  saveAllocationEZProgram(frm: any) {
    const rowToSave = new AllocationEZProgramModel();

    if (this.IsCropListDisabled) {
      rowToSave.AllocationEZProgramId = this.selectedRow.AllocationEZProgramId;
      rowToSave.IsEditable = true;
    } else {
      rowToSave.AllocationEZProgramId = '00000000-0000-0000-0000-000000000000';
      rowToSave.IsEditable = false;
    }

    rowToSave.CropID = this.allocationEZProgramForm.CropID;
    rowToSave.Crop = this.crops.find(s => s.CropID === this.allocationEZProgramForm.CropID)!.CropName;
    rowToSave.ProgramId = this.allocationEZProgramForm.ProgramId;
    rowToSave.EvaluationZodeId = this.allocationEZProgramForm.EvaluationZodeId;
    rowToSave.AllocationEZProgramTypeId = this.allocationEZProgramForm.AllocationEZProgramTypeId;
    rowToSave.IsExcluded = this.allocationEZProgramForm.IsExcluded;

    this._allocationEZProgramService.saveAllocationEZProgramData(JSON.stringify(rowToSave))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const program = this.allPrograms
              .find(s => s.ProgramId === this.allocationEZProgramForm.ProgramId)!;

            if (this.IsCropListDisabled) {
              this.selectedRow.IsExcluded = this.allocationEZProgramForm.IsExcluded;
              this.selectedRow.ProgramCode = program.ProgramCode;
              this.selectedRow.ProgramPurpose = program.ProgramPurpose;

              this.selectedRow.EvalZoneCode = this.evalutionZones
              .find(s => s.EvaluationZodeId === this.allocationEZProgramForm.EvaluationZodeId)!.EvaluationZodeCode;

            } else {
              rowToSave.ProgramCode = program.ProgramCode;
              rowToSave.ProgramPurpose = program.ProgramPurpose;

              rowToSave.EvalZoneCode = this.evalutionZones
                .find(s => s.EvaluationZodeId === this.allocationEZProgramForm.EvaluationZodeId)!.EvaluationZodeCode;

              rowToSave.AllocationEZProgramId = Result.ID;
              rowToSave.IsEditable = true;
              rowToSave.Allocation = 'No';
              this.allocationEZProgramData.splice(0, 0, rowToSave);
              this.filteredData = this.allocationEZProgramData = this.allocationEZProgramData.slice();
            }

            this.DisplaySuccessMessage('Success', Result.Message);
            this.dialogVisible = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
  });
  }

  deleteAllocationEZProgram(row: AllocationEZProgramModel, rowIndex: number) {
    this._confirmation.confirm({
      message: Constants.CONFIRMDELETEALLOCATIONEZPROGRAM,
      accept: () => {
        this._allocationEZProgramService.deleteAllocationEZProgramData(JSON.stringify(row)).subscribe({
            next: Result => {
              if (Result.Status === 'SUCCESS') {
                this.allocationEZProgramData.splice(rowIndex, 1);
                this.filteredData = this.allocationEZProgramData = this.allocationEZProgramData.slice();
                this.DisplaySuccessMessage('Success', Result.Message);
              } else {
                this.DisplayErrorMessage('Error', Result.Message);
              }
            },
            error: error => {
              if (error === '') {
                this.DisplayErrorMessage('Error', Constants.NOSERVICE);
              } else {
                this.DisplayErrorMessage('Error', error);
              }
            }
      });
          }
    });
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const offsetHeight = document.getElementById('dvAllocationEZProgramGrid')!.offsetTop;
    this.panelHeight = ((viewPortHeight) - (offsetHeight) - (300)).toString() + 'px';
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      return 10;
    }
  }

  exportCSVForAllocationEZProgram() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._allocationEZProgramService.exportCSVForAllocationEZProgram(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel' });
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'AllocationEZProgramData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
        error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
  });
  }
}