import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BreedingZoneResultModel } from './../shared/breedingZoneResultModel';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Constants, RequestStatus, UserRequestedData } from '../shared';
import { UserClaim} from "../shared/userClaim";
import { AppService } from '../app.service';
import { BreedingZoneService } from './breedingzone.service';

import { CropModel } from './../shared/cropModel';
import { RegionModel } from './../shared/regionModel';
import { BreedingZoneType } from './../shared/breedingZoneType';
import { CommonService } from './../shared/common.service';
import { Message } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Person } from './../shared/person';
import { ApprovalComments } from '.././shared/approvalComments';
import { BreedingZoneForm } from './../shared/breedingZoneFormControls';
import { BreedingZoneCombo } from '../shared/breedingZoneCombo';

import { LERequestedData } from './../shared/lERequstedData';
import { ExperimentProjectCodeResult } from './../shared/experimentProjectCodeResult';
import { LocationProjectCodeResult } from './../shared/locationProjectCodeResult';
import { ProjectLEResult } from './../shared/projectLEResult';
import { SSLLEResult } from './../shared/sSLLEResult';
import { StationLEResult } from './../shared/stationLEResult';
import {SharedService} from './../services/shared.service';

@Component({
  selector: 'app-breedingzone',
  templateUrl: './breedingzone.component.html',
  styleUrls: ['./breedingzone.component.scss']
})
export class BreedingZoneComponent implements OnChanges {

  public _approvalComments: ApprovalComments = new ApprovalComments();
  public BreedingZoneID: string;

  expandedItems: any[] = [];
  msgs: Message[] = [];
  status: SelectItem[] = [];
  displayComments: boolean;
  displayBreedingZoneLead: boolean;
  dialogVisible: boolean;
  selectedStatus: string;
  sourceIdentifier: string;
  public bzLead: SelectItem[] = [];
  public breedingZoneAllData: BreedingZoneResultModel[] = [];
  public filteredData: BreedingZoneResultModel[] = [];
  public breedingZoneTypeName: BreedingZoneType[] = [];
  public breedingZoneCropName: CropModel[] = [];
  public breedingZoneFormGroup: FormGroup;
  public breedingZoneData: BreedingZoneResultModel = new BreedingZoneResultModel();
  public approvalCommentData: any[] = [];
  public existingPeopleString = '';
  public existingPeople: Person[] = [];
  public regionName: RegionModel[] = [];
  public breedingZoneCodes: BreedingZoneCombo[] = [];
  public parentComponent = 'BreedingZone';
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = true;
  loading: boolean;
  cols: any[];
  public disableCommentButton = false;
  public distinctCodes: SelectItem[];
  public sortedCodes: SelectItem[];
  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  public filteredBZCode: string;
  public filteredBZName: string;
  public filteredBZCropDescription: string;
  public filteredCRMLower: string;
  public filteredCRMUpper: string;
  public filteredPBreedingZoneLeadNames: string;
  public filteredBZRequestStatus: string;

  public displayLEIDWindow = false;
  public existingLEIDString = '';
  public aoiIDs: number[] = [];
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = [];
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public panelHeight = '350px';
  public resizeIcon = 'fa fa-expand';
  public height = 580;
  public width = 1200;
  public positionLeft = '50px';
  public positionTop = '40px';
  public top = 0;
  public left = 0;

  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  public breedingZoneForm = new BreedingZoneForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    0,
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;

  constructor(private route: ActivatedRoute, private _appService: AppService,
    private _breedingZoneService: BreedingZoneService, private _commonService: CommonService,
    private _confirmation: ConfirmationService, private _fb: FormBuilder, private sharedservice: SharedService) {
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.breedingZoneFormGroup = this._fb.group({
        'BreedingZoneName': [this.breedingZoneForm.BreedingZoneName, Validators.required],
        'BreedingZoneCode': [this.breedingZoneForm.BreedingZoneCode, Validators.required],
        'BreedingZoneLeadNames': [this.breedingZoneForm.BreedingZoneLeadNames],
        'Description': [this.breedingZoneForm.Description],
        'Color': [this.breedingZoneForm.Color],
        'CropID': [this.breedingZoneForm.CropID, Validators.required],
        'CropName': [this.breedingZoneForm.CropName],
        'CropDescription': [this.breedingZoneForm.CropDescription],
        'Region': [this.breedingZoneForm.Region],
        'CRMUpper': [this.breedingZoneForm.CRMUpper],
        'CRMLower': [this.breedingZoneForm.CRMLower],
        'BreedingZoneTypeID': [this.breedingZoneForm.BreedingZoneTypeID],
        'BreedingZoneTypeCode': [this.breedingZoneForm.BreedingZoneTypeCode],
        'BreedingZoneTypeName': [this.breedingZoneForm.BreedingZoneTypeName],
        'RequestStatus': [this.breedingZoneForm.RequestStatus],
        'PRISM_AOI_ID': [this.breedingZoneForm.PRISM_AOI_ID, this.breedingZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.breedingZoneForm.ApprovalComments]
      });
    } else {
      this.breedingZoneFormGroup = this._fb.group({
        'BreedingZoneName': [this.breedingZoneForm.BreedingZoneName, Validators.required],
        'BreedingZoneCode': [this.breedingZoneForm.BreedingZoneCode, Validators.required],
        'BreedingZoneLeadNames': [this.breedingZoneForm.BreedingZoneLeadNames],
        'Description': [this.breedingZoneForm.Description],
        'Color': [this.breedingZoneForm.Color],
        'CropID': [this.breedingZoneForm.CropID, Validators.required],
        'CropName': [this.breedingZoneForm.CropName],
        'CropDescription': [this.breedingZoneForm.CropDescription],
        'Region': [this.breedingZoneForm.Region],
        'CRMUpper': [this.breedingZoneForm.CRMUpper],
        'CRMLower': [this.breedingZoneForm.CRMLower],
        'BreedingZoneTypeID': [this.breedingZoneForm.BreedingZoneTypeID],
        'BreedingZoneTypeCode': [this.breedingZoneForm.BreedingZoneTypeCode],
        'BreedingZoneTypeName': [this.breedingZoneForm.BreedingZoneTypeName],
        'RequestStatus': [this.breedingZoneForm.RequestStatus],
        'PRISM_AOI_ID': [this.breedingZoneForm.PRISM_AOI_ID,  this.breedingZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.breedingZoneForm.ApprovalComments]
      });
    }
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 5) {
      this.getLoginInfo();
      this.buildForm();

      if( (this.cleanCache || (this.breedingZoneAllData == null || this.breedingZoneAllData.length === 0))
          && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 5)) {
        this.route.queryParams.subscribe(p=>{
          const breedingZoneID: string = p['BreedingZoneID'];
          this.selectedRequestID = breedingZoneID ?? '';
        })

        this.cols = [
          { field: 'BreedingZoneID', header: 'BZ ID', hidden: 'true', title: 'Breeding Zone ID' },
          { field: 'BreedingZoneCode', header: 'BZ Code', title: 'Breeding Zone Code' },
          { field: 'BreedingZoneName', header: 'BZ Name', title: 'Breeding Zone Name' },
          { field: 'CropDescription', header: 'Crop' },
          { field: 'CRMLower', header: 'CRM Lower' },
          { field: 'CRMUpper', header: 'CRM Upper' },
          { field: 'BreedingZoneLeadNames', header: 'BZ Leads', title: 'Breeding Zone Leads' },
          { field: 'RequestStatus', header: 'Status' }
        ];

        this.bindBreedingZoneDropdownLists();
        this.getBreedingZones();
        this.cleanUpCache.emit(false);
      }
    }
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  bindBreedingZoneDropdownLists() {
    this._breedingZoneService.bindBreedingZoneDropdownLists().subscribe({
      next:data => {
        if (data.BreedingZoneCodes && data.BreedingZoneCodes.length > 0) {
          this.breedingZoneCodes = [];

          data.BreedingZoneCodes.forEach((item: { Id: any; Code: string; Name: string; RequestStatus: any; CropID: any; }) => {
            this.breedingZoneCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              RequestStatus: item.RequestStatus,
              CropID: item.CropID,
              label: item.Code + ' - ' + item.Name,
              value: item.Id
            });
          });
        }

        if (data.Crops && data.Crops.length > 0) {
          this.breedingZoneCropName = data.Crops;
          this.breedingZoneCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (data.BreedingZoneTypes && data.BreedingZoneTypes.length > 0) {
          this.breedingZoneTypeName = data.BreedingZoneTypes;
          this.breedingZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  getBreedingZones() {
    this.loading = true;
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;

      this._breedingZoneService.getBreedingZoneResult().subscribe({
        next: response => {
          this.filteredData = this.breedingZoneAllData = response;
          if (response != null && this.selectedRequestID !== '') {
            const selectedItem = response.find(element => element.BreedingZoneID.toString());
            this.first = 0;
            if(!selectedItem){
              this.DisplayInfoMessage('Info', `The Breeding Zone
              requested in the URL either doesn't exist or is no more in Pending State.`);
            }
            else{
              let index = response.indexOf(selectedItem);
              const pageNumber = Math.ceil(++index / 10)
              this.first = (pageNumber - 1) * 10;
              this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
              this.editBreedingZones(selectedItem);
            }
          }
          this.distinctBZCodesDropdown();
          this.loading = false;
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }

  distinctBZCodesDropdown() {
    const items = this.breedingZoneAllData;

    this.distinctCodes = [];
    this.distinctCodes = items.map(x => { return { label: x.BreedingZoneCode, value: x.BreedingZoneCode } })
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
    this.distinctCodes = this.sortedCodes.slice();
  }

  expandRow(item: number) {
    let exists = false;
    this.expandedItems.forEach(x => {
      if (x.BreedingZoneID === this.breedingZoneAllData[item].BreedingZoneID) {
        exists = true;
      }
    });

    if (exists) {
      this.expandedItems.pop();
    } else {
      this.expandedItems.push(this.breedingZoneAllData[item]);
    }
  }

  editBreedingZones(breedingZone: BreedingZoneResultModel) {
    //this.setHeight();

    if (this.isROVAdmin) {
      this.breedingZoneFormGroup.controls['RequestStatus'].enable();
    } else {
      this.breedingZoneFormGroup.controls['RequestStatus'].disable();
    }

    this.buildForm();
    this.breedingZoneForm = Object.assign({}, breedingZone);
    this.dialogVisible = true;

    this.status = [];

    this.bzLead = [];
    if (breedingZone.BreedingZoneLeads != null) {
      breedingZone.BreedingZoneLeads.forEach(pr => {
        this.bzLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
      });
    }

    if (this.isROVAdmin) {
      this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
      this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
    }
    this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

    if (breedingZone.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }
      this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
      this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
    }

    this.selectedStatus = this.status.find(s => s.label === breedingZone.RequestStatus)?.value;
    this.breedingZoneForm.RequestStatus = this.selectedStatus;

    this.breedingZoneForm.BreedingZoneID = breedingZone.BreedingZoneID;
    this.breedingZoneForm.BaseBreedingZoneID = breedingZone.BaseBreedingZoneID;

    this.breedingZoneForm.ApprovalComments = [];
    this.breedingZoneFormGroup.controls['Comment'].setValue('');

    this.statusChange(this.breedingZoneForm.RequestStatus,false);

    if (this.isROVAdmin) {
      this.getLEData(this.breedingZoneForm.BreedingZoneCode);
    }
  }

  getLEData(code: any) {
    if (code) {
      const queryString = `code=${code}&type=${this.parentComponent}`;

      this.projectLEResult = [];
      this.stationLEResult = [];
      this.sSLLEResult = [];
      this.locationProjectCodeResult = [];
      this.experimentProjectCodeResult = [];
      this.aoiIDs = [];

      this._commonService.getLEAndAOIReportData(queryString)
        .subscribe({
          next: Result => {
            this.projectLEResult = Result.ProjectLE;
            this.projectLEResult = this.projectLEResult.slice();

            this.stationLEResult = Result.StationLE;
            this.stationLEResult = this.stationLEResult.slice();

            this.sSLLEResult = Result.SSLLE;
            this.sSLLEResult = this.sSLLEResult.slice();

            this.locationProjectCodeResult = Result.LocationProjectCode;
            this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

            if (this.sSLLEResult && this.sSLLEResult.length > 0) {
              this.sSLLEResult.forEach(s => {
                this.aoiIDs.push(s.AOIId);
              });
            }

            this.experimentProjectCodeResult = Result.ExperimentProjectCode;
            this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string, isChange: boolean) {
    if(this.breedingZoneForm.PRISM_AOI_ID=="0")
    {
      this.breedingZoneForm.PRISM_AOI_ID="";
    }
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.breedingZoneForm.RequestStatus === RequestStatus.RETIRED ||
      this.breedingZoneForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.breedingZoneFormGroup = this._fb.group({
        'BreedingZoneName': [this.breedingZoneForm.BreedingZoneName, Validators.required],
        'BreedingZoneCode': [this.breedingZoneForm.BreedingZoneCode, Validators.required],
        'BreedingZoneLeadNames': [this.breedingZoneForm.BreedingZoneLeadNames],
        'Description': [this.breedingZoneForm.Description],
        'Color': [this.breedingZoneForm.Color],
        'CropID': [this.breedingZoneForm.CropID, Validators.required],
        'CropName': [this.breedingZoneForm.CropName],
        'CropDescription': [this.breedingZoneForm.CropDescription],
        'Region': [this.breedingZoneForm.Region],
        'CRMUpper': [this.breedingZoneForm.CRMUpper],
        'CRMLower': [this.breedingZoneForm.CRMLower],
        'BreedingZoneTypeID': [this.breedingZoneForm.BreedingZoneTypeID, Validators.required],
        'BreedingZoneTypeCode': [this.breedingZoneForm.BreedingZoneTypeCode],
        'BreedingZoneTypeName': [this.breedingZoneForm.BreedingZoneTypeName],
        'RequestStatus': [this.breedingZoneForm.RequestStatus],
        'PRISM_AOI_ID': [this.breedingZoneForm.PRISM_AOI_ID, Validators.required],
        'Comment': [this.breedingZoneForm.ApprovalComments]
      });
    } else {
      this.buildForm();
    }
    if(isChange)
    this.breedingZoneFormGroup.markAsDirty();
  }

  saveBreedingZone(frm: any, status: string) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }

  save(frm: any, status: string) {
    const crop = this.breedingZoneCropName.find(s => s.value === this.breedingZoneForm.CropID);

    if (crop) {
      this.breedingZoneForm.CropID = crop.CropID;
      this.breedingZoneForm.CropName = crop.CropName;
      this.breedingZoneForm.CropDescription = crop.CropDescription;
    } else {
      this.breedingZoneForm.CropID = '';
      this.breedingZoneForm.CropName = '';
      this.breedingZoneForm.CropDescription = '';
    }

    const breedingZoneType = this.breedingZoneTypeName.find(s => s.value === this.breedingZoneForm.BreedingZoneTypeID);

    if (breedingZoneType) {
      this.breedingZoneForm.BreedingZoneTypeID = breedingZoneType.Id;
      this.breedingZoneForm.BreedingZoneTypeName = breedingZoneType.Name;
      this.breedingZoneForm.BreedingZoneTypeCode = breedingZoneType.Code;
    } else {
      this.breedingZoneForm.BreedingZoneTypeID = '';
      this.breedingZoneForm.BreedingZoneTypeName = '';
      this.breedingZoneForm.BreedingZoneTypeCode = '';
    }

    this.existingPeople = [];
    if (this.breedingZoneForm.BreedingZoneLeads) {
      this.breedingZoneForm.BreedingZoneLeads.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    this.breedingZoneData = Object.assign({}, this.breedingZoneForm);

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.breedingZoneData.RequestStatus = status;

      this._breedingZoneService.saveBreedingZone(JSON.stringify(this.breedingZoneData))
        .subscribe({
          next: (Result: { Status: string; BreedingZone: BreedingZoneResultModel; Message: string; }) => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.breedingZoneData.BreedingZoneID;
              this.breedingZoneData = Result.BreedingZone;

              const i = this.breedingZoneAllData.findIndex(s => s.BreedingZoneID === primaryID);
              const j = this.expandedItems.findIndex(s => s.BreedingZoneID === primaryID);
              const k = this.breedingZoneCodes.findIndex(s => s.Id === primaryID);

              if (Result.BreedingZone.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.breedingZoneAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.breedingZoneCodes.splice(k, 1);
                }
              } else {
                this.breedingZoneAllData[i] = this.breedingZoneData;
                this.expandedItems[j] = this.breedingZoneData;

                if (k !== -1) {
                  this.breedingZoneCodes.splice(k, 1,
                    ({
                      Id: this.breedingZoneData.BreedingZoneID,
                      Code: this.breedingZoneData.BreedingZoneCode,
                      Name: this.breedingZoneData.BreedingZoneName,
                      RequestStatus: this.breedingZoneData.RequestStatus,
                      CropID: this.breedingZoneData.CropID,
                      label: this.breedingZoneData.BreedingZoneCode + ' - ' + this.breedingZoneData.BreedingZoneName,
                      value: this.breedingZoneData.BreedingZoneID
                    }));
                }

                this.cleanUpCache.emit(true);
              }

              this.breedingZoneCodes = this.breedingZoneCodes.slice();
              this.expandedItems = this.expandedItems.slice();
              this.breedingZoneAllData = this.breedingZoneAllData.slice();
              this.distinctBZCodesDropdown();

              if (this.selectedStatus === RequestStatus.PENDING && Result.BreedingZone.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);

              this.buildForm();
            } else {
              this.breedingZoneData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('Error', Result.Message);
            }
            this.dialogVisible = false;
          },
         error: (error: string) => {
            if (error === '') {
              this.breedingZoneData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(isApprove: boolean) {
    if (!this.breedingZoneForm.CropName || this.breedingZoneForm.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (!this.breedingZoneForm.BreedingZoneName || this.breedingZoneForm.BreedingZoneName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Name is required...');
      return false;
    }

    if ((!this.breedingZoneForm.BreedingZoneCode
      || (this.breedingZoneForm.BreedingZoneCode
        && this.breedingZoneForm.BreedingZoneCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Code is required...');
      return false;
    }

    const bzCode = this.breedingZoneCodes.find(s => s.Code.toString().toLowerCase()
      === this.breedingZoneForm.BreedingZoneCode.toString().toLowerCase()
      && s.CropID === this.breedingZoneForm.CropID
      && s.Id !== this.breedingZoneForm.BreedingZoneID && s.RequestStatus !== RequestStatus.RETIRED);

    if (bzCode && this.breedingZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'This Breeding Zone Code and Crop combo already exists. Please enter a new Code or Crop...');
      return false;
    }

    if ((this.breedingZoneForm.BreedingZoneCode
      && (this.breedingZoneForm.BreedingZoneCode.trim().length < 2
        || this.breedingZoneForm.BreedingZoneCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Code should be between 2 to 4 characters long...');
      return false;
    }

    if (this.breedingZoneForm.CRMLower
      && (this.breedingZoneForm.CRMLower.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Lower...');
      return false;
    }

    if (this.breedingZoneForm.CRMLower
      && this.breedingZoneForm.CRMLower === parseInt(this.breedingZoneForm.CRMLower.toString(), 10)
      && (this.breedingZoneForm.CRMLower < -255 || this.breedingZoneForm.CRMLower > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Lower should be between -255 to 255 digits long...');
      return false;
    }

    if (this.breedingZoneForm.CRMUpper
      && (this.breedingZoneForm.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Upper...');
      return false;
    }

    if (this.breedingZoneForm.CRMUpper
      && this.breedingZoneForm.CRMUpper === parseInt(this.breedingZoneForm.CRMUpper.toString(), 10)
      && (this.breedingZoneForm.CRMUpper < -255 || this.breedingZoneForm.CRMUpper > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be between -255 to 255 digits long...');
      return false;
    }

    if (!(this.breedingZoneForm.CRMUpper === undefined || this.breedingZoneForm.CRMUpper == null)
      && !(this.breedingZoneForm.CRMLower === undefined || this.breedingZoneForm.CRMLower == null)
      && (this.breedingZoneForm.CRMUpper === parseInt(this.breedingZoneForm.CRMUpper.toString(), 10)
        && this.breedingZoneForm.CRMLower === parseInt(this.breedingZoneForm.CRMLower.toString(), 10)
        && parseInt(this.breedingZoneForm.CRMUpper.toString(), 10) < parseInt(this.breedingZoneForm.CRMLower.toString(), 10))) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be greater than CRM Lower...');
      return false;
    }

    if (this.breedingZoneForm.Color
      && (this.breedingZoneForm.Color.toString().indexOf('-') >= 0
        || this.breedingZoneForm.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.breedingZoneForm.Color
      && this.breedingZoneForm.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Error', 'Allowed Max length is 9 characters for Color...');
      return false;
    }

    if (this.breedingZoneForm.RequestStatus === RequestStatus.PENDING
      && this.selectedStatus !== RequestStatus.PENDING) {
      this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
      return false;
    }

    if (this.breedingZoneForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
      if (isApprove && (!this.breedingZoneForm.PRISM_AOI_ID
        || (this.breedingZoneForm.PRISM_AOI_ID
          && this.breedingZoneForm.PRISM_AOI_ID.toString().trim() === ''))) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is required for Request Status other than Pending and Retired...');
        return false;
      }

      const idx = this.aoiIDs.findIndex(s => s.toString() === this.breedingZoneForm.PRISM_AOI_ID.toString());

      if (isApprove && (this.breedingZoneForm.PRISM_AOI_ID === '0' || idx === -1)) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is not a valid AOI ID...');
        return false;
      }
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  clearBZT() {
    this.breedingZoneForm.BreedingZoneTypeID = '';
  }

  clearCrop() {
    this.breedingZoneForm.CropID = '';
  }

  onCloseEditBreedingZone(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: { dirty: any; }) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
    }
  }

  viewComments(breedingZone: BreedingZoneResultModel) {
    this.approvalCommentData = [];

    if (breedingZone.ApprovalComments != null) {
      breedingZone.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;
    this.sourceIdentifier = breedingZone.BreedingZoneID;
  }

  onSaveComment(data: any){
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const breedingZoneID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = breedingZoneID;

    this._breedingZoneService.saveBreedingZoneComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const breedingZoneIndex = this.breedingZoneAllData
              .findIndex(s => s.BreedingZoneID.toString() === breedingZoneID.toString());

            if (breedingZoneIndex !== -1) {
              this.breedingZoneAllData[breedingZoneIndex] = Result.BreedingZone;
              this.breedingZoneAllData[breedingZoneIndex].ApprovalComments = Result.BreedingZone.ApprovalComments.slice();
              this.breedingZoneAllData = this.breedingZoneAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.BreedingZoneID = Result.BreedingZone.BreedingZoneID);

              this.expandedItems[expandedIndex] = Result.BreedingZone;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.BreedingZone.ApprovalComments != null) {
                Result.BreedingZone.ApprovalComments.forEach((element: { CommentDate: Date; Commenter: any; Comment: any; }) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
  });
  }

  filterBreedingZoneDataTable(event: { filteredValue?: BreedingZoneResultModel[] }) {
    if(event?.filteredValue)
      this.filteredData = event.filteredValue;
  }

  exportCSVForBreedingZones() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._breedingZoneService.exportCSVForBreedingZones(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel' });
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'BreedingZoneData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
        error:error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
  });
  }

  GetAOIID(breedingZone: BreedingZoneResultModel) {
    if (breedingZone.BreedingZoneCode) {
      if (!breedingZone.BreedingZoneCode) {
        this.DisplayErrorMessage('Error', 'Please enter the Breeding Zone Code first...');
        return;
      }

      if (!breedingZone.BreedingZoneName) {
        this.DisplayErrorMessage('Error', 'Please enter the Breeding Zone Name first...');
        return;
      }

      if (!breedingZone.Description) {
        this.DisplayErrorMessage('Error', 'Please enter the Description first...');
        return;
      }

      const obj = new LERequestedData();
      obj.ParentComponent = this.parentComponent;
      obj.LEAOIID = 0;

      if (!this.breedingZoneFormGroup.controls['BreedingZoneCode'].dirty) {
        obj.Code = '';
        obj.ProjectLE = this.projectLEResult;
        obj.StationLE = this.stationLEResult;
        obj.SSLLE = this.sSLLEResult;
        obj.LocationProjectCode = this.locationProjectCodeResult;
        obj.ExperimentProjectCode = this.experimentProjectCodeResult;
      } else {
        obj.Code = breedingZone.BreedingZoneCode;
        obj.ProjectLE = [];
        obj.StationLE = [];
        obj.SSLLE = [];
        obj.LocationProjectCode = [];
        obj.ExperimentProjectCode = [];
      }

      obj.OtherObject = JSON.stringify(breedingZone);

      this.existingLEIDString = JSON.stringify(obj);
      this.displayLEIDWindow = true;
    } else {
      this.DisplayErrorMessage('Error', 'Please enter the Code first...');
    }
  }

  onaddLEDataClick(data: LERequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayLEIDWindow = false;

      this.breedingZoneForm.PRISM_AOI_ID = data.LEAOIID.toString();

      this.projectLEResult = data.ProjectLE;
      this.stationLEResult = data.StationLE;
      this.sSLLEResult = data.SSLLE;

      this.aoiIDs = [];

      if (this.sSLLEResult && this.sSLLEResult.length > 0) {
        this.sSLLEResult.forEach(s => {
          this.aoiIDs.push(s.AOIId);
        });
      }

      this.locationProjectCodeResult = data.LocationProjectCode;
      this.experimentProjectCodeResult = data.ExperimentProjectCode;

      this.existingLEIDString = '';
    }
  }

  addLeads() {
    this.existingPeople = [];

    if (this.breedingZoneForm.BreedingZoneLeads) {
      this.breedingZoneForm.BreedingZoneLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayBreedingZoneLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayBreedingZoneLead = false;
      let leadNames = '';

      this.breedingZoneForm.BreedingZoneLeads = [];
      this.breedingZoneForm.BreedingZoneLeadNames = '';
      this.bzLead = [];

      data.person.forEach(pr => {
        if (!this.breedingZoneForm.BreedingZoneLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.breedingZoneForm.BreedingZoneLeads.push({
            ID: '', PersonID: pr.PersonID, Person: pr, BreedingZone: null,
            BreedingZoneID: this.breedingZoneForm.BreedingZoneID
          });
          this.bzLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        leadNames = leadNames + pr.DisplayName + '; ';
      });

      leadNames = leadNames.trim();

      if (leadNames.lastIndexOf(';') === leadNames.length - 1) {
        leadNames = leadNames.substring(0, leadNames.lastIndexOf(';'));
      }

      this.breedingZoneForm.BreedingZoneLeadNames = leadNames;
      this.existingPeopleString = '';
    }
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      this.panelHeight = '495px';
      return 10;
    }
    return 0;
  }
}