import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingService } from "../services/loadingService";
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';

@Injectable({
    providedIn: 'root'
})
export class AllocationEZProgramService {
    protected urlConfig: UrlConfig;
    protected http: HttpClient;

    constructor(private _http: HttpClient, private environment: EnvironmentService, private loader: LoadingService) {
        this.urlConfig = environment.getEnvironment();
        this.http = _http;
    }

    getAllocationEZProgramData(): Observable<any> {
        this.loader.setMessage('Loading...');
        return this.http.get(this.urlConfig.getAllocationEZProgramData)
        // .catch((error: any) => Observable.throw(error.statusText))
        // .finally(() => this.loader.clearMessage());
    }

    saveAllocationEZProgramData(allocationEZProgramModel: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.urlConfig.saveAllocationEZProgramData, allocationEZProgramModel, httpOptions)
        // .catch((error: any) => Observable.throw(error.statusText))
        // .finally(() => this.loader.clearMessage());
    }

    saveAllocationEZPrograms(allocationEZPrograms: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.urlConfig.saveAllocationEZPrograms, allocationEZPrograms, httpOptions)
        // .catch((error: any) => Observable.throw(error.statusText))
        // .finally(() => this.loader.clearMessage());
    }

    deleteAllocationEZProgramData(allocationEZProgramModel: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.urlConfig.deleteAllocationEZProgramData, allocationEZProgramModel, httpOptions)
        // .catch((error: any) => Observable.throw(error.statusText))
        // .finally(() => this.loader.clearMessage());
    }

    exportCSVForAllocationEZProgram(allocationEZProgram: string) {
        this.loader.setMessage('Loading...');
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.urlConfig.exportCSVForAllocationEZProgram, allocationEZProgram,
            { headers: httpHeaders, responseType: 'arraybuffer' });
        // .catch((error: any) => Observable.throw(error.statusText))
        // .finally(() => this.loader.clearMessage());
    }

}