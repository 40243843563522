import { ApprovalComments } from './approvalComments';
export class CPFieldScientistForm {
    constructor(
        public Id: string,
        public FirstName: string,
        public LastName: string,
        public CPRegionId: string,
        public Code: string,
        public Lat: string,
        public Long: string,
        public StatusId: string,
        public StatusName: string,
        public CPSubfunctionId: string, 
        public CityAoiId: number|null,
        public City: string | null,
        public CountyAoiId: number|null,
        public County: string | null,
        public StateAoiId: number|null,
        public State: string | null,
        public CountryAoiId: number|null,
        public Country: string|null,
        public ApprovalComments: ApprovalComments[] = [],
        public GeographicLocale: string|null,
        public GAAResponseJson: string,
        public Level3Type: string
    ) { }
}
