export class RegionModel {
    Id: string;
    Name: string;
    Description: string;
    Code: string;
    CropID: string;
    RequestStatus: string;
    label: string;
    value: any;
}
