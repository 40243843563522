export class CRAData {
    GUID: string;
    Name: string;
    Definition: string;
    IsActive: boolean;
    IsIncluded: boolean;
    Units: string;
    Order: number;
    Comment: string;
}
