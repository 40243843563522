import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EvaluationZoneResultModel } from '../shared/evaluationZoneResultModel';
import { RegionModel } from './../shared/regionModel';
import { LoadingService } from '../services/loadingService';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationZoneService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  bindEvaluationZoneDropdownLists(): Observable<any> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.evaluationZoneDropDownLists)
  }

  getRegionsByCropID(crop: string): Observable<RegionModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.regionsByCropID + '?cropID=' + crop)
  }

  getProgramNamesByEvaluationZoneID(evaluationZoneID: string): Observable<RegionModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.programsByEvaluationZoneID + '?evaluationZoneID=' + evaluationZoneID)
  }

  getEvaluationZoneResult(): Observable<EvaluationZoneResultModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.evaluationZone).pipe(
      map((dataArray: EvaluationZoneResultModel[]) => {
        return dataArray.map(data => {
          return {
            ...data,
            RegionCode: data.Region?.Code
          };
        });
      })
    );
  }
    
  saveEvaluationZone(evaluationZone: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveEvaluationZone, evaluationZone, httpOptions)
  }

  saveEvaluationZoneComment(evaluationZoneComment: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveEvaluationZoneComment, evaluationZoneComment, httpOptions)
  }

  exportCSVForEvaluationZones(evaluationZone: string) {
    this.loader.setMessage('Loading...');
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForEvaluationZones, evaluationZone, { headers: httpHeaders, responseType: 'arraybuffer' })
  }

  saveAOIDataToPrismEvaluationZone(evaluationZoneAOIData: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveAOIDataToPrismEvaluationZone, evaluationZoneAOIData, httpOptions)
  }
}
