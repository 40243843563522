export class AllocationEZProgramModel {
    public AllocationEZProgramId: string;
    public Crop: string;
    public CropID: string;
    public EvalZoneCode: string;
    public EvaluationZodeId: string;
    public ProgramCode: string;
    public ProgramId: string;
    public AllocationEZProgramTypeId: string;
    public ProgramPurpose: string;
    public Allocation: string;
    public IsEditable: boolean;
    public IsExcluded: boolean;
    public IsLocal: boolean;
}
