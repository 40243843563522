<div id="dvcpFieldScientistGrid">
    <p-table #dt [columns]="cols" id="dtCPFieldScientist" [value]="cpFieldScientistAllData" dataKey="Id"
            sortMode="single" columnResizeMode="fit" [scrollable]="true" scrollHeight="530px" 
            exportFilename="CP Field Scientist" (onFilter)="filterCPFieldScientistDataTable($event)" selectionMode="single"
            (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
            [totalRecords]="cpFieldScientistAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows"
            [(first)]="first">
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix align-center">
            <p-button label="CP Field Scientist Count" icon="fa fa-wrench" styleClass="p-button-outlined p-button-secondary"
                      badge="{{cpFieldScientistAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
            <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary"
                      badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
            <button id="btnCPFSExport" type="button" pButton icon="fa fa-file-o" class="p-button-success"
                    iconPos="left" label="Export" (click)="exportCSVForCPFieldScientists()"
                    style="float:right;margin-left:10px"></button>
            <button id="btnSyncGAA" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
                *ngIf="userName === 'vijay.gutta@corteva.com'" label="Sync GAA" (click)="syncGAA()" style="float:right;"></button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 1.25em"></th>
                <ng-container *ngFor="let col of columns">
                    <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </ng-container>
            </tr>
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                    <input id="txtFilterFirstName" *ngSwitchCase="'FirstName'" pInputText type="text"
                        (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
                        placeholder="Search">
                    <input id="txtFilterLastName" *ngSwitchCase="'LastName'" pInputText type="text"
                        (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
                        placeholder="Search">
                    <!-- <input id="txtFilterCountry" *ngSwitchCase="'Country'" pInputText type="text"
                        (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
                        placeholder="Search">  -->
                        <input id="msFilterCPFieldCountry" *ngSwitchCase="'Country'"  pInputText
                         type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
                         style="width:100%" placeholder="Search">
                    <p-multiSelect id="msFilterCPFieldCode" *ngSwitchCase="'Code'" [resetFilterOnHide]="true"
                        [options]="distinctCodes" [optionValue]="'value'" placeholder="All Codes"
                        (click)="$event.stopPropagation();" appendTo="body"
                        (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
                        [style]="{'width':'100%'}" [maxSelectedLabels]="3"
                        selectedItemsLabel="{0} items selected"></p-multiSelect>
<!--
                    <input id="txtFilterStatus" *ngSwitchCase="'StatusName'" pInputText type="text"
                        (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
                        placeholder="Search"> -->
                    <p-multiSelect id="msFilterCPFieldStatus" *ngSwitchCase="'StatusName'" [resetFilterOnHide]="true"
                        [options]="distinctStatus" [optionValue]="'value'" placeholder="All Status" (click)="$event.stopPropagation();"
                        appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
                        [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
                <td style="width: 1.25em">
                    <a href="#" [pRowToggler]="rowData">
                        <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'"
                            style="font-size: 1.25em"></i>
                    </a>
                </td>
                <ng-container *ngFor="let col of columns">
                    <td *ngIf="!col.hidden">
                        {{rowData[col.field]}}
                    </td>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template let-cpFieldScientist pTemplate="rowexpansion" id="rowCPFSexpand" let-columns="columns">
            <tr>
                <td [attr.colspan]="columns.length">
                    <div class="grid">
                        <div class="col">
                            <p-panel class="height">
                                <div class="grid grid-nogutter">
                                    <div class="col-6">
                                        <label>First Name :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.FirstName ? cpFieldScientist.FirstName : '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Last Name :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.LastName ? cpFieldScientist.LastName : '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Code :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.Code ? cpFieldScientist.Code : '-'}}
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <label>CP Subfunction :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.CPSubfunction?.Name ? cpFieldScientist.CPSubfunction.Name
                                            :
                                            '-'}}
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <label>CP Region :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.CPRegion?.Name ? cpFieldScientist.CPRegion.Name : '-'}}
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <label>Status :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.Status ? cpFieldScientist.Status.Name : '-'}}
                                        </p>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                        <div class="col">
                            <p-panel class="height" id="editCPFSPanel2 ">
                              <div class="grid grid-nogutter">
                                <div class="col-6">
                                    <label>Latitude :</label>
                                  </div>
                                  <div class="col-6">
                                    <p style="white-space: pre-wrap">{{cpFieldScientist.Lat}}</p>
                                  </div>
                                  <div class="col-6">
                                    <label>Longitude :</label>
                                  </div>
                                  <div class="col-6">
                                    <p style="white-space: pre-wrap">{{cpFieldScientist.Long}}</p>
                                  </div>
                                <div class="col-6">
                                  <label>Geographic Locale :</label>
                                </div>
                                <div class="col-6">
                                  <p style="white-space: pre-wrap">{{cpFieldScientist.GeographicLocale}}</p>
                                </div>
                              </div>
                            </p-panel>
                        </div>
                        <div class="col">
                            <p-panel class="height" id="editPanel3">
                                <div class="grid grid-nogutter">
                                    <div class="col-6">
                                        <label>Created By :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.CreatedBy ? cpFieldScientist.CreatedBy.DisplayName :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Date Created :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{ConvertDateToString(cpFieldScientist.CreatedOn)}}
                                        </p>
                                    </div>
                                    <div class="col-6">
                                        <label>Approved By :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.ApprovedBy ? cpFieldScientist.ApprovedBy.DisplayName :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Date Approved :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{ConvertDateToString(cpFieldScientist.ApprovedOn)}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Retired By :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.RetiredBy ? cpFieldScientist.RetiredBy.DisplayName :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Date Retired :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{ConvertDateToString(cpFieldScientist.RetiredOn)}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Last Modified By :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{cpFieldScientist.ModifiedBy ? cpFieldScientist.ModifiedBy.DisplayName :
                                            '-'}}</p>
                                    </div>
                                    <div class="col-6">
                                        <label>Date Last Modified :</label>
                                    </div>
                                    <div class="col-6">
                                        <p style="white-space: pre-wrap">
                                            {{ConvertDateToString(cpFieldScientist.ModifiedOn)}}</p>
                                    </div>
                                </div>
                            </p-panel>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-8"></div>
                        <div class="col-2">
                            <button id="btnCPFSComments" pButton type="button" style="width: 100%"
                                (click)="viewComments(cpFieldScientist)" label="View / Add Comments" iconPos="left"
                                icon="fa fa-pencil" class="p-button-info"></button>
                        </div>

                        <div class="col-2">
                            <div [hidden]="!(isROVAdmin)">
                                <button id="btnCPFSEdit" pButton type="button" style="width: 100%"
                                    (click)="editCPFieldScientist(cpFieldScientist)" label="Edit" iconPos="left"
                                    icon="fa fa-fw fa-edit"></button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="7">
                    No records found
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog id="dlgCPFSComments" header="View / Add Comments" [style]="{width: '900px', height:'470px'}"
    [(visible)]="displayComments" showEffect="fade" [modal]="true">
    <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
        [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<p-dialog id="dlgCPFSEdit" [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible"
    [breakpoints]="{'640px':'100vw'}" showEffect="fade" [modal]="true" [style]="{width: '1050px'}"
    (onHide)="onCloseEditCPFieldScientist($event, frm)" [closable]="false" [closeOnEscape]="false" [showHeader]="true"
    header="CP Field Scientist Details">
    <p-panel id="editCPFSPanel4" appendTo="body">
        <form #frm="ngForm" id="frmCPFS" [formGroup]="cpFieldScientistFormGroup">
            <div class="grid">
                <div class="col-6">
                    <div class="grid">
                        <div class="col-5 controlLabel">
                            <label>First Name</label>
                        </div>
                        <div class="col-7">
                            <input pInputText id="txtFirstName" [(ngModel)]="cpFieldScientistForm.FirstName"
                                formControlName="FirstName" minlength="1" maxlength="25" styleClass="width100" />
                        </div>
                        <div class="col-5 controlLabel">
                            <label>Last Name</label>
                        </div>
                        <div class="col-7">
                            <input pInputText id="txtLastName" [(ngModel)]="cpFieldScientistForm.LastName"
                                formControlName="LastName" minlength="1" maxlength="25" styleClass="width100" />
                        </div>
                        <div class="col-5 controlLabel">
                            <label>Code</label>
                        </div>
                        <div class="col-7">
                            <input pInputText id="txtCode" [(ngModel)]="cpFieldScientistForm.Code"
                                formControlName="Code" minlength="1" maxlength="25" styleClass="width100" />
                        </div>
                        <div class="col-5 controlLabel">
                            <label>CP Subfunction</label>
                        </div>
                        <div class="col-7">
                            <div class="grid">
                                <div class="col-10">
                                    <p-dropdown id="ddCPSubfunction" name="CPSubfunction" [filter]=true
                                        [(ngModel)]="cpFieldScientistForm.CPSubfunctionId"
                                        formControlName="CPSubfunctionId" [style]="{'width':'100%'}"
                                        placeholder="Select a CP Subfunction" [showClear]="true"
                                        [options]="cpSubfunctions">
                                    </p-dropdown>
                                </div>
                                <div class="col-2">
                                    <button id="btnCPSubfunctionDialog" type="button" pButton icon="fa fa-edit"
                                        class="p-button-info" style="width: 30px; float: right; margin-right: 8px;"
                                        (click)="showCPSubfunctionDialog()" title="Add/Edit CP Subfunction"></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 controlLabel">
                            <label>CP Region</label>
                        </div>
                        <div class="col-7">
                            <div class="grid">
                                <div class="col-10">
                                    <p-dropdown id="ddCPRegion" name="CPRegion" [filter]=true [options]="cpRegions"
                                        [(ngModel)]="cpFieldScientistForm.CPRegionId" formControlName="CPRegionId"
                                        appendTo="body" [style]="{'width':'100%'}" placeholder="Select a CP Region"
                                        [showClear]="true">
                                    </p-dropdown>
                                </div>
                                <div class="col-2">
                                    <button id="btnRegionDialog" type="button" pButton icon="fa fa-edit"
                                        class="p-button-info" style="width: 30px; float: right; margin-right: 8px;"
                                        (click)="showCPRegionDialog()" title="Add/Edit CP Region"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">

                        <div class="col-3 controlLabel">
                            <label>Latitude</label>
                        </div>
                        <div class="col-9">
                            <input id="txtCPFieldLatitude" type="text" pInputText required name="Latitude" maxlength="15" step="any"
                            [(ngModel)]="cpFieldScientistForm.Lat" formControlName="Lat" />
                        </div>
                        <div class="col-3 controlLabel">
                            <label>Longitude</label>
                        </div>
                        <div class="col-9">
                            <input id="txtCPFieldLongitude" type="text" pInputText required name="Longitude" (keyup)="GetGAAData($event)" maxlength="15"
                            step="any" [(ngModel)]="cpFieldScientistForm.Long" formControlName="Long" />
                        </div>
                        <div class="col-3 controlLabel">
                            <label>Geographic Locale</label>
                        </div>
                        <div class="col-9">
                            <input id="txtCPFieldGeographicLocale" type="text" readonly pInputText name="GeographicLocale"
                            [(ngModel)]="cpFieldScientistForm.GeographicLocale" formControlName="GeographicLocale" />
                        </div>
                        <div class="col-3 controlLabel">
                            <label>Status</label>
                        </div>
                        <div class="col-9">
                            <p-dropdown id="ddStatus" name="Status" [filter]=true
                                [(ngModel)]="cpFieldScientistForm.StatusId" formControlName="StatusId"
                                [style]="{'width':'100%'}" placeholder="Select a Status" [showClear]="true"
                                [options]="statuses">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </p-panel>

    <ng-template pTemplate="footer">
        <div [hidden]="!isROVUser" style="display: inline;">
            <button id="btnCPFSCancel" type="button" pButton icon="fa fa-close" class="p-button-warning"
                (click)="close(frm)" label="Cancel" style='width: 200px;'>
            </button>
        </div>
        <button id="btnCPFSSave" type="submit" pButton [icon]="saveIcon" class="{{saveIconClass}}"
            (click)="saveCPFieldScientist(frm)" [disabled]="!frm.form.valid" label="{{saveLabelText}}"
            style='width: 200px;'></button>
    </ng-template>
</p-dialog>

<p-dialog id="dlgEditCPSubfunction" header="Edit CP Subfunction" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayCPSubfunctionDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="CPSubFunction" (getLookUpEvent)="getCPSubfunctionLookUp()"></manage-lookup>
</p-dialog>

<p-dialog id="dlgEditCPRegion" header="Edit CP Region" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayCPRegionDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="CPRegion" (getLookUpEvent)="getCPRegionLookUp()"></manage-lookup>
</p-dialog>
