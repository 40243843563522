import { Injectable } from '@angular/core';

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) { 
  }

  intercept(
    httpRequest: HttpRequest<any>,
    httpHandler: HttpHandler
  ): Observable<HttpEvent<any>> {

    const endpoint = 'api://manager-api/access_as_user';

    let authRequest = {
      scopes: [endpoint],
    };

    const promise = this.msalService.initialize().pipe(
      switchMap(()=>this.msalService.acquireTokenSilent(authRequest))
    );
    const observable = from(promise);

    return observable.pipe(
      switchMap((token) => {
        if(httpRequest.url.toLowerCase().includes('importresourcefile')){
          httpRequest = httpRequest.clone({
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
              'Pragma': 'no-cache',
              'Expires': '0',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': '*',
              'Authorization': `Bearer ${token['accessToken']}`,
              'X-Research-Identity': `${token['accessToken']}`
            }),
          });
        }
        else if (!httpRequest.url.includes('graph')) {
          httpRequest = httpRequest.clone({
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
              'Pragma': 'no-cache',
              'Expires': '0',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': '*',
              'Authorization': `Bearer ${token['accessToken']}`,
              'X-Research-Identity': `${token['accessToken']}`
            }),
          });
        }
        return httpHandler.handle(httpRequest);
      })
    );
  }
}
