<div id="dvProgramGrid">
  <p-table #dt [columns]="cols" id="dtPrograms" [value]="programAllData" dataKey="ProgramID" sortMode="single"
      [scrollable]="true" [(selection)]="selectedItems" scrollHeight="530px"
      columnResizeMode="fit" exportFilename="Program" (onFilter)="filterProgramDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="programAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix align-center">
        <p-button label="Program Count" icon="fa fa-database" styleClass="p-button-outlined p-button-secondary" badge="{{programAllData.length}}" badgeClass="p-badge-secondary" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary" badgeClass="p-badge-secondary"></p-button>
        <button id="btnProgramExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
          label="Export" (click)="exportCSVForPrograms()" style="float:right; width: 110px"></button>
        <span [hidden]="!isROVAdmin" style="float: right; margin-right: 10px;">
          <p-checkbox id="cbIncludeProgramSize" name="includeProgramSize" [(ngModel)]="includeProgramSize"
            [binary]="true" label="Include Program Size in Exported List"></p-checkbox>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 1.25em"></th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header" pResizableColumn>
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
           <p-multiSelect id="msPRogramCode" *ngSwitchCase="'ProgramCode'" [(ngModel)]="filteredProgramCode" [resetFilterOnHide]="true" [options]="distinctCodes"
           placeholder="All Programs" (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
           styleClass="ui-column-filter" [style]="{'width':'100%'}"[maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterProgramName" *ngSwitchCase="'ProgramName'" [(ngModel)]="filteredProgramName" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtProgramCropDescription" *ngSwitchCase="'CropDescription'" [(ngModel)]="filteredProgramCrop"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <p-multiSelect id="msProgramRegion" *ngSwitchCase="'Region'" [(ngModel)]="filteredRegion"
            [options]="distinctRegions" placeholder="All RCZs" (click)="$event.stopPropagation();" appendTo="body"
            (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <p-multiSelect id="msProgramStationCode" *ngSwitchCase="'Station.StationCode'"
            [(ngModel)]="filteredStationCode" [resetFilterOnHide]="true" [options]="distinctStations"
            placeholder="All R&D Center" (click)="$event.stopPropagation();" appendTo="body"
            (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <p-multiSelect id="msProgramEZCode" *ngSwitchCase="'EvaluationZone.Code'" [(ngModel)]="filteredEZ"
            [resetFilterOnHide]="true" [options]="distinctEZones" placeholder="All EZ"
            (click)="$event.stopPropagation();" appendTo="body" (onChange)="dt.filter($event.value,col.field,'in')"
            styleClass="ui-column-filter" [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtProgramLeads" *ngSwitchCase="'ProgramPrincipalInvestigatorNames'"
            [(ngModel)]="filteredProgramPrincipalInvestigatorNames" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtProgramStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredPRequestStatus" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
      <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
        <td style="width: 1.25em">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'"
              style="font-size: 1.25em"></i>
          </a>
        </td>
        <ng-container *ngFor="let col of columns; let i= index;">
          <td *ngIf="(!col.hidden && i !== 5 && i !== 6)">
            {{rowData[col.field]}}
          </td>
          <td *ngIf="(i === 5)">
            {{rowData.Station?.StationCode}}
          </td>
          <td *ngIf="(i === 6)">
            {{rowData.EvaluationZone && rowData.EvaluationZone.Code ? rowData.EvaluationZone.Code : '-'}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template let-program let-columns="columns" pTemplate="rowexpansion">
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="grid">
              <div class="col-12 lg:col md:col-12 stretched">
                <p-panel class="height">
                  <div class="grid grid-nogutter">
                      <div class="col-7">
                        <label>Program Name :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.ProgramName}}</p>
                      </div>
                      <div class="col-7">
                        <label>Program Code :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.ProgramCode ? program.ProgramCode : '-'}}</p>
                      </div>
                      <div class="col-7">
                        <label>Principal Investigators :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap" *ngFor="let item of program.ProgramPrincipalInvestigatorNames.split(';')">{{item}}</p>
                      </div>
                      <div class="col-7">
                        <label style="word-wrap: break-word">Description :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.Description}}</p>
                      </div>
                      <div class="col-7">
                        <label>Crop :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.CropDescription}}</p>
                      </div>
                      <div class="col-7">
                        <label>Status :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.RequestStatus}}</p>
                      </div>
                      <div class="col-7">
                        <label>Primary Responsible Subfunction :</label>
                      </div>
                      <div class="col-5">
                        <p style="white-space: pre-wrap">{{program.PrimaryResponsibleSubFunction?.Name ?  program.PrimaryResponsibleSubFunction.Name : '-'}}</p>
                      </div>
                  </div>
                </p-panel>
              </div>
              <div class="col-12 lg:col md:col-12">
                <p-panel class="height">
                  <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label>Program Type :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ProgramType ? program.ProgramType.Name : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Program Purpose :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ProgramPurpose && program.ProgramPurpose.Code ?
                        program.ProgramPurpose.Code : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Program Size :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ProgramSize ? program.ProgramSize : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>CRM Upper :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.CRMUpper ? program.CRMUpper : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>CRM Lower :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.CRMLower ? program.CRMLower : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Evaluation Zone :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.EvaluationZone ? program.EvaluationZone.Code : '-'}}
                      </p>
                    </div>
                    <div class="col-6">
                      <label>Breeding Zone :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.BreedingZones}}</p>
                    </div>
                    <div class="col-6">
                      <label>R&D Center :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.Station ? program.Station.StationCode : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Ship To R&D Center :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ShipToStation ? program.ShipToStation.StationCode :
                        '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Seed Storage Location :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.SeedStorageLocation?.Name ?
                        program.SeedStorageLocation.Name : '-'}}</p>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="col-12 lg:col md:col-12">
                <p-panel class="height">
                  <div class="grid grid-nogutter">
                    <div class="col-6">
                      <label>LE ID :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.LEID ? program.LEID : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Created By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.CreatedByUserName}}</p>
                    </div>
                    <div class="col-6">
                      <label>Date Created :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{ConvertDateToString(program.CreatedOn)}}</p>
                    </div>
                    <div class="col-6">
                      <label>Approved By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ApprovedByUserName ? program.ApprovedByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Date Approved :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ApprovedOn ? ConvertDateToString(program.ApprovedOn) : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Retired By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.RetiredByUserName ? program.RetiredByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Date Retired :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.RetiredOn ? ConvertDateToString(program.RetiredOn) : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Last Modified By :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ModifiedByUserName ? program.ModifiedByUserName : '-'}}</p>
                    </div>
                    <div class="col-6">
                      <label>Date Last Modified :</label>
                    </div>
                    <div class="col-6">
                      <p style="white-space: pre-wrap">{{program.ModifiedOn ? ConvertDateToString(program.ModifiedOn) : '-'}}</p>
                    </div>
                  </div>
                </p-panel>
              </div>
          </div>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-4">
              <div class="grid">
                <div class="col-4">
                  <div
                    [hidden]="!((program.CreatedBy !== null && program.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                    <button id="btnClone" pButton type="button" style="width: 100%" (click)="onCloneProgram(program)" label="Clone"
                      iconPos="left" icon="fa fa-fw fa-copy" class="p-button-info"></button>
                  </div>
                </div>
                <div class="col-4">
                  <button id="btnProgramComments" pButton type="button" style="width: 100%" (click)="viewComments(program)"
                    label="{{program.ApprovalComments === null || program.ApprovalComments.length === 0 ?  'Add Comment' : 'View / Add Comment(s)'}}"
                    iconPos="left" [icon]="program.ApprovalComments === null || program.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                    class="p-button-info"></button>
                </div>
                <div class="col-4">
                  <div [hidden]="!((program.CreatedBy !== null && program.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                    <button id="btnEditPrograms" pButton type="button" (click)="editPrograms(program)"
                      label="Edit" iconPos="left" icon="fa fa-fw fa-edit" style="width: 100%"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="8">
              No records found
          </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
  showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
   [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<p-dialog [contentStyle]="{'overflow':'visible'}" [(visible)]="dialogVisible" showEffect="fade"
  [modal]="true"  [style]="{width: '900px'}" (onHide)="onCloseEditProgram($event, frm)" [closeOnEscape]="false" [closable]="false"
  [showHeader]="true" header="Program Details">
  <p-panel appendTo="body">
    <form #frm="ngForm" [formGroup]="programFormGroup">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
              <div class="col-5 controlLabel">
                <label for="txtProgramName">Program Name</label>
              </div>
              <div class="col-7">
                <input pInputText id="txtProgramName" [(ngModel)]="programForm.ProgramName" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="ProgramName" minlength="1" maxlength="25" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramCode">Program Code</label>
              </div>
              <div class="col-7">
                <input pInputText id="txtProgramCode" minlength="2" maxlength="4" [(ngModel)]="programForm.ProgramCode"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="ProgramCode" styleClass="width100" (keydown)="programForm.LEID = ''" />
              </div>
              <div class="col-5 controlLabel">
                <label for="lstProgramLead">Principal Investigators</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstProgramLead" [options]="programLead" [(ngModel)]="programForm.ProgramPrincipalInvestigatorNames"
                      optionLabel="label" formControlName="ProgramPrincipalInvestigatorNames" styleClass="width100"
                      [style]="{'min-height':'35px'}" [listStyle]="{'max-height':'100px'}">
                    </p-listbox>
                  </div>
                  <div class="col-2">
                    <button id="btnAddLead" type="button" pButton icon= "fa fa-user-plus" class="p-button-icon-only" style="width: 30px;"
                      (click)="addLeads()" title="Add Principal Investigators"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramDescription">Description</label>
              </div>
              <div class="col-7">
                <input id="txtProgramDescription" pInputText minlength="1" maxlength="255" [(ngModel)]="programForm.Description"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="Description" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramCRMLower">CRM Lower</label>
              </div>
              <div class="col-7">
                <input id="txtProgramCRMLower" type="number" pInputText [(ngModel)]="programForm.CRMLower"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="CRMLower" minlength="0" maxlength="4" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramCRMUpper">CRM Upper</label>
              </div>
              <div class="col-7">
                <input id="txtProgramCRMUpper" type="number" pInputText [(ngModel)]="programForm.CRMUpper"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="CRMUpper" minlength="0" maxlength="4" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramSize">Program Size</label>
              </div>
              <div class="col-7">
                <input id="txtProgramSize" type="number" pInputText [(ngModel)]="programForm.ProgramSize"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="ProgramSize" styleClass="width100" />
              </div>
              <div class="col-5 controlLabel">
                <label for="ddprimaryResponsibleSubFunction">Primary Responsible Subfunction</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-dropdown id="ddprimaryResponsibleSubFunction" [options]="responsiblesubfunctions" [(ngModel)]="programForm.PrimaryResponsibleSubFunctionId"
                formControlName="PrimaryResponsibleSubFunctionId" appendTo="body" styleClass="width100"
                placeholder="Select a Primary Responsible Subfunction"  [showClear]="true" [filter]=true>
                </p-dropdown>
                  </div>
                  <div class="col-2">
                    <button id="btnPrimaryResponsibleSubfunctionDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                    style="width: 30px; float: right; margin-right: 8px;" (click)="showResponsibleSubfunctionDialog()"
                    title="Add/Edit Primary Responsible Subfunction"></button>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
              <div class="col-5 controlLabel">
                <label for="ddProgramTypeName">Program Type</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramTypeName" [options]="programTypeName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.ProgramTypeID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                formControlName="ProgramTypeID" [filter]=true styleClass="width100" placeholder="Select a Program Type" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramCropName">Crop </label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramCropName" #dd1 [options]="programCropName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.CropID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                formControlName="CropID" (onChange)="clearDropdownSelection(dd1,dd2,dd3,dd4,dd5); getStationsByCropID()"
                [filter]="true" filterBy="label,value.name" styleClass="width100"
                placeholder="Select a Crop" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramStationName">R&D Center</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramStationName" #dd2 [options]="allStationName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.StationID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                (onChange)="getSeedStorageLocationByCropID()" formControlName="StationID" [filter]="true"
                filterBy="label,value.name" styleClass="width100" placeholder="Select a R&D Center" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramSTS">Ship To R&D Center</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramSTS" #dd3 [options]="allShipToStationName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.ShipToStationID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                (onChange)="getSeedStorageLocationByCropID()" formControlName="ShipToStationID" [filter]="true"
                filterBy="label,value.name" styleClass="width100" placeholder="Select a R&D Center" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramSSL">Seed Storage Location</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramSSL" #dd5 [options]="allSeedStorageLocationName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.SeedStorageLocationID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                formControlName="SeedStorageLocationID" [filter]="true" filterBy="label,value.name" styleClass="width100"
                placeholder="Select a SSL" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramPurposeName">Program Purpose</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramPurposeName" [options]="programPurposeName" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.ProgramPurposeID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                formControlName="ProgramPurposeID" [filter]="true" filterBy="label,value.name" styleClass="width100"
                placeholder="Select a Program Purpose" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramEZ">Evaluation Zone</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramEZ" #dd4 [options]="evalutionZones" [style]="{'width':'100%'}"
                [(ngModel)]="programForm.EvaluationZoneID" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                formControlName="EvaluationZoneID" [filter]="true" filterBy="label,value.name" styleClass="width100"
                placeholder="Select a Evaluation Zone" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="msProgramBZName">Breeding Zone</label>
              </div>
              <div class="col-7">
                <p-multiSelect id="msProgramBZName" [options]="breedingZones" scrollHeight="100" name="breedingZoneName"
                  [(ngModel)]="selectedBZ" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  [maxSelectedLabels]=1 (onChange)="changeBZ($event)" formControlName="ProgramBreedingZoneAssoc"
                  styleClass="width100" placeholder="Select a Breeding Zone">
                </p-multiSelect>
              </div>
              <div class="col-5 controlLabel">
                <label for="ddProgramStatus">Status</label>
              </div>
              <div class="col-7">
                <p-dropdown id="ddProgramStatus" [style]="{'width':'100%'}" [options]="status" class="disabled"
                  [(ngModel)]="programForm.RequestStatus" ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="RequestStatus" placeholder="Select a Status" (onChange)="statusChange(programForm.RequestStatus,true)" [showClear]="true"></p-dropdown>
              </div>
              <div class="col-5 controlLabel">
                <label for="txtProgramLEID">LE ID</label>
              </div>
              <div class="col-7">
                <input id="txtProgramLEID" type="number" pInputText [(ngModel)]="programForm.LEID"
                  ng-model-options="{'updateOn': 'default blur','debounce': {'default': 300,'blur': 0}}"
                  formControlName="LEID" maxlength="9" styleClass="width100" readonly="readonly" />
              </div>
              <div class="col-5">
              </div>
              <div class="col-7">
                <button id="btnProgramCheckLEID" type="button" pButton icon="fa fa-eye" class="p-button-info" style="width: 175px"
                  (click)="GetLEID(programForm)" label="Check Legal Entity" title="Check Legal Entity"></button>
              </div>
          </div>
        </div>
      </div>
    </form>
  </p-panel>
  <ng-template pTemplate="footer">
      <div [hidden]="!isROVUser" style="display:inline">
        <button id="btnProgramReset" type="button" pButton icon= "fa fa-close" class="p-button-warning" (click)="close(frm)"
          label="Cancel" style='width: 200px; margin-bottom:10px;'>
        </button>
      </div>
      <button id="btnProgramSave" type="submit" pButton [icon]="saveIcon" [ngClass]="saveIconClass" (click)="saveProgram(frm, programForm.RequestStatus)"
        [disabled]="!(frm.form.valid || programForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
        style='width: 200px; margin-left: 10px; margin-right: 10px; margin-bottom:10px;'></button>
  </ng-template>
</p-dialog>

<p-dialog id="dlgProgramLead" header="Select Principal Investigators" [style]="{width: '1000px', height:'580px'}" [(visible)]="displayInvestigator"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker id="appProgramPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgProgramSelectAOI" header="Select LE ID" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
  [(visible)]="displayLEIDWindow" showEffect="fade" [modal]="true" [closeOnEscape]="true"
  [closable]="true" [resizable]="false">
  <app-leidpicker id="appProgramLEID" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
</p-dialog>

<p-dialog id="dlgEditResponsibleSubfunction" header="Edit Responsible Subfunction"
  [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayResponsibleSubfunctionDialog" showEffect="fade" [modal]="true">
  <manage-lookup [lkpType]="PrimaryResponsibleSubFunction"
    (getLookUpEvent)="getResponsibleSubfunctionLookUp()"></manage-lookup>
</p-dialog>
