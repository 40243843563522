import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BreedingZoneResultModel } from '../shared/breedingZoneResultModel';
import { HttpHeaders } from '@angular/common/http';
import { LoadingService } from '../services/loadingService';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class BreedingZoneService {
    protected urlConfig: UrlConfig;
    protected http: HttpService;

    constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
        this.urlConfig = environment.getEnvironment();
        this.http = _http;
    }

    bindBreedingZoneDropdownLists(): Observable<any> {
        this.loader.setMessage('Loading...');
        return this.http.get(this.urlConfig.breedingZoneDropDownLists)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    getBreedingZoneResult(): Observable<BreedingZoneResultModel[]> {
       this.loader.setMessage('Loading...');
       return this.http.get(this.urlConfig.breedingZone)
       //.catch((error: any) => Observable.throw(error.statusText))
       //.finally(() => this.loader.clearMessage());
    }

    saveBreedingZone(breedingZone: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(this.urlConfig.saveBreedingZone, breedingZone, httpOptions)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    saveBreedingZoneComment(breedingZoneComment: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(this.urlConfig.saveBreedingZoneComment, breedingZoneComment, httpOptions)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    exportCSVForBreedingZones(breedingZone: string) {
        this.loader.setMessage('Loading...');
        const httpHeaders = new HttpHeaders({
          'Content-Type' : 'application/json'
        });
        return this.http.post(this.urlConfig.exportCSVForBreedingZones, breedingZone, { headers: httpHeaders, responseType: 'arraybuffer' })
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }

    saveAOIDataToPrismBreedingZone(breedingZoneAOIData: string): Observable<any> {
        this.loader.setMessage('Loading...');
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post(this.urlConfig.saveAOIDataToPrismBreedingZone, breedingZoneAOIData, httpOptions)
        //.catch((error: any) => Observable.throw(error.statusText))
        //.finally(() => this.loader.clearMessage());
    }
}