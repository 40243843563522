import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { BrowserModule } from "@angular/platform-browser";
import { ButtonModule } from "primeng/button";
import { MessageService } from "primeng/api";
import { ManageLookupComponent } from "./manage-lookup.component";

@NgModule({
    declarations: [ManageLookupComponent],
    imports: [
        BrowserModule,
        TableModule,
        DialogModule,
        ButtonModule,
        FormsModule
    ],
    exports: [ManageLookupComponent],
    providers: [MessageService]

})
export class ManageLookupComponentModule {

}
