<p-panel id="panelPeoplePicker">
  <div class="grid">
    <div class="col-12">
      <div class="grid">
        <div class="col-3">
          <input id="txtPPLastName" [(ngModel)]="lastName"  minlength="1" maxlength="25" pInputText
            autofocus name="txtLastName" (keypress)="valueChangeLName($event)" placeholder="Last Name" />
        </div>
        <div class="col-3">
          <input id="txtPPFirstName" [(ngModel)]="firstName"  minlength="1" maxlength="25" pInputText
            name="txtFirstName" (keypress)="valueChangeFName($event)" placeholder="First Name" />
        </div>
        <div class="col-3">
          <input id="txtPPLoginName" [(ngModel)]="loginName"  minlength="1" maxlength="25" pInputText
            autofocus name="txtLoginName" (keypress)="valueChangeLName($event)" placeholder="Login Name" />
        </div>
        <div class="col-3">
          <button id="txtPPSearch" class="rightAlign p-button-info" type="button" style="width: 50px" pButton
            icon="fa fa-search" [disabled]="!(lastName || firstName || loginName)" (click)="getLDAPUsers()"
            title="Search Users"></button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <p-table #dt id="dtPP" [columns]="peopleCols" [value]="people"
           columnResizeMode="fit" class="table-bordered" [scrollable]="true" scrollHeight="220px"
           selectionMode="multiple" [(selection)]="selPeopleData">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
              [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td [pSelectableRow]="rowData" *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="5">Count: {{people.length}}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>
    <div class="col-12">
      <p-table id="dataTableADUser" [columns]="adUserCols" class="gridHeight" [value]="selPeopleData"
        scrollHeight="200px" [resizableColumns]="true" responsiveLayout="scroll">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
              [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [hidden]="!col.isSortable" [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{user[col.field]}}
            </td>
            <td>
              <button id="btnPPDelete" styleClass="width20px" class="closeIconWidth centretAlign p-button-danger"
                type="button" pButton (click)="deleteUser(user)" title="Delete user" icon="fa fa-close"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-10">
    </div>
    <div class="col-2">
      <button id="btnPPUpdate" class="rightAlign p-button-success" type="button"
        [disabled]="!selPeopleData || selPeopleData.length === 0" pButton
        (click)="SendPeopleData()" label="Update" title="Update" icon="fa fa-plus-circle"></button>
    </div>
  </div>
</p-panel>
