import { SeedStorageLocationType } from './seedStorageLocationType';
import { DemandCalendarCategoryModel } from './DemandCalendarCategoryModel';
import { RuleModel } from './RuleModel';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { EnvironmentService, UrlConfig } from "src/environments/environment.service";
import { CropModel } from './cropModel';
import { Person } from './person';
import { Message, MessageService, SelectItem } from "primeng/api";
import { ResourceFileHistory } from './ResourceFileHistory';
import { CRAData } from './cRAData';
import { HttpHeaders } from '@angular/common/http'; 
import { HttpService } from '../http/http.service';
import { LookupType } from './lookupType';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;
  msgs: Message[] = [];

  constructor(private _http: HttpService, private environment: EnvironmentService, private _messageService: MessageService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  bindGenerateResourceFileDropdownLists(): Observable<any> {
    return this.http.get(this.urlConfig.resourceFileDropDownLists);
  }

  getSavedRules(): Observable<any> {
    return this.http.get(this.urlConfig.getSavedRules);
  }

  getCrops(): Observable<CropModel[]> {
    return this.http.get(this.urlConfig.crops);
  }

  getSSLTypes(): Observable<SeedStorageLocationType[]> {
    return this.http.get(this.urlConfig.sSLType);
  }

  getLDAPUsers(filter: string): Observable<Person[]> {
    return this.http.get(this.urlConfig.lDAPUsers + '?' + filter)
  }

  getLEAndAOIReportData(filter: string): Observable<any> {
    return this.http.get(this.urlConfig.getLEAndAOIReportData + '?' + filter)
  }

  getResourceFileColumns(): Observable<object[]> {
    return this.http.get(this.urlConfig.resourceFileColumns);
  }

  importResourceFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('ROVFile', file, file.name);
    return this.http.post(this.urlConfig.importResourceFile, formData);
  }

  manualImport(mappedColumns: string, file: File): Observable<any> {
    const formData: any = new FormData();
    formData.append('mappedColumns', mappedColumns);
    formData.append('ROVFile', file, file.name);
    return this.http.post(this.urlConfig.manualImportResourceFile, formData, { responseType: 'arraybuffer' });
  }

  SaveResourceFileHistory(fileName: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post2(this.urlConfig.saveResourceFileHistory, fileName, 'Saving File...', { headers: httpHeaders, responseType: 'json' })
  }

  savePublishFile(fileID: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post2(this.urlConfig.savePublishFile, fileID, 'Publishing...', { headers: httpHeaders, responseType: 'json' });
  }

  GetResourceFileScheduler(): Observable<any> {
    return this.http.get(this.urlConfig.getResourceFileScheduler)
  }

  saveRules(ruleModelList: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveRule, ruleModelList, httpOptions);
  }

  DeleteRules(ruleIDs: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urlConfig.deleteRules, ruleIDs, httpOptions);
  }

  GenerateResourceFile(selectedData: RuleModel[]) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post2(this.urlConfig.generateResourceFile, 
      selectedData, 
      'Generating File...',
      { headers: httpHeaders, responseType: 'arraybuffer' });
  }

  getResourceFileHistory(): Observable<ResourceFileHistory[]> {
    return this.http.get(this.urlConfig.getResourceFileHistory);
  }

  SaveResourceFileScheduler(scheduleData: string, subject: string, body: string): Observable<any> {
    const formData = { ScheduleData: scheduleData, Subject: subject, Body: body };
    return this.http.post(this.urlConfig.saveResourceFileScheduler, formData)
  }

  getCategories(): Observable<CRAData[]> {
    return this.http.get(this.urlConfig.getCategories)
  }

  saveCategories(categories: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveCategories, categories, httpOptions)
  }

  exportCSVForCategoryManager(category: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForCategoryManager, 
      category, 
      { headers: httpHeaders, responseType: 'arraybuffer' })
  }

  generateResourceFileFromHistory(Id: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post2(this.urlConfig.generateResourceFileFromHistory, 
      Id, 
      'Generating File...',
      { headers: httpHeaders, responseType: 'arraybuffer' });
  }

  generateResourceFileFromEmailLink(Id: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post2(this.urlConfig.generateResourceFileFromEmailLink, 
      Id, 
      'Generating Published File...',
      { headers: httpHeaders, responseType: 'arraybuffer' });
  }

  getCalendarCategories(): Observable<DemandCalendarCategoryModel[]> {
    return this.http.get(this.urlConfig.getCalendarCategories);
  }

  saveCalendarCategory(category: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveCalendarCategory, category, httpOptions)
  } 

  getLookupTypes(lookupType:string, activeOnly: boolean): Observable<LookupType[]> {
    return this.http.get(`${this.urlConfig.getLookupTypes}?lookupType=${lookupType}&activeOnly=${activeOnly ? 'true' : 'false'}`);
  } 

  saveLookupTypes(lookupTypes: LookupType[], lookupType: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${this.urlConfig.saveLookupTypes}?lookupType=${lookupType}`, lookupTypes, httpOptions);
  }

  public ConvertDateToString(dt: Date): string {
    if (dt == null) {
        return '-';
    }

    const cloudReleaseDate = new Date('2021-07-24'); //Phase 1 release date of api to cloud
    var date = new Date(dt);
    if(date > cloudReleaseDate){
      const utcDate = new Date(dt);
      date = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    }

    //const date = new Date(dt);
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const hr = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    let mon: string;
    let day: string;
    let year: string;
    let hour: string;
    let minute: string;
    let second: string;

    mon = mm < 10 ? '0' + mm.toString() : mm.toString();
    day = dd < 10 ? '0' + dd.toString() : dd.toString();
    year = yyyy.toString();
    hour = hr < 10 ? '0' + hr.toString() : hr.toString();
    minute = min < 10 ? '0' + min.toString() : min.toString();
    second = sec < 10 ? '0' + sec.toString() : sec.toString();

    let stringDate = year + '/' + mon + '/' + day; // format (yyyy/MM/dd)
    if (stringDate === '1/01/01') {
        stringDate = '-';
    }

    return stringDate;
  } 

  public ConvertDateToStringByFormat(dt: Date, format: string, seperator: string, AppendTime: boolean): string {
    if (dt == null) {
      return '-';
    }

    const date = new Date(dt);
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const hr = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    let mon: string;
    let day: string;
    let year: string;
    let hour: string;
    let minute: string;
    let second: string;

    mon = mm < 10 ? '0' + mm.toString() : mm.toString();
    day = dd < 10 ? '0' + dd.toString() : dd.toString();
    year = yyyy.toString();
    hour = hr < 10 ? '0' + hr.toString() : hr.toString();
    minute = min < 10 ? '0' + min.toString() : min.toString();
    second = sec < 10 ? '0' + sec.toString() : sec.toString();

    let stringDate = year + seperator + mon + seperator + day; // format (yyyy/MM/dd)
    if (stringDate === '1/01/01') {
      stringDate = '-';
    } else {
      if (format === 'yyyy/MM/dd') {
        stringDate = `${year}${seperator}${mon}${seperator}${day}`;
      } else if (format === 'MM/dd/yyyy') {
        stringDate = `${mon}${seperator}${day}${seperator}${year}`;
      } else if (format === 'dd/MM/yyyy') {
        stringDate = `${day}${seperator}${mon}${seperator}${year}`;
      } else if (format === 'yyyy/dd/MM') {
        stringDate = `${year}${seperator}${day}${seperator}${mon}`;
      }

      if (AppendTime) {
        stringDate = `${stringDate} ${hour}:${minute}:${second}`;
      }
    }

    return stringDate;
  }

  public IsValidInteger(intValue:any) {
    return !isNaN(intValue)
      && (function (x) {
        return (x || 0) === x;
      }
      )
        (parseFloat(intValue))
      && intValue.indexOf('-') < 0
      && intValue.indexOf('.') < 0;
  }

  public IsValidEmail(emailValue:any) {
    const EMAIL_REGEXP = /^.*(?=.{8,})[\w.]+@[\w.]+[.][a-zA-Z0-9]+$/;
    return !EMAIL_REGEXP.test(emailValue);
  }

  public randomString(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  } 

  public GetDistinctItems(inputArray: string[]) : SelectItem[] {
    const filteredArray = inputArray.filter(item => item !== undefined);
    let uniqueArray: string[] = [...new Set(filteredArray)];
    let distinctItems: SelectItem[] = uniqueArray.map(code => {
      return {
        label: code,
        value: code
      }
    });

    return distinctItems;
  }

  public GetDistinctSortedItems(inputArray: string[]) : SelectItem[] {
    const filteredArray = inputArray.filter(item => item !== undefined);
    let uniqueArray: string[] = [...new Set(filteredArray)];

    if (uniqueArray && Array.isArray(uniqueArray) && uniqueArray.length > 0) {
      let sortedItems = Array.from(uniqueArray)
        .filter(item => typeof item === 'string' && item.length > 0) // Filter out invalid items
        .sort((a, b) => a.localeCompare(b))
        .map(item => ({ label: item, value: item }));

      return sortedItems;
    } else {
      return [];
    }
  }  

  public DisplayErrorMessage(title: string, message: string) {
    this._messageService.add({ severity: 'error', summary: title, detail: message, life: 10000 });
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._messageService.add({ severity: 'warn', summary: title, detail: message, life: 10000 });
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._messageService.add({ severity: 'info', summary: title, detail: message, life: 10000 });
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._messageService.add({ severity: 'success', summary: title, detail: message, life: 10000 });
  }
  
  public GetGAAData(gaaData: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.gaaData, gaaData, httpOptions)
  }
}