<div id="dvStationGrid">
  <p-table #dt [columns]="cols" id="dtStations" [value]="stationAllData" dataKey="StationID" sortMode="single"
      [scrollable]="true" [(selection)]="selectedItems" scrollHeight="530px"
      columnResizeMode="fit" exportFilename="RDCenter" (onFilter)="filterStationDataTable($event)"
      selectionMode="single" (onRowDblclick)="dt.toggleRow($event)" [loading]="loading"
      [totalRecords]="stationAllData.length" [resizableColumns]="true" [expandedRowKeys]="expandedRows" [(first)]="first">
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix align-center">
        <p-button label="R&D Center Count" icon="fa fa-building" styleClass="p-button-outlined p-button-secondary" badge="{{stationAllData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
        <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
        <button id="btnStationExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
          label="Export" (click)="exportCSVForStations()" style="float:right;margin-left:10px"></button>
        <button id="btnSyncGAA" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left"
          *ngIf="isROVAdmin" label="Sync GAA" (click)="syncGAA()" style="float:right;"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 1.25em"></th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.title ?? col.header">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <p-multiSelect id="msFilterStationCode" *ngSwitchCase="'StationCode'" [(ngModel)]="filteredStationCode"
            [resetFilterOnHide]="true" [options]="distinctCodes" placeholder="All R&D Center"
            (click)="$event.stopPropagation();" appendTo="body"
            (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterStationName" *ngSwitchCase="'StationName'" [(ngModel)]="filteredStationName" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterStationCropName" *ngSwitchCase="'CropNames'" [(ngModel)]="filteredCropNames" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <p-multiSelect id="msProgramRegion" *ngSwitchCase="'RegionName'" [(ngModel)]="filteredRegionNames"
            [options]="distRegions" placeholder="All RCZs" (click)="$event.stopPropagation();" appendTo="body"
            (onChange)="dt.filter($event.value,col.field,'in')" styleClass="ui-column-filter"
            [style]="{'width':'100%'}" [maxSelectedLabels]="3" selectedItemsLabel="{0} items selected"></p-multiSelect>
          <input id="txtFilterStationState" *ngSwitchCase="'State'" [(ngModel)]="filteredState" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterStationCountry" *ngSwitchCase="'Country'" [(ngModel)]="filteredCountry" pInputText
            type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
          <input id="txtFilterStationUSerResponsibility" *ngSwitchCase="'UserResponsibilityStationAssocNames'"
            [(ngModel)]="filteredUserResponsibilityStationAssocNames" pInputText type="text"
            (input)="dt.filter($any($event.target).value, col.field, 'contains')" style="width:100%"
            placeholder="Search">
          <input id="txtFilterStationStatus" *ngSwitchCase="'RequestStatus'" [(ngModel)]="filteredStationRequestStatus"
            pInputText type="text" (input)="dt.filter($any($event.target).value, col.field, 'contains')"
            style="width:100%" placeholder="Search">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
      <tr [pSelectableRow]="rowData" (dblclick)="dt.toggleRow(rowData)">
        <td style="width: 1.25em">
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-pw pi-chevron-circle-right'" style="font-size: 1.25em"></i>
          </a>
        </td>
        <ng-container *ngFor="let col of columns">
          <td *ngIf="!col.hidden">
            {{rowData[col.field]}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-station let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="grid">
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label for="StationName">R&D Center Name :</label>
                  </div>
                  <div class="col-6">
                    <p id="StationName" style="white-space: pre-wrap">{{station.StationName}}</p>
                  </div>
                  <div class="col-6">
                    <label for="StationCode">R&D Center Code :</label>
                  </div>
                  <div class="col-6">
                    <p id="StationCode" style="white-space: pre-wrap">{{station.StationCode}}</p>
                  </div>
                  <div class="col-6">
                    <label for="StationType">R&D Center Type :</label>
                  </div>
                  <div class="col-6">
                    <p id="StationType" style="white-space: pre-wrap">{{station.StationTypeName ? station.StationTypeName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>R&D Center Operations Lead :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.UserResponsibilityStationAssocNames ? station.UserResponsibilityStationAssocNames : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Ship to R&D Center Contact :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.UserResponsibilityShiptoStationAssocNames ? station.UserResponsibilityShiptoStationAssocNames : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>R&D Center Safety Contact :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.UserResponsibilityStationSafetyContactAssocNames ? station.UserResponsibilityStationSafetyContactAssocNames : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label style="word-wrap: break-word">Description : </label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.Description}}</p>
                  </div>
                  <div class="col-6">
                    <label>Crop(s) :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.CropNames}}</p>
                  </div>
                  <div class="col-6">
                    <label>Consolidation Crop(s) :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.ConsolidationCropNames}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label>Address :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.Address}}</p>
                  </div>

                  <div [hidden]="true">
                    <p style="white-space: pre-wrap">{{station.GAAResponseJson}}</p>
                  </div>
                  <div class="col-6">
                    <label style="word-wrap: break-word">Research Commercial Zone :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.RegionName}}</p>
                  </div>
                  <div class="col-6">
                    <label style="word-wrap: break-word">Latitude :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.Latitude}}</p>
                  </div>
                  <div class="col-6">
                    <label style="word-wrap: break-word">Longitude : </label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.Longitude}}</p>
                  </div>
                  <div class="col-6">
                    <label>Geographic Locale :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.GeographicLocale}}</p>
                  </div>
                  <div class="col-6">
                    <label>R3 Site Code :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{station.R3SiteCode ? station.R3SiteCode : '-'}}</p>
                  </div>

                  <div class="col-6">
                    <label>R3 Ship To :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{station.R3ShipTo ? station.R3ShipTo : '-'}}</p>
                  </div><div class="col-6">
                  <label>R3 Sold To :</label>
                </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{station.R3SoldTo ? station.R3SoldTo : '-'}}</p>
                  </div><div class="col-6">
                  <label>R3 Delivery Plant :</label>
                </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{station.R3DeliveryPlant ? station.R3DeliveryPlant : '-'}}</p>
                  </div>

                  <div class="col-6">
                    <label>Status :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.RequestStatus}}</p>
                  </div>
                  <div class="col-6">
                    <label>IT Infrastructure Technology Tier :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.TechnologyTierName ? station.TechnologyTierName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Corporate IT Code :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.CorporateITCode ? station.CorporateITCode : '-'}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="col">
              <p-panel class="height">
                <div class="grid grid-nogutter">
                  <div class="col-6">
                    <label>Seed Storage Location :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap; word-wrap: break-word">{{station.SSL ? station.SSL : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>PRISM LE ID :</label>
                  </div>
                  <div class="col-6">
                    <p style="word-wrap: break-word">{{station.PRISMLEID ? station.PRISMLEID : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Created By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.CreatedByUserName}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Created :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(station.CreatedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Approved By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.ApprovedByUserName ? station.ApprovedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Approved :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(station.ApprovedOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Retired By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.RetiredByUserName ? station.RetiredByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Retired :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(station.RetiredOn)}}</p>
                  </div>
                  <div class="col-6">
                    <label>Last Modified By :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{station.ModifiedByUserName ? station.ModifiedByUserName : '-'}}</p>
                  </div>
                  <div class="col-6">
                    <label>Date Last Modified :</label>
                  </div>
                  <div class="col-6">
                    <p style="white-space: pre-wrap">{{ConvertDateToString(station.ModifiedOn)}}</p>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-2">
              <button id="btnStationComments" pButton type="button" style="width: 100%" (click)="viewComments(station)"
                label="{{station.ApprovalComments === null || station.ApprovalComments.length === 0 ? 'Add Comment' : 'View / Add Comment(s)'}}"
                iconPos="left"
                [icon]="station.ApprovalComments === null || station.ApprovalComments.length === 0 ? 'fa fa-pencil' : 'fa fa-comments-o'"
                class="p-button-info"></button>
            </div>
            <div class="col-2">
              <div
                [hidden]="!((station.CreatedBy !== null && station.CreatedBy.Email.toLocaleLowerCase() === userName) || isROVAdmin)">
                <button id="btnEditStations" pButton type="button" style="width: 100%" (click)="editStation(station)" label="Edit"
                  iconPos="left" icon="fa fa-fw fa-edit"></button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="8">
          No records found
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="View / Add Comments" [style]="{width: '900px', height:'470px'}" [(visible)]="displayComments"
  showEffect="fade" [modal]="true">
  <view-comments [disableCommentButton]="disableCommentButton" [approvalCommentData]="approvalCommentData"
    [sourceIdentifier]="sourceIdentifier" (saveCommentEvent)="onSaveComment($event)"></view-comments>
</p-dialog>

<p-dialog header="R&D Center Details" [(visible)]="dialogVisible" [contentStyle]="{'overflow':'visible'}" showEffect="fade"
  [modal]="true" [style]="{width: '1000px'}" (onHide)="onCloseEditStation($event, frm)" [closeOnEscape]="false" [closable]="false"
  [showHeader]="true">
  <p-panel appendTo="body">
    <form #frm="ngForm" [formGroup]="stationFormGroup">
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-5 controlLabel">
              <label for="StationName">R&D Center Name</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtStationName" [(ngModel)]="stationForm.StationName"
              formControlName="StationName" minlength="1" maxlength="25" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="stationCode">R&D Center Code</label>
            </div>
            <div class="col-7">
              <input id="txtStationCode" [(ngModel)]="stationForm.StationCode" formControlName="stationCode"
                pInputText minlength="2" maxlength="4" styleClass="width100"
                (keydown)="stationForm.PRISMLEID = ''" />
            </div>
            <div class="col-5 controlLabel">
              <label for="ddStationTypes">R&D Center Type</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddStationTypes" name="stationTypes" [options]="stationTypes" [filter]=true
                [(ngModel)]="stationForm.StationTypeID" formControlName="StationTypeID" appendTo="body"
                [style]="{'width':'100%'}" placeholder="Select a R&D Center Type" [showClear]="true">
              </p-dropdown>
            </div>
            <!-- <div class="col-1">
                <button id="btnClearST" (click)="clearST()" pButton icon="fa fa-close" title="Clear Selection"
                  class="buttonStyle" style="border:none;background-color: white; color: black;"></button>
              </div> -->
              <div class="col-5 controlLabel">
                <label for="lstStationOperationLead">R&D Center Operations Lead</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstStationOperationLead" [options]="stationOperationLead" optionLabel="label"
                      [(ngModel)]="stationForm.UserResponsibilityStationAssocNames"
                      formControlName="UserResponsibilityStationAssocNames" styleClass="width100"
                      [style]="{'min-height':'35px'}" [listStyle]="{'max-height':'100px'}">
                    </p-listbox>
                  </div>
                  <div class="col-2">
                    <button id="btnStationLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                      style="width: 30px" (click)="ShowPeoplePicker('OPLD')"
                      title="Add R&D Center Operation Lead"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label for="lstShiptoStationContact">Ship to R&D Center Contact</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstShiptoStationContact" [options]="shiptoStationContact" optionLabel="label"
                      [(ngModel)]="stationForm.UserResponsibilityShiptoStationAssocNames"
                      formControlName="UserResponsibilityShiptoStationAssocNames" styleClass="width100"
                      [style]="{'min-height':'35px'}" [listStyle]="{'max-height':'100px'}">
                    </p-listbox>
                  </div>
                  <div class="col-2">
                    <button id="btnShiptoStationContact" type="button" pButton icon="fa fa-user-plus"
                      class="p-button-info" style="width: 30px" (click)="ShowPeoplePicker('SSHP')"
                      title="Add Ship to R&D Center Contact"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label for="lstStationSafetyContact">R&D Center Safety Contact</label>
              </div>
              <div class="col-7">
                <div class="grid">
                  <div class="col-10">
                    <p-listbox id="lstStationSafetyContact" [options]="stationSafetyContact" optionLabel="label"
                      [(ngModel)]="stationForm.UserResponsibilityStationSafetyContactAssocNames"
                      formControlName="UserResponsibilityStationSafetyContactAssocNames" styleClass="width100"
                      [style]="{'min-height':'35px'}" [listStyle]="{'max-height':'100px'}">
                    </p-listbox>
                  </div>
                  <div class="col-2">
                    <button id="btnStationSafetyContact" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                      style="width: 30px" (click)="ShowPeoplePicker('SAFE')"
                      title="Add R&D Center Safety Contact"></button>
                  </div>
                </div>
              </div>
              <div class="col-5 controlLabel">
                <label for="msStationCropName">Crop(s)</label>
              </div>
              <div class="col-7">
                <p-multiSelect id="msStationCropName" [options]="crops" [(ngModel)]="selectedCrops" placeholder="Select a Crop(s)"
                  formControlName="crop" appendTo="body" styleClass="width100" (onChange)="changeCrops($event)"
                  selectedItemsLabel="{0} items selected">
                </p-multiSelect>
              </div>
              <div class="col-5 controlLabel">
                <label for="msStationConsolidationCrops">Consolidation Crop(s)</label>
              </div>
              <div class="col-7">
                <p-multiSelect id="msStationConsolidationCrops" [options]="consolidationCrops" [(ngModel)]="selectedConsolidationCropIds" placeholder="Select a Crop(s)"
                  formControlName="consolidationCrop" appendTo="body" styleClass="width100" (onChange)="changeConsildationCrops($event)"
                  selectedItemsLabel="{0} items selected">
                </p-multiSelect>
              </div>
              <div class="col-5 controlLabel">
                <label for="txtStationDescription">Description</label>
              </div>
              <div class="col-7">
                <textarea id="txtStationDescription" [cols]="30" [rows]="2" minlength="1" maxlength="255" pInputTextarea
                [(ngModel)]="stationForm.Description" formControlName="description" class="p-inputtext"></textarea>
              </div>
            </div>
          </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-5 controlLabel">
              <label for="txtStationAddress">Address</label>
            </div>
            <div class="col-7">
              <textarea id="txtStationAddress" [cols]="30" [rows]="2" maxlength="150" pInputTextarea
                [(ngModel)]="stationForm.Address" formControlName="Address" class="p-inputtext"></textarea>
            </div>
            <div class="col-5 controlLabel">
              <label for="ddstationTechnologyTier">IT Infrastructure Technology Tier</label>
            </div>
            <div class="col-7">
              <div class="grid">
                <div class="col-10">
                  <p-dropdown id="ddstationTechnologyTier" [options]="technologyTiers" [(ngModel)]="stationForm.TechnologyTierTypeId"
                  formControlName="TechnologyTierTypeId" appendTo="body" styleClass="width100"
                  placeholder="Select a Technology Tier"  [showClear]="true" [filter]=true>
                  </p-dropdown>
                </div>
                <div class="col-2">
                  <button id="btnTechTierDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="showTechnologyTierDialog()"
                  title="Add/Edit IT Infrastructure Technology Tier"></button>
                </div>
              </div>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtCorpITCode">Corporate IT Code</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtCorpITCode" [(ngModel)]="stationForm.CorporateITCode"
              formControlName="CorporateITCode" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationR3SiteCode">R3 Site Code</label>
            </div>
            <div class="col-7">
              <input id="txtStationR3SiteCode" pInputText [(ngModel)]="stationForm.R3SiteCode"
                formControlName="R3SiteCode" styleClass="width100" />
            </div>

            <div class="col-5 controlLabel">
              <label for="txtStationR3ShipTo">R3 Ship To</label>
            </div>
            <div class="col-7">
              <input id="txtStationR3ShipTo" pInputText [(ngModel)]="stationForm.R3ShipTo"
                     formControlName="R3ShipTo" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationR3SoldTo">R3 Sold To</label>
            </div>
            <div class="col-7">
              <input id="txtStationR3SoldTo" pInputText [(ngModel)]="stationForm.R3SoldTo"
                     formControlName="R3SoldTo" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationR3DeliveryPlant">R3 Delivery Plant                      </label>
            </div>
            <div class="col-7">
              <input id="txtStationR3DeliveryPlant" pInputText [(ngModel)]="stationForm.R3DeliveryPlant"
                     formControlName="R3DeliveryPlant" styleClass="width100" />
            </div>

            <div class="col-5 controlLabel">
              <label for="ddStationRegionCodes">Research Commercial Zone</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddStationRegionCodes" name="regionCodes" [options]="regionCodes" [filter]=true
                [(ngModel)]="stationForm.RegionID" formControlName="RegionID" appendTo="body"
                [style]="{'width':'100%'}" placeholder="Select a RCZ" [showClear]="true">
              </p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationLatitude">Latitude</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtStationLatitude" type="text" maxlength="15" step="any"
                [(ngModel)]="stationForm.Latitude" formControlName="Latitude" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationLongitude">Longitude</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtStationLongitude" type="text" maxlength="15" step="any"
                [(ngModel)]="stationForm.Longitude" (keyup)="GetGAAData($event)" formControlName="Longitude" styleClass="width100" />
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationGeographicLocale">Geographic Locale</label>
            </div>
            <div class="col-7">
              <input pInputText id="txtStationGeographicLocale" type="text" readonly
                [(ngModel)]="stationForm.GeographicLocale" formControlName="GeographicLocale" styleClass="width100" />
          </div>
            <div class="col-5 controlLabel">
              <label for="ddStationStatus">Status</label>
            </div>
            <div class="col-7">
              <p-dropdown id="ddStationStatus" [style]="{'width':'100%'}" name="status" [filter]=true
                [options]="status" [(ngModel)]="stationForm.RequestStatus" formControlName="RequestStatus"
                placeholder="Select a Status" (onChange)="statusChange(stationForm.RequestStatus,true)"
                appendTo="body" [showClear]="true">
              </p-dropdown>
            </div>
            <div class="col-5 controlLabel">
              <label for="txtStationPRISMLEID">PRISM LE ID</label>
            </div>
            <div class="col-7">
              <input id="txtStationPRISMLEID" type="number" pInputText id="PRISMLEID"
                [(ngModel)]="stationForm.PRISMLEID" formControlName="PRISMLEID" maxlength="9"
                styleClass="width100" readonly="readonly" />
            </div>
            <div class="col-5">
            </div>
            <div class="col-7">
              <button id="btnStationCheckLEID" type="button" pButton icon="fa fa-eye" class="p-button-info"
                (click)="GetLEID(stationForm)" label="Check Legal Entity" title="Check Legal Entity"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </p-panel>
  <ng-template pTemplate="footer">
    <div [hidden]="!isROVUser" style="display: inline;">
      <button id="btnCancelStation" type="button" pButton icon="fa fa-close" class="p-button-warning"
        (click)="close(frm)" label="Cancel" style='width: 200px;'>
      </button>
    </div>
    <button id="btnSaveStation" type="submit" pButton [icon]="saveIcon" class="{{saveIconClass}}"
      (click)="saveStation(frm, stationForm.RequestStatus)"
      [disabled]="!(frm.form.valid || stationForm.RequestStatus === 'Declined')" label="{{saveLabelText}}"
      style='width: 200px;'></button>
  </ng-template>
</p-dialog>

<p-dialog [header]="peoplePickerHeader" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayStationLead"
  showEffect="fade" [modal]="true" [closeOnEscape]="true">
  <app-peoplepicker [existingPeopleString]="existingPeopleString"
    (addUserDataClick)="onaddUserDataClick($event)"></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgStationSelectAOI" header="Select LE ID"
  [(visible)]="displayLEIDWindow" [style]="{'top': positionTop, 'left': positionLeft, width: 'width', height:'height'}"
  [closeOnEscape]="true" [closable]="true" showEffect="fade" [modal]="true"  [resizable]="false">
  <app-leidpicker id="appStationLEID" [existingLEIDString]="existingLEIDString" (addLEDataClick)="onaddLEDataClick($event)"></app-leidpicker>
</p-dialog>

<p-dialog id="dlgEditLookup" *ngIf="selectedTabIndex === 1" [header]="lookupDialogHeader" [style]="{'width': '1000px', 'height': '580px'}"
  [(visible)]="displayLookupDialog" showEffect="fade" [modal]="true" (onHide)="closeTechnologyTierDialog()">
  <manage-lookup [lkpType]="lookupType"  (getLookUpEvent)="getLookupTypes()"></manage-lookup>
</p-dialog>
