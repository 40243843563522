import { Component, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import { Constants } from './../shared/constants';
import { AppService } from '../app.service';
import { StationService } from '../station/station.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BaseService } from '../basecomponents/base.service';
import { BaseComponent } from './../basecomponents/base.component';
import {SharedService} from './../services/shared.service';

@Component({
  selector: 'app-recent-history',
  templateUrl: './recent-history.component.html',
  styleUrls: ['./recent-history.component.css']
})
export class RecentHistoryComponent  extends BaseComponent {
  hideMessage = true;
  pendingChanges: any[];
  recentComments: any[];
  selectedComments: any[] = [];
  selectedCommentIDs: string[] = [];
  loadingRequests = false;
  loadingComments = false;
  public panelHeight = '500px';
  public gridHeight = '390px';
  colsPendingChanges: any[];
  colsRecentComments: any[];
  @ViewChild('dvPendingChanges') dvPendingChanges: ElementRef;

  constructor(private _appService: AppService, private _stationService: StationService,
    private _confirmation: ConfirmationService, public override _baseService: BaseService,
    _messageService: MessageService, sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.buildForm();

    super.setupRoles();
    this.hideMessage = this.isROVAdmin;
  }

  buildForm() {
    this.colsPendingChanges = [
      { field: 'CreatedBy', header: 'Requester', width: '50%' },
      { field: 'RequestType', header: 'Area Type', width: '20%' },
      { field: 'Code', header: 'Code', width: '10%' },
      { field: 'CreatedOn', header: 'Date', width: '20%' }
    ];
    this.colsRecentComments = [
      { field: 'ApprovalCommentID', header: 'Comment ID', hidden: 'true' },
      { field: 'Commenter', header: 'Commenter', width: '25%' },
      { field: 'Comment', header: 'Comment', width: '20%' },
      { field: 'CommentType', header: 'Research Area Type', width: '25%' },
      { field: 'Code', header: 'Code', width: '10%' },
      { field: 'CommentDate', header: 'Date', width: '20%' }
    ];

    this.getRecentHistory();
    this.SelectMenuOption('RecentHistory');
  }

  getRecentHistory() {
    this.loadingRequests = true;
    this.loadingComments = true;
    this._appService.getRecentHistory()
      .subscribe({
        next: data => {
          this.pendingChanges = [];
          this.recentComments = [];
          if (data) {
            if (data.PendingRequests) {
              this.pendingChanges = data.PendingRequests;
              this.loadingRequests = false;
            }

            if (data.AllComments) {
              this.recentComments = data.AllComments;
              this.loadingComments = false;
            }
          }
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.loadingRequests = false;
          this.loadingComments = false;
        }
      });
  }

  public DeleteComments() {
    if (this.selectedComments.length === this.recentComments.length && this.recentComments.length > 1) {
      this.DisplayErrorMessage('Error', 'You cannot delete all comments in one go...');
      return;
    }

    this._confirmation.confirm({
      message: Constants.CONFIRMDELETE,
      accept: () => {
        this.DeleteCommentsAfterConfirmation();
      }
    });
  }

  public DeleteCommentsAfterConfirmation() {
    this.loadingComments = true;
    this.selectedCommentIDs = [];

    this.selectedComments.forEach(s => {
      this.selectedCommentIDs.push(s.ApprovalCommentID);
    }
    );

    this._stationService.deleteRecentComments(JSON.stringify(this.selectedCommentIDs))
      .subscribe({
        next: data => {
          this.recentComments = [];
          this.selectedCommentIDs = [];
          this.selectedComments = [];

          if (data) {
            this.recentComments = data;
            this.loadingComments = false;
          } else {
            this.loadingComments = false;
          }

          this.DisplaySuccessMessage('Success', 'Selected Comment(s) deleted successfully...');
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.loadingComments = false;
        }
      });
  }
}