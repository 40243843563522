<div>
    <p-tabView (onChange)="onTabChange($event)" [activeIndex]="index">
    <p-tabPanel id="tabProgram" header="Program" leftIcon="fa fa-database" [selected]="selectedTabIndex === 0">
      <app-program [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-program>
    </p-tabPanel>
    <p-tabPanel id="tabStation" header="R&D Center" leftIcon="fa fa-building" [selected]="selectedTabIndex === 1">
      <app-station [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-station>
    </p-tabPanel>
    <p-tabPanel id="tabSSL" header="Seed Storage Location" leftIcon="fa fa-location-arrow" [selected]="selectedTabIndex === 2">
      <app-seedstoragelocation [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-seedstoragelocation>
    </p-tabPanel>
    <p-tabPanel id="tabEZ" header="Evaluation Zone" leftIcon="fa fa-crop" [selected]="selectedTabIndex === 3">
      <app-evaluationzone [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-evaluationzone>
    </p-tabPanel>
    <p-tabPanel id="tabRegion" header="Research Commercial Zone" leftIcon="fa fa-globe" [selected]="selectedTabIndex === 4">
      <app-region [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-region>
    </p-tabPanel>
    <p-tabPanel id="tabBZ" header="Breeding Zone" leftIcon="fa fa-road" [selected]="selectedTabIndex === 5">
      <app-breedingzone [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-breedingzone>
    </p-tabPanel>
    <p-tabPanel id="tabCPField" header="CP Field Scientist" leftIcon="fa fa-flask" [selected]="selectedTabIndex === 6">
      <app-cpFieldscientist [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-cpFieldscientist>
    </p-tabPanel>
    <p-tabPanel id="tabCMZ" header="Commercial Market Zone" leftIcon="fa fa-telegram" [selected]="selectedTabIndex === 7">
      <app-commercialmarket [selectedTabIndex]="selectedTabIndex" [selectedRequestID]="selectedRequestID" (cleanUpCache)="onCleanUpCache($event)"
        [cleanCache]="cleanCache">
      </app-commercialmarket>
    </p-tabPanel>
  </p-tabView>
</div>

<div *ngIf="!hideHomeTab" style="text-align:center">
  <br>
  Please select a tab to
  <b>View Research Area Data</b>.
</div>

<div *ngIf="isDownloading" style="text-align:center">
  <br>
  <b style="color: rgb(255, 85, 0) !important; font-size: x-large !important;">Downloading published file: {{publishedFile}}</b>.
</div>