import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem, Message } from 'primeng/api';
import { Renderer2 } from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';
import { Constants, CountriesModel, CreateFileModel, CropModel, EvaluationModel, ProgramsModel, RegionModel, RuleModel, StationsModel } from '../shared';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-createfile',
  templateUrl: './createfile.component.html',
  styleUrls: ['./createfile.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CreatefileComponent implements OnInit {
  display = true;
  msgs: Message[] = [];
  public stationCropName: CropModel[] = [];
  selectedCrops: CropModel[] = [];
  crops: CropModel[] = [];
  public RegionName: RegionModel[] = [];
  selectRegions: RegionModel[] = [];
  regions: RegionModel[] = [];
  selectedScopes: any[];
  public ProgramsName: ProgramsModel[] = [];
  selectedPrograms: ProgramsModel[] = [];
  programs: ProgramsModel[] = [];
  public EvaluationsName: EvaluationModel[] = [];
  selectedEvaluations: EvaluationModel[] = [];
  evaluations: EvaluationModel[] = [];
  public StationsName: StationsModel[] = [];
  selectedStations: StationsModel[] = [];
  stations: StationsModel[] = [];
  public CountriesName: CountriesModel[] = [];
  selectedCountries: CountriesModel[] = [];
  countries: CountriesModel[] = [];
  public ruleModel: RuleModel[] = [];
  public ruleResult: CreateFileModel[] = [];
  public ruleResultCopy: CreateFileModel[] = [];
  public cropCode: string[] = [];
  public username = '';
  ruleIDs: string[] = [];
  public fieldName: string;
  public regionCode: string[] = [];
  public evalCode: string[] = [];
  public programCode: string[] = [];
  public countryCode: string[] = [];
  public stationCode: string[] = [];
  public originalValue = '';
  public isDataChanged = false;
  public defaultData: CreateFileModel[] = [];
  public filteredData: CreateFileModel[] = [];
  public selectedRow: CreateFileModel | null;
  public sortedCodes: SelectItem[];
  loading: boolean;
  cols: any[];
  filter: any[];
  rowGroupMetadata: any;
  ActYear1 = false;
  ActYear2 = false;
  ActYear3 = false;
  EstYear1 = false;
  EstYear2 = false;
  EstYear3 = false;
  displayTitle = false;
  disableTitleButton = true;
  txtRandomString = '';
  scrollHeight = '500px';
  createFileTitle: string = '';

  @ViewChild('ms') multiselect: MultiSelect;
  isMSVisible = false;
  constructor(private _commonService: CommonService, private _confirmation: ConfirmationService,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.bindGenerateResourceFileDropdownLists();
    this.cols = [
      { field: 'Title', header: 'Title' },
      { field: 'Crops', header: 'Crop' },
      { field: 'Regions', header: 'RCZ', title: 'Research Commercial Zone' },
      { field: 'EvaluationZones', header: 'Evaluation Zone' },
      { field: 'Programs', header: 'Program' },
      { field: 'Stations', header: 'R&D Center' },
      { field: 'Countries', header: 'Country' },
      { field: 'CreatedBy', header: 'Created By' },
      { field: 'CreatedOn', header: 'Created On' }
    ];

    this.renderer.listen('document', 'click', () => {
      if (this.isMSVisible && this.ruleResultCopy.length > 0 && !(this.multiselect && this.multiselect.overlayVisible)) {
        this.isMSVisible = false;
        this.ruleResult = JSON.parse(JSON.stringify(this.ruleResultCopy));
        this.ruleResultCopy = [];
      }
    });

    setTimeout(function () {
      const resNav =  document.querySelectorAll('#res-nav');
      if(resNav && resNav.length > 0) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = 'grey';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(4) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(4) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(4) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(5) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
      }
    }, 100);
  }

  onTitleDialogShow(){
    const title: any = (document.querySelector('#txtCreateFileTitleData') as HTMLInputElement).value;
    (document.querySelector('#txtCreateFileTitle') as HTMLTextAreaElement).focus();
    (document.querySelector('#txtCreateFileTitle') as HTMLInputElement).value = title;
    (document.querySelector('#txtCreateFileTitleOriginal') as HTMLInputElement).value = title;
  }

  editTitle(rowData: any, rowIndex: any, copy: any) {
    (document.querySelector('#txtCreateFileTitleIndex') as HTMLInputElement).value = rowIndex;
    (document.querySelector('#txtCreateFileTitleData') as HTMLInputElement).value = rowData.Title;
    (document.querySelector('#txtCreateFileCopy') as HTMLInputElement).value = copy;

    this.disableTitleButton = false;
    this.displayTitle = true;
  }

  validateTitle() {
    if ((document.querySelector('#txtCreateFileTitle') as HTMLTextAreaElement).value) {
      this.disableTitleButton = false;
    } else {
      this.disableTitleButton = true;
    }
  }

  setTitle() {
    const title = (document.querySelector('#txtCreateFileTitle') as HTMLTextAreaElement).value;
    const origTitle = (document.querySelector('#txtCreateFileTitleOriginal') as HTMLTextAreaElement).value;

    if (!title || title.toString().trim() === '') {
      this.DisplayErrorMessage('Error', 'Please enter the title first...');
      (document.querySelector('#txtCreateFileTitle') as HTMLTextAreaElement).focus();
      return;
    }

    if (this.ruleResult.filter(s => s.Title.toString().trim().toLowerCase() === title.trim().toLowerCase()).length > 0) {
      this.DisplayErrorMessage('Error', 'This Title is already assigned to some other Rule. Please enter a different Title...');
      (document.querySelector('#txtCreateFileTitle') as HTMLTextAreaElement).focus();
      return;
    }    

    this.disableTitleButton = true;

    // #txtCreateFileTitleIndex is set to -1 for Add Rule
    // #txtCreateFileTitleIndex is set to the row index of the rule that is being renamed and Copy Rule
    const createFileTitleIndexStr = (document.querySelector('#txtCreateFileTitleIndex') as HTMLTextAreaElement).value;
    const index = createFileTitleIndexStr ? parseInt(createFileTitleIndexStr, 10) : -1;

    if(index >= 0) {
      // #txtCreateFileCopy is set to -1 for Add Rule and renamed rule
      // #txtCreateFileCopy is set to 1 for Copy Rule
      const createFileCopyStr = (document.querySelector('#txtCreateFileCopy') as HTMLTextAreaElement).value;
      const copy = createFileCopyStr ? parseInt(createFileCopyStr, 10) : 0;

      if(index === this.ruleResult.findIndex(s => s.Title.toString().trim().toLowerCase() === origTitle.trim().toLowerCase())) {
        const rules = this.ruleResult.filter(s => s.Title.toString().trim().toLowerCase() === origTitle.trim().toLowerCase());
        if(rules && rules.length > 0) {
          if(copy === 1) {
            rules.forEach(r=> {
              const rule = new CreateFileModel();
              rule.GUID = '00000000-0000-0000-0000-000000000000';
              rule.Title = title;
              rule.Crops = r.Crops;
              rule.CropNames = r.CropNames;
              rule.Countries = r.Countries;
              rule.CountryNames = r.CountryNames;
              rule.EvaluationZones = r.EvaluationZones;
              rule.EvaluationZoneNames = r.EvaluationZoneNames;
              rule.Programs = r.Programs;
              rule.ProgramNames = r.ProgramNames;
              rule.Regions = r.Regions;
              rule.RegionNames = r.RegionNames;
              rule.Stations = r.Stations;
              rule.StationNames = r.StationNames;
              rule.CreatedBy = this.username;
              rule.CreatedOn = this._commonService.ConvertDateToStringByFormat(new Date(), 'yyyy/MM/dd', '/', false);
              this.ruleResult.push(rule);
            });
            
            this.ruleResult = this.ruleResult.slice();
          }
          else {
            rules.forEach(r => {
              r.Title = title;
            });
          }
        }
      }
    }
    else {
      const rule = new CreateFileModel();
      rule.GUID = '00000000-0000-0000-0000-000000000000';
      rule.Title = title;
      rule.Crops = this.selectedCrops;
      rule.CropNames = ['Select Crops'];
      rule.Countries = [];
      rule.CountryNames = ['Select Countries'];
      rule.EvaluationZones = [];
      rule.EvaluationZoneNames = ['Select EZs'];
      rule.Programs = [];
      rule.ProgramNames = ['Select Programs'];
      rule.Regions = [];
      rule.RegionNames = ['Select RCZs'];
      rule.Stations = [];
      rule.StationNames = ['Select R&D Centers'];
      rule.CreatedBy = this.username;
      rule.CreatedOn = this._commonService.ConvertDateToStringByFormat(new Date(), 'yyyy/MM/dd', '/', false);
      this.ruleResult.push(rule);
      this.ruleResult = this.ruleResult.slice();
    }
    
    this.updateRowGroupMetaData();

    this.txtRandomString = '';
    this.displayTitle = false;
  }

  addNewRule(dt: any) {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    let ruleTitle = 'New Title';
    this.createFileTitle = ruleTitle;
    this.selectedRow = null;

    this.disableTitleButton = true;
    this.editTitle( { Title: ruleTitle }, -1, -1);
  }

  Add(rowData: any, rowIndex: any) {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    const length = this.ruleResult.filter(s => s.Title.trim().toLowerCase() === rowData.Title.trim().toLowerCase()).length;
    if (length > 20) {
      this.DisplayErrorMessage('Error', 'User cannot add more than 20 sub rules..');
      return;
    } 
    else {
      const temp = {
        GUID: '00000000-0000-0000-0000-000000000000',
        Title: rowData.Title,
        Crops: [],
        CropNames: ['Select Crops'],
        Countries: [],
        CountryNames: ['Select Countries'],
        EvaluationZones: [],
        EvaluationZoneNames: ['Select EZs'],
        Programs: [],
        ProgramNames: ['Select Programs'],
        Regions: [],
        RegionNames: ['Select RCZs'],
        Stations: [],
        StationNames: ['Select R&D Centers'],
        CreatedBy: this.username,
        CreatedOn: this._commonService.ConvertDateToStringByFormat(new Date(), 'yyyy/MM/dd', '/', false)
      };

      let result: CreateFileModel[] = [];
      result = this.ruleResult;
      result.splice(rowIndex + this.ruleResult.filter(s => s.Title === rowData.Title).length, 0, temp);
      this.ruleResult = result;
      this.ruleResult = this.ruleResult.slice();
      this.updateRowGroupMetaData();
    }
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.ruleResult) {
      for (let i = 0; i < this.ruleResult.length; i++) {
        const rowData = this.ruleResult[i];
        const title = rowData.Title;
        if (i === 0) {
          this.rowGroupMetadata[title] = { index: 0, size: 1 };
        } else {
          const previousRowData = this.ruleResult[i - 1];
          const previousRowGroup: any[] = [];
          previousRowGroup.push(previousRowData.Title);
          if (previousRowGroup.find(f => f === title)) {
            this.rowGroupMetadata[title].size++;
          } else {
            this.rowGroupMetadata[title] = { index: i, size: 1 };
          }
        }
      }
    }

    //let temp = this.ruleResult.slice();
    //temp.sort((a,b)=>a.Title.localeCompare(b.Title, undefined, {sensitivity: 'base'}));

    this.ruleResult = this.ruleResult.slice();
  }

  bindGenerateResourceFileDropdownLists() {
    this._commonService.bindGenerateResourceFileDropdownLists().subscribe(
      data => {
        if (data.Crops && data.Crops.length > 0) {
          this.stationCropName = data.Crops;
          this.stationCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (data.RegionCodes && data.RegionCodes.length > 0) {
          this.RegionName = data.RegionCodes;
          this.RegionName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }

        if (data.EvaluationZoneCodes && data.EvaluationZoneCodes.length > 0) {
          this.EvaluationsName = data.EvaluationZoneCodes;
          this.EvaluationsName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }

        if (data.ProgramCodes && data.ProgramCodes.length > 0) {
          this.ProgramsName = data.ProgramCodes;
          this.ProgramsName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }

        if (data.StationCodes && data.StationCodes.length > 0) {
          this.StationsName = data.StationCodes;
          this.StationsName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }

        this.CountriesName = [];
        if (data.CountryCodes && data.CountryCodes.length > 0) {
          data.CountryCodes.forEach((element: any) => {
            this.CountriesName.push({
              Id: '', Name: '', RegionId: '', Country: '',
              label: element.CountryCode, value: element.CountryCode, CountryCode: element.CountryCode
            });
          });
        }
        this.isDataChanged = false;

        this.getSavedRules();
      }
    );
  }

  getSavedRules() {
    let id;
    let createdBy;
    let createdOn;
    let title;
    this.ruleResult = [];
    this.distinctDropDownValues();
    this.loading = true;

    this._commonService.getSavedRules().subscribe(
      data => {
        id = '';
        title = '';
        createdBy = '';
        createdOn = '';

        data.forEach((element: any) => {
          id = element.GUID;
          title = element.Title;
          createdBy = element.CreatedBy;
          createdOn = element.CreatedOn;
          this.crops = [];
          this.cropCode = [];
          this.regions = [];
          this.regionCode = [];
          this.programs = [];
          this.programCode = [];
          this.countries = [];
          this.countryCode = [];
          this.stations = [];
          this.stationCode = [];
          this.evaluations = [];
          this.evalCode = [];

          if (!element.Crops || element.Crops.length === 0) {
            this.cropCode = ['Select Crops'];
          } else {
            element.Crops.forEach((el: any) => {
              this.stationCropName.filter(e => e.CropID === el.CropID).forEach(e2 => {
                this.crops.push(el.CropID);
                this.cropCode.push(e2.CropDescription);
              });
            });
          }
          if (!element.Programs || element.Programs.length === 0) {
            this.programCode = ['Select Programs'];
          } else {
            element.Programs.forEach((el: any) => {
              this.selectedPrograms.filter(e => e.Id === el.Id).forEach(e2 => {
                this.programs.push(el.Id);
                this.programCode.push(e2.Code);
              });
            });
          }
          if (!element.Regions || element.Regions.length === 0) {
            this.regionCode = ['Select RCZs'];
          } else {
            element.Regions.forEach((el: any) => {
              this.selectRegions.filter(e => e.Id === el.Id).forEach(e2 => {
                this.regions.push(el.Id);
                this.regionCode.push(e2.Code);
              });
            });
          }
          if (!element.EvaluationZones || element.EvaluationZones.length === 0) {
            this.evalCode = ['Select EZs'];
          } else {
            element.EvaluationZones.forEach((el: any) => {
              this.selectedEvaluations.filter(e => e.Id === el.Id).forEach(e2 => {
                this.evaluations.push(el.Id);
                this.evalCode.push(e2.Code);
              });
            });
          }
          if (!element.Stations || element.Stations.length === 0) {
            this.stationCode = ['Select R&D Centers'];
          } else {
            element.Stations.forEach((el: any) => {
              this.selectedStations.filter(e => e.Id === el.Id).forEach(e2 => {
                this.stations.push(el.Id);
                this.stationCode.push(e2.Code);
              });
            });
          }
          if (!element.Countries || element.Countries.length === 0) {
            this.countryCode = ['Select Countries'];
          } else {
            element.Countries.forEach((el: any) => {
              this.countries.push(el.CountryCode);
              this.countryCode.push(el.CountryCode);
            });
          }

          this.ruleResult.push({
            GUID: id,
            Title: title,
            Crops: this.crops,
            CropNames: this.cropCode,
            Countries: this.countries,
            CountryNames: this.countryCode,
            Regions: this.regions,
            RegionNames: this.regionCode,
            Programs: this.programs,
            ProgramNames: this.programCode,
            EvaluationZones: this.evaluations,
            EvaluationZoneNames: this.evalCode,
            Stations: this.stations,
            StationNames: this.stationCode,
            CreatedBy: createdBy,
            CreatedOn: createdOn
          });
        });
        this.filteredData = [];

        this.defaultData = (JSON.parse(JSON.stringify(this.ruleResult)));
        this.updateRowGroupMetaData();
        this.loading = false;
      }
    );
  }

  distinctDropDownValues() {
    const items = this.RegionName;
    const countryItems = this.CountriesName;
    const stationItems = this.StationsName;
    const evalZoneItems = this.EvaluationsName;
    const programItems = this.ProgramsName;
    let flags: { [code: string]: boolean } = {};
    this.selectRegions = [];
    this.selectedEvaluations = [];
    this.selectedPrograms = [];
    this.selectedStations = [];
    this.selectedCountries = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (!flags[items[i].Code]) {
          flags[items[i].Code] = true;
          this.selectRegions.push({
            Id: items[i].Id,
            Name: '',
            Description: '',
            Code: items[i].Code,
            CropID: '',
            RequestStatus: '',
            value: items[i].Id,
            label: items[i].Code
          });
        }
      }
      if (this.selectRegions) {
        this.sortedCodes = this.selectRegions.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    if (programItems) {
      flags = {};
      for (let i = 0; i < programItems.length; i++) {
        if (!flags[programItems[i].Code]) {
          flags[programItems[i].Code] = true;
          this.selectedPrograms.push({
            Id: programItems[i].Id,
            Name: '',
            Code: programItems[i].Code,
            CropID: '',
            EvaluationZoneId: '',
            StationId: '',
            value: programItems[i].Id,
            label: programItems[i].Code
          });
        }
      }
      if (this.selectedPrograms) {
        this.sortedCodes = this.selectedPrograms.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    if (countryItems) {
      flags = {};
      for (let i = 0; i < countryItems.length; i++) {
        if (!flags[countryItems[i].CountryCode]) {
          flags[countryItems[i].CountryCode] = true;
          this.selectedCountries.push({
            Id: countryItems[i].Id,
            Name: '',
            RegionId: '',
            Country: '',
            CountryCode: countryItems[i].CountryCode,
            value: countryItems[i].value,
            label: countryItems[i].label
          });
        }
      }
      if (this.selectedCountries) {
        this.sortedCodes = this.selectedCountries.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    if (stationItems) {
      flags = {};
      for (let i = 0; i < stationItems.length; i++) {
        if (!flags[stationItems[i].Code]) {
          flags[stationItems[i].Code] = true;
          this.selectedStations.push({
            Id: stationItems[i].Id,
            Name: '',
            Code: stationItems[i].Code,
            RegionId: '',
            value: stationItems[i].Id,
            label: stationItems[i].Code
          });
        }
      }
      if (this.selectedStations) {
        this.sortedCodes = this.selectedStations.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    if (evalZoneItems) {
      flags = {};
      for (let i = 0; i < evalZoneItems.length; i++) {
        if (!flags[evalZoneItems[i].Code]) {
          flags[evalZoneItems[i].Code] = true;
          this.selectedEvaluations.push({
            Id: evalZoneItems[i].Id,
            Name: '',
            Code: evalZoneItems[i].Code,
            CropID: '',
            RegionId: '',
            value: evalZoneItems[i].Id,
            label: evalZoneItems[i].Code
          });
        }
      }
      if (this.selectedEvaluations) {
        this.sortedCodes = this.selectedEvaluations.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }
  }

  filterCreateFileTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  filterChange() {
    this.filteredData = this.defaultData;
    const titleValue = (document.querySelector('#txtCFileFilterTitle') as HTMLInputElement).value;
    const cropValue = (document.querySelector('#txtCFileFilterCrops') as HTMLInputElement).value;
    const regionValue = (document.querySelector('#txtCFileFilterRegions') as HTMLInputElement).value;
    const ezValue = (document.querySelector('#txtCFileFilterEvaluationZones') as HTMLInputElement).value;
    const programValue = (document.querySelector('#txtCFileFilterPrograms') as HTMLInputElement).value;
    const stationValue = (document.querySelector('#txtCFileFilterStations') as HTMLInputElement).value;
    const countriesValue = (document.querySelector('#txtCFileFilterCountries') as HTMLInputElement).value;
    const CreatedByValue = (document.querySelector('#txtCFileFilterCreatedBy') as HTMLInputElement).value;
    const CreatedOnValue = ((document.querySelector('#txtCFileFilterCreatedOn') as HTMLInputElement).value);
    
    if ((titleValue) || (cropValue) || (regionValue) || (ezValue) 
        || (programValue) || (stationValue) || (countriesValue) 
        || (CreatedByValue) || (CreatedOnValue)) {
      if (titleValue) {
        this.filteredData = this.filteredData.filter(x => x.Title.toLowerCase()
          .indexOf(titleValue.toString().toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (cropValue) {
        this.filteredData = this.filteredData.filter(x => x.CropNames.toString().toLowerCase().replace('select crops,', '')
          .indexOf(cropValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (regionValue) {
        this.filteredData = this.filteredData.filter(x => x.RegionNames.toString().toLowerCase().replace('select regions,', '')
          .indexOf(regionValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (ezValue) {
        this.filteredData = this.filteredData.filter(x => x.EvaluationZoneNames.toString().toLowerCase().replace('select evalzones,', '')
          .indexOf(ezValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (programValue) {
        this.filteredData = this.filteredData.filter(x => x.ProgramNames.toString().toLowerCase().replace('select programs,', '')
          .indexOf(programValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (stationValue) {
        this.filteredData = this.filteredData.filter(x => x.StationNames.toString().toLowerCase().replace('select stations,', '')
          .indexOf(stationValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (countriesValue) {
        this.filteredData = this.filteredData.filter(x => x.CountryNames.toString().toLowerCase().replace('select countries,', '')
          .indexOf(countriesValue.toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (CreatedByValue) {
        this.filteredData = this.filteredData.filter(x => x.CreatedBy.toLowerCase()
          .indexOf(CreatedByValue.toString().toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
      if (CreatedOnValue) {
        this.filteredData = this.filteredData = this.filteredData.filter(x => x.CreatedOn.toLowerCase()
          .indexOf(CreatedOnValue.toString().toLowerCase()) >= 0);
        this.ruleResult = JSON.parse(JSON.stringify(this.filteredData));
      }
    }
    else {
      this.ruleResult = JSON.parse(JSON.stringify(this.defaultData));
    }

    this.ruleResult = this.ruleResult.slice();
    this.updateRowGroupMetaData();
  }

  onEditInit(event:any) {
    this.fieldName = event.field;
  }

  onMSPanelHide() {
    if (this.ruleResultCopy.length > 0) {
      this.isMSVisible = false;
      this.ruleResult = JSON.parse(JSON.stringify(this.ruleResultCopy));
      this.ruleResultCopy = [];
    }
  }

  onMSChange(rowData: any, rowIndex: any, dt:any) {
    this.cropCode = [];
    this.regionCode = [];
    this.evalCode = [];
    this.countryCode = [];
    this.programCode = [];
    this.stationCode = [];

    this.cropCode.push(rowData.CropNames);
    this.regionCode.push(rowData.RegionNames);
    this.evalCode.push(rowData.EvaluationZoneNames);
    this.countryCode.push(rowData.CountryNames);
    this.programCode.push(rowData.ProgramNames);
    this.stationCode.push(rowData.StationNames);

    if (dt.editingCell.cellIndex === 1) {
      this.cropCode = [];
      for (let i = 0; i < rowData.Crops.length; i++) {
        this.stationCropName.filter(k => k.CropID === rowData.Crops[i].toString()).forEach(e => {
          this.cropCode.push(e.CropDescription);
        });
      }
    } else if (dt.editingCell.cellIndex === 2) {
      this.regionCode = [];
      for (let i = 0; i < rowData.Regions.length; i++) {
        this.selectRegions.filter(k => k.Id === rowData.Regions[i].toString()).forEach(e => {
          this.regionCode.push(e.Code);
        });
      }
    } else if (dt.editingCell.cellIndex === 3) {
      this.evalCode = [];
      for (let i = 0; i < rowData.EvaluationZones.length; i++) {
        this.selectedEvaluations.filter(k => k.Id === rowData.EvaluationZones[i].toString()).forEach(e => {
          this.evalCode.push(e.Code);
        });
      }
    } else if (dt.editingCell.cellIndex === 4) {
      this.programCode = [];
      for (let i = 0; i < rowData.Programs.length; i++) {
        this.selectedPrograms.filter(k => k.Id === rowData.Programs[i].toString()).forEach(e => {
          this.programCode.push(e.Code);
        });
      }
    } else if (dt.editingCell.cellIndex === 5) {
      this.stationCode = [];
      for (let i = 0; i < rowData.Stations.length; i++) {
        this.selectedStations.filter(k => k.Id === rowData.Stations[i].toString()).forEach(e => {
          this.stationCode.push(e.Code);
        });
      }
    } else if (dt.editingCell.cellIndex === 6) {
      this.countryCode = [];
      for (let i = 0; i < rowData.Countries.length; i++) {
        this.selectedCountries.filter(k => k.label === rowData.Countries[i].toString()).forEach(e => {
          this.countryCode.push(e.CountryCode);
        });
      }
    }
    this.isMSVisible = true;
    this.ruleResultCopy = JSON.parse(JSON.stringify(this.ruleResult));
    this.ruleResultCopy[rowIndex] = {
      GUID: rowData.GUID,
      Title: rowData.Title,
      Crops: rowData.Crops,
      CropNames: this.cropCode,
      Countries: rowData.Countries,
      CountryNames: this.countryCode,
      Regions: rowData.Regions,
      RegionNames: this.regionCode,
      Programs: rowData.Programs,
      ProgramNames: this.programCode,
      EvaluationZones: rowData.EvaluationZones,
      EvaluationZoneNames: this.evalCode,
      Stations: rowData.Stations,
      StationNames: this.stationCode,
      CreatedBy: rowData.CreatedBy,
      CreatedOn: rowData.CreatedOn
    };
    this.updateRowGroupMetaData();
  }

  Reset() {
    this.ruleResult = (JSON.parse(JSON.stringify(this.defaultData)));
  }

  SaveRules() {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    const data: CreateFileModel[] = [];
    this.ruleResult.forEach(s => {
      this.crops = [];
      this.regions = [];
      this.evaluations = [];
      this.countries = [];
      this.programs = [];
      this.stations = [];
      for (let i = 0; i < s.Crops.length; i++) {
        if (s.Crops[i]) {
          this.stationCropName.filter(k => k.CropID === s.Crops[i].toString()).forEach(e => {
            this.crops.push({
              CropID: e.CropID.toString(),
              CropName: e.CropName, CropDescription: e.CropDescription, StationID: '', StationCropID: '', label: '', value: '', ConsolidationFlag: null
            });
          });
        }
      }
      for (let i = 0; i < s.Regions.length; i++) {
        if (s.Regions[i]) {
          this.selectRegions.filter(k => k.Id === s.Regions[i].toString()).forEach(e => {
            this.regions.push({
              Id: e.Id.toString(),
              Name: e.Name, Description: e.Description, Code: e.Code, CropID: '', RequestStatus: '', label: '', value: ''
            });
          });
        }
      }
      for (let i = 0; i < s.EvaluationZones.length; i++) {
        if (s.EvaluationZones[i]) {
          this.selectedEvaluations.filter(k => k.Id === s.EvaluationZones[i].toString()).forEach(e => {
            this.evaluations.push({
              Id: e.Id.toString(),
              Name: e.Name, Code: e.Code, CropID: '', RegionId: '', label: '', value: ''
            });
          }
          );
        }
      }
      for (let i = 0; i < s.Programs.length; i++) {
        if (s.Programs[i]) {
          this.selectedPrograms.filter(k => k.Id === s.Programs[i].toString()).forEach(e => {
            this.programs.push({
              Id: e.Id.toString(),
              Name: e.Name, Code: e.Code, CropID: '', EvaluationZoneId: '', StationId: '', label: '', value: ''
            });
          });
        }
      }
      for (let i = 0; i < s.Stations.length; i++) {
        if (s.Stations[i]) {
          this.selectedStations.filter(k => k.Id === s.Stations[i].toString()).forEach(e => {
            this.stations.push({
              Id: e.Id.toString(),
              Name: e.Name, Code: e.Code, RegionId: '', label: '', value: ''
            });
          });
        }
      }
      for (let i = 0; i < s.Countries.length; i++) {
        if (s.Countries[i]) {
          this.selectedCountries.filter(k => k.CountryCode === s.Countries[i].toString()).forEach(e => {
            this.countries.push({
              Id: e.Id.toString(),
              Name: e.Name, CountryCode: e.CountryCode, RegionId: '', Country: '', label: '', value: ''
            });
          });
          this.countryCode.push(s.Countries[i].toString());
        }
      }
      data.push({
        Crops: this.crops,
        CropNames: [],
        Regions: this.regions,
        RegionNames: [],
        EvaluationZones: this.evaluations,
        EvaluationZoneNames: [],
        Programs: this.programs,
        ProgramNames: [],
        Stations: this.stations,
        StationNames: [],
        Countries: this.countries,
        CountryNames: [],
        CreatedBy: s.CreatedBy,
        CreatedOn: s.CreatedOn,
        Title: s.Title,
        GUID: s.GUID
      });
    });

    this._commonService.saveRules(JSON.stringify(data)).subscribe({
      next: Result => {
        if (Result.Status === 'SUCCESS') {
          this.DisplaySuccessMessage('Success', Result.Message);
        } else {
          this.DisplayErrorMessage('Error', Result.Message);
        }

        this.getSavedRules();
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  GenerateResourceFile() {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    this.ruleModel = [];

    if (!this.selectedRow) {
      this.DisplayErrorMessage('Error', 'Please select a Rule to generate Resource File...');
      return;
    }
    let selectedResourceFileData: CreateFileModel[] = [];
    selectedResourceFileData = this.ruleResult.filter(s => s.Title === this.selectedRow!.Title);
    selectedResourceFileData.forEach(s => {
      this.cropCode = [];
      this.regionCode = [];
      this.evalCode = [];
      this.countryCode = [];
      this.programCode = [];
      this.stationCode = [];
      for (let i = 0; i < s.Crops.length; i++) {
        if (s.Crops[i]) {
          this.cropCode.push(s.Crops[i].toString());
        }
      }
      for (let i = 0; i < s.Regions.length; i++) {
        if (s.Regions[i]) {
          this.regionCode.push(this.selectRegions.find(a => a.value === s.Regions[i].toString())!.label);
        }
      }
      for (let i = 0; i < s.EvaluationZones.length; i++) {
        if (s.EvaluationZones[i]) {
          this.evalCode.push(this.selectedEvaluations.find(a => a.value === s.EvaluationZones[i].toString())!.label);
        }
      }
      for (let i = 0; i < s.Programs.length; i++) {
        if (s.Programs[i]) {
          this.programCode.push(this.selectedPrograms.find(a => a.value === s.Programs[i].toString())!.label);
        }
      }
      for (let i = 0; i < s.Stations.length; i++) {
        if (s.Stations[i]) {
          this.stationCode.push(this.selectedStations.find(a => a.value === s.Stations[i].toString())!.label);
        }
      }
      for (let i = 0; i < s.Countries.length; i++) {
        if (s.Countries[i]) {
          this.countryCode.push(s.Countries[i].toString());
        }
      }
      this.ruleModel.push({
        CropIds: this.cropCode,
        RegionCodes: this.regionCode,
        EvaluationZoneCodes: this.evalCode,
        ProgramCodes: this.programCode,
        StationCodes: this.stationCode,
        CountryCodes: this.countryCode,
        ActYear1: this.ActYear1,
        ActYear2: this.ActYear2,
        ActYear3: this.ActYear3,
        EstYear1: this.EstYear1,
        EstYear2: this.EstYear2,
        EstYear3: this.EstYear3,
      });
    });

    this._commonService.GenerateResourceFile(this.ruleModel).subscribe({
      next: data => {
        const date = new Date();
        const dd = date.getDate();
        const mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        let mon: string;
        let day: string;
        let year: string;

        mon = mm < 10 ? '0' + mm.toString() : mm.toString();
        day = dd < 10 ? '0' + dd.toString() : dd.toString();
        year = yyyy.toString();

        const stringDate = year + mon + day;

        const blob = new Blob([data], { type: 'application/ms-excel'});
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);
        link.download = this.selectedRow!.Title.substring(0, 14).toUpperCase() + '_' + stringDate + '.xlsx';
        document.body.appendChild(link);
        link.click();

        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }, 100);
      },
      error: () => {
        this.DisplayErrorMessage('Error', Constants.NOSERVICE);
      }
    });
  }

  public remove(rowIndex: any) {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    if (this.ruleResult[rowIndex].GUID && this.ruleResult[rowIndex].GUID.toString() !== '00000000-0000-0000-0000-000000000000') {
      this.ruleIDs = [];
      this.ruleIDs.push(this.ruleResult[rowIndex].GUID.toString());

      this._confirmation.confirm({
        message: Constants.CONFIRMDELETERULES,
        accept: () => {
          this.DeleteRulesAfterConfirmation();
        }
      });
    } else {
      this.ruleResult.splice(rowIndex, 1);
      this.ruleResult = this.ruleResult.slice();
    }
  }

  warningToExpand() {
    this.DisplayErrorMessage('Error', 'Please expand the Title row first...');
  }

  public removeAll(row:any) {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    let removedRule: CreateFileModel[] = [];
    removedRule = this.ruleResult.filter(s => s.Title === row.Title);

    let idExisis = false;
    this.ruleIDs = [];

    for (let i = 0; i < removedRule.length; i++) {
      if (removedRule[i].GUID && removedRule[i].GUID.toString() !== '00000000-0000-0000-0000-000000000000') {
        idExisis = true;

        if (removedRule[i].GUID) {
          this.ruleIDs.push(removedRule[i].GUID.toString());
        }
      }
    }

    if (idExisis) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDELETERULES,
        accept: () => {
          this.DeleteRulesAfterConfirmation();
        }
      });
    } else {
      removedRule.forEach(r => {
        this.ruleResult.splice(this.ruleResult.findIndex(s => s.GUID === r.GUID), 1);
        this.ruleResult = this.ruleResult.slice();
      });
    }
  }

  public DeleteRulesAfterConfirmation() {
    this._commonService.DeleteRules(JSON.stringify(this.ruleIDs)).subscribe({
      next: data => {
        this.ruleIDs = [];

        if (data.Status === 'SUCCESS') {
          this.DisplaySuccessMessage('Success', data.Message);
        } else {
          this.DisplayErrorMessage('Error', data.Message);
        }

        this.getSavedRules();
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  public copy(row: any, rowIndex: any) {
    if (this.filteredData.length !== 0 && this.filteredData.length !== this.ruleResult.length) {
      this.DisplayErrorMessage('Error', 'Please remove Grid filter(s) first..');
      return;
    }

    let title = row.Title;

    this.selectedRow = null;
    this.editTitle({ Title: title }, rowIndex, 1);
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}