<p-panel header="New Associations to be Created" *ngIf="newAssociations.length !== 0">
  <p-table [value]="newAssociations" dataKey="id" columnResizeMode="fit">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 170px" pSortableColumn="user.label">User
          <p-sortIcon field="user.label"/>
        </th>
        <th style="width: 170px" pSortableColumn="crop.label">Crop
          <p-sortIcon field="crop.label"/>
        </th>
        <th style="width: 45%">Stations</th>
        <th style="width: 45%">Programs</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assoc>
      <tr>
        <td>{{ assoc.user.label }}</td>
        <td>{{ assoc.crop.label }}</td>
        <td>
          <div class="chip-container">
            <div *ngFor="let station of assoc.stations">
              <p-chip label="{{station.label}}" [removable]="false"></p-chip>
            </div>
          </div>
        </td>
        <td>
          <div class="chip-container">
            <div *ngFor="let program of assoc.programs">
              <p-chip label="{{program.label}}"></p-chip>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<div style="height: 20px" *ngIf="removedAssociations.length !== 0"></div>
<p-panel header="Associations to be Removed" *ngIf="removedAssociations.length !== 0">
  <p-table [value]="removedAssociations" dataKey="id" columnResizeMode="fit">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 170px" pSortableColumn="user.label">User
          <p-sortIcon field="user.label"/>
        </th>
        <th style="width: 170px" pSortableColumn="crop.label">Crop
          <p-sortIcon field="crop.label"/>
        </th>
        <th style="width: 45%">Stations</th>
        <th style="width: 45%">Programs</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assoc>
      <tr>
        <td>{{ assoc.user.label }}</td>
        <td>{{ assoc.crop.label }}</td>
        <td>
          <div class="chip-container">
            <div *ngFor="let station of assoc.stations">
              <p-chip label="{{station.label}}" [removable]="false"></p-chip>
            </div>
          </div>
        </td>
        <td>
          <div class="chip-container">
            <div *ngFor="let program of assoc.programs">
              <p-chip label="{{program.label}}"></p-chip>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<p-footer>
  <div class="grid" style="margin-top: 10px">
    <div class="col-8"></div>
    <div class="col-2">
      <button id="btnCancel" type="button"
              class="p-button-warning alignRight"
              pButton icon="fa fa-close"
              (click)="cancel()" label="Cancel"></button>
    </div>
    <div class="col-2">
      <button id="btnConfirm" type="button"
              class="p-button-success alignRight"
              style="margin-left: 5px"
              pButton icon="fa fa-save"
              (click)="confirm()"
              label="Confirm"></button>
    </div>
  </div>
</p-footer>
