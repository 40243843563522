<div id="dvAllocationEZProgramGrid" class="ui-grid ui-grid-responsive ui-fluid">
    <p-panel id="pnlEZProgram" class="margin titleHide" appendTo="body">
      <p-header>
        <span>
          <b>Allocation EZProgram</b>
        </span>
      </p-header>

      <p-table id="dtAllocationEZProgram" #dt [value]="allocationEZProgramData" sortMode="single"
        [scrollable]="true" scrollHeight="580px" [loading]="loading"
        editable="true" selectionMode="multiple" [(selection)]="selectedData" columnResizeMode="expand"
        (onRowSelect)="rowSelected($event)" (onFilter)="filterDataTable($event)"
        [totalRecords]="allocationEZProgramData.length" [resizableColumns]="true">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-9" style="text-align: center">
            <p-button label="Total Count" styleClass="p-button-outlined p-button-secondary" badge="{{allocationEZProgramData.length}}" badgeClass="p-badge-secondary"></p-button>&nbsp;
            <p-button label="Filtered Count" icon="fa fa-filter" styleClass="p-button-outlined p-button-secondary" badge="{{filteredData.length}}" badgeClass="p-badge-secondary"></p-button>
          </div>
          <div class="col-3" style="text-align: right;">
            <button id="btnAllocEZSavePrograms" type="submit" pButton icon="fa fa-save" class="p-button-success" iconPos="left" label="Save" title="Save" style="width: 100px; margin-right: 10px;"
              (click)="saveAllocationEZPrograms()"></button>
            <button id="btnAllocEZAdd" type="button" pButton icon="fa fa-plus-circle" iconPos="left" label="Add" title="Add" style="width: 100px; margin-right: 10px;" (click)="addAllocationEZProgram()"></button>
            <button id="btnAllocEZExport" type="button" pButton icon="fa fa-file-o" class="p-button-success" iconPos="left" label="Export" title="Export" (click)="exportCSVForAllocationEZProgram()"
              style="width: 100px;"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Excluded</th>
          <th pSortableColumn="Crop">Crop <p-sortIcon field="Crop"></p-sortIcon></th>
          <th pSortableColumn="EvalZoneCode">Evaluation Zone <p-sortIcon field="EvalZoneCode"></p-sortIcon></th>
          <th pSortableColumn="ProgramCode">Program <p-sortIcon field="ProgramCode"></p-sortIcon></th>
          <th pSortableColumn="ProgramPurpose">Program Purpose <p-sortIcon field="ProgramPurpose"></p-sortIcon></th>
          <th pSortableColumn="Allocation">Allocation <p-sortIcon field="Allocation"></p-sortIcon></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th>
            <input id="txtCrop" [(ngModel)]="filteredCrop" pInputText
            type="text" (input)="dt.filter($any($event.target).value, 'Crop', 'contains')"
            style="width:100%" placeholder="Search">

          </th>
          <th>
            <input id="txtEvalZoneCode" [(ngModel)]="filteredEvalZoneCode" pInputText
            type="text" (input)="dt.filter($any($event.target).value, 'EvalZoneCode', 'contains')"
            style="width:100%" placeholder="Search">
          </th>
          <th>
            <input id="txtProgramCode" [(ngModel)]="filteredProgramCode" pInputText
            type="text" (input)="dt.filter($any($event.target).value, 'ProgramCode', 'contains')"
            style="width:100%" placeholder="Search">
          </th>
          <th>
            <input id="txtProgramPurpose" [(ngModel)]="filteredProgramPurpose" pInputText
            type="text" (input)="dt.filter($any($event.target).value, 'ProgramPurpose', 'contains')"
            style="width:100%" placeholder="Search">
          </th>
          <th>
            <input id="txtProgramAllocation" [(ngModel)]="filteredAllocation" pInputText
            type="text" (input)="dt.filter($any($event.target).value, 'Allocation', 'contains')"
            style="width:100%" placeholder="Search">
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
        <tr>
          <td> <input type="checkbox" [(ngModel)]="row.IsExcluded" (change)="checked(row)" /></td>
          <td>{{row.Crop}}</td>
          <td>{{row.EvalZoneCode}}</td>
          <td>{{row.ProgramCode}}</td>
          <td>{{row.ProgramPurpose}}</td>
          <td>{{row.Allocation}}</td>
          <td>
            <div [hidden]="!row.IsEditable">
              <button type="button" pButton icon="fa fa-edit" class="p-button-success" iconPos="left" title="Edit"
                (click)="editAllocationEZProgram(row)"></button>
            </div>
          </td>
          <td>
            <div [hidden]="!row.IsEditable">
              <button type="button" pButton icon="fa fa-minus-circle" class="p-button-danger" (click)="deleteAllocationEZProgram(row, rowIndex)"
                title="Delete"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      </p-table>
  </p-panel>
</div>
<p-dialog header="Allocation Details" [(visible)]="dialogVisible" showEffect="fade" [modal]="true" [style]="{'width': '600px'}"
  [closable]="true">
  <form #frm="ngForm" [formGroup]="allocationEZProgramFormGroup">
    <div class="grid">
      <div class="col-5">
        <label for="ddAllocEZCrops">Crops</label>
      </div>
      <div class="col-7">
        <p-dropdown id="ddAllocEZCrops" [options]="crops" [(ngModel)]="allocationEZProgramForm.CropID" formControlName="CropID" (onChange)="getProgramAndEvaluationByCropID()"
          [disabled]="IsCropListDisabled" [style]="{'width':'100%'}" appendTo="body" placeholder="Select Crop"></p-dropdown>
      </div>
      <div class="col-5">
        <label for="ddAllocEZProgramCode">Program Code</label>
      </div>
      <div class="col-7">
        <p-dropdown id="ddAllocEZProgramCode" [options]="program" [(ngModel)]="allocationEZProgramForm.ProgramId" [filter]="true" formControlName="ProgramId"
          [style]="{'width':'100%'}" appendTo="body" placeholder="Select ProgramCode"></p-dropdown>
      </div>
      <div class="col-5">
        <label for="ddAllocEZCode">Evaluation Zone Code</label>
      </div>
      <div class="col-7">
        <p-dropdown id="ddAllocEZCode" [options]="evalutionZones" [(ngModel)]="allocationEZProgramForm.EvaluationZodeId" [filter]="true" formControlName="EvaluationZodeId"
          [style]="{'width':'100%'}" appendTo="body" placeholder="Select EvaluationZoneCode"></p-dropdown>
      </div>
      <div class="col-5">
        <label for="ddAllocEZProgramType">AllocEZ Program type</label>
      </div>
      <div class="col-7">
        <p-dropdown id="ddAllocEZProgramType" [options]="allocationEZProgramTypes" [(ngModel)]="allocationEZProgramForm.AllocationEZProgramTypeId" [filter]="true"
          formControlName="AllocationEZProgramTypeId" [style]="{'width':'100%'}" appendTo="body" placeholder="Select Allocation Type"></p-dropdown>
      </div>
      <div class="col-5">
        <label for="cbAllocEZISExcluded">Exclude from File</label>
      </div>
      <div class="col-7">
        <input id="cbAllocEZISExcluded"  type="checkbox" [(ngModel)]="allocationEZProgramForm.IsExcluded" formControlName="IsExcluded"
          style="float: left; width: 5%" />
      </div>
    </div>
  </form>
  <p-footer>
    <div class="grid">
      <div class="col-12">
        <button id="btnAllocEZSave" type="submit" (click)="saveAllocationEZProgram(frm)" pButton icon="fa fa-save" class="p-button-success" [disabled]="!frm.form.valid"
          label="Save"></button>
        <button id="btnAllocEZSCanel" type="button" pButton icon="fa fa-close" class="p-button-warning" (click)="close(frm)" label="Cancel"> </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
