import { ExperimentProjectCodeResult } from './../shared/experimentProjectCodeResult';
import { LocationProjectCodeResult } from './../shared/locationProjectCodeResult';
import { ProjectLEResult } from './../shared/projectLEResult';
import { SSLLEResult } from './../shared/sSLLEResult';
import { StationLEResult } from './../shared/stationLEResult';

export class LERequestedData {
    public ParentComponent: string;
    public Code: string;
    public LEAOIID: number;
    public OtherObject: string;
    public ProjectLE: ProjectLEResult[] = [];
    public StationLE: StationLEResult[] = [];
    public SSLLE: SSLLEResult[] = [];
    public LocationProjectCode: LocationProjectCodeResult[] = [];
    public ExperimentProjectCode: ExperimentProjectCodeResult[] = [];
}
