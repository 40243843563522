import { BaseComponent } from './../basecomponents/base.component';
import { Component, SimpleChanges } from '@angular/core';

import { MessageService } from 'primeng/api';
import { Person } from '.././shared/person';
import { UserRequestedData } from '.././shared/userRequestedData';
import { CommonService } from '../shared/common.service';
import { Constants } from './../shared/constants';
import { ResourceFileSchedulerModel } from '.././shared/ResourceFileSchedulerModel';
import { BaseService } from '../basecomponents/base.service';
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-scheduleradmin',
  templateUrl: './scheduleradmin.component.html',
  styleUrls: ['./scheduleradmin.component.css']
})
export class ScheduleradminComponent extends BaseComponent {
  FileName: string;
  Name: string;
  index: number;
  id: string;
  disableSaveScheduler = true;
  toggleScheduler = true;
  toggleSnapShot = false;
  Body: string;
  Subject: string;
  DL: string;
  SchedulerIndex: number;
  public originalValue: any;
  public scheduleData: ResourceFileSchedulerModel[] = [];
  public scheduler: ResourceFileSchedulerModel = new ResourceFileSchedulerModel();
  public parentComponent = 'SchedulerAdmin';
  public displayStationLead = false;
  public displayDL = false;
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public hideMessage = true;

  constructor(private _commonService: CommonService, public override _baseService: BaseService,
    _messageService: MessageService, sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit(): void {
    this.GetResourceFileScheduler();
    super.setupRoles();
    this.buildForm();
  }

  buildForm() {
    if (this.isROVAdmin) {
      this.hideMessage = true;
    } 
    else {
      this.hideMessage = false;
    }

    this.SelectMenuOption('Scheduler');
  }

  shedulerToggle(event: any) {
    if (event.collapsed === true) {
      this.toggleSnapShot = false;
    } else {
      this.toggleSnapShot = true;
    }
  }

  snapshotToggle(event: any) {
    if (event.collapsed === true) {
      this.toggleScheduler = false;
    } else {
      this.toggleScheduler = true;
    }
  }

  GetResourceFileScheduler() {
    this._commonService.GetResourceFileScheduler().subscribe({
      next: data => {
        this.scheduleData = data.scheduleData;
        this.Subject = data.EmailFormat.Subject;
        this.Body = data.EmailFormat.Body;

        if (this.scheduleData.length === 0) {
          this.disableSaveScheduler = true;
        } else {
          this.disableSaveScheduler = false;
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  onCalendarSelect(event: any, col: any, rowData: { [x: string]: any; }, rowIndex: any, dt: { onEditComplete: { emit: (arg0: { column: any; data: any; index: any; }) => void; }; }) {
    rowData[col.field] = event;
    dt.onEditComplete.emit({ column: { col }, data: rowData, index: rowIndex });
  }

  dataTableOnBlur(event: any, col: any, rowData: any, rowIndex: any, dt: { onEditComplete: { emit: (arg0: { column: any; data: any; index: any; }) => void; }; }) {
    dt.onEditComplete.emit({ column: col, data: rowData, index: rowIndex });
  }

  onEditInit(event: any) {
    this.originalValue = event.data[event.field];
  }

  onEditCancel(event: any) {
    event.data[event.column.field] = this.originalValue;
  }

  onEditComplete(event: any) {
    const value = event.data[event.column];
    const currentDate = new Date();
    const todayDate = (currentDate.getMonth() + 1) + '/' + currentDate.getDate() + '/' + currentDate.getFullYear();
    if (event.column === 'ScheduledDate') {
      const myDate = new Date(value.toString());
      const date = (myDate.getMonth() + 1) + '/' + myDate.getDate() + '/' + myDate.getFullYear();
      if (date === todayDate) {
        this.DisplayErrorMessage('Error', `${event.column.header} should be greater than today's date.`);
        event.data[event.column] = this.originalValue;
        return;
      }
      event.data[event.column] = (value.getMonth() + 1) + '/' + value.getDate() + '/' + value.getFullYear();
    }

    if (event.column === 'FileName') {
      if (!value || value.toString().trim() === '') {
        this.DisplayErrorMessage('Error', `${event.column.header} cannot be empty.`);
        event.data[event.column] = this.originalValue;
        return;
      }

      if (value.toString().length > 50) {
        this.DisplayErrorMessage('Error', 'Allowed max length for FileName is 50 characters.');
        event.data[event.column] = this.originalValue;
        return;
      }
    }
  }

  addNewScheduler() {
    if (this.toggleScheduler) {
      this.DisplayInfoMessage('Info', 'Please expand the panel to add a new scheduler...');
      return;
    }

    const scheduler = new ResourceFileSchedulerModel();
    scheduler.ID = '00000000-0000-0000-0000-000000000000';
    scheduler.FileName = 'File Name';
    scheduler.NotificationUsers = [];
    const myDate = new Date();
    const date = (myDate.getMonth() + 1) + '/' + (myDate.getDate() + 1) + '/' + myDate.getFullYear();
    scheduler.ScheduledDate = date.toString();
    scheduler.CreatedOn = '';
    scheduler.UpdatedOn = '';
    scheduler.NotificationUserDisplayName = '';
    scheduler.EmailDL = '';
    if (this.scheduleData.length > 4) {
      this.DisplayErrorMessage('Error', 'You cannot add more than 5 records');
    } else {
      this.scheduleData.push(scheduler);
      this.scheduleData = this.scheduleData.slice();

      const scheduleDatacount = this.scheduleData.length;

    setTimeout(function () {
      const newRow = document.querySelector(
        `#dtScheduler table > tbody > tr:nth-child(${scheduleDatacount})`
      );
      // newRow.scrollIntoView();
      (newRow as HTMLElement).style.background = 'lightgrey';

      const secondCell = document.querySelector(
        `#dtScheduler table > tbody > tr:nth-child(${scheduleDatacount}) > td:nth-child(3)`
      );
      (secondCell as HTMLElement).click();
    }, 100);
    }

    this.disableSaveScheduler = false;
  }

  deleteScheduler(row: ResourceFileSchedulerModel, rowIndex: number) {
    this.scheduleData.splice(rowIndex, 1);
    this.scheduleData = this.scheduleData.slice();

    if (this.scheduleData.length === 0) {
      this.disableSaveScheduler = true;
    } else {
      this.disableSaveScheduler = false;
    }
  }

  SaveResourceFileScheduler(frm1: any) {
    if (this.scheduleData.length === 0) {
      this.DisplayErrorMessage('Error', 'Please create a schedular first.');
      return;
    } else {
      let rowIndex = this.scheduleData.findIndex(s => s.FileName === null
        || s.FileName.toString().trim() === '');

      if (rowIndex !== -1) {
        this.DisplayErrorMessage('Error', `Please enter the File Name for scheduler number: ${(rowIndex + 1)}.`);
        return;
      }

      rowIndex = this.scheduleData.findIndex(s => s.FileName !== null
        && s.FileName.toString().trim().length > 50);

      if (rowIndex !== -1) {
        this.DisplayErrorMessage('Error', `File Name is more than 50 characters for scheduler number: ${(rowIndex + 1)}.`);
        return;
      }
    }

    this.existingPeople = [];
    if (this.scheduler.NotificationUsers) {
      this.scheduler.NotificationUsers.forEach(s => {
        this.existingPeople.push(s.NotifyUser);
      });
    }
    this.scheduleData = this.scheduleData.slice();
    this._commonService.SaveResourceFileScheduler(JSON.stringify(this.scheduleData), frm1.value.Subject
      , frm1.value.body).subscribe({
       next:  Result => {
          if (Result.Status === 'SUCCESS') {
            this.DisplaySuccessMessage('Success', Result.Message);
            this.GetResourceFileScheduler();
          } else if (Result.Status === 'INFO') {
            this.DisplayInfoMessage('Info', Result.Message);
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
       error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
  });

    this.existingPeople = [];
    this.existingPeopleString = '';
  }

  ShowDL(event: any, col: any, rowData: any, rowIndex: number, dt: any) {
    this.displayDL = true;
    this.SchedulerIndex = rowIndex;
    this.DL = this.scheduleData[rowIndex].EmailDL;
  }

  ShowPeoplePicker(event: any, col: any, rowData: any, rowIndex: number, dt: { onEditComplete: { emit: (arg0: { column: any; data: any; index: any; }) => void; }; }) {
    this.index = rowIndex;
    dt.onEditComplete.emit({ column: col, data: rowData, index: rowIndex });
    this.existingPeople = [];
    this.existingPeopleString = '';

    if (this.scheduleData[this.index]) {
      this.scheduleData[this.index].NotificationUsers.forEach(lead => {
        this.existingPeople.push(lead.NotifyUser);
      });
    }
    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;

    this.existingPeopleString = JSON.stringify(obj);
    this.displayStationLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayStationLead = false;
      let NotifyNames = '';

      this.scheduleData[this.index].NotificationUsers = [];
      this.scheduler.NotificationUsers = [];
      this.scheduler.NotificationUserDisplayName = '';

      data.person.forEach(pr => {
        if (!this.scheduler.NotificationUsers.find(s => s.NotifyUser.LoginName === pr.LoginName)) {
          this.scheduler.NotificationUsers.push({
            ID: '00000000-0000-0000-0000-000000000000',
            NotifyUserID: pr.PersonID, NotifyUser: pr, ResourceFileScheduler: null, ResourceFileSchedulerID: this.scheduler.ID
          });
        }

        if (!this.scheduleData[this.index].NotificationUsers.find(s => s.NotifyUser.LoginName === pr.LoginName)) {
          this.scheduleData[this.index].NotificationUsers.push({
            ID: '00000000-0000-0000-0000-000000000000',
            NotifyUserID: pr.PersonID, NotifyUser: pr, ResourceFileScheduler: null, ResourceFileSchedulerID: this.scheduler.ID
          });
        }

        NotifyNames = NotifyNames + pr.DisplayName + '; ';
      });

      NotifyNames = NotifyNames.trim();

      if (NotifyNames.lastIndexOf(';') === NotifyNames.length - 1) {
        NotifyNames = NotifyNames.substring(0, NotifyNames.lastIndexOf(';'));
      }

      this.scheduler.NotificationUserDisplayName = NotifyNames;

      if (this.scheduler.NotificationUsers.length > 0 && this.scheduleData[this.index].EmailDL) {
        this.scheduler.NotificationUserDisplayName =
          this.scheduler.NotificationUserDisplayName + ', ' + this.scheduleData[this.index].EmailDL;
      } else {
        if (this.scheduler.NotificationUsers.length === 0) {
          this.scheduler.NotificationUserDisplayName = this.scheduleData[this.index].EmailDL;
        }
      }

      this.scheduleData[this.index].NotificationUserDisplayName = this.scheduler.NotificationUserDisplayName;
      this.existingPeopleString = '';
    }
  }

  saveDL() {
    if (this.DL) {
      const arrDL = this.DL.split(';');

      for (let i = 0; i < arrDL.length; i++) {
        if (arrDL[i] && arrDL[i].trim() !== ''
          && this._commonService.IsValidEmail(arrDL[i].trim())) {
          if (arrDL.length > 1) {
            this.DisplayErrorMessage('Error', 'One of the DLs provided seems to be invalid...');
          } else {
            this.DisplayErrorMessage('Error', 'Provided DL seems to be invalid...');
          }
          return;
        }
      }
      this.scheduleData[this.SchedulerIndex].EmailDL = this.DL;
      let users = '';

      this.scheduleData[this.SchedulerIndex].NotificationUsers.forEach(pr => {
        users = users + pr.NotifyUser.DisplayName + '; ';
      });

      this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName = users.trim();

      if (this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName.lastIndexOf(';')
        === this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName.length - 1) {
        this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName
          = this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName
            .substring(0, this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName.lastIndexOf(';'));
      }

      if (this.scheduleData[this.SchedulerIndex].NotificationUsers.length > 0) {
        this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName
          = this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName + ', ' + this.DL;
      } else {
        this.scheduleData[this.SchedulerIndex].NotificationUserDisplayName = this.DL;
      }
    }
    this.displayDL = false;
  }

  SaveResourceFileHistory(frm: any) {
    this._commonService.SaveResourceFileHistory(JSON.stringify(frm.value.fileName))
      .subscribe({
       next: (data: any)=> {
          if (data.Status === 'SUCCESS') {
            this.DisplaySuccessMessage('Success', data.Message);
          } else {
            this.DisplayErrorMessage('Error', data.Message);
          }

          this.DL = '';
          this.SchedulerIndex = -1;
        },
        error: (error) => {
          this.DisplayErrorMessage('Error', error);
        }
      }
  );
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const offsetHeight = document.getElementById('dvScheduler')!.offsetTop;

    return ((viewPortHeight) - (offsetHeight) - (700)).toString() + 'px';
  }

  public setHeights() {
    const viewPortHeight = document.documentElement.clientHeight;
    const offsetHeight = document.getElementById('dvScheduler')!.offsetTop;

    return ((viewPortHeight) - (offsetHeight) - (450)).toString() + 'px';
  }
}