import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ApprovalComments, Constants, RequestStatus, LookupType,GAAData } from '../shared';
import { StationService } from '../station/station.service';
import { CommonService } from '../shared/common.service';
import { StationResultModel } from '../shared';
import { AppService } from '../app.service';
import { BaseStationComponent } from '../basecomponents/basestation.component';
import { BaseService } from '../basecomponents/base.service';
import { MessageService } from 'primeng/api';
import { StationCrops } from '../shared/stationCrops';
import {SharedService} from '../services/shared.service';

@Component({
  selector: 'app-createstation',
  templateUrl: './createstation.component.html',
  styleUrls: ['./createstation.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreatestationComponent extends BaseStationComponent {
  public displayRDCenterTypeDialog: boolean;
  public StationType: string = Constants.LOOKUPTYPERANDDCENTERTYPE;
  technologyTiers: LookupType[];
  public gaaData: GAAData = new GAAData();
  consolidationCrops: StationCrops[] = []
  selectedConsolidationCrops: StationCrops[] = [];
  @Input()
  public selectedTabIndex: number;

  constructor(private _commonService: CommonService, public override _stationService: StationService,
    private _appService: AppService, public override _fb: FormBuilder,
    public override _baseService: BaseService, _messageService: MessageService,
    sharedservice: SharedService) {
      super(_fb, _baseService, _stationService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.parentComponent = 'CreateStation';
    this.buildForm(true);
    this.stationData.RequestStatus = RequestStatus.PENDING;
    this.stationForm.CountryId = 0;
    this.stationForm.CityId = 0;
    this.addBorderSafetyContact();
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 1 )  {
      this.getLookupTypes();
      this.getRDCenterTypeLookup();
      if(this.crops.length === 0) {
        this._baseService.getDropdownLists().subscribe(data => {
          this.fillDropDownList(data);
        });
      }
    }
  }

  showRDCenterTypeDialog() {
    this.displayRDCenterTypeDialog = true;
  }

  public getRDCenterTypeLookup() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPERANDDCENTERTYPE, true)
      .subscribe({
        next: (response) => {
          this.stationTypes = response;

          this.stationTypes.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
          this.stationTypes.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        },
        error: (error) => {
        }
      });
  }

  changeCrops(event: any) {
    this.clearRegions();
    this.stationData.StationCrops = [];
    this.stationData.CropNames = '';
    this.regionCodes = [];
    this.consolidationCrops = [];
    this.regions.forEach(reg => {
      if(!this.regionCodes.some(r => r.Code == reg.Code))
        this.regionCodes.push(reg);
    });

    event.value.forEach((cropid: string) => {
      const cropData = this.crops.find(s => s.CropID === cropid)
      this.stationData.StationCrops.push({
        CropID: cropData!.CropID,
        CropName: cropData!.CropName,
        CropDescription: cropData!.CropDescription,
        StationID: cropData!.StationID,
        StationCropID: cropData!.StationCropID,
        ConsolidationFlag: null
      });
      this.stationData.CropNames = this.stationData.CropNames + cropData!.CropDescription + ', ';
      this.consolidationCrops.push(cropData!);
    });

    this.selectedConsolidationCrops.forEach((item,index) => {
     const exists= this.consolidationCrops.some(s => s.CropID === item.CropID);
     if(!exists){
     this.selectedConsolidationCrops.splice(index,1);
     }
    });

    this.stationData.StationCrops.forEach((item,index) => {
      if(this.selectedConsolidationCrops.some(s => s.CropID === item.CropID)){
        this.stationData.StationCrops[index].ConsolidationFlag = true;
      }
      else{
        this.stationData.StationCrops[index].ConsolidationFlag = false;
      }
    });

    if (this.stationData.CropNames) {
      const x = document.querySelector('#msCStationCrops > div');
      (x as HTMLElement).style.borderBottom = '1px solid #ced4da';

    }
    else{
      const x = document.querySelector('#msCStationCrops > div');
      (x as HTMLElement).style.borderBottom = '3px solid #a94442';
    }

    const items = this.regionCodes;
    let flags: { [code: string]: boolean } = {};
    this.distinctRegions = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (!flags[items[i].Code]) {
          flags[items[i].Code] = true;
          this.distinctRegions.push({
            Id: items[i].Id,
            Name: '',
            Description: '',
            Code: items[i].Code,
            CropID: '',
            RequestStatus: '',
            value: items[i].Id,
            label: items[i].Code
          });
        }
      }
      if (this.distinctRegions) {
        this.sortedRegionCodes = this.distinctRegions.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    this.stationData.CropNames = this.stationData.CropNames.trim();

    if (this.stationData.CropNames.lastIndexOf(',') === this.stationData.CropNames.length - 1) {
      this.stationData.CropNames = this.stationData.CropNames.substring(0, this.stationData.CropNames.lastIndexOf(','));
    }
  }

  changeConsolidationCrops(event: any) {
    this.selectedConsolidationCrops = [];
    this.consolidationCrops.forEach(item => {
      let cropExists = event.value.includes(item.CropID);
      let index = this.stationData.StationCrops.findIndex(x => x.CropID === item.CropID);
      if(cropExists) {
        this.stationData.StationCrops[index].ConsolidationFlag = true;
        this.selectedConsolidationCrops.push(this.stationData.StationCrops[index]);
      }
      else {
        this.stationData.StationCrops[index].ConsolidationFlag = null;
      }
    });
  }

  public getLookupTypes() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPETECHNOLOGYTIERTYPE, true)
      .subscribe({
        next: (response) => {
          this.technologyTiers = response;

          this.technologyTiers.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
          this.technologyTiers.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));

          const none = this.technologyTiers.find(s=>s.label == "none");
          if(none){
            this.technologyTiers = this.moveToTop(this.technologyTiers, none);
          }
        },
        error: (error) => {
        }
      });
  }

  moveToTop(arr: any, item: any):any {
    const index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
      arr.unshift(item)
    }

    return arr;
  }

  saveStation(frm: any) {
    if (this.validateForm(frm)) {
      if (this.stationData.Comment && this.stationData.Comment.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.stationData.Comment.toString();
        this.stationData.ApprovalComments = [];
        this.stationData.ApprovalComments.push(comment);
      }

      if (!this.stationData.StationCode) {
        this.stationData.StationCode = '';
      }

      this.stationData.StationType = this.stationTypes.find(s => s.value === this.stationData.StationTypeID)!;
      this.stationData.Region = this.regionCodes.find(s => s.value === this.stationData.RegionID)!;

      this._stationService.saveStation(JSON.stringify(this.stationData))
        .subscribe({
          next: Result => {
            this._appService.sendPendingCount(1);
            this.getStations();

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset();
          },
         error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(frm: NgForm) {
    if (!this.stationData.StationName || this.stationData.StationName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'R&D Center Name is required...');
      return false;
    }

    if (!this.stationData.StationCode || this.stationData.StationCode.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'R&D Center Code is required...');
      return false;
    }
    if (!this.stationData.Address || this.stationData.Address.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'Address is required...');
      return false;
    }
    if (!this.stationData.CropNames || this.stationData.CropNames.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (this.stationData.StationCode.toString().trim().length < 2 || this.stationData.StationCode.toString().trim().length > 4) {
      this.DisplayWarningMessage('Warning', 'R&D Center Code should be 2-4 characters long...');
      return false;
    }

    if (this.stationData.StationCode && this.stationData.StationCode.toString().trim() !== '') {
      const sCode = this.stations.find(s => s.StationCode.toString().toLowerCase()
        === this.stationData.StationCode.toString().toLowerCase()
        && !(s.RequestStatus === RequestStatus.RETIRED || s.RequestStatus === RequestStatus.DECLINED));

      if (sCode) {
        this.DisplayWarningMessage('Warning', 'This R&D Center Code already exists. Please enter a new Code...');
        return false;
      }
    }

    if (!this.stationData.Latitude || this.stationData.Latitude.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'Latitude is required...');
      return false;
    }

    if (this.stationData.Latitude.toString().length > 15) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 15 characters for Latitude...');
      return false;
    }

    if (!this.stationData.Longitude || this.stationData.Longitude.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'Longitude is required...');
      return false;
    }

    if (this.stationData.Longitude.toString().length > 15) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 15 characters for Longitude...');
      return false;
    }

    return true;
  }

  reset() {

    this.stationData.CropNames="";
    this.stationData = new StationResultModel();
    this.stationData.RequestStatus = RequestStatus.PENDING;
    this.existingPeople = [];
    this.stationOperationLead = [];
    this.shiptoStationContact = [];
    this.stationSafetyContact = [];
    this.stationForm.CountryId = 0;

    this.addBorderSafetyContact();

    this.parentComponent = 'CreateStation';
    this.buildForm(true);
    this.stationData.RequestStatus = RequestStatus.PENDING;
    this.stationForm.CountryId = 0;
    this.stationForm.CityId = 0;
    this.addBorderSafetyContact();
    this.selectedCrops = [];
    this.selectedConsolidationCrops = []
    this.selectedConsolidationCropIds = [];
    this.clearRegions();
    this.stationData.StationCrops = [];
    this.regionCodes = [];
    this.distinctRegions = [];
    this.sortedRegionCodes=[];
    this.crops=[];
    this.consolidationCrops = [];
    this.stationData.CropNames='';

    this._baseService.getDropdownLists().subscribe(data => {
      this.fillDropDownList(data);
    });
  }

  GetGAAData(event: any) {
      this.gaaData.Latitude = this.stationData.Latitude;
      this.gaaData.Longitude = this.stationData.Longitude;

      if(this.gaaData.Longitude == undefined
        || this.gaaData.Longitude.toString().split(".").length < 2
        || this.gaaData.Longitude.toString().split(".")[1].length < 2
        || this.gaaData.Latitude.toString().split(".")[1].length < 2) {
        return;
      }

      if(this.gaaData.Latitude != null){
      this._commonService.GetGAAData(JSON.stringify(this.gaaData)).subscribe({
        next: (response: any) => {
          if(response != GAAData){
            this.stationData.GeographicLocale = response.GeographicLocale;
            this.stationData.Country = response.Country;
            this.stationData.State = response.State;
            this.stationData.CountryId = response.CountryAoiId;
            this.stationData.StateId = response.StateAoiId;
            this.stationData.City = response.City;
            this.stationData.CityId = response.CityAoiId;
          }
          else{
            this.stationData.GeographicLocale = null;
            this.stationData.Country = null;
            this.stationData.State = null;
            this.stationData.City = null;
            this.stationData.CountryId = null;
            this.stationData.StateId = null;
            this.stationData.CityId = null;
          }

        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }
}
