<p-panel id="pnlUploadFile">
  <p-header>
    <span>
      <b>Upload Resource File</b>
    </span>
  </p-header>
  <div class="grid">
    <div class="col-12">
      <p-fileUpload id="fuUploadFile" name="myfile[]" styleClass="submitButtonColor" [customUpload]="true" chooseLabel="Upload" uploadLabel="Submit" 
	  accept=".xls,.xlsx" (uploadHandler)="myUploader($event)" (onClear)="clearFile($event)">
      </p-fileUpload>
    </div>
  </div>
  <div class="grid" *ngIf="!manualGrid">
    <div class="col-2">
      <p-multiSelect id="msUploadFileDistinctColumns" [options]="columnOptions"
        [(ngModel)]="distinctColumns" [style]="{'width':'200px'}" selectedItemsLabel="{0} items selected">
      </p-multiSelect>
    </div>
    <div class="col-8">
    </div>
    <div class="col-2">
      <button id="btnUploadFileManualImport" type="button" pButton icon="fa fa-upload"
        class="p-button-success" (click)="manualImport()" iconPos="left" label="Manual Import"
        title="Submit Manual Columns" style="float: right"></button>
    </div>
  </div>
  <div class="grid">
    <div class="col-12" [hidden]="manualGrid">
      <p-panel styleClass="height">
        <p-table id="dtUploadScreen" #dt [value]="items" [columns]="distinctColumns"
            [scrollable]="true" Width="100%" [loading]="loading"
            unfrozenWidth="100%" [totalRecords]="5" [resizableColumns]="true" [autoLayout]="true">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn>
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn>
                <p-dropdown id="ddUploadFileAllColumns[i]" #dd [options]="cols"
                  [(ngModel)]="selectedColumn[distinctColumns.indexOf(col)]" defaultLabel="All Columns"
                  [style]="{'width':'100%'}" appendTo="body" placeholder="Select" (click)="$event.stopPropagation();"
                  (onChange)="dt.filter($event, col, 'contains')"
                  (onChange)="onColumnChange($event, dt, col, dd, distinctColumns.indexOf(col))"
                  styleClass="ui-column-filter" [group]="true">
                  <ng-template let-group pTemplate="group">
                    <span style="vertical-align:middle;margin-left:.25em">{{group.label}}</span>
                  </ng-template>
                </p-dropdown>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </p-table>

      </p-panel>
    </div>
  </div>
</p-panel>