import { ResourceFileSchedulerNotificationUser } from './ResourceFileSchedulerNotificationUser';
import { Person } from './person';
export class ResourceFileSchedulerModel {
    ID: string;
    FileName: string;
    NotificationUsers: ResourceFileSchedulerNotificationUser[] = [];
    NotificationUserDisplayName: string;
    EmailDL: string;
    ScheduledDate: string;
    CreatedOn: string;
    UpdatedOn: string;
}
