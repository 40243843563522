<form #frm="ngForm" [formGroup]="programFormGroup">
  <p-panel id="pnlCreateProgram" [toggleable]="false">
    <p-header>
      <span>
        <b>Program Information</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-12 lg:col md:col-12">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="txtCProgramName">Program Name</label>
          </div>
          <div class="col-9">
            <input id="txtCProgramName" name="programName" pInputText autofocus formControlName="ProgramName"
              [(ngModel)]="programData.ProgramName" required minlength="1" maxlength="25" />
          </div>
          <div class="col-3 controlLabel">
            <label for="ddCProgramType">Program Type</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
            <p-dropdown id="ddCProgramType" name="programType" [options]="programTypeName" [filter]=true
              [(ngModel)]="programData.ProgramTypeID" formControlName="ProgramTypeID" appendTo="body"
              [style]="{'width':'100%'}" placeholder="Select a Program Type" [showClear]="true">
            </p-dropdown>
          </div>
          <div class="col-1">
            <button id="btnProgramTypeDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
              style="width: 30px; float: right; margin-right: 8px;" (click)="showProgramTypeDialog()"
              title="Add/Edit ProgramType"></button>
          </div>
        </div>
          </div>
          <div class="col-3 controlLabel">
            <label for="txtCProgramSize">Program Size</label>
          </div>
          <div class="col-9">
            <input id="txtCProgramSize" type="number" pInputText formControlName="ProgramSize" name="ProgramSize"
              [(ngModel)]="programData.ProgramSize" />
          </div>
          <div class="col-3 controlLabel">
            <label for="txtCProgramCode">Program Code</label>
          </div>
          <div class="col-9">
            <input id="txtCProgramCode" name="programCode" formControlName="ProgramCode" pInputText
              [(ngModel)]="programData.ProgramCode" minlength="2" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <label for="ddCPResponsiblesubfunction">Primary Responsible Subfunction</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-dropdown id="ddCPResponsiblesubfunction" name="PrimaryResponsibleSubFunction" [filter]=true
                  [options]="responsiblesubfunctions" [(ngModel)]="programData.PrimaryResponsibleSubFunctionId"
                  formControlName="PrimaryResponsibleSubFunctionId" appendTo="body" [style]="{'width':'100%'}"
                  placeholder="Select a Primary Responsible Subfunction " [showClear]="true">
                </p-dropdown>
              </div>
              <div class="col-1">
                <button id="btnPResponsibleSubfunctionDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="showResponsibleSubfunctionDialog()"
                  title="Add/Edit Primary Responsible Subfunction"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col md:col-12">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="txtCProgramDescription">Description</label>
          </div>
          <div class="col-9">
            <textarea id="txtCProgramDescription" [cols]="30" [rows]="4" formControlName="Description" minlength="1"
              maxlength="500" name="description" [(ngModel)]="programData.Description" pInputTextarea
              class="p-inputtext"></textarea>
          </div>
          <div class="col-3 controlLabel">
            <label for="lstCProgramLead">Principal Investigator</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCProgramLead" [options]="programLead"
                  [(ngModel)]="programData.ProgramPrincipalInvestigatorNames"
                  formControlName="ProgramPrincipalInvestigatorNames" [style]="{'min-height':'35px'}"
                  [listStyle]="{'max-height':'100px'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnCProgramAddLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" title="Add Principal Investigators"
                  (click)="ShowPeoplePicker()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel [toggleable]="false">
    <p-header>
      <span>
        <b>Program Details</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-12 lg:col md:col-12">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="ddCProgramCropName">Crop</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramCropName" #dd1 [style]="{'width':'100%'}" name="programCropName" [filter]=true
              [options]="programCropName" [(ngModel)]="programData.CropID" formControlName="CropID"
              placeholder="Select a Crop" (onChange)="getStationsByCropID()" [showClear]="true"></p-dropdown>
          </div>

          <div class="col-3 controlLabel">
            <label for="ddCProgramStationName">R&D Center</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramStationName" #dd2 [style]="{'width':'100%'}" name="programStationName"
              [filter]=true [options]="allStationName" [(ngModel)]="programData.StationID"
              placeholder="Select a R&D Center" formControlName="StationID"
              (onChange)="getSeedStorageLocationByCropID()" [showClear]="true"></p-dropdown>
          </div>

          <div class="col-3 controlLabel">
            <label for="ddCProgramShipToStation">Ship to R&D Center</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramShipToStation" #dd3 [style]="{'width':'100%'}" name="shipToStationName"
              [filter]=true formControlName="ShipToStationID" [options]="allShipToStationName"
              [(ngModel)]="programData.ShipToStationID" placeholder="Select a Ship to R&D Center"
              (onChange)="getSeedStorageLocationByCropID()" [showClear]="true"></p-dropdown>
          </div>

          <div class="col-3 controlLabel">
            <label for="ddCProgramSSLName">Seed Storage Location</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramSSLName" #dd5 [style]="{'width':'100%'}" name="seedStorageLocationName"
              [filter]=true [options]="allSeedStorageLocationName" [(ngModel)]="programData.SeedStorageLocationID"
              placeholder="Select a Seed Storage Location" formControlName="SeedStorageLocationID"
              [showClear]="true"></p-dropdown>
          </div>

          <div class="col-3 controlLabel">
            <label for="ddCProgramEZName">Evaluation Zone</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramEZName" #dd4 [style]="{'width':'100%'}" name="seedEvaluationZoneName"
              [filter]=true [options]="evalutionZones" [(ngModel)]="programData.EvaluationZoneID"
              placeholder="Select a Seed Evaluation Zone" formControlName="EvaluationZoneID"
              [showClear]="true"></p-dropdown>
          </div>
          <div class="col-3 controlLabel">
            <label for="msCProgramBZ">Breeding Zone</label>
          </div>
          <div class="col-9">
            <p-multiSelect id="msCProgramBZ" panelclass="long-width" [options]="breedingZones"
              name="seedBreedingZoneName" [(ngModel)]="selectedBZ" appendTo="body" formControlName="BreedingZoneID"
              [style]="{'width':'100%'}" placeholder="Select a Breeding Zone">
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col md:col-12">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="ddCProgramPurposeName">Program Purpose</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCProgramPurposeName" [style]="{'width':'100%'}" name="programPurposeName" [filter]=true
              [options]="programPurposeName" [(ngModel)]="programData.ProgramPurposeID"
              placeholder="Select a Program Purpose" formControlName="ProgramPurposeID" [showClear]="true"></p-dropdown>
          </div>
          <!-- <div class="col-1">
            <button id="btnCProgramClearPP" (click)="clearPP()" pButton icon="fa fa-close" title="Clear Selection" class="buttonStyle" style="border:none;background-color: white; color: black;"></button>
          </div> -->
          <div class="col-3 controlLabel">
            <label for="txtCProgramCRMUpper">CRM Upper</label>
          </div>
          <div class="col-9">
            <input id="txtCProgramCRMUpper" type="number" pInputText formControlName="CRMUpper"
              [(ngModel)]="programData.CRMUpper" name="CRMUpper" minlength="0" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <label for="txtCProgramCRMLower">CRM Lower</label>
          </div>
          <div class="col-9">
            <input id="txtCProgramCRMLower" type="number" pInputText [(ngModel)]="programData.CRMLower"
              formControlName="CRMLower" name="CRMLower" minlength="0" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <br />
            <label for="txtCProgramComment">Comment</label>
          </div>
          <div class="col-9">
            <span>Please enter comments or notes regarding changes.</span>
            <textarea id="txtCProgramComment" [cols]="30" [rows]="4" formControlName="Comment" name="Comment"
              pInputTextarea [(ngModel)]="comments" class="p-inputtext"></textarea>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="grid">
        <div class="col-8">
        </div>
        <div class="col-2">
          <button id="btnCProgramReset" type="button" class="p-button-warning alignRight" pButton icon="fa fa-close"
            (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2">
          <button id="btnCProgramSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save"
            (click)="saveProgram(frm)" [disabled]="!frm.form.valid" label="Submit"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>
<br />

<p-dialog id="dlgEditResponsibleSubfunction" header="Edit Responsible Subfunction"
  [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayResponsibleSubfunctionDialog" showEffect="fade" [modal]="true">
  <manage-lookup [lkpType]="PrimaryResponsibleSubFunction"
    (getLookUpEvent)="getResponsibleSubfunctionLookUp()" ></manage-lookup>
</p-dialog>

<p-dialog header="Select Principal Investigators" [style]="{'width': '1000px', 'height': '580px'}"
  [(visible)]="displayStationLead" showEffect="fade" [modal]="true">
  <app-peoplepicker [existingPeopleString]="existingPeopleString"
    (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgEditProgramType" header="Edit Program Type" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayProgramTypeDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="ProgramType" (getLookUpEvent)="getProgramTypeLookUp()"></manage-lookup>
</p-dialog>
