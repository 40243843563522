import { ApprovalComments } from "./approvalComments";
import { LookupType } from "./lookupType";
import { Person } from "./person";

export class CPFieldScientistModel {
    Id: string;
    FirstName: string;
    LastName: string;
    City: string | null;
    State: string | null;
    Country: string|null;
    CPRegionId: string;
    CPRegion: string;
    Code: string;
    Lat: string;
    Long: string;
    StatusId: string;
    StatusName: string;
    Status: LookupType;
    CPSubfunctionId: string;
    CPSubfunction: string;
    CreatedBy: Person;
    CreatedOn: Date;
    ModifiedBy: Person;
    ModifiedOn: Date;
    ApprovedBy: Person;
    ApprovedOn: Date;
    RetiredBy: Person;
    RetiredOn: Date;

    public CityAoiId: number|null;

    public CountyAoiId: number|null;
    public County: string | null;

    public StateAoiId: number|null;

    public CountryAoiId: number|null;

    public ApprovalComments: ApprovalComments[] = [];

    public RequestStatus: string;
    public GeographicLocale: string|null;
    public GAAResponseJson: string;
    public Level3Type: string;
}

export class GAAData {
    public Latitude: string;
    public Longitude: string;
    public Country: string;
    public CountryAoiId: number;
    public State: string;
    public StateAoiId: number;
    public City: string;
    public CityAoiId: number;
    public Level3Type: string;
    public GeographicLocale: string;
    public GAAResponseJson: string;
}