<form #frm="ngForm" [formGroup]="sslFormGroup">
  <p-panel id="pnlViewComments" [toggleable]="false">
    <p-header>
      <span>
        <b>Seed Storage Location Parameters</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="txtCSSLName">Seed Storage Location Name</label>
          </div>
          <div class="col-9">
            <input id="txtCSSLName" name="sslName" pInputText [(ngModel)]="sslData.Name" formControlName="SSLName"
              autofocus minlength="2" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <label for="ddCSSLType">SSL Type</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
            <p-dropdown id="ddCSSLType" [style]="{'width':'100%'}" name="SeedStorageLocationTypeId" [filter]=true
              [options]="sslTypeName" [(ngModel)]="sslData.SeedStorageLocationTypeId"
              formControlName="SeedStorageLocationTypeId" placeholder="Select a SSL Type" appendTo="body"
              [showClear]="true"></p-dropdown>
          </div>
          <div class="col-1">
            <button id="btnSSLTypeDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
              style="width: 30px; float: right; margin-right: 8px;" (click)="showSeedStorageLocationTypeDialog()"
              title="Add/Edit SeedStorageLocationType"></button>
          </div>
        </div>
          </div>
          <div class="col-3 controlLabel">
            <label for="ddCSSLCropName">Crop</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCSSLCropName" #dd [style]="{'width':'100%'}" name="sslCropName" [filter]=true
              [options]="sslCropName" [(ngModel)]="sslData.CropID" formControlName="CropID" placeholder="Select a Crop"
              (onChange)="clearStation(); getStationsByCropID();" [showClear]="true"></p-dropdown>
          </div>
          <div class="col-3 controlLabel">
            <label for="ddCSSLStationName">R&D Center</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCSSLStationName" #dd [style]="{'width':'100%'}" name="sslStationName" [filter]=true
              [options]="allStationName" [(ngModel)]="sslData.StationId" formControlName="StationId"
              placeholder="Select a R&D Center" [showClear]="true"></p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="txtCSLLDescription">Description</label>
          </div>
          <div class="col-9">
            <textarea id="txtCSLLDescription" [cols]="30" [rows]="3" minlength="1" maxlength="500" name="description"
              [(ngModel)]="sslData.Description" formControlName="Description" pInputTextarea
              class="p-inputtext"></textarea>
          </div>
          <div class="col-3 controlLabel">
            <label for="lstCSSLLead">Key Contacts</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCSSLLead" [options]="keyContactsLead"
                  [(ngModel)]="sslData.SeedStorageLocationKeyContactNames" formControlName="KeyContacts"
                  styleClass="width100" [style]="{'min-height':'35px'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnCSSLAddLead" type="button" pButton icon="fa fa-user-plus" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" title="Add Key Contacts"
                  (click)="ShowPeoplePicker()"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel>
    <p-header>
      <span>
        <b>PRISM & WWS Information</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label for="cbCSSLVirtual">Virtual</label>
          </div>
          <div class="col-9">
            <div class="ui-g-12 ui-md-12">
              <p-checkbox id="cbCSSLVirtual" name="virtual" [(ngModel)]="sslData.IsVirtual" formControlName="Virtual"
                [binary]="true"></p-checkbox>
            </div>
          </div>
          <div class="col-3 controlLabel">
            <label for="txtCSSLR3BuildingCode">R3 building Code</label>
          </div>
          <div class="col-9">
            <div class="ui-g-12 ui-md-12">
              <input id="txtCSSLR3BuildingCode" type="text" pInputText name="r3BuildingCode"
                [(ngModel)]="sslData.R3BuildingCode" formControlName="R3BuildingCode" maxlength="25" />
            </div>
          </div>
          <div class="col-3 controlLabel">
            <label for="txtCSSLWwsShipping">WWS Shipping Project</label>
          </div>
          <div class="col-9">
            <input id="txtCSSLWwsShipping" type="text" pInputText name="wwsAddressID"
              [(ngModel)]="sslData.WWSShippingProject" formControlName="WWSShippingProject" maxlength="255" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <br />
            <label for="txtCSSLComment">Comment</label>
          </div>
          <div class="col-9">
            <span>Please enter comments or notes regarding changes.</span>
            <textarea id="txtCSSLComment" [cols]="30" [rows]="4" name="Comment" pInputTextarea class="p-inputtext"
              [(ngModel)]="comments" formControlName="Comment"></textarea>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="grid">
        <div class="col-8">
        </div>
        <div class="col-2">
          <button id="btnCSSLReset" type="button" class="p-button-warning alignRight" pButton icon="fa fa-close"
            (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2">
          <button id="btnCSSLSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save"
            (click)="saveSSL(frm)" [disabled]="!frm.form.valid" label="Submit"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>
<br />

<p-dialog header="Select Key Contacts" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayStationLead"
  showEffect="fade" [modal]="true">
  <app-peoplepicker [existingPeopleString]="existingPeopleString"
    (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
</p-dialog>
<p-dialog id="dlgEditSSLType" header="Edit SeedStorageLocationType" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displaySeedStorageLocationTypeDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="SeedStorageLocationType" (getLookUpEvent)="getSeedStorageLocationTypeLookUp()"></manage-lookup>
</p-dialog>
