import { SelectItem, SelectItemGroup } from "primeng/api";
import { Component, ViewEncapsulation } from "@angular/core";
import { Dropdown } from "primeng/dropdown";
import { CommonService } from "../shared/common.service";
import * as XLSX from 'xlsx';
import { Constants } from "../shared";
import { BaseComponent } from "../basecomponents/base.component";
import { BaseService } from "../basecomponents/base.service";
import { MessageService } from "primeng/api";
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class UploadfileComponent extends BaseComponent {
  public fileName = '';
  public uploadedFiles: any[] = [];
  public message = '';
  arrayBuffer: any;
  manualGrid = true;
  cols: SelectItemGroup[] = [];
  filterValue: any[] = [];
  result: any;
  selectedColumn: string[] = [];
  yearTimeout: any;
  columnOptions: SelectItem[];
  public fixedColumns: SelectItem[] = [];
  public dynamicColumns: SelectItem[] = [];
  public distinctColumns: any[] = [];
  public sortedCodes: SelectItem[];
  public items: any;
  public panelHeight: any;
  dd: Dropdown;
  file: File;

  constructor(public _commonService: CommonService, _baseService: BaseService,
    _messageService: MessageService, sharedservice: SharedService) {
    super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.SelectMenuOption('Upload');
  }

  GetResourceFileColumns() {
    this.loading = true;
    this._commonService.getResourceFileColumns().subscribe(
      fileData => {
        this.result = fileData;
        this.fixedColumns = [];
        this.dynamicColumns = [];
        this.result.FixedColumns.forEach((item: any) => {
          this.fixedColumns.push({ label: item, value: item });
        });
        this.result.DynamicColumns.forEach((item: any) => {
          this.dynamicColumns.push({ label: item, value: item });
        });
        this.cols.push({ label: 'Fixed Columns', value: 'Fixed Columns', items: this.fixedColumns });
        this.cols.push({ label: 'Dynamic Columns', value: 'Dynamic Columns', items: this.dynamicColumns });
        this.loading = false;
      }
    );
  }

  public clearFile(event : any) {
    this.distinctColumns = [];
    this.cols = [];
    this.columnOptions = [];
    this.manualGrid = true;
  }

  public myUploader(event: any) {
    this.filterValue = [];
    const fileList: FileList = event.files;

    if (fileList.length > 0) {
      this.file = fileList[0];
      this.fileName = this.file.name;
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        const data = new Uint8Array(this.arrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        this.items = XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 0, defval: '' });
        this.distinctColumns = [];

        if (this.items.length > 0) {
          let keyValue: any[] = [];
          this.items.forEach((item: any) => {
            keyValue = Object.keys(item);

          });

          keyValue.forEach((item: any) => {
            this.distinctColumns.push({ field: item, header: item });
          });

          this.manualGrid = false;
        } else {
          this.DisplayErrorMessage('Error', 'This is a blank file, Please upload file with data');
           this.manualGrid = false;
        }
        this.columnOptions = [];
        if (this.distinctColumns) {
          if (this.distinctColumns.some(i => i.header === 'CropId') && this.distinctColumns.some(i => i.header === 'RegionId')
              && this.distinctColumns.some(i => i.header === 'EvaluationZoneId')
              && this.distinctColumns.some(i => i.header === 'ProgramId')) {
            this.manualGrid = true;
            this.importResourceFile(this.file);
          } else {
            this.manualGrid = false;
            for (let i = 0; i < this.distinctColumns.length; i++) {
              this.columnOptions.push({ label: this.distinctColumns[i].header, value: this.distinctColumns[i] });
              this.selectedColumn[i] = '';
            }
            this.GetResourceFileColumns();
          }
        }
      };
      fileReader.readAsArrayBuffer(this.file);
    }
  }

  onColumnChange(event: any, dt: any, col:any, dd:any, index:any) {
    const originalValue = event.value;
    for (let i = 0; i < this.filterValue.length; i++) {
      if (event.value === this.filterValue[i].label) {
        this.selectedColumn[index] = '';
        dd.value = '';
        this.DisplayErrorMessage('Error', 'This column is already mapped, select another column');
        if (this.filterValue[index]
          && this.filterValue[index].label !== '') {
          this.filterValue.splice(index, 1, ({ label: originalValue, value: col.header }));
        }
        return;
      }
    }
    if (event) {
      if (this.filterValue.length > 0) {
        for (let i = 0; i < this.filterValue.length; i++) {
          const j = this.filterValue.findIndex(x => x.value === col.field);
          if (this.filterValue && this.filterValue.some(x => x.value === col.field)) {
            this.filterValue.splice(j, 1, ({ label: event.value, value: col.field }));
          } else {
            this.filterValue.splice(index, 0, ({ label: event.value, value: col.field }));
          }
        }
      } else {
        this.filterValue.splice(index, 0, ({ label: event.value, value: col.field }));
      }
    }
  }

  importResourceFile(file:any) {
    this._commonService.importResourceFile(file)
      .subscribe({
        next: Result => {
          if (Result.DynamicDataColumnsNotExists !== '') {
            this.message = Result.Message + '. ' + Result.DynamicDataColumnsNotExists;
          } else {
            this.message = Result.Message;
          }

          if (Result.Status === 'SUCCESS') {
            this.DisplayInfoMessage('Success', this.message);
          } else {
            this.DisplayErrorMessage('Error', this.message);
          }
        },
        error: error => {
          if (error === '') {
            this.message = Constants.NOSERVICE;
          } else {
            this.message = error;
          }
          this.DisplayErrorMessage('Error', this.message);
        }
      });
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    if (viewPortHeight <= 650) {
      this.panelHeight = viewPortHeight;
      return 4;
    } else {
      this.panelHeight = '580px';
      return 12;
    }
  }

  manualImport() {
    const mappedColumns: any[] = [];

    for (let i = 0; i < this.filterValue.length; i++) {
      mappedColumns[this.filterValue[i].label] = this.filterValue[i].value;
    }

    if (!(Object.keys(mappedColumns).includes('Crop') && Object.keys(mappedColumns).includes('Region') &&
      Object.keys(mappedColumns).includes('Program') && Object.keys(mappedColumns).includes('Year') &&
      this.result.DynamicColumns.some((ele:any) => Object.keys(mappedColumns).includes(ele)))) {
      this.DisplayErrorMessage('Error', 'Mapped compulsary columns do not exist in uploaded file...');
    } else {
      this._commonService.manualImport(JSON.stringify(mappedColumns), this.file)
        .subscribe(
          Result => {
            if (Result) {
              this.DisplaySuccessMessage('Success', 'File Uploaded...');
              const blob = new Blob([Result], { type: 'application/ms-excel'});
              const link = document.createElement('a');
              link.style.display = 'none';
              link.href = window.URL.createObjectURL(blob);
              link.download = 'ErrorManualResourceFile.xlsx';
              document.body.appendChild(link);
              link.click();
              setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
              }, 100);
            } else {
              this.DisplayErrorMessage('Error', 'Blank file uploaded or no change was found to update...');
              const blob = new Blob([Result], { type: 'application/ms-excel'});
              const link = document.createElement('a');
              link.style.display = 'none';
              link.href = window.URL.createObjectURL(blob);
              link.download = 'ErrorManualResourceFile.xlsx';
              document.body.appendChild(link);
              link.click();
              setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
              }, 100);
            }
          },
          error => {
            if (error === '') {
              this.message = Constants.NOSERVICE;
            } else {
              this.message = error;
            }
            this.DisplayErrorMessage('Error', this.message);
          }
        );
    }
  }

  public override DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public override DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public override DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public override DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}