import { BaseComponent } from './../basecomponents/base.component';
import { Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from '../basecomponents/base.service';
import { MessageService } from 'primeng/api';
import {SharedService} from './../services/shared.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CreateComponent extends BaseComponent {
  public selectedTabIndex: number;
  public hideHomeTab = false;
  public index = -1;

  public constructor(_viewContainerRef: ViewContainerRef, private route: ActivatedRoute,
    public override _baseService: BaseService, _messageService: MessageService,
    sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.route.queryParams.subscribe(p=>{
      if(p['ProgramID']){
        this.selectedTabIndex = this.index = 0;
      }
      else if(p['StationID'])
      {
        this.selectedTabIndex = this.index = 1;
      }
      else if(p['SeedStorageLocationID'])
      {
        this.selectedTabIndex = this.index = 2;
      }
      else if(p['EvaluationZoneID'])
      {
         this.selectedTabIndex = this.index = 3;
      }
      else if(p['RegionID'])
      {
        this.selectedTabIndex = this.index = 4;
      }
      else if(p['BreedingZoneID'])
      {
        this.selectedTabIndex = this.index = 5;
      } 
      else if(p['Id'])
      {
        this.selectedTabIndex = this.index = 6;
      }
  });
    this.SelectMenuOption('Create');
    var paramValue = this.route.snapshot.paramMap.get("type"); 
    if (paramValue == "Program") {  this.selectedTabIndex = this.index = 0; }  
    if (paramValue == "SeedStorageLocation") {  this.selectedTabIndex = this.index = 2; }  
    if (paramValue == "EvaluationZone") {  this.selectedTabIndex = this.index = 3; }
  }

  public onTabChange(event: any) {
    this.selectedTabIndex = event.index;
    this.hideHomeTab = true;
  }
}