<div class="container-fluid" [hidden]="isROVAdmin || isROVBDSupportUser || isROVSuperUser">
  <div class="col-sm-3"></div>
  <div class="col-sm-6">
    <h3>
      <span [hidden]="hideMessage">
        You don't have access to the
        <b>User Utility</b> screen.
        <br />
        <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
      </span>
    </h3>
  </div>
  <div class="co-sm-3"></div>
</div>

<div class="grid ui-grid-responsive ui-fluid" id="dvUserUtility" [hidden]="!(isROVAdmin || isROVBDSupportUser || isROVSuperUser)">
    <div class="col-12">
      <p-panel class="margin">
        <p-header>
          <span>
            <b>RAD User Utility</b>
          </span>
        </p-header>
        <div class="grid">
          <div class="col-12">
            <ul style="list-style-type:none">
              <li>
                <span>
                  <b>Lead must be a assigned as a PRISM user, before he / she can be assigned in RAD.</b>
                </span>
                <br /> 
                <br /> 
                <ul style="list-style-type:none">
                  <li>
                    <span>Enter User Details and click Search, if found select "Update RAD".</span>
                  </li>
                  <li>
                    <span>If user is not found, he/she is not a PRISM user, therefore must be requested through your
                      support person
                      to be added as a PRISM user.</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <p-panel id="panelPeoplePicker">
              <p-header>
                <span>
                  <b>Search User</b>
                </span>
              </p-header>
              <div id="userSearchDiv" class="ui-grid ui-grid-responsive ui-fluid">
                <form #frm="ngForm">
                  <div class="grid">
                    <div class="col-3">
                      <input [(ngModel)]="lastName" maxlength="25" pInputText autofocus
                        id="txtLastName" name="txtLastName" (keypress)="valueChangeLName($event)" placeholder="Last Name" />
                    </div>
                    <div class="col-3">
                      <input [(ngModel)]="firstName" maxlength="25" pInputText
                        id="txtFirstName" name="txtFirstName" (keypress)="valueChangeFName($event)" placeholder="First Name" />
                    </div> 
                    <div class="col-3">
                      <input [(ngModel)]="loginName" maxlength="25" pInputText
                        id="txtLoginName" name="txtLoginName" (keypress)="valueChangeFName($event)" placeholder="Login Name" />
                    </div>
                    <div class="col-1">
                      <button id="btnUserUtilitySearchUser" class="rightAlign p-button-info" type="button" pButton
                        icon="fa fa-search" [disabled]="!frm.form.valid" (click)="getLDAPUsers()" title="Search Users"
                        style="width: 50px;"></button>
                    </div>
                  </div>
                </form>
                <div class="ui-grid-row" style="padding: 5px">
                  <p-table #dt id="dtUserUtilitySearchUser" [columns]="userCols" class="gridHeight" [value]="people" columnResizeMode="fit"
                    class="table-bordered" selectionMode="single" [(selection)]="selectedItems" sortMode="single" [reorderableColumns]="true"
                    [scrollable]="true" (onRowSelect)="onRowSelect($event)" scrollHeight="250px">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field"
                          pResizableColumn>
                          {{col.header}}
                          <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr>
                        <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                          {{rowData[col.field]}}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="5">
                          No records found
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <tr>
                        <td [attr.colspan]="5">
                          Count: {{people.length}}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </p-panel>
          </div>
        </div>
        <div class="grid">
          <div class="col-12">
            <p-panel>
              <p-header>
                <span>
                  <b>Save User</b>
                </span>
              </p-header>
              <div class="ui-grid ui-grid-responsive ui-fluid ui-g"> 
                <p-table #dt id="dtUserUtilitySaveUser" [columns]="saveCols" class="gridHeight" [value]="leSearchResult" 
                 sortMode="single" [loading]="loading" [style]="{'height': '100px'}">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field"
                      pResizableColumn>
                      {{col.header}}
                      <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                      {{rowData[col.field]}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="5">
                      No records found
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              </div>
            </p-panel>
          </div>
        </div>
        <p-footer>
          <div class="grid">
            <div class="col-8"></div>
            <div class="col-2">
              <button id="btnUserUtilityReset" type="button" pButton icon="fa fa-close" class="p-button-warning"
                iconPos="left" label="Reset" title="Reset" [disabled]="!(people && people.length > 0)"
                (click)="reset()"></button>
            </div>
            <div class="col-2">
              <button id="btnUserUtilityUpdate" type="button" pButton icon="fa fa-save" class="p-button-success"
                iconPos="left" label="Update RAD" title="Update RAD"
                [disabled]="!(!IsRADUser && leSearchResult && leSearchResult.length === 1 && leSearchResult[0].LeId !== 'Not Found')"
                (click)="updateRAD()"></button>
            </div>
          </div>
        </p-footer>
      </p-panel>
    </div>
</div>