<div class="container-fluid" [hidden]="isROVAdmin || isROVSuperUser">
  <div class="col-sm-3">
  </div>

  <div class="col-sm-6">
    <h3>
        <span>
          You don't have access to the
          <b>User Associations</b> screen.
          <br/>
          <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
        </span>
    </h3>
  </div>

  <div class="co-sm-3">
  </div>
</div>

<form id="frmUserAssoc"
      #frm="ngForm"
      [formGroup]="userAssocForm">
  <p-panel>
    <p-header id="pnlHdrUserAssoc">
          <span>
            User Associations
          </span>
    </p-header>
    <p-accordion [multiple]="true" [activeIndex]="activeIndex">
      <p-accordionTab id="accFilter" header="Search for">
        <div class="grid">
          <div class="controlLabel col-3">
            <label id="lblUsers">Users</label>
          </div>
          <div class="col-7">
                <span class="p-fluid">
                    <p-multiSelect id="msUsers" formControlName="selectedUsers" (onChange)="onChange()"
                                   [options]="users" optionLabel="label" placeholder="Enter one or more Users"
                                   display="chip" show [showClear]="true" [loading]="loadingUsers"
                                   [maxSelectedLabels]="1000"/>
                </span>
          </div>
          <div class="controlLabel col-3">
            <label id="lblCrops">Crops</label>
          </div>
          <div class="col-7">
                <span class="p-fluid">
                    <p-multiSelect id="msCrops" formControlName="selectedCrops" (onChange)="onChange()"
                                   [options]="crops" optionLabel="label" placeholder="Enter one or more Crops"
                                   display="chip" show [showClear]="true" [loading]="loading"
                                   [maxSelectedLabels]="1000"/>
                </span>
          </div>
          <div class="controlLabel col-3">
            <label id="lblStations">Stations</label>
          </div>
          <div class="col-7">
                <span class="p-fluid">
                    <p-multiSelect id="msStations" formControlName="selectedStations" (onChange)="onChange()"
                                   [options]="stations" optionLabel="label" placeholder="Enter one or more Stations"
                                   display="chip" show [showClear]="true" [loading]="loading"
                                   [maxSelectedLabels]="1000"/>
                </span>
          </div>
          <div class="controlLabel col-3">
            <label id="lblPrograms">Programs</label>
          </div>
          <div class="col-7">
                <span class="p-fluid">
                    <p-multiSelect id="msPrograms" formControlName="selectedPrograms" (onChange)="onChange()"
                                   [options]="programs" optionLabel="label" placeholder="Enter one or more Programs"
                                   display="chip" show [showClear]="true" [loading]="loading"
                                   [maxSelectedLabels]="1000"/>
                </span>
          </div>

          <div class="controlLabel col-4">
            <p-button id="btnFindAssoc" class="leftAlign p-button-info"
                      icon="fa fa-search"
                      [disabled]="findAssocDisabled"
                      (click)="findAssociations()"
                      label="Find Associations"/>
          </div>
        </div>
      </p-accordionTab>

      <div style="margin-top: 10px" class="grid" *ngIf="showFilter">
        <div class="controlLabel col-3">
          <label id="lblFilter" style="font-size: 18px">Filter</label>
        </div>
        <div class="col-7">
                <span class="p-fluid">
                    <input type="text" id="txtFilter"
                           style="font-size: 18px"
                           formControlName="filter"
                           (input)="onFilterChange($event)"
                           placeholder="Enter Filter Text"/>
                </span>
        </div>
      </div>

      <p-accordionTab id="accExistentAssoc" header="Existing Associations">
        <p-table [value]="existingAssociationsFiltered" dataKey="id" columnResizeMode="fit">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 170px" pSortableColumn="user.label">User
                <p-sortIcon field="user.label"/>
              </th>
              <th style="width: 170px" pSortableColumn="crop.label">Crop
                <p-sortIcon field="crop.label"/>
              </th>
              <th style="width: 45%">Stations</th>
              <th style="width: 45%">Programs</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-assoc>
            <tr>
              <td>{{ assoc.user.label }}</td>
              <td>{{ assoc.crop.label }}</td>
              <td>
                <div class="chip-container">
                  <div *ngFor="let station of assoc.stations">
                    <p-chip label="{{station.label}}" [removable]="true"
                            (onRemove)="onChipRemoved( 'exiSta', assoc.id, station)"></p-chip>
                  </div>
                </div>
              </td>
              <td>
                <div class="chip-container">
                  <div *ngFor="let program of assoc.programs">
                    <p-chip label="{{program.label}}" [removable]="true"
                            (onRemove)="onChipRemoved( 'exiPgm', assoc.id, program)"></p-chip>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab id="accNewAssoc" header="New Associations">
        <p-table [value]="newAssociationsFiltered" columnResizeMode="fit">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 170px" pSortableColumn="user.label">User
                <p-sortIcon field="user.label"/>
              </th>
              <th style="width: 170px" pSortableColumn="crop.label">Crop
                <p-sortIcon field="crop.label"/>
              </th>
              <th style="width: 45%">Stations</th>
              <th style="width: 45%">Programs</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-assoc>
            <tr>
              <td>{{ assoc.user.label }}</td>
              <td>{{ assoc.crop.label }}</td>
              <td>
                <div class="chip-container">
                  <div *ngFor="let station of assoc.stations">
                    <p-chip label="{{station.label}}" [removable]="true"
                            (onRemove)="onChipRemoved( 'newSta', assoc.id, station)"></p-chip>
                  </div>
                </div>
              </td>
              <td>
                <div class="chip-container">
                  <div *ngFor="let program of assoc.programs">
                    <p-chip label="{{program.label}}" [removable]="true"
                            (onRemove)="onChipRemoved( 'newPgm', assoc.id, program)"></p-chip>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>

    <p-footer>
      <div class="grid">
        <div class="col-6"></div>
        <div class="col-2">
          <button id="btnCancel" type="button"
                  class="p-button-warning alignRight"
                  pButton icon="fa fa-close"
                  (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2">
          <button id="btnExportExcel" type="button"
                  class="pi-file-excel alignRight"
                  pButton icon="fa fa-close"
                  (click)="exportToExcel()" label="Export to Excel"></button>
        </div>
        <div class="col-2">
          <button id="btnSave" type="button"
                  class="p-button-success alignRight"
                  pButton icon="fa fa-save"
                  (click)="showConfirmation()" [disabled]="!frm.form.valid || (newAssociationsFiltered.length === 0 && associationsToRemove.length === 0)"
                  label="Submit"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>
<div>
  <p-dialog header="Some submitted records failed to be saved/removed" [(visible)]="failedDialogVisible" [modal]="true" [style]="{'min-width': '50%'}">
    <app-failed-association-submission [failedAdd]="failedAddAssoc"
                                       [failedRemove]="failedRemovalAssoc"
                                       (onExport)="exportFailedToExcel()"
                                       (onCancel)="failedDialogVisible = false">
    </app-failed-association-submission>
  </p-dialog>
  <p-dialog header="Confirm" [(visible)]="confirmChangesDialogVisible" [modal]="true" [style]="{'min-width': '50%'}">
    <app-confirm-association-changes [removedAssociations]="removedAssociations"
                                     [newAssociations]="newAssociationsFiltered"
                                     (onConfirm)="submit()"
                                     (onCancel)="confirmChangesDialogVisible = false"></app-confirm-association-changes>
  </p-dialog>
</div>
