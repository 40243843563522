export class UserClaim {
    givenName: string;
    familyName: string;
    username: string;
    name: string;
    email: string;
    version: string;
    isROVUser: boolean;
    isROVAdmin: boolean;
    isROVPublishedResourceFileUser: boolean;
    isROVSuperUser: boolean;
    isGluuUp: boolean;
  }
  export interface GraphMe {
    value: Group[];
}
export interface Group{
  displayName:string;
  }