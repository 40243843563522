import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StationResultModel } from '../shared/stationResultModel';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class StationService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  getStationResult(): Observable<StationResultModel[]> {
    return this.http.get(this.urlConfig.station)
  }

  syncGAA(entityType:string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.urlConfig.syncGAA, JSON.stringify(  entityType), { headers: httpHeaders, responseType: 'arraybuffer' })
  } 

  saveStation(station: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveStation, station, httpOptions)
  }

  saveStationComment(stationComment: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveStationComment, stationComment, httpOptions)
  }

  exportCSVForStations(station: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForStations, station, { headers: httpHeaders, responseType: 'arraybuffer' })
  }

  saveLEDataToPrismStation(stationLEData: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveLEDataToPrismStation, stationLEData, httpOptions)
  }

  bindStationDropdownLists(): Observable<any> {
    return this.http.get(this.urlConfig.stationDropDownLists)
  }

  GetAllCountriesFomPRISM(): Observable<any> {
    return this.http.get(this.urlConfig.countries)
  }

  deleteRecentComments(recentComments: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.deleteRecentComments, recentComments, httpOptions)
  }
}