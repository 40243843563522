import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class UserUtilityService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  checkRADUser(filter: string): Observable<any> {
    return this.http.get(this.urlConfig.checkRADUser + '?' + filter)
  }

  saveRADUser(user: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveRADUser, user, httpOptions)
  }
}