import { RegionLead } from './regionLead';
import { ApprovalComments } from './approvalComments';
import { Person } from './person';

export class RegionForm {
  constructor(
    public RegionID: string,
    public RegionName: string,
    public Description: string,
    public RegionCode: string,
    public Color: string,
    public CropID: string,
    public CropName: string,
    public CropDescription: string,
    public RegionLeadNames: string,
    public RegionLeads: RegionLead[] = [],
    public PRISM_AOI_ID: string,
    public RequestStatus: string,
    public ApprovalComments: ApprovalComments[] = [],
    public CreatedBy: Person,
    public CreatedByUserName: string,
    public CreatedOn: Date,
    public ModifiedByUserName: string,
    public ModifiedBy: Person,
    public ModifiedOn: Date,
    public ApprovedByUserName: string,
    public ApprovedBy: Person,
    public ApprovedOn: Date,
    public RetiredByUserName: string,
    public RetiredBy: Person,
    public RetiredOn: Date,
    public BaseRegionID: string
  ) {}
}
