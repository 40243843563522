import { Region } from './region';
import { ApprovalComments } from './approvalComments';
import { Person } from './person';

export class CommercialMarketZoneForm {
  constructor(
    public CommercialMarketZoneID: string,
    public CommercialMarketZoneName: string,
    public Description: string,
    public CommercialMarketZoneCode: string,
    public RegionID: string, 
    public Region: Region,
    public PRISM_AOI_ID: string,
    public RequestStatus: string,
    public ApprovalComments: ApprovalComments[] = [],
    public CreatedBy: Person, 
    public CreatedByUserName: string,
    public CreatedOn: Date,
    public ModifiedByUserName: string,
    public ModifiedBy: Person,
    public ModifiedOn: Date,
    public ApprovedByUserName: string,
    public ApprovedBy: Person,
    public ApprovedOn: Date,
    public RetiredByUserName: string,
    public RetiredBy: Person,
    public RetiredOn: Date,
    public BaseCommercialMarketZoneID: string,
  ) {}
}
