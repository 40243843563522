import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CPFieldScientistModel, Constants, GeoPoliticalUnitModel, LookupType, RequestStatus, GAAData, ApprovalComments } from "../shared";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CPFieldScientistForm } from "../shared/cpFieldScientistFormControl";
import { CommonService } from "../shared/common.service";
import { AppService } from "../app.service";
import { CPFieldScientistService } from "../cpFieldScientist/cpFieldScientist.service";

@Component({
  selector: 'app-createcpFieldscientist',
  templateUrl: './createcpFieldscientist.component.html',
  styleUrls: ['./createcpFieldscientist.component.scss']
})

export class CreateCPFieldScientistComponent implements OnInit, OnChanges {
  public cpFieldScientistData: CPFieldScientistModel = new CPFieldScientistModel();
  public CPFieldScientistFormGroup: FormGroup;
  statuses: LookupType[];
  cpSubfunctions: LookupType[];
  cpRegions: LookupType[];
  public countries: GeoPoliticalUnitModel[] = [];
  public displayCPSubfunctionDialog: boolean;
  public displayCPRegionDialog: boolean;
  public CPSubfunction: string = Constants.LOOKUPTYPECPSUBFUNCTION;
  public CPRegion: string = Constants.LOOKUPTYPECPREGION;
  public comments = '';
  public gaaData: GAAData = new GAAData();

  @Input()
  public selectedTabIndex: number;

  public cpFieldScientistForm = new CPFieldScientistForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    [],
    '',
    '',
    ''
  );

  constructor(private _appService: AppService, private _cpFieldScientistService: CPFieldScientistService,
    private _commonService: CommonService, private _fb: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.GetAllCountriesFomPRISM();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 6) {
      this.getStatusLookUp();
      this.getCPSubfunctionLookUp();
    }
  }

  buildForm(): void {
    this.CPFieldScientistFormGroup = this._fb.group({
      'FirstName': [this.cpFieldScientistForm.FirstName, Validators.required],
      'LastName': [this.cpFieldScientistForm.LastName, Validators.required],
      'City': [this.cpFieldScientistForm.City],
      'State': [this.cpFieldScientistForm.State],
      'Country': [this.cpFieldScientistForm.Country],
      'CPRegionId': [this.cpFieldScientistForm.CPRegionId],
      'Code': [this.cpFieldScientistForm.Code, Validators.required],
      'Lat': [this.cpFieldScientistForm.Lat],
      'Long': [this.cpFieldScientistForm.Long],
      'StatusId': [this.cpFieldScientistForm.StatusId],
      'StatusName': [this.cpFieldScientistForm.StatusName],
      'CPSubfunctionId': [this.cpFieldScientistForm.CPSubfunctionId],
      'CityAoiId': [this.cpFieldScientistForm.CityAoiId],
      'StateAoiId': [this.cpFieldScientistForm.StateAoiId],
      'CountryAoiId': [this.cpFieldScientistForm.CountryAoiId],
      'CountyAoiId': [this.cpFieldScientistForm.CountyAoiId],
      'Comment': [this.cpFieldScientistForm.ApprovalComments],
      'GeographicLocale': [this.cpFieldScientistForm.GeographicLocale],
      'GAAResponseJson': [this.cpFieldScientistForm.GAAResponseJson]
    });
  }

  public getStatusLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPESTATUS, true)
      .subscribe({
        next: (response) => {
          this.statuses = response;

          this.statuses.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
          this.statuses.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        },
        error: (error) => {
        }
      });
  }

  public getCPSubfunctionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPSUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.cpSubfunctions = response;

          this.cpSubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
          this.cpSubfunctions.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        },
        error: (error) => {
        }
      });
  }

  public getCPRegionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPREGION, true)
      .subscribe({
        next: (response) => {
          this.cpRegions = response;

          this.cpRegions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
          this.cpRegions.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        },
        error: (error) => {
        }
      });
  }

  saveCPFieldScientist(frm: any) {
      if (this.validateForm()) {

          if (this.comments
              && this.comments.toString().trim() !== '') {
              const comment = new ApprovalComments();
              comment.Comment = this.comments.toString();
              this.cpFieldScientistData.ApprovalComments = [];
              this.cpFieldScientistData.ApprovalComments.push(comment);
          }

      this.cpFieldScientistData.RequestStatus = RequestStatus.PENDING;

      this._cpFieldScientistService.saveCPFieldScientist(JSON.stringify(this.cpFieldScientistData)).subscribe({
        next: (response) => {
          if (response.Status === 'SUCCESS') {
            this.DisplaySuccessMessage('Success', response.Message);

            this._appService.sendPendingCount(1);
            this.reset();
          }
          else {
            this.DisplayErrorMessage('Error', response.Message);
          }
        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }

  validateForm() {
    if (!this.cpFieldScientistData.FirstName || this.cpFieldScientistData.FirstName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'First name is required...');
      return false;
    }

    if (!this.cpFieldScientistData.LastName || this.cpFieldScientistData.LastName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Last name is required...');
      return false;
    }

    if (!this.cpFieldScientistData.Code || this.cpFieldScientistData.Code.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Code is required...');
      return false;
    }

    if (!this.cpFieldScientistData.Lat || this.cpFieldScientistData.Lat.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Code is required...');
      return false;
    }

    if (!this.cpFieldScientistData.Long || this.cpFieldScientistData.Long.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Code is required...');
      return false;
    }

    if ((this.cpFieldScientistData.Code
      && (this.cpFieldScientistData.Code.trim().length < 2
        || this.cpFieldScientistData.Code.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Code should be 2 to 4 characters long...');
      return false;
    }

    return true;
  }

  GetAllCountriesFomPRISM() {
    this.countries = [];
    this._cpFieldScientistService.GetAllCountriesFomPRISM().subscribe({
      next: (res) => {
        this.countries = res;
        this.countries.forEach(element => {
          element.value = element.AoiId;
          element.label = element.Name;
        });
        this.countries.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  reset() {
    this.cpFieldScientistData = new CPFieldScientistModel();
    this.comments = '';
  }

  showCPSubfunctionDialog() {
    this.displayCPSubfunctionDialog = true;
  }

  showCPRegionDialog() {
    this.displayCPRegionDialog = true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  GetGAAData(event: any) {
       this.gaaData.Latitude = this.cpFieldScientistData.Lat;
      this.gaaData.Longitude = this.cpFieldScientistData.Long;
      if(this.gaaData.Longitude == undefined
        || this.gaaData.Longitude.toString().split(".").length < 2
        || this.gaaData.Longitude.toString().split(".")[1].length < 2
        || this.gaaData.Latitude.toString().split(".")[1].length < 2) {
        return;
      }

      if(this.gaaData.Latitude != null) {

      this._commonService.GetGAAData(JSON.stringify(this.gaaData)).subscribe({
        next: (response: GAAData) => {
          if(response != null){
            this.cpFieldScientistData.GeographicLocale = response.GeographicLocale;
            this.cpFieldScientistData.Country = response.Country;
            this.cpFieldScientistData.State = response.State;
            this.cpFieldScientistData.CountryAoiId = response.CountryAoiId;
            this.cpFieldScientistData.StateAoiId = response.StateAoiId;
            this.cpFieldScientistData.City = response.City;
            this.cpFieldScientistData.CityAoiId = response.CityAoiId;
          }
          else{
            this.cpFieldScientistData.GeographicLocale = null;
            this.cpFieldScientistData.Country = null;
            this.cpFieldScientistData.State = null;
            this.cpFieldScientistData.City = null;
            this.cpFieldScientistData.CountryAoiId = null;
            this.cpFieldScientistData.StateAoiId = null;
            this.cpFieldScientistData.CityAoiId = null;
          }

        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }
}
