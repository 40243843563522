<div class="container-fluid grid" [hidden]="isROVAdmin">
  <div class="col-3"></div>
  <div class="col-6">
    <h3>
      <span [hidden]="hideMessage">
        You don't have access to the
        <b>Scheduler Admin</b> screen.
        <br />
        <a href="/">Click Here</a> to go to Home Screen.
      </span>
    </h3>
  </div>
  <div class="co-3"></div>
</div>

<div [hidden]="!(isROVAdmin)">
  <form #frm="ngForm">
    <p-panel [toggleable]="true" [collapsed]="toggleSnapShot" (onAfterToggle)="snapshotToggle($event)">
      <p-header>
        <span>
          <b>Admin Snapshot</b>
        </span>
      </p-header>
      <div class="grid" id="dvScheduler">
        <div class="col-8">
          <div class="grid">
            <div class="col-3 controlLabel">
              <label for="txtSchedulerAdminFileName">File Name</label>
            </div>
            <div class="col-9" id="mlCrop">
              <input pInputText id="txtSchedulerAdminFileName" name="fileName" required [(ngModel)]="FileName"
                styleClass="width100" />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="grid">
            <div class="col-3"></div>
            <div class="col-9">
              <button id="btnSchedulerAdminTakeSnapshot" type="button" pButton icon="fa fa fa-save"
                class="p-button-success" (click)="SaveResourceFileHistory(frm)" [disabled]="!frm.form.valid"
                iconPos="left" label="Take Snapshot" title="Take Snapshot" style="float:right"></button>
            </div>
          </div>
        </div>
      </div>
    </p-panel>
  </form>
  <form #frm1="ngForm">
    <p-panel [toggleable]="true" [collapsed]="toggleScheduler" (onAfterToggle)="shedulerToggle($event)">
      <p-header style="width: 100%">
        <div class="grid">
          <div class="col-9">
            <span>
              <b>Scheduler Admin</b>
            </span>
          </div>
          <div class="col-3">
            <button id="btnSchedulerAdminAdd" type="button" pButton icon="fa fa-plus-circle"
              class="p-button-primary mr-2" (click)="addNewScheduler()" iconPos="left" label="Add"
              title="Add New Scheduler" style="float: right;"></button>
          </div>
        </div>
      </p-header>
      <p-table id="dtScheduler" #dt [value]="scheduleData" sortMode="single" [loading]="loading"
        [totalRecords]="scheduleData.length" editable="true" (onEditInit)="onEditInit($event)"
        (onEditComplete)="onEditComplete($event)" [responsive]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th hidden>ID</th>
            <th>Date To Capture</th>
            <th>Name</th>
            <th>Notify The Following</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
          <tr>
            <td hidden></td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-calendar id="calSchedulerAdminDate"
                    (onSelect)="onCalendarSelect($event, 'ScheduledDate', row, rowIndex, dt)" name="date"
                    appendTo="body"></p-calendar>
                </ng-template>
                <ng-template pTemplate="output">
                  {{row.ScheduledDate | date: 'yyyy/MM/dd'}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="row" [pEditableColumnField]="'FileName'" [pEditableColumnRowIndex]="rowIndex">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input id="txtFileName" name="fileName" pInputText type="text" maxlength="75"
                    [(ngModel)]="row.FileName" (blur)="dataTableOnBlur($event, 'FileName', row, rowIndex, dt)" />
                </ng-template>
                <ng-template pTemplate="output">
                  {{row.FileName}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              {{row.NotificationUserDisplayName}}
            </td>
            <td style="text-align: right;">
              <!-- <div> -->
                <button id="btnSchedulerAdminAddDL" type="button" pButton icon="fa fa-user-plus"
                  class="ui-button-info mr-1"
                  (click)="ShowDL($event, 'NotificationUserDisplayName', row, rowIndex, dt)" title="Add DL(s)"
                  style="width: 25px;"></button>
                <button id="btnSchedulerAdminAddUser" type="button" pButton icon="fa fa-user-plus"
                  class="ui-button-primary  mr-1"
                  (click)="ShowPeoplePicker($event, 'NotificationUserDisplayName', row, rowIndex, dt)"
                  title="Add user(s) to notify" style="width: 25px;"></button>
                <button id="btnSchedulerAdminDelete" type="button" pButton icon="fa fa-minus-circle"
                  class="p-button-danger" (click)="deleteScheduler(row, index)" title="Remove Scheduler"
                  style="width: 25px;"></button>
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="5">
              No records found
            </td>
          </tr>
        </ng-template>
      </p-table>
      <br />
      <p-panel>
        <p-header>
          <span>
            <b>Include this message in the reminder</b>
          </span>
        </p-header>
        <div class="grid">
          <div class="col-1 controlLabel">
            <label for="txtSchedulerAdminSubject">Subject</label>
          </div>
          <div class="col-11" id="idSub">
            <input pInputText id="txtSchedulerAdminSubject" name="Subject" [(ngModel)]="Subject"
              styleClass="width100" />
          </div>
          <div class="col-1 controlLabel">
            <label for="txtSchedulerAdminBody">Body</label>
          </div>
          <div class="col-11" id="idBody">
            <textarea pInputTextarea id="txtSchedulerAdminBody" [cols]="30" [rows]="5" name="body" class="p-inputtext"
              [(ngModel)]="Body"></textarea>
          </div>
        </div>
      </p-panel>
      <p-footer>
        <div class="grid">
          <div class="col-10"></div>
          <div class="col-2">
            <button id="btnSchedulerAdminSave" type="button" pButton icon="fa fa-save" class="ui-button-success"
              [disabled]="disableSaveScheduler" (click)="SaveResourceFileScheduler(frm1)" iconPos="left"
              label="Save Scheduler" style="float: right"></button>
          </div>
        </div>
      </p-footer>
    </p-panel>
  </form>
  <p-dialog header="Select User" [style]="{width: '1000px', height:'580px'}" [(visible)]="displayStationLead"
    [responsive]="true" showEffect="fade" [modal]="true">
    <app-peoplepicker [existingPeopleString]="existingPeopleString"
      (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
  </p-dialog>

  <p-dialog header="ADD DL(s)" width="550" height="230" [(visible)]="displayDL" [responsive]="true" showEffect="fade"
    [modal]="true">
    <div class="grid">
      <div class="col-1"></div>
      <div class="col-11">
        <span>For adding more DL(s), Please use semicolon to separate the DL(s).</span>
      </div>
      <div class="col-1">
        <label for="txtSchedulerAdminDL">DL(s):</label>
      </div>
      <div class="col-11">
        <input type="hidden" [(ngModel)]="SchedulerIndex">
        <textarea id="txtSchedulerAdminDL" [cols]="30" [rows]="3" name="DL" pInputTextarea class="p-inputtext"
          [(ngModel)]="DL"></textarea>
      </div>
    </div>
    <p-footer>
      <button id="btnAddDl" type="button" pButton icon="fa fa-plus-circle" (click)="saveDL()" label="Add"
        title="Add DL(s)"></button>
    </p-footer>
  </p-dialog>
</div>
