<div class="container-fluid" [hidden]="isROVAdmin || isROVSuperUser">
  <div class="col-sm-3">
  </div>

  <div class="col-sm-6">
    <h3>
        <span>
          You don't have access to the
          <b>User Roles</b> screen.
          <br />
          <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
        </span>
    </h3>
  </div>

  <div class="co-sm-3">
  </div>
</div>

<form id="userRoleForm" [formGroup]="userRoleForm">
  <p-panel>
    <p-header id="pnlHdrUserRoles">
          <span>User Roles</span>
    </p-header>

    <p-accordion id="acParent" [activeIndex]="accordionIndex" [multiple]="true">
      <p-accordionTab id="actSearch" header="Search for">
        <div class="grid">
          <div class=" col-3 controlLabel">
            <label for="acUsers">Users</label>
          </div>
          <div class="col-7">
            <span class="p-fluid">
              <p-multiSelect id="msUsers" formControlName="selectedUsers" (onChange)="onChange()"
                  [options]="users" optionLabel="label" placeholder="Enter one or more Users"
                  [filter]="true" (onFilter)="onUserFilter($event)" [resetFilterOnHide]="true"
                  display="chip" show [showClear]="true" [loading]="loadingUsers" [maxSelectedLabels]="1000"/>
            </span>
          </div>
          <div class=" col-3 controlLabel">
            <label for="acRoles">Roles</label>
          </div>
          <div class="col-7">
            <span class="p-fluid">
              <p-multiSelect id="msRoles" formControlName="selectedRoles" (onChange)="onChange()"
                  [options]="roles" optionLabel="label" placeholder="Enter one or more Roles"
                  display="chip" show [showClear]="true" [loading]="loadingUsers" [maxSelectedLabels]="1000"/>
            </span>
          </div>

          <div class="controlLabel col-4">
            <p-button id="btnFindRoles" class="leftAlign p-button-info"
                    icon="fa fa-search"
                    [disabled]="findRolesDisabled || saving"
                    (click)="findRoles()"
                    label="Find Roles"/>
          </div>

          <div class="col-2">
            <p-button id="btnCreateNewRole" class="p-button-info right"
                icon="fa fa-plus"
                (click)="createNewRole()" [disabled]="saving"
                label="Create New Role" />
          </div>
        </div>
      </p-accordionTab>

      <div style="margin-top: 10px" class="grid" *ngIf="showFilter">
        <div class="controlLabel col-3">
          <label id="lblFilter" style="font-size: 18px">Filter</label>
        </div>

        <div class="col-7">
          <span class="p-fluid">
              <input type="text" id="txtFilter"
                  formControlName="filter"
                  style="font-size: 18px"
                  (input)="onFilterChange($event)" [disabled]="saving"
                  placeholder="Enter Filter Text"/>
          </span>
        </div>
      </div>

      <p-accordionTab id="actExistingRoles" header="Existing Roles">
        <p-table id="tExistingUserRoles" [value]="filteredExistingUserRoles" columnResizeMode="fit">
          <ng-template pTemplate="header">
              <tr>
                  <th pSortableColumn="LoginName" style="width: 200px">User <p-sortIcon field="LoginName"/></th>
                  <th pColumn="roles">Roles</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
              <tr>
                  <td>{{ user.LoginName }}</td>
                  <div class="chip-container">
                    <div *ngFor="let role of user.RolesArray">
                      <p-chip label="{{role.label}}" [removable]="true" (onRemove)="onExistingRoleChipRemoved(user.Id, role)"/>
                    </div>
                  </div>
              </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>

      <p-accordionTab id="actNewRoles" header="New Roles">
        <p-table id="tNewUserRoles" [value]="filteredNewUserRoles" columnResizeMode="fit">
          <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="LoginName" style="width: 200px">User <p-sortIcon field="LoginName"/></th>
                <th pColumn="roles">Roles</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
              <tr>
                  <td>{{ user.LoginName }}</td>
                  <div class="chip-container">
                    <div *ngFor="let role of user.RolesArray">
                      <p-chip label="{{role.label}}" [removable]="true" (onRemove)="onNewRoleChipRemoved(user.Id, role)"/>
                    </div>
                  </div>
              </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>

    <p-footer style="width: 100%;">
      <div class="grid">
        <div class="col-8"></div>
        <div class="col-2">
          <button id="btnCancel" type="button"
              class="p-button-warning alignRight"
              pButton icon="fa fa-close" [disabled]="saving"
              (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2">
          <button id="btnSubmit" type="button"
              class="p-button-success alignRight"
              pButton icon="fa fa-save"
              (click)="showConfirmation()" [disabled]="!(submitEnabled)"
              label="Submit" [loading]="saving"></button>
        </div>
      </div>    
    </p-footer>
  </p-panel>
</form>

<br/>

<p-dialog id="newRoleDialog" header="Create New Role" [style]="{'width': '400px', 'height': '200px'}"
    [(visible)]="showNewRoleDialog" showEffect="fade" [modal]="true">
  <form id="newRoleForm" [formGroup]="newRoleForm">
    <div class="grid">
      <div class="controlLabel col-2">
        <label>Role</label>
      </div>
      <div class="col-8">
        <input id="txtNewRole" formControlName="newRole" pInputText autofocus type="text"/>
        <div *ngIf="newRoleForm.get('newRole')?.invalid" style="color: red;">Role already exists</div>

        <div class="d-flex" style="padding-top: 10px;">
          <p-button id="btnNewRoleOk" severity="info"
              icon="fa fa-save"
              (click)="saveNewRole()"
              label="Save" />
        
          <p-button id="btnNewRoleCancel" severity="warning"
              icon="fa fa-close" class="ml-2"
              (click)="cancelNewRole()"
              label="Cancel" />
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Confirm" [(visible)]="confirmChangesDialogVisible" [modal]="true" [style]="{'min-width': '50%'}">
  <app-confirm-role-changes [removedRoles]="removedUserRoles"
      [newRoles]="newUserRoles"
      (onConfirm)="submit()"
      (onCancel)="confirmChangesDialogVisible = false" />
</p-dialog>