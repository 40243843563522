<form id="frmCreateCPField" #frm="ngForm" [formGroup]="CPFieldScientistFormGroup">
    <p-panel>
        <p-header>
            <span>
                <b>CP Field Scientist Details</b>
            </span>
        </p-header>
        <div class="grid">
            <div class="col-6">
                <div class="grid">
                    <div class="controlLabel col-3">
                        <label>First Name</label>
                    </div>
                    <div class="col-9">
                        <input id="txtCPFieldFirstName" name="FirstName" formControlName="FirstName" pInputText required
                            autofocus minlength="1" maxlength="25" [(ngModel)]="cpFieldScientistData.FirstName" />
                    </div>
                    <div class="controlLabel col-3">
                        <label>Last Name</label>
                    </div>
                    <div class="col-9">
                        <input id="txtCPFieldLastName" name="LastName" formControlName="LastName" pInputText required
                            autofocus minlength="1" maxlength="25" [(ngModel)]="cpFieldScientistData.LastName" />
                    </div>
                    <div class="controlLabel col-3">
                        <label>Code</label>
                    </div>
                    <div class="col-9">
                        <input id="txtCPFieldCode" name="Code" formControlName="Code" pInputText required autofocus
                            minlength="1" maxlength="25" [(ngModel)]="cpFieldScientistData.Code" />
                    </div>
                    <div class="controlLabel col-3">
                        <label>CP Subfunction</label>
                    </div>
                    <div class="col-9">
                        <div class="grid">
                            <div class="col-11">
                                <p-dropdown id="ddCCPSsubfunction" name="CPSubfunction" [filter]=true
                                    [options]="cpSubfunctions" [(ngModel)]="cpFieldScientistData.CPSubfunctionId"
                                    formControlName="CPSubfunctionId" appendTo="body" [style]="{'width':'100%'}"
                                    placeholder="Select a CP Subfunction" [showClear]="true">
                                </p-dropdown>
                            </div>
                            <div class="col-1">
                                <button id="btnCPSubfunctionDialog" type="button" pButton icon="fa fa-edit"
                                    class="p-button-info" style="width: 30px; float: right; margin-right: 8px;"
                                    (click)="showCPSubfunctionDialog()" title="Add/Edit CPSubfunction"></button>
                            </div>
                        </div>
                    </div>
                    <div class="controlLabel col-3">
                        <label>CP Region</label>
                    </div>
                    <div class="col-9">
                        <div class="grid">
                            <div class="col-11">
                                <p-dropdown id="ddCCPFieldCU" name="CPRegion" [filter]=true [options]="cpRegions"
                                    [(ngModel)]="cpFieldScientistData.CPRegionId" formControlName="CPRegionId"
                                    appendTo="body" [style]="{'width':'100%'}" placeholder="Select a CP Region"
                                    [showClear]="true">
                                </p-dropdown>
                            </div>
                            <div class="col-1">
                                <button id="btnCPRegionDialog" type="button" pButton icon="fa fa-edit"
                                    class="p-button-info" style="width: 30px; float: right; margin-right: 8px;"
                                    (click)="showCPRegionDialog()" title="Add/Edit CP Region"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="grid">
                 
                    <div class="controlLabel col-3">
                        <label>Latitude</label>
                    </div>
                    <div class="col-9">
                        <input id="txtCPFieldLatitude" type="text" pInputText required name="Latitude" maxlength="15" step="any"
                            [(ngModel)]="cpFieldScientistData.Lat" formControlName="Lat" />
                    </div>
                    <div class="col-3 controlLabel">
                        <label>Longitude</label>
                    </div>
                    
                    <div class="col-9">
                        <input id="txtCPFieldLongitude" type="text" pInputText required name="Longitude" (keyup)="GetGAAData($event)" maxlength="15"
                            step="any" [(ngModel)]="cpFieldScientistData.Long" formControlName="Long" />
                    </div>
                    <div class="controlLabel col-3">
                        <label id="lblCPFieldGeographicLocale">Geographic Locale</label>
                    </div>
                    <div class=" removeSpacing col-9">
                      <input id="txtCPFieldGeographicLocale" type="text" pInputText readonly name="GeographicLocale"
                           [(ngModel)]="cpFieldScientistData.GeographicLocale" formControlName="GeographicLocale" />
                    </div>
                    <div class="col-3 controlLabel">
                        <label>Comment</label>
                    </div>
                    <div class="col-9">
                        <span>Please enter comments or notes regarding changes.</span>
                        <textarea id="txtCPFieldComment" [cols]="30" [rows]="5" name="Comment" pInputTextarea class="p-inputtext"
                          [(ngModel)]="comments" formControlName="Comment"></textarea>
                      </div>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="grid">
                <div class="col-8">
                </div>
                <div class="col-2 d-grid">
                    <button id="btnCCPFieldReset" type="button" class="p-button-warning alignRight" pButton
                        icon="fa fa-close" (click)="reset()" label="Reset"></button>
                </div>
                <div class="col-2 d-grid">
                    <button id="btnCCPFieldSave" type="submit" class="p-button-success alignRight" pButton
                        icon="fa fa-save" [disabled]="!frm.form.valid" (click)="saveCPFieldScientist(frm)"
                        label="Submit"></button>
                </div>
            </div>
        </p-footer>
    </p-panel>
</form>

<p-dialog id="dlgEditCPSubfunction" header="Edit CP Subfunction" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayCPSubfunctionDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="CPSubfunction" (getLookUpEvent)="getCPSubfunctionLookUp()"></manage-lookup>
</p-dialog>

<p-dialog id="dlgEditCPRegion" header="Edit CP Region" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayCPRegionDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="CPRegion" (getLookUpEvent)="getCPRegionLookUp()"></manage-lookup>
</p-dialog>
