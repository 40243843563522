import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'CAE034B7AC5C43EC8501BD8A207B598D';
@Injectable()
export class SharedService {

  constructor() { }

  getSessionStorageValue(key: any): any {
    let data: any;

    if (sessionStorage.getItem(key) !== null) {
        data = CryptoJS.AES.decrypt(sessionStorage.getItem(key) || "", SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
    } else {
        data = sessionStorage.getItem(key)
    }

    return data;
  }

  setSessionStorageValue(key: any, value: string) {
    let encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    sessionStorage.setItem(key, encryptedValue);
  }
}