import { Injectable } from '@angular/core';
import { Observable, Subject ,throwError} from 'rxjs';
import { EnvironmentService, UrlConfig } from '../environments/environment.service';
import { map, finalize ,catchError} from "rxjs/operators";
import { HttpService } from './http/http.service';

interface Version {
  version: string;
}

@Injectable()
export class AppService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;
  private subject = new Subject<any>();

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

public version(): Observable<any> {

  return this.http.get(`version.json?v=${new Date().getTime()}`).pipe(
    map((res: any) => {
      const ver: string = JSON.stringify(res);
      const result: Version = JSON.parse(ver) as Version;
      return result.version;
    }));
}

public getAPIVersion(): Observable<string> {
     return this.http.get(this.urlConfig.getAPIVersion).pipe(
      map(
          (result) => result,
          (error: any) => error
      ),
      finalize(() => { })
  );
}

public cleanCache(): Observable<boolean> {
     return  this.http.get(this.urlConfig.cleanCache).pipe(
      map(
          (result) => result,
          (error: any) => error
      ),
      finalize(() => { })
  );
}

public migrateCRAInfo(): Observable<boolean> {
  return this.http.get(this.urlConfig.migrateCRAInfo).pipe(
    map(
        (result) => result,
        (error: any) => error
    ),
    finalize(() => { })
);
}

public getRecentHistoryCount(): Observable<number> {
    return  this.http.get(this.urlConfig.getRecentHistoryCount).pipe(
      map(result => result),
      catchError(error => error),
      finalize(() => { })
  );
}

public getRecentHistory(): Observable<any> {
    return  this.http.get(this.urlConfig.getRecentHistory).pipe(
      map(
          (result) => result,
          (error: any) => error
      ),
      finalize(() => { })
  );
}

public getUserClaim(): Observable<any> {
  return this.http.get(this.urlConfig.getClaims)
}

sendPendingCount(count: number) {
  this.subject.next(count);
}

getPendingCount(): Observable<any> {
  return this.subject.asObservable();
}

  // Error handling
handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}