<p-panel id="pnlUploadFile" class="margin">
  <p-header>
    <span><b>File History</b></span>
  </p-header>
    <p-table id="dtHistoryFile" [value]="fileData" sortMode="single" [resizableColumns]="true"
        [loading]="loading" scrollHeight="600px" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="FileName">
            Name
            <p-sortIcon field="FileName"></p-sortIcon>
          </th>
          <th pSortableColumn="CreatedOn">
            Created On
            <p-sortIcon field="CreatedOn"></p-sortIcon>
          </th>
          <th></th>
          <th></th>
          <th pSortableColumn="PublishedOn">
            Published On
            <p-sortIcon field="PublishedOn"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        <tr>
          <td><i class="fa fa-file-excel-o excelColor mr-1"></i>{{file.FileName}}</td>
          <td>{{file.CreatedOn}}</td>
          <td>
            <button type="button" pButton icon="fa fa-cloud-download" class="p-button-success" style="background-color: #5cb85c"
              (click)="generateResourceFileFromHistory(file)" label="GetFile"></button>
          </td>
          <td>
            <button type="button" pButton class="p-button-success" style="background-color: #98c119;"
              (click)="savePublishFile(file)" label="Publish"></button>
          </td>
          <td>{{file.PublishedOn}}</td>
        </tr>
      </ng-template>
    </p-table>
</p-panel>