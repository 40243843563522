import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgramResultModel } from '../shared/programResultModel';
import { LoadingService } from '../services/loadingService';
import { Station } from './../shared/station';
import { SeedStorageLocationCombo } from './../shared/seedStorageLocationCombo';
import { SeedEvaluationZoneCombo } from './../shared/seedEvaluationZoneCombo';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class ProgramService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  bindProgramDropdownLists(): Observable<any> {
    return this.http.get(this.urlConfig.programDropDownLists);
  }

  getProgramResult(): Observable<ProgramResultModel[]> {
    return this.http.get(this.urlConfig.program);
  }

  saveProgram(program: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveProgram, program, httpOptions);
  }

  saveProgramComment(programComment: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveProgramComment, programComment, httpOptions);
  }

  exportCSVForPrograms(station: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForPrograms, station, { headers: httpHeaders, responseType: 'arraybuffer' });
  }

  getStationsByCropID(crop: string): Observable<Station[]> {
    return this.http.get(this.urlConfig.stationsByCropID + '?cropID=' + crop);
  }

  getEvaluationByCropID(crop: string): Observable<SeedEvaluationZoneCombo[]> {
    return this.http.get(this.urlConfig.evaluationByCropID + '?cropID=' + crop);  
  }

  getSeedStorageLocationByCropID(crop: string, station: string): Observable<SeedStorageLocationCombo[]> {
    return this.http.get(this.urlConfig.seedStorageLocationByCropID + '?cropID=' + crop + '&' + 'stationID=' + station);  
  }

  saveLEDataToPrismProgram(programLEData: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveLEDataToPrismProgram, programLEData, httpOptions);  
  }

}
