import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { UserRequestedData } from './../shared/userRequestedData';
import { Constants, RequestStatus } from '../shared';
import { UserClaim} from "../shared/userClaim";
import { AppService } from '../app.service';
import { CommonService } from './../shared/common.service';
import { Message } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from './../shared/person';
import { Region } from './../shared/region';
import { RegionModel } from './../shared/regionModel';
import { EvaluationZoneType } from './../shared/evaluationZoneType';
import { CropModel } from './../shared/cropModel';
import { EvaluationZoneService } from './evaluationzone.service';
import { EvaluationZoneResultModel } from './../shared/evaluationZoneResultModel';
import { EvaluationZoneForm } from './../shared/evaluationZoneFormControls';
import { ApprovalComments } from '.././shared/approvalComments';
import { SeedEvaluationZoneCombo } from '../shared/seedEvaluationZoneCombo';

import { LERequestedData } from './../shared/lERequstedData';
import { ExperimentProjectCodeResult } from './../shared/experimentProjectCodeResult';
import { LocationProjectCodeResult } from './../shared/locationProjectCodeResult';
import { ProjectLEResult } from './../shared/projectLEResult';
import { SSLLEResult } from './../shared/sSLLEResult';
import { StationLEResult } from './../shared/stationLEResult';

import {SharedService} from './../services/shared.service';

@Component({
  selector: 'app-evaluationzone',
  templateUrl: './evaluationzone.component.html',
  styleUrls: ['./evaluationzone.component.scss']
})
export class EvaluationzoneComponent implements OnChanges {
  msgs: Message[] = [];
  status: SelectItem[] = [];
  displayComments: boolean;
  dialogVisible: boolean;
  displayEvaluationZoneLead: boolean;
  selectedStatus: string;
  sourceIdentifier: string;
  expandedItems: any[] = [];
  public ezLead: SelectItem[] = [];
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = true;
  public evaluationZoneAllData: EvaluationZoneResultModel[] = [];
  public filteredData: EvaluationZoneResultModel[] = [];
  public _approvalComments: ApprovalComments = new ApprovalComments();
  public evaluationZoneFormGroup: FormGroup;
  public existingPeopleString = '';
  public existingPeople: Person[] = [];
  public approvalCommentData: any[] = [];
  public evaluationZoneCodes: SeedEvaluationZoneCombo[] = [];
  public evaluationZoneCropName: CropModel[] = [];
  public regionName: RegionModel[] = [];
  public allRegions: RegionModel[] = [];
  public evaluationZoneTypeName: EvaluationZoneType[] = [];
  public evaluationZoneData: EvaluationZoneResultModel = new EvaluationZoneResultModel();
  public parentComponent = 'EvaluationZone';
  loading: boolean;

  public filteredEZCodes: string;
  public filteredEZName: string;
  public filteredEZCrops: string;
  public filteredEZRegionCodes: string;
  public filteredEZDEscription: string;
  public filteredEZLeadNames: string;
  public filteredEZRequestStatus: string;
  public EvaluationZoneID: string;
  public disableCommentButton = false;
  public distinctCodes: SelectItem[];
  public distinctRegions: SelectItem[];
  public sortedCodes: SelectItem[];
  public sortedRegions: SelectItem[];
  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  public panelHeight = '350px';
  public resizeIcon = 'fa fa-expand';
  public height = 580;
  public width = 1200;
  public positionLeft = '50px';
  public positionTop = '40px';
  public displayLEIDWindow = false;
  public existingLEIDString = '';
  public aoiIDs: number[] = [];
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = [];
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public top = 0;
  public left = 0;
  cols: any[];

  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  public evaluationZoneForm = new EvaluationZoneForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    new Region(),
    '',
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;
  constructor(private route: ActivatedRoute, private _appService: AppService,
    private _evaluationZoneService: EvaluationZoneService, private _commonService: CommonService,
    private _confirmation: ConfirmationService, private router: Router,
    private _fb: FormBuilder, private sharedservice: SharedService) {
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.evaluationZoneFormGroup = this._fb.group({
        'EvaluationZoneName': [this.evaluationZoneForm.EvaluationZoneName, Validators.required],
        'EvaluationZoneCode': [this.evaluationZoneForm.EvaluationZoneCode, Validators.required],
        'EvaluationZoneLeadNames': [this.evaluationZoneForm.EvaluationZoneLeadNames],
        'Description': [this.evaluationZoneForm.Description, Validators.required],
        'Color': [this.evaluationZoneForm.Color],
        'CropID': [this.evaluationZoneForm.CropID, Validators.required],
        'RegionID': [this.evaluationZoneForm.RegionID, Validators.required],
        'CropName': [this.evaluationZoneForm.CropName],
        'CropDescription': [this.evaluationZoneForm.CropDescription],
        'EvaluationZoneTypeID': [this.evaluationZoneForm.EvaluationZoneTypeID, this.evaluationZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'EvaluationZoneTypeCode': [this.evaluationZoneForm.EvaluationZoneTypeCode],
        'EvaluationZoneTypeName': [this.evaluationZoneForm.EvaluationZoneTypeName],
        'RequestStatus': [this.evaluationZoneForm.RequestStatus],
        'Programs': [{ value: this.evaluationZoneForm.Programs, disabled: true }],
        'PRISM_AOI_ID': [this.evaluationZoneForm.PRISM_AOI_ID, this.evaluationZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.evaluationZoneForm.ApprovalComments]
      });
    } else {
      this.evaluationZoneFormGroup = this._fb.group({
        'EvaluationZoneName': [this.evaluationZoneForm.EvaluationZoneName, Validators.required],
        'EvaluationZoneCode': [this.evaluationZoneForm.EvaluationZoneCode, Validators.required],
        'EvaluationZoneLeadNames': [this.evaluationZoneForm.EvaluationZoneLeadNames],
        'Description': [this.evaluationZoneForm.Description, Validators.required],
        'Color': [this.evaluationZoneForm.Color],
        'CropID': [this.evaluationZoneForm.CropID, Validators.required],
        'RegionID': [this.evaluationZoneForm.RegionID, Validators.required],
        'CropName': [this.evaluationZoneForm.CropName],
        'CropDescription': [this.evaluationZoneForm.CropDescription],
        'EvaluationZoneTypeID': [this.evaluationZoneForm.EvaluationZoneTypeID,this.evaluationZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'EvaluationZoneTypeCode': [this.evaluationZoneForm.EvaluationZoneTypeCode],
        'EvaluationZoneTypeName': [this.evaluationZoneForm.EvaluationZoneTypeName],
        'RequestStatus': [this.evaluationZoneForm.RequestStatus],
        'Programs': [{ value: this.evaluationZoneForm.Programs, disabled: true }],
        'PRISM_AOI_ID': [this.evaluationZoneForm.PRISM_AOI_ID, this.evaluationZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.evaluationZoneForm.ApprovalComments]
      });
    }
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  getUserName(){
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
      this.userName = userClaim.username;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 3) {
      this.getUserName();
      this.buildForm();

      if( (this.cleanCache || (this.evaluationZoneAllData == null || this.evaluationZoneAllData.length === 0))
          && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 3)) {
        this.route.queryParams.subscribe(p=>{
          const evaluationZoneID: string = p['EvaluationZoneID'];
          this.selectedRequestID = evaluationZoneID ?? '';
        })

        this.cols = [
          { field: 'EvaluationZoneID', header: 'EZ ID', hidden: 'true', title: 'Evaluation Zone ID' },
          { field: 'EvaluationZoneCode', header: 'EZ Code', title: 'Evaluation Zone Code' },
          { field: 'EvaluationZoneName', header: 'EZ Name', title: 'Evaluation Zone Name' },
          { field: 'CropDescription', header: 'Crop' },
          { field: 'RegionCode', header: 'RCZ', title: 'Research Commercial Zone' },
          { field: 'Description', header: 'Description' },
          { field: 'EvaluationZoneLeadNames', header: 'EZ Lead', title: 'Evaluation Zone Lead' },
          { field: 'RequestStatus', header: 'Status' }
        ];
         this.bindEvaluationZoneDropdownLists();
          this.getEvaluationZones();
      }
    }
  }

  bindEvaluationZoneDropdownLists() {
    this._evaluationZoneService.bindEvaluationZoneDropdownLists().subscribe({
     next: data => {
        if (data.EvaluationZoneCodes && data.EvaluationZoneCodes.length > 0) {
          this.evaluationZoneCodes = [];

          data.EvaluationZoneCodes.forEach((item: { Id: any; Code: string; Name: string; CropID: any; RequestStatus: any; }) => {
            this.evaluationZoneCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              CropID: item.CropID,
              RequestStatus: item.RequestStatus,
              label: item.Code + ' - ' + item.Name,
              value: item.Id
            });
          });
        }

        if (data.Crops && data.Crops.length > 0) {
          this.evaluationZoneCropName = data.Crops;
          this.evaluationZoneCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (data.EvaluationZoneTypes && data.EvaluationZoneTypes.length > 0) {
          this.evaluationZoneTypeName = data.EvaluationZoneTypes;
          this.evaluationZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
        }

        if (data.Regions && data.Regions.length > 0) {
          this.regionName = data.Regions;
          this.regionName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  onCloneEvaluationZone(evaluationzone: EvaluationZoneResultModel)
  {
    if(evaluationzone.RequestStatus === RequestStatus.PENDING || evaluationzone.RequestStatus === RequestStatus.ACTIVE) {
      localStorage.setItem("ToBeClonedEZ", JSON.stringify(evaluationzone));
      this.router.navigate(['/create','EvaluationZone']);
    }
    else {
      this.DisplayInfoMessage('Cloning', 'Only Active or Pending records can be cloned');
    }
  }

  clearRegion() {
    this.evaluationZoneForm.RegionID = '';
  }

  getRegionsByCropID() {
    this.allRegions = [];
    const cropID = this.evaluationZoneForm.CropID;
    this.allRegions = this.regionName.filter(s => s.CropID === cropID);
  }

  getProgramNamesByEvaluationZoneID() {
    this.regionName = [];
    const evaluationZoneID = this.evaluationZoneForm.BaseEvaluationZoneID;
    this._evaluationZoneService.getProgramNamesByEvaluationZoneID(evaluationZoneID)
      .subscribe({
        next: data => {
          this.evaluationZoneFormGroup.controls['Programs'].setValue(data);
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
  });
  }

  getEvaluationZones() {
    if(this.evaluationZoneAllData.length === 0){
      this.loading = true;
      let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
      if (localRoles !== '' && localRoles !== null) {
        const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
        this.isROVAdmin = userClaim.isROVAdmin;
        this.isROVUser = userClaim.isROVUser;

        this._evaluationZoneService.getEvaluationZoneResult().subscribe({
          next: response => {
            this.filteredData = this.evaluationZoneAllData = response;
            if (response != null && this.selectedRequestID !== '') {
              const selectedItem = response.find(element => element.EvaluationZoneID.toString());
              this.first = 0;
              if(!selectedItem){
                this.DisplayInfoMessage('Info', `The Evaluation Zone
                requested in the URL either doesn't exist or is no more in Pending State.`);
              }
              else{
                let index = response.indexOf(selectedItem);
                const pageNumber = Math.ceil(++index / 10)
                this.first = (pageNumber - 1) * 10;
                this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
                this.editEvaluationZones(selectedItem);
              }
            }
            this.distinctAllDropdownCodes();
            this.loading = false;
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          }
        });
      }
    }
  }

  distinctAllDropdownCodes() {
    const items = this.evaluationZoneAllData;
    let flags: { [code: string]: boolean } = {};
    const l = items.length;

    this.distinctCodes = [];
    for (let i = 0; i < items.length; i++) {
      if (!flags[items[i].EvaluationZoneCode]) {
        flags[items[i].EvaluationZoneCode] = true;
        this.distinctCodes.push({
          label: items[i].EvaluationZoneCode,
          value: items[i].EvaluationZoneCode
        });
      }
    }
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }

    flags = {};
    this.distinctRegions = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].Region && items[i].Region.Code) {
        if (!flags[items[i].Region.Code]) {
          flags[items[i].Region.Code] = true;
          this.distinctRegions.push({
            label: items[i].Region.Code,
            value: items[i].Region.Code
          });
        }
      }
    }
    if (this.distinctRegions) {
      this.sortedRegions = this.distinctRegions.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
  }

  expandRow(item: number) {
    let exists = false;
    this.expandedItems.forEach(x => {
      if (x.EvaluationZoneID === this.evaluationZoneAllData[item].EvaluationZoneID) {
        exists = true;
      }
    });

    if (exists) {
      this.expandedItems.pop();
    } else {
      this.expandedItems.push(this.evaluationZoneAllData[item]);

      if (this.isROVAdmin
        || (this.evaluationZoneAllData[item].CreatedBy.Email.toLocaleLowerCase() === this.userName.toLocaleLowerCase())) {
        // try {
        //   setTimeout(function () {
        //     document.getElementById('dtEvaluationZones')
        //       .querySelector('p-rowexpansionloader').scrollIntoView();
        //   }, 100);
        // } catch (err) { console.log(Constants.SCROLLERROR + err.toString()); }

        this.editEvaluationZones(this.evaluationZoneAllData[item]);
      }
    }
  }

  editEvaluationZones(evaluationZone: EvaluationZoneResultModel) {
    //this.setHeight();

    if (this.isROVAdmin) {
      this.evaluationZoneFormGroup.controls['RequestStatus'].enable();
    } else {
      this.evaluationZoneFormGroup.controls['RequestStatus'].disable();
    }

    this.buildForm();
    this.evaluationZoneForm = Object.assign({}, evaluationZone);
    this.getRegionsByCropID();
    this.dialogVisible = true;

    if (!this.evaluationZoneForm.RegionID || !this.allRegions.find(s => s.Id.toString()
      === this.evaluationZoneForm.RegionID.toString())) {
      this.evaluationZoneForm.RegionID = '';
    }

    this.status = [];

    this.ezLead = [];

    if (evaluationZone.EvaluationZoneLeads != null) {
      evaluationZone.EvaluationZoneLeads.forEach(pr => {
        this.ezLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
      });
    }
    if (this.isROVAdmin) {
      this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
      this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
    }
    this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

    if (evaluationZone.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }
      this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
      this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
    }

    this.selectedStatus = this.status.find(s => s.label === evaluationZone.RequestStatus)?.value;
    this.evaluationZoneForm.RequestStatus = this.selectedStatus;

    this.evaluationZoneForm.EvaluationZoneID = evaluationZone.EvaluationZoneID;
    this.evaluationZoneForm.BaseEvaluationZoneID = evaluationZone.BaseEvaluationZoneID;

    this.evaluationZoneForm.ApprovalComments = [];
    this.evaluationZoneFormGroup.controls['Comment'].setValue('');

    this.statusChange(this.evaluationZoneForm.RequestStatus,false);

    if (this.isROVAdmin) {
      this.getLEData(this.evaluationZoneForm.EvaluationZoneCode);
    }
  }

  getLEData(code: string) {
    if (code) {
      const queryString = `code=${code}&type=${this.parentComponent}`;

      this.projectLEResult = [];
      this.stationLEResult = [];
      this.sSLLEResult = [];
      this.locationProjectCodeResult = [];
      this.experimentProjectCodeResult = [];
      this.aoiIDs = [];

      this._commonService.getLEAndAOIReportData(queryString)
        .subscribe({
          next: Result => {
            this.projectLEResult = Result.ProjectLE;
            this.projectLEResult = this.projectLEResult.slice();

            this.stationLEResult = Result.StationLE;
            this.stationLEResult = this.stationLEResult.slice();

            this.sSLLEResult = Result.SSLLE;
            this.sSLLEResult = this.sSLLEResult.slice();

            this.locationProjectCodeResult = Result.LocationProjectCode;
            this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

            if (this.sSLLEResult && this.sSLLEResult.length > 0) {
              this.sSLLEResult.forEach(s => {
                this.aoiIDs.push(s.AOIId);
              });
            }

            this.experimentProjectCodeResult = Result.ExperimentProjectCode;
            this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
          },
          error:(error: any) => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
         } );
    }
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string, isChange: boolean) {
    if(this.evaluationZoneForm.PRISM_AOI_ID=="0")
    {
      this.evaluationZoneForm.PRISM_AOI_ID="";
    }
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.evaluationZoneForm.RequestStatus === RequestStatus.RETIRED ||
      	this.evaluationZoneForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.evaluationZoneFormGroup = this._fb.group({
        'EvaluationZoneName': [this.evaluationZoneForm.EvaluationZoneName, Validators.required],
        'EvaluationZoneCode': [this.evaluationZoneForm.EvaluationZoneCode, Validators.required],
        'EvaluationZoneLeadNames': [this.evaluationZoneForm.EvaluationZoneLeadNames],
        'Description': [this.evaluationZoneForm.Description],
        'Color': [this.evaluationZoneForm.Color],
        'CropID': [this.evaluationZoneForm.CropID],
        'RegionID': [this.evaluationZoneForm.RegionID],
        'CropName': [this.evaluationZoneForm.CropName],
        'CropDescription': [this.evaluationZoneForm.CropDescription],
        'EvaluationZoneTypeID': [this.evaluationZoneForm.EvaluationZoneTypeID],
        'EvaluationZoneTypeCode': [this.evaluationZoneForm.EvaluationZoneTypeCode],
        'EvaluationZoneTypeName': [this.evaluationZoneForm.EvaluationZoneTypeName],
        'RequestStatus': [this.evaluationZoneForm.RequestStatus],
        'Programs': [{ value: this.evaluationZoneForm.Programs, disabled: true }],
        'PRISM_AOI_ID': [this.evaluationZoneForm.PRISM_AOI_ID, Validators.required],
        'Comment': [this.evaluationZoneForm.ApprovalComments]
      });
    } else {
      this.buildForm();
    }
    if(isChange)
    this.evaluationZoneFormGroup.markAsDirty();
  }



  saveEvaluationZone(frm: any, status: string) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }


  save(frm: any, status: string) {
    const crop = this.evaluationZoneCropName.find(s => s.value === this.evaluationZoneForm.CropID);

    if (crop) {
      this.evaluationZoneForm.CropID = crop.CropID;
      this.evaluationZoneForm.CropName = crop.CropName;
      this.evaluationZoneForm.CropDescription = crop.CropDescription;
    } else {
      this.evaluationZoneForm.CropID = '';
      this.evaluationZoneForm.CropName = '';
      this.evaluationZoneForm.CropDescription = '';
    }

    const region = this.regionName.find(s => s.value === this.evaluationZoneForm.RegionID);

    if (region) {
      this.evaluationZoneForm.Region = region;
    } else {
      const rgn = new Region();
      rgn.Code = '';
      rgn.Description = '';
      rgn.Id = '';
      rgn.Name = '';
      this.evaluationZoneForm.Region = rgn;
    }

    const evaluationZoneType = this.evaluationZoneTypeName.find(s => s.value === this.evaluationZoneForm.EvaluationZoneTypeID);

    if (evaluationZoneType) {
      this.evaluationZoneForm.EvaluationZoneTypeID = evaluationZoneType.Id;
      this.evaluationZoneForm.EvaluationZoneTypeName = evaluationZoneType.Name;
      this.evaluationZoneForm.EvaluationZoneTypeCode = evaluationZoneType.Code;
    } else {
      this.evaluationZoneForm.EvaluationZoneTypeID = '';
      this.evaluationZoneForm.EvaluationZoneTypeName = '';
      this.evaluationZoneForm.EvaluationZoneTypeCode = '';
    }

    this.existingPeople = [];
    if (this.evaluationZoneForm.EvaluationZoneLeads) {
      this.evaluationZoneForm.EvaluationZoneLeads.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    this.evaluationZoneData = { ...Object.assign({}, this.evaluationZoneForm), RegionCode: '' };

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.evaluationZoneData.RequestStatus = status;

      this._evaluationZoneService.saveEvaluationZone(JSON.stringify(this.evaluationZoneData))
        .subscribe({
          next: (Result: { Status: string; EvaluationZone: EvaluationZoneResultModel; Message: string; }) => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.evaluationZoneData.EvaluationZoneID;
              this.evaluationZoneData = Result.EvaluationZone;

              const i = this.evaluationZoneAllData.findIndex(s => s.EvaluationZoneID === primaryID);
              const j = this.expandedItems.findIndex(s => s.EvaluationZoneID === primaryID);
              const k = this.evaluationZoneCodes.findIndex(s => s.Id === primaryID);

              if (Result.EvaluationZone.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.evaluationZoneAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.evaluationZoneCodes.splice(k, 1);
                }
              } else {
                this.evaluationZoneAllData[i] = this.evaluationZoneData;
                this.expandedItems[j] = this.evaluationZoneData;

                if (k !== -1) {
                  this.evaluationZoneCodes.splice(k, 1,
                    ({
                      Id: this.evaluationZoneData.EvaluationZoneID,
                      Code: this.evaluationZoneData.EvaluationZoneCode,
                      Name: this.evaluationZoneData.EvaluationZoneName,
                      CropID: this.evaluationZoneData.CropID,
                      RequestStatus: this.evaluationZoneData.RequestStatus,
                      label: this.evaluationZoneData.EvaluationZoneCode + ' - ' + this.evaluationZoneData.EvaluationZoneName,
                      value: this.evaluationZoneData.EvaluationZoneID
                    }));
                }

                this.cleanUpCache.emit(true);
              }

              this.evaluationZoneCodes = this.evaluationZoneCodes.slice();
              this.expandedItems = this.expandedItems.slice();
              this.evaluationZoneAllData = this.evaluationZoneAllData.slice();
              this.distinctAllDropdownCodes();

              if (this.selectedStatus === RequestStatus.PENDING && Result.EvaluationZone.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);
              this.buildForm();
            } else {
              this.evaluationZoneData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('Error', Result.Message);
            }
            this.dialogVisible = false;
          },
          error: (error: string) => {
            this.evaluationZoneData.RequestStatus = this.selectedStatus;
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(isApprove: boolean) {
    if ((!this.evaluationZoneForm.CropName || this.evaluationZoneForm.CropName === '')
      && this.evaluationZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (!this.evaluationZoneForm.EvaluationZoneName || this.evaluationZoneForm.EvaluationZoneName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Name is required...');
      return false;
    }

    if ((!this.evaluationZoneForm.EvaluationZoneCode
      || (this.evaluationZoneForm.EvaluationZoneCode
        && this.evaluationZoneForm.EvaluationZoneCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Code is required...');
      return false;
    }



    if ((this.evaluationZoneForm.EvaluationZoneCode
      && (this.evaluationZoneForm.EvaluationZoneCode.trim().length < 2
        || this.evaluationZoneForm.EvaluationZoneCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Code should be between 2 and 4 characters long...');
      return false;
    }

    const ezCode = this.evaluationZoneCodes.find(s => s.Code.toString().toLowerCase()
      === this.evaluationZoneForm.EvaluationZoneCode.toString().toLowerCase()
      && s.CropID === this.evaluationZoneForm.CropID
      && s.Id !== this.evaluationZoneForm.EvaluationZoneID && s.RequestStatus !== RequestStatus.RETIRED);

    if (ezCode && this.evaluationZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning',
        'This Evaluation Zone Code and Crop combo already exists. Please enter a new Code or Crop..');
      return false;
    }

    if ((!this.evaluationZoneForm.Description || this.evaluationZoneForm.Description.trim() === '')
      && this.evaluationZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Description is required...');
      return false;
    }

    if (this.evaluationZoneForm.Color
      && (this.evaluationZoneForm.Color.toString().indexOf('-') >= 0
        || this.evaluationZoneForm.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.evaluationZoneForm.Color
      && this.evaluationZoneForm.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 9 characters for Color...');
      return false;
    }

    if (this.evaluationZoneForm.RequestStatus === RequestStatus.PENDING
      && this.selectedStatus !== RequestStatus.PENDING) {
      this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
      return false;
    }

    // if ((!this.evaluationZoneForm.Region
    //   || (this.evaluationZoneForm.Region && this.evaluationZoneForm.Region.Name === ''))
    //   && this.evaluationZoneForm.RequestStatus !== RequestStatus.RETIRED) {
    //   this.DisplayWarningMessage('Warning', 'RCZ is required...');
    //   return false;
    // }

    if (isApprove && !this.evaluationZoneForm.EvaluationZoneTypeName &&
      this.evaluationZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Type is required...');
      return false;
    }

    // if (!this.evaluationZoneForm.EvaluationZoneLeadNames
    //     || this.evaluationZoneForm.EvaluationZoneLeadNames.trim() === '') {
    //     this.DisplayErrorMessage('Error', 'At least one Evaluation Zone Lead is required...');
    //     return false;
    // }this.programForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)

    if (this.evaluationZoneForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
      if (isApprove && (!this.evaluationZoneForm.PRISM_AOI_ID
        || (this.evaluationZoneForm.PRISM_AOI_ID
          && (this.evaluationZoneForm.PRISM_AOI_ID.toString().trim() === '' || this.evaluationZoneForm.PRISM_AOI_ID.toString().trim() === '0')))) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is required for Request Status other than Pending and Retired...');
        return false;
      }

      const idx = this.aoiIDs.findIndex(s => s.toString() === this.evaluationZoneForm.PRISM_AOI_ID.toString());

      if (isApprove && (this.evaluationZoneForm.PRISM_AOI_ID === '0' || idx === -1)) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is not a valid AOI ID...');
        return false;
      }
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  clearCrop() {
    this.evaluationZoneForm.CropID = '';
    this.evaluationZoneForm.RegionID = '';
  }

  clearEZT() {
    this.evaluationZoneForm.EvaluationZoneTypeID = '';
  }

  clearEZRegion(){}

  onCloseEditEvaluationZone(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: any) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
    }
  }

  viewComments(evaluationZone: EvaluationZoneResultModel) {
    this.approvalCommentData = [];

    if (evaluationZone.ApprovalComments != null) {
      evaluationZone.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;

    this.sourceIdentifier = evaluationZone.EvaluationZoneID;
  }

  onSaveComment(data: any){
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const evaluationZoneID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = evaluationZoneID;

    this._evaluationZoneService.saveEvaluationZoneComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const evaluationZoneIndex = this.evaluationZoneAllData
              .findIndex(s => s.EvaluationZoneID.toString() === evaluationZoneID.toString());

            if (evaluationZoneIndex !== -1) {
              this.evaluationZoneAllData[evaluationZoneIndex] = Result.EvaluationZone;
              this.evaluationZoneAllData[evaluationZoneIndex].ApprovalComments = Result.EvaluationZone.ApprovalComments.slice();
              this.evaluationZoneAllData = this.evaluationZoneAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.EvaluationZoneID = Result.EvaluationZone.EvaluationZoneID);

              this.expandedItems[expandedIndex] = Result.EvaluationZone;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.EvaluationZone.ApprovalComments != null) {
                Result.EvaluationZone.ApprovalComments.forEach((element : any) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
        error:error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
  });
  }

  filterEvaluationZoneDataTable(event: { filteredValue?: EvaluationZoneResultModel[]; }) {
    if(event?.filteredValue)
      this.filteredData = event.filteredValue;
  }

  exportCSVForEvaluationZones() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._evaluationZoneService.exportCSVForEvaluationZones(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel'});
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'EvaluationZoneData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
        error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
  });
  }

  GetAOIID(evaluationZone: EvaluationZoneForm) {
    if (evaluationZone.EvaluationZoneCode) {
      if (!evaluationZone.EvaluationZoneCode) {
        this.DisplayErrorMessage('Error', 'Please enter the Evaluation Zone Code first...');
        return;
      }

      if (!evaluationZone.EvaluationZoneName) {
        this.DisplayErrorMessage('Error', 'Please enter the Evaluation Zone Name first...');
        return;
      }

      if (!evaluationZone.Description) {
        this.DisplayErrorMessage('Error', 'Please enter the Description first...');
        return;
      }

      const obj = new LERequestedData();
      obj.ParentComponent = this.parentComponent;
      obj.LEAOIID = 0;

      if (!this.evaluationZoneFormGroup.controls['EvaluationZoneCode'].dirty) {
        obj.Code = '';
        obj.ProjectLE = this.projectLEResult;
        obj.StationLE = this.stationLEResult;
        obj.SSLLE = this.sSLLEResult;
        obj.LocationProjectCode = this.locationProjectCodeResult;
        obj.ExperimentProjectCode = this.experimentProjectCodeResult;
      } else {
        obj.Code = evaluationZone.EvaluationZoneCode;
        obj.ProjectLE = [];
        obj.StationLE = [];
        obj.SSLLE = [];
        obj.LocationProjectCode = [];
        obj.ExperimentProjectCode = [];
      }

      obj.OtherObject = JSON.stringify(evaluationZone);

      this.existingLEIDString = JSON.stringify(obj);
      this.displayLEIDWindow = true;
    } else {
      this.DisplayErrorMessage('Error', 'Please enter the Code first...');
    }
  }

  onaddLEDataClick(data: LERequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayLEIDWindow = false;

      this.evaluationZoneForm.PRISM_AOI_ID = data.LEAOIID.toString();

      this.projectLEResult = data.ProjectLE;
      this.stationLEResult = data.StationLE;
      this.sSLLEResult = data.SSLLE;

      this.aoiIDs = [];

      if (this.sSLLEResult && this.sSLLEResult.length > 0) {
        this.sSLLEResult.forEach(s => {
          this.aoiIDs.push(s.AOIId);
        });
      }

      this.locationProjectCodeResult = data.LocationProjectCode;
      this.experimentProjectCodeResult = data.ExperimentProjectCode;

      this.existingLEIDString = '';
    }
  }

  addLeads() {
    this.existingPeople = [];

    if (this.evaluationZoneForm.EvaluationZoneLeads) {
      this.evaluationZoneForm.EvaluationZoneLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayEvaluationZoneLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayEvaluationZoneLead = false;
      let leadNames = '';

      this.evaluationZoneForm.EvaluationZoneLeads = [];
      this.evaluationZoneForm.EvaluationZoneLeadNames = '';
      this.ezLead = [];

      data.person.forEach(pr => {
        if (!this.evaluationZoneForm.EvaluationZoneLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.evaluationZoneForm.EvaluationZoneLeads.push({
            ID: '', PersonID: pr.PersonID, Person: pr, EvaluationZone: null,
            EvaluationZoneID: this.evaluationZoneForm.EvaluationZoneID
          });
          this.ezLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        leadNames = leadNames + pr.DisplayName + '; ';
      });

      leadNames = leadNames.trim();

      if (leadNames.lastIndexOf(';') === leadNames.length - 1) {
        leadNames = leadNames.substring(0, leadNames.lastIndexOf(';'));
      }

      this.evaluationZoneForm.EvaluationZoneLeadNames = leadNames;
      this.existingPeopleString = '';
    }
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      this.panelHeight = '500px';
      return 10;
    }
    return 0;
  }
}