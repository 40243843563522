import { ApprovalComments } from './approvalComments';
import { Region } from './region';
import { EvaluationZoneLead } from './evaluationZoneLead';
import { Person } from './person';

export class EvaluationZoneResultModel {
    public EvaluationZoneID: string;
    public EvaluationZoneName: string;
    public Description: string;
    public EvaluationZoneCode: string;
    public Color: string;
    public CropID: string;
    public CropName: string;
    public CropDescription: string;
    public EvaluationZoneTypeID: string;
    public EvaluationZoneTypeName: string;
    public EvaluationZoneTypeCode: string;
    public RegionID: string;
    public RegionCode: string;
    public Region: Region;
    public Programs: string;
    public EvaluationZoneLeadNames: string;
    public EvaluationZoneLeads: EvaluationZoneLead[] = [];
    public PRISM_AOI_ID: string;
    public RequestStatus: string;
    public ApprovalComments: ApprovalComments[] = [];
    public CreatedBy: Person;
    public CreatedByUserName: string;
    public CreatedOn: Date;
    public ModifiedByUserName: string;
    public ModifiedBy: Person;
    public ModifiedOn: Date;
    public ApprovedByUserName: string;
    public ApprovedBy: Person;
    public ApprovedOn: Date;
    public RetiredByUserName: string;
    public RetiredBy: Person;
    public RetiredOn: Date;
    public BaseEvaluationZoneID: string;
}