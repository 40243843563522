import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Configuration as MsalBrowserConfiguration } from '@azure/msal-browser';

export const APPLICATION_SETTINGS = new InjectionToken<ApplicationSettings>('APPLICATION_SETTINGS');

export interface ApplicationSettings {
  environment: {
    production: boolean;
    name: string,
  }

  msal: {
    config: MsalBrowserConfiguration,
    guard: MsalGuardConfiguration,
    interceptor: MsalInterceptorConfiguration
  },
  groupIds: [],
    graphApi: "",
    graphUserApi: string,
    SUPPORT_BOT_SETTINGS: {
        botAlias: string,
        botName: string,
        supportName: string,
        roleArn: string,
        roleSessionName: string
    },

  apiUrl:string,
  appInsights: {
    instrumentationKey: string
  }

}

/** Simple Service to Inject, so you don't have to directly inject the Injection Token */
@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {
  constructor(@Inject(APPLICATION_SETTINGS) public settings: ApplicationSettings) { }
}
