import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { IJwtTokenRetriever } from '../lib/services/jwt-token-retriever.service';

@Injectable()
export class JwtTokenService implements IJwtTokenRetriever {
    public constructor(
        private msalService: MsalService
    ) {
        this.msalService.initialize().subscribe( result => {});
     }

    public get jwtToken(): Observable<AuthenticationResult> {
        let currentUser = this.msalService.instance.getAllAccounts();
        var silentRequest = {
            scopes: ["user.read"],
            account: currentUser[0],
            forceRefresh: false
        };
        
        return this.msalService.acquireTokenSilent(silentRequest);
    }
}