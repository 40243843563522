import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegionResultModel } from '../shared/regionResultModel';
import { LoadingService } from '../services/loadingService';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';
import { CPFieldScientistModel, GeoPoliticalUnitModel } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class CPFieldScientistService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService, private loader: LoadingService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  getCPFieldScientists(): Observable<CPFieldScientistModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.getCPFieldScientists)
  }

  saveCPFieldScientist(cpFieldScientist: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveCPFieldScientists, cpFieldScientist, httpOptions)
  }  

  saveCPFieldScientistComment(cpFieldScientistComment: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
     return this.http.post(this.urlConfig.saveCPFieldScientistComment, cpFieldScientistComment, httpOptions)
    //.catch((error: any) => Observable.throw(error.statusText))
    //.finally(() => this.loader.clearMessage());
  }

  exportCSVForCPFieldScientists(cpFieldScientist: string) {
    this.loader.setMessage('Loading...');
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForCPFieldScientists, cpFieldScientist, { headers: httpHeaders, responseType: 'arraybuffer' })
    //.catch((error: any) => Observable.throw(error.statusText))
    //.finally(() => this.loader.clearMessage());
  }

  syncGAA(entityType:string) {
    this.loader.setMessage('Loading...');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.urlConfig.syncGAA, JSON.stringify(  entityType), { headers: httpHeaders, responseType: 'arraybuffer' })
    //.catch((error: any) => Observable.throw(error.statusText))
    //.finally(() => this.loader.clearMessage());
  } 

  GetAllCountriesFomPRISM(): Observable<GeoPoliticalUnitModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.getAllCountriesFomPRISM)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }

 /*  GetNextlevelAreaFromPRISM(aoi_id: string): Observable<GeoPoliticalUnitModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get(this.urlConfig.getNextlevelAreaFromPRISM + '?aoi_id=' + aoi_id)
      //.catch((error: any) => Observable.throw(error.statusText))
      //.finally(() => this.loader.clearMessage());
  }  */

  GetGAAData(gaaData: string): Observable<any> {
    this.loader.setMessage('Loading...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.gaaData, gaaData, httpOptions)
  }
    
}
