import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loadingService';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { StationResultModel } from '../shared/stationResultModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class BaseService {
  protected urlConfig: UrlConfig;
  protected http: HttpClient;

  constructor(private _http: HttpClient, private environment: EnvironmentService, private loader: LoadingService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  getDropdownLists(): Observable<any> {
    this.loader.setMessage('Loading...');
    return this.http.get<any>(this.urlConfig.getDropdownLists)
  }

  getStationResult(): Observable<StationResultModel[]> {
    this.loader.setMessage('Loading...');
    return this.http.get<StationResultModel[]>(this.urlConfig.station)
  }
}