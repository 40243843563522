import { MessageService, SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Person } from '../shared';
import { RegionModel } from '../shared';
import { StationTypeModel } from '../shared/stationTypeModel';
import { StationResultModel } from '../shared';
import { StationForm } from '../shared/stationFormControls';
import { BaseComponent } from './base.component';
import { BaseService } from './base.service';
import { UserRequestedData } from '../shared';
import { StationService } from '../station/station.service';
import { Region } from '../shared';
import { Constants, RequestStatus } from '../shared';
import {SharedService} from '../services/shared.service';

export class BaseStationComponent extends BaseComponent {
  private Stations: StationResultModel[] = [];
  get stations(): StationResultModel[] {
    return this.Stations;
  }
  set stations(stations: StationResultModel[]) {
    this.Stations = stations;
  }

  public stationData: StationResultModel = new StationResultModel();
  public parentComponent = '';
  public peoplePickerHeader = '';
  public lookupDialogHeader: string = 'Edit Technology Tier';
  public existingPeopleString = '';
  public stationOperationLead: SelectItem[] = [];
  public shiptoStationContact: SelectItem[] = [];
  public stationSafetyContact: SelectItem[] = [];
  public displayStationLead: boolean;
  public displayLookupDialog: boolean;
  public existingPeople: Person[] = [];
  public selectedCrops: string[] = [];
  public selectedConsolidationCropIds: string[] = [];
  public selectedTechnologyTier: string[] = [];
  public regionCodes: Region[] = [];
  public sortedCodes: SelectItem[];
  public stationFormGroup: FormGroup;
  public stationForm = new StationForm(
    '',
    '',
    '',
    '',
    '',
    '',
    [],
    [],
    [],
    [],
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    '',
    '',
    '',
    new Person(),
    '',
    [],
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    '',
    new StationTypeModel(),
    '',
    new RegionModel(),
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );

  public lookupType: string;
  public CGULabel: string = Constants.CGU;

  constructor(public _fb: FormBuilder, public override _baseService: BaseService,
    public _stationService: StationService, _messageService: MessageService,
    sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  buildForm(isNewStation: boolean): void {
    if (this.isROVAdmin) {
      this.stationFormGroup = this._fb.group({
        'StationName': [this.stationForm.StationName, Validators.required],
        'UserResponsibilityStationAssocNames': [this.stationForm.UserResponsibilityStationAssocNames],
        'ServerNames': [this.stationForm.ServerNames],
        'crop': [this.selectedCrops, Validators.required],
        'consolidationCrop': [this.selectedConsolidationCropIds],
        'R3SiteCode': [this.stationForm.R3SiteCode],
        'R3ShipTo': [this.stationForm.R3ShipTo],
        'R3SoldTo': [this.stationForm.R3SoldTo],
        'R3DeliveryPlant': [this.stationForm.R3DeliveryPlant],
        'description': [this.stationForm.Description],
        'RequestStatus': [this.stationForm.RequestStatus],
        'stationCode': [this.stationForm.StationCode, Validators.required],
        'PRISMLEID': [this.stationForm.PRISMLEID, this.stationForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Address': [this.stationForm.Address, Validators.required],
        'CityId': [this.stationForm.CityId],
        'City': [this.stationForm.City],
        'CountyId': [this.stationForm.CountyId],
        'County': [this.stationForm.County],
        'StateId': [this.stationForm.StateId],
        'State': [{ value: this.stationForm.State }],
        'CountryId': [this.stationForm.CountryId,Validators.required],
        'Country': [this.stationForm.Country],
        'Longitude': [this.stationForm.Longitude, Validators.required],
        'Latitude': [this.stationForm.Latitude, Validators.required],
        'Comment': [this.stationForm.ApprovalComments],
        'StationTypeID': [this.stationForm.StationTypeID],
        'RegionID': [this.stationForm.RegionID],
        'TechnologyTierTypeId': [this.stationForm.TechnologyTierTypeId],
        'CorporateITCode': [this.stationForm.CorporateITCode],
        'UserResponsibilityShiptoStationAssocNames': [this.stationForm.UserResponsibilityShiptoStationAssocNames],
        'UserResponsibilityStationSafetyContactAssocNames': [this.stationForm.UserResponsibilityStationSafetyContactAssocNames],
        'GeographicLocale': [this.stationForm.GeographicLocale],
        'GAAResponseJson': [this.stationForm.GAAResponseJson]
      });
    } else {
      this.stationFormGroup = this._fb.group({
        'StationName': [this.stationForm.StationName, Validators.required],
        'UserResponsibilityStationAssocNames': [this.stationForm.UserResponsibilityStationAssocNames],
        'ServerNames': [this.stationForm.ServerNames],
        'crop': [this.selectedCrops, Validators.required],
        'consolidationCrop': [this.selectedConsolidationCropIds],
        'R3SiteCode': [this.stationForm.R3SiteCode],
        'R3ShipTo': [this.stationForm.R3ShipTo],
        'R3SoldTo': [this.stationForm.R3SoldTo],
        'R3DeliveryPlant': [this.stationForm.R3DeliveryPlant],
        'description': [this.stationForm.Description],
        'RequestStatus': [this.stationForm.RequestStatus],
        'stationCode': [this.stationForm.StationCode, Validators.required],
        'PRISMLEID':  [this.stationForm.PRISMLEID, this.stationForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Address': [this.stationForm.Address, Validators.required],
        'CityId': [this.stationForm.CityId, Validators.required],
        'City': [this.stationForm.City],
        'CountyId': [this.stationForm.CountyId],
        'County': [this.stationForm.County],
        'StateId': [this.stationForm.StateId],
        'State': [{ value: this.stationForm.State }],
        'CountryId': [this.stationForm.CountryId,Validators.required],
        'Country': [this.stationForm.Country],
        'Longitude': [this.stationForm.Longitude, Validators.required],
        'Latitude': [this.stationForm.Latitude, Validators.required],
        'Comment': [this.stationForm.ApprovalComments],
        'StationTypeID': [this.stationForm.StationTypeID],
        'RegionID': [this.stationForm.RegionID],
        'TechnologyTierTypeId': [this.stationForm.TechnologyTierTypeId],
        'CorporateITCode': [this.stationForm.CorporateITCode],
        'UserResponsibilityShiptoStationAssocNames': [this.stationForm.UserResponsibilityShiptoStationAssocNames],
        'UserResponsibilityStationSafetyContactAssocNames': [this.stationForm.UserResponsibilityStationSafetyContactAssocNames],
        'GeographicLocale': [this.stationForm.GeographicLocale],
        'GAAResponseJson': [this.stationForm.GAAResponseJson]
      });
    }
  }

  getStations() {
    this._baseService.getStationResult().subscribe(
      stations => {
        this.Stations = stations;
      });
  }

  showTechnologyTierDialog() {
    this.lookupType = Constants.LOOKUPTYPETECHNOLOGYTIERTYPE;
    this.displayLookupDialog = true;
  }

  closeTechnologyTierDialog() {
    this.lookupType = "";
    this.displayLookupDialog = false;
  }

  ShowPeoplePicker(responsibilityCode: string) {
    this.existingPeople = [];

    switch(responsibilityCode){
      case "OPLD":
        this.peoplePickerHeader = 'Select Operations Leads';
        if (this.stationData.UserResponsibilityStationAssoc) {
          this.stationData.UserResponsibilityStationAssoc.forEach(lead => {
            this.existingPeople.push(lead.Person);
          });
        }
        break;
      case "SSHP":
        this.peoplePickerHeader = 'Select Ship to R&D Center Contacts';
        if (this.stationData.UserResponsibilityShiptoStationAssoc) {
          this.stationData.UserResponsibilityShiptoStationAssoc.forEach(lead => {
            this.existingPeople.push(lead.Person);
          });
        }
        break;
      case "SAFE":
        this.peoplePickerHeader = 'Select R&D Center Safety Contact';
        if (this.stationData.UserResponsibilityStationSafetyContactAssoc) {
          this.stationData.UserResponsibilityStationSafetyContactAssoc.forEach(lead => {
            this.existingPeople.push(lead.Person);
          });
        }
        break;
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    obj.ResponsibilityCode = responsibilityCode;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayStationLead = true;
  }

  addBorderSafetyContact(){
    if (this.stationSafetyContact.length !== 0) {
      const x = document.querySelector('#lstCStationSafetyContacts > div');
      if(x != null) {
        (x as HTMLElement).style.borderBottom = '1px solid';
        (x as HTMLElement).style.borderBottomColor = '#c0c0c0';
      }
    } else {
      const x = document.querySelector('#lstCStationSafetyContacts > div');
      if(x != null) {
        (x as HTMLElement).style.borderBottomColor = '3px solid #a94442';
      }
    }
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayStationLead = false;
      let selectedUser = '';

      switch(data.ResponsibilityCode) {
        case "OPLD":
          this.stationOperationLead = [];
          this.stationData.UserResponsibilityStationAssoc = [];
          this.stationData.UserResponsibilityStationAssocNames = '';

          this.stationForm.UserResponsibilityStationAssoc = [];
          this.stationForm.UserResponsibilityStationAssocNames = '';
          break;
        case "SSHP":
          this.shiptoStationContact = [];
          this.stationData.UserResponsibilityShiptoStationAssoc = [];
          this.stationData.UserResponsibilityShiptoStationAssocNames = '';

          this.stationForm.UserResponsibilityShiptoStationAssoc = [];
          this.stationForm.UserResponsibilityShiptoStationAssocNames = ''
          break;
        case "SAFE":
          this.stationSafetyContact = [];
          this.stationData.UserResponsibilityStationSafetyContactAssoc = [];
          this.stationData.UserResponsibilityStationSafetyContactAssocNames = '';

          this.stationForm.UserResponsibilityStationSafetyContactAssoc = [];
          this.stationForm.UserResponsibilityStationSafetyContactAssocNames = '';
          break;
      }

      data.person.forEach(pr => {

      switch(data.ResponsibilityCode) {
        case "OPLD":
          if (!this.stationData.UserResponsibilityStationAssoc.find(s => s.Person.LoginName === pr.LoginName)) {
            const userResponsibilityStationAssocObj = {
              ID: '', PersonID: pr.PersonID, Person: pr, Station: null,
              StationID: this.stationData.StationID, ResponsibilityID: '',
              ResponsibilityCode: 'OPLD'
            };
            this.stationData.UserResponsibilityStationAssoc.push(userResponsibilityStationAssocObj);
            this.stationForm.UserResponsibilityStationAssoc.push(userResponsibilityStationAssocObj);
            this.stationOperationLead.push({ label: pr.DisplayName, value: pr.DisplayName });
          }
          break;

        case "SSHP":
          if (!this.stationData.UserResponsibilityShiptoStationAssoc.find(s => s.Person.LoginName === pr.LoginName)) {
            const userResponsibilityShiptoStationAssocObj = {
              ID: '', PersonID: pr.PersonID, Person: pr, Station: null,
              StationID: this.stationData.StationID, ResponsibilityID: '',
              ResponsibilityCode: 'SSHP'
            };
            this.stationData.UserResponsibilityShiptoStationAssoc.push(userResponsibilityShiptoStationAssocObj);
            this.stationForm.UserResponsibilityShiptoStationAssoc.push(userResponsibilityShiptoStationAssocObj);
            this.shiptoStationContact.push({ label: pr.DisplayName, value: pr.DisplayName });
          }
          break;

        case "SAFE":
          if (!this.stationData.UserResponsibilityStationSafetyContactAssoc.find(s => s.Person.LoginName === pr.LoginName)) {
            const userResponsibilityStationSafetyContactAssocObj = {
              ID: '', PersonID: pr.PersonID, Person: pr, Station: null,
              StationID: this.stationData.StationID, ResponsibilityID: '',
              ResponsibilityCode: 'SAFE'
            };
            this.stationData.UserResponsibilityStationSafetyContactAssoc.push(userResponsibilityStationSafetyContactAssocObj);
            this.stationForm.UserResponsibilityStationSafetyContactAssoc.push(userResponsibilityStationSafetyContactAssocObj);
            this.stationSafetyContact.push({ label: pr.DisplayName, value: pr.DisplayName });
          }
          this.addBorderSafetyContact();
          break;
        }

        selectedUser = selectedUser + pr.DisplayName + '; ';
      });

      selectedUser = selectedUser.trim();

      if (selectedUser.lastIndexOf(';') === selectedUser.length - 1) {
        selectedUser = selectedUser.substring(0, selectedUser.lastIndexOf(';'));
      }

      switch(data.ResponsibilityCode) {
        case "OPLD":
          this.stationData.UserResponsibilityStationAssocNames = selectedUser;
          this.stationForm.UserResponsibilityStationAssocNames = selectedUser;
          break;
        case "SSHP":
          this.stationData.UserResponsibilityShiptoStationAssocNames = selectedUser;
          this.stationForm.UserResponsibilityShiptoStationAssocNames = selectedUser;
          break;
        case "SAFE":
          this.stationData.UserResponsibilityStationSafetyContactAssocNames  = selectedUser;
          this.stationForm.UserResponsibilityStationSafetyContactAssocNames = selectedUser;
          break;
      }

      this.existingPeopleString = '';
    }
  }

  clearST() {
    this.stationData.StationTypeID = '';
  }

  clearRegions() {
    this.stationData.RegionID = '';
  }
}
