import { BaseComponent } from "../basecomponents/base.component";
import { Component, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { MessageService } from "primeng/api";
import { CommonService } from "../shared/common.service";
import { Constants, ResourceFileHistory } from "../shared";
import { BaseService } from "../basecomponents/base.service";
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-historyfile',
  templateUrl: './historyfile.component.html',
  styleUrls: ['./historyfile.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HistoryfileComponent extends BaseComponent {
  public message = '';
  public fileData: ResourceFileHistory[] = [];
  public panelHeight = '600px';

  constructor(private _commonService: CommonService, _baseService: BaseService,
    _messageService: MessageService, sharedservice: SharedService) {
      super(_baseService, _messageService, sharedservice);
  }

  override ngOnInit(): void {
    this.SelectMenuOption('History');
    this.getResourceFileHistory();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    // this control is hidden if not an Admin, so no roles needed
    let i = 0;
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const offsetHeight = document.getElementById('pnlHistoryFile')!.offsetTop;
    this.panelHeight = ((viewPortHeight) - (offsetHeight) - (320)).toString() + 'px';
  }

  getResourceFileHistory() {
    this.fileData = [];
    this.loading = true;
    this._commonService.getResourceFileHistory().subscribe({
      next: data => {
        this.fileData = data;
        this.loading = false;
      },
      error: error => { this.loading = false; },
      complete: ()=> {  this.loading = false; }
    });
  }

  savePublishFile(file: ResourceFileHistory) {
    this._commonService.savePublishFile(JSON.stringify(file.ID)).subscribe({
      next: Result => {
        if (Result.Status === 'SUCCESS') {
          this.DisplaySuccessMessage('Success', Result.Message);
          this.getResourceFileHistory();
        } 
        else if (Result.Status === 'INFO') {
          this.DisplayInfoMessage('Info', Result.Message);
        } 
        else {
          this.DisplayErrorMessage('Error', Result.Message);
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } 
        else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  generateResourceFileFromHistory(file: ResourceFileHistory) {
    this._commonService.generateResourceFileFromHistory(JSON.stringify(file.ID)).subscribe({
      next: data => {
        const blob = new Blob([data], { type: 'application/ms-excel'});
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);
        link.download = file.FileName + '.xlsx';
        document.body.appendChild(link);
        link.click();

        setTimeout(function () {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        }, 100);
      },
      error: error => {
        this.DisplayErrorMessage('Error', Constants.NOSERVICE);
      }
    });
  }

  public override DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public override DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public override DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public override DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}