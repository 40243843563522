import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { CommonService } from '.././shared/common.service';
import { LERequestedData } from './../shared/lERequstedData';
import { Message } from "primeng/api";

import { ExperimentProjectCodeResult } from './../shared/experimentProjectCodeResult';
import { LocationProjectCodeResult } from './../shared/locationProjectCodeResult';
import { ProjectLEResult } from './../shared/projectLEResult';
import { SSLLEResult } from './../shared/sSLLEResult';
import { StationLEResult } from './../shared/stationLEResult';

import { StationResultModel } from './../shared/stationResultModel';
import { ProgramResultModel } from './../shared/programResultModel';
import { EvaluationZoneResultModel } from './../shared/evaluationZoneResultModel';
import { BreedingZoneResultModel } from './../shared/breedingZoneResultModel';
import { RegionResultModel } from './../shared/regionResultModel';

import { StationService } from './../station/station.service';
import { ProgramService } from './../program/program.service';
import { EvaluationZoneService } from '../evaluationzone/evaluationzone.service';
import { BreedingZoneService } from './../breedingzone/breedingzone.service';
import { RegionService } from '../region/region.service';

import { Constants } from './../shared/constants';
import { CommercialMarketZoneResultModel } from '../shared/commercialMarketZoneResultModel';
import { CommercialMarketService } from '../commercialMarket/commercialmarket.service';

@Component({
  selector: 'app-leidpicker',
  templateUrl: './leidpicker.component.html',
  styleUrls: ['./leidpicker.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class LEIDPickerComponent implements OnInit, OnChanges {
  public msgs: Message[] = [];
  public selectedItems: any[];
  public lERequestedData: LERequestedData;
  public parentComponent = '';
  public LEAOIID: number;
  public code: string;
  public isAOI = false;
  public otherObject = '';
  public disableCreateButton = false;
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = []; 
  projectCols: any[]; 
  stationCols: any[]; 
  ssLCols: any[]; 
  locationCols: any[]; 
  experimentCols: any[];
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public addBtnName = 'Add New Legal Entity';
  public station: StationResultModel;
  public program: ProgramResultModel;
  public eZone: EvaluationZoneResultModel;
  public cmZone: CommercialMarketZoneResultModel;
  public bZone: BreedingZoneResultModel;
  public region: RegionResultModel;
  public loadingProject = false;
  public loadingStation = false;
  public loadingSSL = false;
  public loadingLocationProject = false;
  public loadingExperimentProject = false;

  @Input()
  public existingLEIDString: string;

  @Output()
  public addLEDataClick: EventEmitter<LERequestedData> = new EventEmitter<LERequestedData>();

  constructor(private _commonService: CommonService, private _stationService: StationService,
    private _programService: ProgramService, private _evaluationZoneService: EvaluationZoneService,
    private _breedingZoneService: BreedingZoneService, private _regionService: RegionService,private _commercialMarketService: CommercialMarketService) {
  }

  ngOnInit() { 
    this.projectCols= [
      { field: 'ResearchProjectGroupMember', header: 'Research Project Group Member' },
      { field: 'LEId', header: 'LE Id' },
      { field: 'LEName', header: 'LE Name' },
      { field: 'LEType', header: 'LE Type' },
      { field: 'NameType', header: 'Name Type' }, 
      { field: 'Description', header: 'Description' },
      { field: 'ActualType', header: 'Actual Type' },
      { field: 'StartDate', header: 'Start Date' },
      { field: 'MaintDate', header: 'Maint. Date' },
      { field: 'NameType', header: 'Name Type' },
    ];  

    this.stationCols= [ 
      { field: 'LEId', header: 'LE Id' },
      { field: 'LEName', header: 'LE Name' },
      { field: 'LEType', header: 'LE Type' }, 
      { field: 'Description', header: 'Description' },
      { field: 'ActualType', header: 'Actual Type' }, 
      { field: 'Editable', header: 'Editable' }, 
      { field: 'Status', header: 'Status' },
      { field: 'Grouper', header: 'Grouper' }, 
      { field: 'InactiveDate', header: 'Inactive Date' }, 
      { field: 'StartDate', header: 'Start Date' },
      { field: 'MaintDate', header: 'Maint. Date' }
    ];
    this.ssLCols= [
      { field: 'AOIName', header: 'Trial Name' },
      { field: 'AOIId', header: 'AOI Id' },
      { field: 'CurrentCrop', header: 'Current Crop' }, 
      { field: 'AOIDescription', header: 'Description' },
      { field: 'CGU', header: 'CGU' },      
      { field: 'NameType', header: 'Name Type' }, 
      { field: 'CreateDate', header: 'Create Date' },
      
    ];

    this.locationCols= [
      { field: 'Project', header: 'Project' },
      { field: 'CurrentCrop', header: 'Current Crop' }, 
      { field: 'Year', header: 'Year' }, 
      { field: 'AOIType', header: 'Trial Type' },
      { field: 'AOICount', header: 'Trial Count' },
    ];
   
    this.experimentCols= [
      { field: 'Project', header: 'Project' },
      { field: 'CropGenus', header: 'Crop Genus' }, 
      { field: 'Year', header: 'Year' }, 
      { field: 'AOIType', header: 'Experiment Type' },
      { field: 'AOICount', header: 'Experiment Count' },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.existingLEIDString) {
      this.lERequestedData = JSON.parse(this.existingLEIDString);

      this.parentComponent = this.lERequestedData.ParentComponent;
      this.code = this.lERequestedData.Code;
      this.otherObject = this.lERequestedData.OtherObject;

      if (this.code) {
        if(this.parentComponent == 'R&D Center')
          this.getLEAOIData(this.code, 'Station');
        else
          this.getLEAOIData(this.code, this.parentComponent);
      } else {
        this.projectLEResult = this.lERequestedData.ProjectLE;
        this.stationLEResult = this.lERequestedData.StationLE;
        this.sSLLEResult = this.lERequestedData.SSLLE;
        this.locationProjectCodeResult = this.lERequestedData.LocationProjectCode;
        this.experimentProjectCodeResult = this.lERequestedData.ExperimentProjectCode;

        if (this.parentComponent === 'R&D Center' || this.parentComponent === 'Program') {
          this.isAOI = false;
          this.addBtnName = 'Add New Legal Entity';
        } else {
          this.isAOI = true;
          this.addBtnName = 'Add New Area Of Interest';
        }

        this.disableButton();
      }
    }
  }

  disableButton() {
    if (this.parentComponent === 'R&D Center' || this.parentComponent === 'Program') {
      if (((!this.projectLEResult || this.projectLEResult.length === 0
        || (this.parentComponent === 'R&D Center' && this.projectLEResult
          && !this.projectLEResult.find(s => s.ActualType && s.ActualType.toUpperCase() !== 'PORG')))
        || (!this.projectLEResult || this.projectLEResult.length === 0
          || (this.parentComponent === 'Program' && this.projectLEResult
            && !this.projectLEResult.find(s => s.LEType && s.LEType.toUpperCase() !== 'RSTA'))))
        && ((!this.stationLEResult || this.stationLEResult.length === 0
          || (this.parentComponent === 'Program' && this.stationLEResult
            && !this.stationLEResult.find(s => s.LEType && s.LEType.toUpperCase() !== 'RSTA')))
          || (!this.stationLEResult || this.stationLEResult.length === 0
            || (this.parentComponent === 'R&D Center' && this.stationLEResult
              && !this.stationLEResult.find(s => s.ActualType && s.ActualType.toUpperCase() !== 'PORG')))
        )) {
        this.disableCreateButton = false;
      } else {
        this.disableCreateButton = true;
      }
    } else {
      if (!this.sSLLEResult || this.sSLLEResult.length === 0) {
        this.disableCreateButton = false;
      } else {
        this.disableCreateButton = true;
      }
    }
  }

  getLEAOIData(code: string, parentComponent: string) {
    const queryString = `code=${code}&type=${parentComponent}`;

    this.projectLEResult = [];
    this.stationLEResult = [];
    this.sSLLEResult = [];
    this.locationProjectCodeResult = [];
    this.experimentProjectCodeResult = [];
    this.loadingProject = true;
    this.loadingStation = true;
    this.loadingSSL = true;
    this.loadingLocationProject = true;
    this.loadingExperimentProject = true;

    this._commonService.getLEAndAOIReportData(queryString)
      .subscribe(
        Result => {
          this.projectLEResult = Result.ProjectLE;
          this.projectLEResult = this.projectLEResult.slice();
          this.loadingProject = false;

          this.stationLEResult = Result.StationLE;
          this.stationLEResult = this.stationLEResult.slice();
          this.loadingStation = false;

          this.sSLLEResult = Result.SSLLE;
          this.sSLLEResult = this.sSLLEResult.slice();
          this.loadingSSL = false;

          this.locationProjectCodeResult = Result.LocationProjectCode;
          this.locationProjectCodeResult = this.locationProjectCodeResult.slice();
          this.loadingLocationProject = false;

          this.experimentProjectCodeResult = Result.ExperimentProjectCode;
          this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
          this.loadingExperimentProject = false;

          if (this.parentComponent === 'R&D Center' || this.parentComponent === 'Program') {
            this.isAOI = false;
            this.addBtnName = 'Add New Legal Entity';
          } else {
            this.isAOI = true;
            this.addBtnName = 'Add New Area Of Interest';
          }

          this.disableButton();
        },
        error => {
          this.loadingProject = false;
          this.loadingStation = false;
          this.loadingSSL = false;
          this.loadingLocationProject = false;
          this.loadingExperimentProject = false;
        }
      );
  }

  AddLEAOIID() {
    if (this.parentComponent === 'R&D Center') {
      this.station = JSON.parse(this.otherObject);
      this.AddLEIDStation(this.station);
    } else if (this.parentComponent === 'Program') {
      this.program = JSON.parse(this.otherObject);
      this.AddLEIDProgram(this.program);
    } else if (this.parentComponent === 'EvaluationZone') {
      this.eZone = JSON.parse(this.otherObject);
      this.AddAOIIDEvaluationZone(this.eZone);
    } else if (this.parentComponent === 'BreedingZone') {
      this.bZone = JSON.parse(this.otherObject);
      this.AddAOIIDBreedingZone(this.bZone);
    } else if (this.parentComponent === 'Region') {
      this.region = JSON.parse(this.otherObject);
      this.AddAOIIDRegion(this.region);
    } else if(this.parentComponent === 'CommercialMarketZone') {
      this.cmZone = JSON.parse(this.otherObject);
      this.AddAOIIDCommercialMarketZone(this.cmZone);
    }
  }

  AddLEIDStation(station: StationResultModel) {
    this._stationService.saveLEDataToPrismStation(JSON.stringify(station))
      .subscribe(
        Result => {
          if (Result.Status === 'SUCCESS') {
            station.PRISMLEID = Result.ID;
            this.getLEAOIData(station.StationCode, 'Station');//Do not rename, api has a check to replace R&D Center
            this.DisplaySuccessMessage('Success', 'New LE ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  AddLEIDProgram(program: ProgramResultModel) {
    this._programService.saveLEDataToPrismProgram(JSON.stringify(program))
      .subscribe(
        (        Result: { Status: string; ID: string; Message: string; }) => {
          if (Result.Status === 'SUCCESS') {
            program.LEID = Result.ID;
            this.getLEAOIData(program.ProgramCode, 'Program');
            this.DisplaySuccessMessage('Success', 'New LE ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        (        error: string) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  AddAOIIDEvaluationZone(eZone: EvaluationZoneResultModel) {
    this._evaluationZoneService.saveAOIDataToPrismEvaluationZone(JSON.stringify(eZone))
      .subscribe(
        Result => {
          if (Result.Status === 'SUCCESS') {
            eZone.PRISM_AOI_ID = Result.ID;
            this.getLEAOIData(eZone.EvaluationZoneCode, 'EvaluationZone');
            this.DisplaySuccessMessage('Success', 'New AOI ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  AddAOIIDCommercialMarketZone(cmZone: CommercialMarketZoneResultModel) {
    this._commercialMarketService.saveAOIDataToPrismCommercialMarket(JSON.stringify(cmZone))
      .subscribe(
        Result => {
          if (Result.Status === 'SUCCESS') {
            cmZone.PRISM_AOI_ID = Result.ID;
            this.getLEAOIData(cmZone.CommercialMarketZoneCode, 'CommercialMarketZone');
            this.DisplaySuccessMessage('Success', 'New AOI ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  AddAOIIDBreedingZone(bZone: BreedingZoneResultModel) {
    this._breedingZoneService.saveAOIDataToPrismBreedingZone(JSON.stringify(bZone))
      .subscribe(
        Result => {
          if (Result.Status === 'SUCCESS') {
            bZone.PRISM_AOI_ID = Result.ID;
            this.getLEAOIData(bZone.BreedingZoneCode, 'BreedingZone');
            this.DisplaySuccessMessage('Success', 'New AOI ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        (error: string) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  AddAOIIDRegion(region: RegionResultModel) {
    this._regionService.saveAOIDataToPrismRegion(JSON.stringify(region))
      .subscribe(
        Result => {
          if (Result.Status === 'SUCCESS') {
            region.PRISM_AOI_ID = Result.ID;
            this.getLEAOIData(region.RegionCode, 'Region');
            this.DisplaySuccessMessage('Success', 'New AOI ID generated successfully...');
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
        },
        error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
      );
  }

  SendLEData(leid: number) {
    this.LEAOIID = leid;
    this.lERequestedData = {
      LEAOIID: this.LEAOIID,
      ParentComponent: this.parentComponent,
      Code: this.code,
      OtherObject: this.otherObject,
      ProjectLE: this.projectLEResult,
      StationLE: this.stationLEResult,
      SSLLE: this.sSLLEResult,
      LocationProjectCode: this.locationProjectCodeResult,
      ExperimentProjectCode: this.experimentProjectCodeResult
    };

    this.addLEDataClick.emit(this.lERequestedData);
    this.code = '';
    this.projectLEResult = [];
    this.stationLEResult = [];
    this.sSLLEResult = [];
    this.station = new StationResultModel();
    this.otherObject = '';
    this.locationProjectCodeResult = [];
    this.experimentProjectCodeResult = [];

    this.projectLEResult = this.projectLEResult.slice();
    this.stationLEResult = this.stationLEResult.slice();
    this.sSLLEResult = this.sSLLEResult.slice();
    this.locationProjectCodeResult = this.locationProjectCodeResult.slice();
    this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();

    this.lERequestedData = new LERequestedData();
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}