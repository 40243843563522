export class LookupType { 
    ID: string;
    Id: string;
    Name: string;
    Description: string;
    Code: string;
    IsActive: boolean;
    SortOrder: number;
    label: string;
    value: any;
}
