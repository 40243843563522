import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CommercialMarketZoneResultModel } from '../shared/commercialMarketZoneResultModel';
import { EnvironmentService, UrlConfig } from '../../environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CommercialMarketService {
  protected urlConfig: UrlConfig;
  protected http: HttpService;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
    this.http = _http;
  }

  bindCommercialMarketDropdownLists(): Observable<any> {
    return this.http.get(this.urlConfig.commercialMarketDropDownLists)
  }

  getCommercialMarketResult(): Observable<CommercialMarketZoneResultModel[]> {
    return this.http.get(this.urlConfig.commercialMarketZones).pipe(
      map((dataArray: CommercialMarketZoneResultModel[]) => {
        return dataArray.map(data => {
          return {
            ...data,
            RegionCode: data.Region?.Code
          };
        });
      })
    );
  }
    
  saveCommercialMarket(commercialMarket: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveCommercialMarket, commercialMarket, httpOptions)
  }

  saveCommercialMarketComment(commercialMarketComment: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveCommercialMarketComment, commercialMarketComment, httpOptions)
  }

  exportCSVForCommercialMarketZones(commercialMarketZones: string) {
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(this.urlConfig.exportCSVForCommercialMarketZones, commercialMarketZones, { headers: httpHeaders, responseType: 'arraybuffer' })
  }

  saveAOIDataToPrismCommercialMarket(commercialMarketAOIData: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.urlConfig.saveAOIDataToPrismCommercialMarket, commercialMarketAOIData, httpOptions)
  }
}