<form id="frmCreateRegion" #frm="ngForm" [formGroup]="createRegionFormGroup">
    <div class="grid">
      <div class="ui-g-12 col-12">
        <p-panel id="panelRegion">
          <p-header id="pnlRegionHeader">
            <span>
              <b>RCZ Details</b>
            </span>
          </p-header>
          <div class="grid">
            <div class="col-6">
              <div class="grid">
                <div class="col-3 controlLabel">
                    <label id="lblRegionName">RCZ Name</label>
                </div>
                <div class="col-9">
                    <input id="txtRegionName" name="regionName" pInputText autofocus [(ngModel)]="regionData.RegionName" required formControlName="RegionName" minlength="1"
                    maxlength="100" />
                </div>
                <div class="col-3 controlLabel">
                    <label id="lblRegionCode">RCZ Code </label>
                </div>
                <div class="col-9">
                    <input id="txtRegioncode" name="regionCode" pInputText [(ngModel)]="regionData.RegionCode" required formControlName="RegionCode" minlength="2"
                    maxlength="4" />
                </div>
                <div class="col-3 controlLabel">
                    <label id="lblCReagionCrop">Crop</label>
                </div>
                <div class="col-9">
                    <p-dropdown id="ddCRegionCrop" [style]="{'width':'100%'}" name="Crop" [filter]=true [options]="regionCropName" [(ngModel)]="regionData.CropID"
                    formControlName="CropID" placeholder="Select a Crop" [showClear]="true"></p-dropdown>
                </div>
                <div class="col-3 controlLabel">
                    <label id="lblCregionDescription">Description</label>
                </div>
                <div class="col-9">
                    <textarea id="txtCRegionDescription" [cols]="30" [rows]="4" maxlength="500" name="description" [(ngModel)]="regionData.Description" formControlName="Description"
                    pInputTextarea class="p-inputtext"></textarea>
                </div>
                <div class="col-3 controlLabel">
                    <label id="lblColor">Color</label>
                </div>
                <div class="col-9">
                    <input id="txtCRegionColor" type="number" name="color" pInputText [(ngModel)]="regionData.Color" formControlName="Color" maxlength="9" styleClass="width100"
                    />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="grid">
                <div class="col-3 controlLabel">
                    <label id="lblRegionLead">RCZ Lead</label>
                </div>
                <div class="col-9">
                  <div class="grid">
                    <div class="col-11">
                      <p-listbox id="lstCRegionLead" [options]="regionLead" [(ngModel)]="regionData.RegionLeadNames" formControlName="RegionLeadNames" styleClass="width100"
                  [style]="{'min-height':'35px'}" [listStyle]="{'max-height':'100px'}">
                  </p-listbox>
                  </div>
                  <div class="col-1">
                      <button id="btnCRegionAddLeads" type="button" pButton icon= "fa fa-user-plus" class="p-button-info" style="width: 30px; float: right; margin-right: 8px;" title="Add RCZ Leads" (click)="ShowPeoplePicker()"></button>
                  </div>
                    </div>
                </div>
                <div class="col-3 controlLabel">
                  <br />
                  <label id="lblComment">Comment</label>
                </div>
                <div class="col-9">
                  <span>Please enter comments or notes regarding changes.</span>
                  <textarea id="txtCRegionComment" [cols]="30" [rows]="7" name="Comment" pInputTextarea class="p-inputtext" [(ngModel)]="comments" formControlName="Comment"></textarea>
                </div>
              </div>
            </div>
          
          </div>
          <p-footer>
            <div class="grid">
                <div class="col-8">
                </div>
                <div class="col-2 d-grid">
                  <button id="btnCRegionReset" type="button" class="p-button-warning alignRight" pButton icon= "fa fa-close" (click)="reset()" label="Reset"></button>
          </div>
              <div class="col-2 d-grid">
                  <button id="btnCRegionSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save" (click)="saveRegion(frm)" [disabled]="!frm.form.valid"
                  label="Submit"></button>
              </div>
            </div>
          </p-footer>
        </p-panel>
      </div>
    </div>
  </form>
  <br/>
  
  <p-dialog id="dlgCRegionLead" header="Select RCZ Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayRegionLead" showEffect="fade"
  [modal]="true">
  <app-peoplepicker id="appCRegionPeoplePicker" [existingPeopleString]="existingPeopleString" 
    (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
</p-dialog>