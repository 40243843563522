import { Person } from './person';
export class ApprovalComments {
    ApprovalCommentID: string;
    RequestTypeID: string ;
    CommentType: string;
    Comment: string;
    CommenterID: string;
    Commenter: Person;
    CommentDate: Date;
    IsActive: boolean;
}
