<div>
  <p-tabView (onChange)="onTabChange($event)" [activeIndex]="index">
    <p-tabPanel id="tabCProgram" header="Program" leftIcon="fa fa-database">
      <app-createprogram [selectedTabIndex]="selectedTabIndex">
      </app-createprogram>
    </p-tabPanel>
    <p-tabPanel id="tabCStation" header="R&D Center" leftIcon="fa fa-building">
      <app-createstation [selectedTabIndex]="selectedTabIndex">
      </app-createstation>
    </p-tabPanel>
    <p-tabPanel id="tabCSSL" header="Seed Storage Location" leftIcon="fa fa-location-arrow">
      <app-createseedstoragelocation [selectedTabIndex]="selectedTabIndex">
      </app-createseedstoragelocation>
    </p-tabPanel>
    <p-tabPanel id="tabCEZ" header="Evaluation Zone" leftIcon="fa fa-crop">
      <app-createevaluationzone [selectedTabIndex]="selectedTabIndex">
      </app-createevaluationzone>
    </p-tabPanel>
    <p-tabPanel id="tabCRegion" header="Research Commercial Zone" leftIcon="fa fa-globe">
      <app-createregion [selectedTabIndex]="selectedTabIndex">
      </app-createregion>
    </p-tabPanel>
    <p-tabPanel id="tabCBZ" header="Breeding Zone" leftIcon="fa fa-road">
      <app-createbreedingzone [selectedTabIndex]="selectedTabIndex">
      </app-createbreedingzone>
    </p-tabPanel>
    <p-tabPanel id="tabCPFS" header="CP Field Scientist" leftIcon="fa fa-flask">
      <app-createcpFieldscientist [selectedTabIndex]="selectedTabIndex">
      </app-createcpFieldscientist>
    </p-tabPanel>
    <p-tabPanel id="tabCCMZ" header="Commercial Market Zone" leftIcon="fa fa-telegram">
      <app-create-commercial-market-zone [selectedTabIndex]="selectedTabIndex">
      </app-create-commercial-market-zone>
    </p-tabPanel>
  </p-tabView>
</div>
<div *ngIf="!hideHomeTab" style="text-align:center">
  <br>
  Please select a tab to
  <b>Create Research Area Data</b>.
</div>

