import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation, SimpleChanges } from "@angular/core";
import { LookupType } from "../lookupType";
import { CommonService } from "../common.service";
import { Constants } from "../constants";
import { UserClaim } from "../userClaim";
import {SharedService} from '../../services/shared.service';

@Component({
  selector: 'manage-lookup',
  templateUrl: './manage-lookup.component.html',
  styleUrls: ['./manage-lookup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ManageLookupComponent implements OnChanges {
  clonedLookupTypes: { [s: string]: LookupType } = {};
  lookupTypes: LookupType[];
  rowIndex: number = 0;

  public isROVAdmin = false;
  public isROVUser = true;
  public codeMaxLength: number;

  @Input()
  public lkpType: string;

  @Output()
  public getLookUpEvent: EventEmitter<any> = new EventEmitter();

  constructor(private _commonService: CommonService, private sharedservice: SharedService) {
    this.getLoginInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getLookupTypes(this.lkpType);
    this.codeMaxLength = this.lkpType == "ResponsibleSubFunction" ? 8 : 4;
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  public getLookupTypes(lkpType: string) {
    if (lkpType) {
      this._commonService.getLookupTypes(lkpType, false)
        .subscribe({
          next: (response) => {
            this.lookupTypes = response;

            this.lookupTypes.forEach((element: any) => {
              element.value = element.Id;
              element.label = element.Name;
            });

            this.getLookUpEvent.emit();
          },
          error: (error) => {
          }
        });
    }
    else{
      this.lookupTypes = [];
    }
  }

  public saveLookupTypes() {

  }

  isAddNewVisible() {
    if (this.lkpType === 'ResponsibleSubFunction') {
      return this.isROVAdmin;
    }
    return true;
  }

  onRowEditInit(lookupType: LookupType) {
    this.clonedLookupTypes[lookupType.ID] = { ...lookupType };
  }

  onRowEditCancel(lookupType: LookupType, index: number) {
    this.lookupTypes[index] = this.clonedLookupTypes[lookupType.ID];
    delete this.clonedLookupTypes[lookupType.ID];
  }

  addLookupType() {
    const newLookup: LookupType = {
      Code: '',
      Name: '',
      Description: '',
      IsActive: false,
      ID: "",
      Id: "",
      label: "",
      value: undefined,
      SortOrder: 0
    };
    this.lookupTypes.push(newLookup);
    let lookupCount = this.lookupTypes.length;
    setTimeout(function () {
      const newRow = document.querySelector(
        `#dtLookupTypes table > tbody > tr:nth-child(${lookupCount})`
      );
      (newRow as HTMLElement).style.background = 'lightgrey';

      const firstCell = document.querySelector(
        `#dtLookupTypes table > tbody > tr:nth-child(${lookupCount}) > td:nth-child(1)`
      );
      (firstCell as HTMLElement).click();
    }, 100);
  }

  onSaveClick() {
    this._commonService.saveLookupTypes(this.lookupTypes, this.lkpType).subscribe({
      next: (response: any) => {
        this.getLookupTypes(this.lkpType);
        this._commonService.DisplaySuccessMessage('Success', Constants.LOOKUPITEMSSAVED);
      }, error: (error: any) => {
        this._commonService.DisplayErrorMessage('Error', error);
      }
    });
  }

  onResetClick() {
    this.getLookupTypes(this.lkpType);
  }
}