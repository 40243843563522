<p-panel id="createID">
  <div class="grid">
    <div class="col-3 col-offset-9">
      <button type="button" [disabled]="disableCreateButton" pButton icon="fa fa-plus-circle"
        class="p-button-info floatRight buttonWidth" (click)="AddLEAOIID()" label="{{addBtnName}}"
        title="{{addBtnName}}"></button>
    </div>
  </div>
</p-panel>
<p-panel id="panelLEIDPicker" [style]="{'height': '430px', 'overflow': 'auto', 'width':'1200px'}">
  <div class="grid">
    <div [hidden]="isAOI">
      <div class="col-12">
        <p-button label="Project LE" styleClass="p-button-outlined p-button-secondary" badge="{{projectLEResult.length}}" badgeClass="p-badge-secondary"></p-button>
      </div>
      <div class="col-12">
        <p-table id="dtProjectLE" [columns]="projectCols" class="gridHeight" [value]="projectLEResult" sortMode="single"
          selectionMode="single" [resizableColumns]="false" [scrollable]="true" scrollHeight="400px" [loading]="loadingProject" loadingIcon="fa fa-spinner">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
                [style.width]="col.width">
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
              <td>
                <button type="button" pButton icon="fa fa-hand-o-down" class="p-button-success" iconPos="left"
                  title="Select" (click)="SendLEData(rowData['LEId'])"
                  [disabled]="(parentComponent === 'R&D Center' && rowData.ActualType && rowData.ActualType.toString().toUpperCase() === 'PORG') || (parentComponent === 'Program' && rowData.LEType && rowData.LEType.toString().toUpperCase() === 'RSTA')"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12">
        <p-button label="R&D Center LE" styleClass="p-button-outlined p-button-secondary" badge="{{stationLEResult.length}}" badgeClass="p-badge-secondary"></p-button>
      </div>
      <div class="col-12">
        <p-table id="dtStationLE" [columns]="stationCols" class="gridHeight" [value]="stationLEResult" sortMode="single"
          selectionMode="single" [resizableColumns]="false" [scrollable]="true" scrollHeight="400px"
          [loading]="loadingStation" loadingIcon="fa fa-spinner">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
                [style.width]="col.width">
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
              <td>
                <button type="button" pButton icon="fa fa-hand-o-down" class="ui-button-success" iconPos="left" title="Select" (click)="SendLEData(rowData['LEId'])"
                [disabled]="(parentComponent === 'R&D Center' && rowData.ActualType && rowData.ActualType.toString().toUpperCase() === 'PORG') || (parentComponent === 'Program' && rowData.LEType && rowData.LEType.toString().toUpperCase() === 'RSTA')"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="col-12">
      <p-button label="Trial" styleClass="p-button-outlined p-button-secondary" badge="{{sSLLEResult.length}}" badgeClass="p-badge-secondary"></p-button>
    </div>
    <div class="col-12">
      <p-table id="dtAOI" [columns]="ssLCols" class="gridHeight" [value]="sSLLEResult" sortMode="single"
        selectionMode="single" [resizableColumns]="false" [scrollable]="true" scrollHeight="400px"
        [loading]="loadingSSL" loadingIcon="fa fa-spinner">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
              [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td [pSelectableRow]="rowData" *ngFor="let col of columns">
              {{rowData[col.field]}}
            </td>
            <td>
              <button type="button" pButton icon="fa fa-hand-o-down" class="p-button-success" iconPos="left"
                title="Select" (click)="SendLEData(rowData['AOIId'])"
                [disabled]="(parentComponent === 'R&D Center' && rowData.ActualType && rowData.ActualType.toString().toUpperCase() === 'PORG') || (parentComponent === 'Program' && rowData.LEType && rowData.LEType.toString().toUpperCase() === 'RSTA')"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="grid" [hidden]="isAOI">
      <div class="col-12">
        <p-button label="Location with Project Code" styleClass="p-button-outlined p-button-secondary" badge="{{locationProjectCodeResult.length}}" badgeClass="p-badge-secondary"></p-button>
      </div>
      <div class="col-12">
        <p-table id="dtLocationwithProjectCode" [columns]="locationCols" class="gridHeight"
          [value]="locationProjectCodeResult" sortMode="single" selectionMode="single" [resizableColumns]="false"
          [scrollable]="true" scrollHeight="250px" [loading]="loadingLocationProject" loadingIcon="fa fa-spinner">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn>
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-12">
        <p-button label="Experiment with Project Code" styleClass="p-button-outlined p-button-secondary" badge="{{experimentProjectCodeResult.length}}" badgeClass="p-badge-secondary"></p-button>
      </div>
      <div class="col-12">
        <p-table id="dtExperimentwithProjectCode" [columns]="experimentCols" class="gridHeight"
          [value]="experimentProjectCodeResult" sortMode="single" selectionMode="single" [resizableColumns]="false"
          [scrollable]="true" scrollHeight="250px" [loading]="loadingExperimentProject" loadingIcon="fa fa-spinner">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
                [style.width]="col.width">
                {{col.header}}
                <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td [pSelectableRow]="rowData" *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-panel>