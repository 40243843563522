import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CPFieldScientistService } from "./cpFieldScientist.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { AppService } from "../app.service";
import { CommonService } from "../shared/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApprovalComments, CPFieldScientistModel, GAAData, Constants, GeoPoliticalUnitModel, LookupType, RequestStatus } from "../shared";
import { UserClaim} from "../shared/userClaim";
import { CPFieldScientistForm } from "../shared/cpFieldScientistFormControl";
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-cpFieldscientist',
  templateUrl: './cpFieldscientist.component.html',
  styleUrls: ['./cpFieldscientist.component.scss']
})
export class CPFieldScientistComponent implements OnInit, OnChanges {
  public _approvalComments: ApprovalComments = new ApprovalComments();
  loading: boolean;
  cols: any[];
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;
  statuses: LookupType[];
  cpSubfunctions: LookupType[];
  cpRegions: LookupType[];
  public cpFieldScientistAllData: CPFieldScientistModel[] = [];
  public filteredData: CPFieldScientistModel[] = [];
  public approvalCommentData: any[] = [];
  status: SelectItem[] = [];
  expandedItems: any[] = [];
  public distinctCodes: SelectItem[];
  public distinctCountrys: SelectItem[];
  public CPSubFunction: string = Constants.LOOKUPTYPECPSUBFUNCTION;
  public CPRegion: string = Constants.LOOKUPTYPECPREGION;
  public sortedCodes: SelectItem[];
  public distinctStatus: SelectItem[];
  public displayCPSubfunctionDialog: boolean;
  public displayCPRegionDialog: boolean;
  public sortedStatus: SelectItem[];
  public sortedCountrys: SelectItem[];
  public disableCommentButton = false;
  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  dialogVisible: boolean;
  selectedStatus: string;
  public cpFieldScientistFormGroup: FormGroup;
  public cpFieldScientistData: CPFieldScientistModel = new CPFieldScientistModel();
  public userName: string;
  public CPFieldScientistID: string;
  displayComments: boolean;
  sourceIdentifier: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public countries: GeoPoliticalUnitModel[] = [];
  public gaaData: GAAData = new GAAData();

  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  public cpFieldScientistForm = new CPFieldScientistForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    [],
    '',
    '',
    ''
  );

  constructor(private route: ActivatedRoute, private _appService: AppService,
    private _cpFieldScientistService: CPFieldScientistService, private _commonService: CommonService,
    private _confirmation: ConfirmationService, private _fb: FormBuilder, private sharedservice: SharedService) {
  }

  ngOnInit() {
    this.GetAllCountriesFomPRISM();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 6) {
      this.getLoginInfo();
      this.buildForm();

      if( (this.cleanCache || (this.cpFieldScientistAllData == null || this.cpFieldScientistAllData.length === 0))
          && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 6)) {
        this.route.queryParams.subscribe(p => {
          const cpFieldScientistID: string = p['CPFieldScientistID'];
          this.selectedRequestID = cpFieldScientistID ?? '';
        })
      
        this.cols = [
          { field: 'Id', header: '', hidden: 'true' },
          { field: 'FirstName', header: 'First Name' },
          { field: 'LastName', header: 'Last Name' },
          { field: 'Country', header: 'Country' },
          { field: 'Code', header: 'Code' },
          { field: 'StatusName', header: 'Status' },
        ];

        this.getCPFieldScientists();
        this.getLookupTypes();
        this.cleanUpCache.emit(false);
      }
    }
  }

  public getCPSubfunctionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPSUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.cpSubfunctions = response;

          this.cpSubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }

  public getCPRegionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPREGION, true)
      .subscribe({
        next: (response) => {
          this.cpRegions = response;

          this.cpRegions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.cpFieldScientistFormGroup = this._fb.group({
        'FirstName': [this.cpFieldScientistForm.FirstName, Validators.required],
        'LastName': [this.cpFieldScientistForm.LastName, Validators.required],
        'City': [this.cpFieldScientistForm.City],
        'State': [this.cpFieldScientistForm.State],
        'Country': [this.cpFieldScientistForm.Country],
        'County': [this.cpFieldScientistForm.County],
        'CPRegionId': [this.cpFieldScientistForm.CPRegionId],
        'Code': [this.cpFieldScientistForm.Code, Validators.required],
        'Lat': [this.cpFieldScientistForm.Lat],
        'Long': [this.cpFieldScientistForm.Long],
        'StatusId': [this.cpFieldScientistForm.StatusId],
        'StatusName': [this.cpFieldScientistForm.StatusName],
        'CPSubfunctionId': [this.cpFieldScientistForm.CPSubfunctionId],
        'CityAoiId': [this.cpFieldScientistForm.CityAoiId],
        'StateAoiId': [this.cpFieldScientistForm.StateAoiId],
        'CountryAoiId': [this.cpFieldScientistForm.CountryAoiId],
        'CountyAoiId': [this.cpFieldScientistForm.CountyAoiId],
        'GeographicLocale': [this.cpFieldScientistForm.GeographicLocale],
        'GAAResponseJson': [this.cpFieldScientistForm.GAAResponseJson]

      });
    } else {
      this.cpFieldScientistFormGroup = this._fb.group({
        'FirstName': [this.cpFieldScientistForm.FirstName, Validators.required],
        'LastName': [this.cpFieldScientistForm.LastName, Validators.required],
        'City': [this.cpFieldScientistForm.City],
        'State': [this.cpFieldScientistForm.State],
        'Country': [this.cpFieldScientistForm.Country],
        'County': [this.cpFieldScientistForm.County],
        'CPRegionId': [this.cpFieldScientistForm.CPRegionId],
        'Code': [this.cpFieldScientistForm.Code, Validators.required],
        'Lat': [this.cpFieldScientistForm.Lat],
        'Long': [this.cpFieldScientistForm.Long],
        'StatusId': [this.cpFieldScientistForm.StatusId],
        'StatusName': [this.cpFieldScientistForm.StatusName],
        'CPSubfunctionId': [this.cpFieldScientistForm.CPSubfunctionId],
        'CityAoiId': [this.cpFieldScientistForm.CityAoiId],
        'StateAoiId': [this.cpFieldScientistForm.StateAoiId],
        'CountryAoiId': [this.cpFieldScientistForm.CountryAoiId],
        'CountyAoiId': [this.cpFieldScientistForm.CountyAoiId],
        'GeographicLocale': [this.cpFieldScientistForm.GeographicLocale],
        'GAAResponseJson': [this.cpFieldScientistForm.GAAResponseJson]
      });
    }
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  getCPFieldScientists() {
    this.loading = true;

    this._cpFieldScientistService.getCPFieldScientists().subscribe({
      next: response => {
        this.cpFieldScientistAllData = this.filteredData = response;

        this.cpFieldScientistAllData.forEach(c => c.StatusName = c.Status?.Name ?? '');

        if (response != null && this.selectedRequestID !== '') {
          const selectedItem = response.find(element => element.Id.toString());
          this.first = 0;
          if (!selectedItem) {
            this.DisplayInfoMessage('Info', `The CP Field Scientist
            requested in the URL either doesn't exist or is no more in Pending State.`);
          }
          else {
            let index = response.indexOf(selectedItem);
            const pageNumber = Math.ceil(++index / 10)
            this.first = (pageNumber - 1) * 10;
            this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
            this.editCPFieldScientist(selectedItem);
          }
        }
        this.distinctCPFieldCountrysDropdown();
        this.distinctCPFieldCodesDropdown();
        this.distinctCPFieldStatusDropdown();
        this.loading = false;
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  public getLookupTypes() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPESTATUS, true)
      .subscribe({
        next: (response) => {
          this.statuses = response;

          this.statuses.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });

    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPSUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.cpSubfunctions = response;

          this.cpSubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });

    this._commonService.getLookupTypes(Constants.LOOKUPTYPECPREGION, true)
      .subscribe({
        next: (response) => {
          this.cpRegions = response;

          this.cpRegions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });

  }

  showCPSubfunctionDialog() {
    this.displayCPSubfunctionDialog = true;
  }


  showCPRegionDialog() {
    this.displayCPRegionDialog = true;
  }

  syncGAA() {
    this._cpFieldScientistService.syncGAA("CPFieldScientist")
      .subscribe({
        next: data => {
        },
        error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
      });
  }

  exportCSVForCPFieldScientists() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._cpFieldScientistService.exportCSVForCPFieldScientists(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel' });
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'CPFieldScientistData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
        error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
      });
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  GetAllCountriesFomPRISM() {
    this.countries = [];
    this._cpFieldScientistService.GetAllCountriesFomPRISM().subscribe({
      next: (res) => {
        this.countries = res;
        this.countries.forEach(element => {
          element.value = element.AoiId;
          element.label = element.Name;
        });
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  editCPFieldScientist(cpFieldScientist: CPFieldScientistModel) {
    this.cpFieldScientistForm = Object.assign({}, cpFieldScientist);
    this.dialogVisible = true;
  }

  statusChange(status: string) {
  }

  onCloseEditCPFieldScientist(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: { dirty: any; }) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
    }
  }

  viewComments(cpFieldScientist: CPFieldScientistModel) {
    this.approvalCommentData = [];

    if (cpFieldScientist.ApprovalComments != null) {
      cpFieldScientist.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;
    this.sourceIdentifier = cpFieldScientist.Id;
  }

  onSaveComment(data: any) {
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const CPFieldScientistID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = CPFieldScientistID;

    this._cpFieldScientistService.saveCPFieldScientistComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const cpFieldScientistIndex = this.cpFieldScientistAllData
              .findIndex(s => s.Id.toString() === CPFieldScientistID.toString());

            if (cpFieldScientistIndex !== -1) {
              this.cpFieldScientistAllData[cpFieldScientistIndex] = Result.cpFieldScientist;
              this.cpFieldScientistAllData[cpFieldScientistIndex].ApprovalComments = Result.cpFieldScientist.ApprovalComments.slice();
              this.cpFieldScientistAllData = this.cpFieldScientistAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.CPFieldScientistID = Result.cpFieldScientist.CPFieldScientistID);

              this.expandedItems[expandedIndex] = Result.cpFieldScientist;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.cpFieldScientist.ApprovalComments != null) {
                Result.cpFieldScientist.ApprovalComments.forEach((element: { CommentDate: Date; Commenter: any; Comment: any; }) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();

            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
      });
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  filterCPFieldScientistDataTable(event: { filteredValue?: CPFieldScientistModel[] }) {
    if(event?.filteredValue)
      this.filteredData = event.filteredValue;
  }

  saveCPFieldScientist(frm: any) {
    this.save();
  }

  save() {
    const temp = Object.assign({}, this.cpFieldScientistForm);
    this.cpFieldScientistData.Id = temp.Id;
    this.cpFieldScientistData.FirstName = temp.FirstName;
    this.cpFieldScientistData.LastName = temp.LastName;
    this.cpFieldScientistData.City = temp.City;
    this.cpFieldScientistData.State = temp.State;
    this.cpFieldScientistData.Country = temp.Country;
    this.cpFieldScientistData.CPRegionId = temp.CPRegionId;
    this.cpFieldScientistData.Code = temp.Code;
    this.cpFieldScientistData.StatusId = temp.StatusId;
    this.cpFieldScientistData.CPSubfunctionId = temp.CPSubfunctionId;
    this.cpFieldScientistData.CityAoiId = temp.CityAoiId;
    this.cpFieldScientistData.CountryAoiId = temp.CountryAoiId;
    this.cpFieldScientistData.StateAoiId = temp.StateAoiId;
    this.cpFieldScientistData.CountyAoiId = temp.CountyAoiId;
    this.cpFieldScientistData.GeographicLocale = temp.GeographicLocale;
    this.cpFieldScientistData.Lat = temp.Lat;
    this.cpFieldScientistData.Long = temp.Long;

    if (this.validateForm()) {
      this._cpFieldScientistService.saveCPFieldScientist(JSON.stringify(this.cpFieldScientistData)).subscribe({
        next: (response) => {
          if (response.Status === 'SUCCESS') {
            this.dialogVisible = false;
            this.getCPFieldScientists();
            this.buildForm();
            this.DisplaySuccessMessage('Success', response.Message);
          }
          else {
            this.DisplayErrorMessage('Error', response.Message);
          }
        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }

  validateForm() {
    if (!this.cpFieldScientistData.FirstName || this.cpFieldScientistData.FirstName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'First name is required...');
      return false;
    }

    if (!this.cpFieldScientistData.LastName || this.cpFieldScientistData.LastName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Last name is required...');
      return false;
    }

    if (!this.cpFieldScientistData.Code || this.cpFieldScientistData.Code.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Code is required...');
      return false;
    }

    if ((this.cpFieldScientistData.Code
      && (this.cpFieldScientistData.Code.trim().length < 2
        || this.cpFieldScientistData.Code.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Code should be 2 to 4 characters long...');
      return false;
    }

    return true;
  }


  distinctCPFieldCountrysDropdown() {
    const items = this.cpFieldScientistAllData;
  }

  distinctCPFieldCodesDropdown() {
    const items = this.cpFieldScientistAllData;

    this.distinctCodes = this._commonService.GetDistinctItems(items.map(i => i.Code));
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
    this.distinctCodes = this.sortedCodes.slice();
  }

  distinctCPFieldStatusDropdown() {
    const items = this.cpFieldScientistAllData;

    this.distinctStatus = [];
    this.distinctStatus = this._commonService.GetDistinctItems(items.filter(i => i.StatusName).map(i => i.StatusName));
    if (this.distinctStatus) {
      this.sortedStatus = this.distinctStatus.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
    this.distinctStatus = this.sortedStatus.slice();
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  public GetGAAData(event: any) {
    this.gaaData.Latitude = this.cpFieldScientistForm.Lat;
    this.gaaData.Longitude = this.cpFieldScientistForm.Long;

    this.cpFieldScientistData.Lat = this.cpFieldScientistForm.Lat;
    this.cpFieldScientistData.Long = this.cpFieldScientistForm.Long;

    if(this.gaaData.Longitude == undefined
      || this.gaaData.Longitude.toString().split(".").length < 2
      || this.gaaData.Longitude.toString().split(".")[1].length < 2){
      return;
    }

    if(this.gaaData.Latitude!=null){
      this._commonService.GetGAAData(JSON.stringify(this.gaaData)).subscribe({
        next: (response: GAAData) => {
          if(response != null){
            this.cpFieldScientistForm.GeographicLocale = response.GeographicLocale;
            this.cpFieldScientistForm.Country = response.Country;
            this.cpFieldScientistForm.State = response.State;
            this.cpFieldScientistForm.CountryAoiId = response.CountryAoiId;
            this.cpFieldScientistData.StateAoiId = response.StateAoiId;
            this.cpFieldScientistForm.City=response.City;
            this.cpFieldScientistForm.CityAoiId=response.CityAoiId;
          }
          else{
            this.cpFieldScientistForm.GeographicLocale = null;
            this.cpFieldScientistForm.Country = null;
            this.cpFieldScientistForm.State = null;
            this.cpFieldScientistForm.City = null;
            this.cpFieldScientistForm.CountryAoiId = null;
            this.cpFieldScientistForm.StateAoiId = null;
            this.cpFieldScientistForm.CityAoiId = null;
          }

        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }
}