import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { CommonService } from '../shared/common.service';
import { BreedingZoneService } from '../breedingzone/breedingzone.service';
import { CropModel } from '../shared';
import { RegionModel } from '../shared';
import { Person } from '../shared';
import { BreedingZoneResultModel } from '../shared/breedingZoneResultModel';
import { ApprovalComments } from '../shared';
import { UserRequestedData } from '../shared';
import { Message, SelectItem } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { BreedingZoneType } from '../shared/breedingZoneType';
import { BreedingZoneCombo } from '../shared';
import { BreedingZoneForm } from '../shared/breedingZoneFormControls';
import { Constants, RequestStatus } from '../shared';


@Component({
  selector: 'app-createbreedingzone',
  templateUrl: './createbreedingzone.component.html',
  styleUrls: ['./createbreedingzone.component.scss']
})

export class CreatebreedingzoneComponent implements OnInit, OnChanges {
  msgs: Message[] = [];
  public displayBreedingZoneLead = false;
  public bzLead: SelectItem[] = [];
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public breedingZoneCropName: CropModel[] = [];
  public regionName: RegionModel[] = [];
  public breedingZoneCodes: BreedingZoneCombo[] = [];
  public breedingZoneTypeName: BreedingZoneType[] = [];
  public breedingZoneData: BreedingZoneResultModel = new BreedingZoneResultModel();
  public displayBreedingZoneTypeDialog: boolean;
  public BreedingZoneType: string = Constants.LOOKUPTYPEBREEDINGZONETYPE;
  public parentComponent = 'CreateBreedingZone';
  public createBreedingZoneFormGroup: FormGroup;
  public comments = '';
  public breedingZoneForm = new BreedingZoneForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    0,
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );

  @Input()
  public selectedTabIndex: number;

  constructor(private _commonService: CommonService, private _breedingZoneService: BreedingZoneService,
    private _appService: AppService, private _fb: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.breedingZoneData.RequestStatus = RequestStatus.PENDING;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 5) {
      this.bindBreedingZoneDropdownLists();
      this.getBreedingZoneTypeLookUp();
    }
  }

  buildForm(): void {
    this.createBreedingZoneFormGroup = this._fb.group({
      'BreedingZoneName': [this.breedingZoneForm.BreedingZoneName, Validators.required],
      'BreedingZoneCode': [this.breedingZoneForm.BreedingZoneCode, Validators.required],
      'BreedingZoneLeadNames': [this.breedingZoneForm.BreedingZoneLeadNames],
      'Description': [this.breedingZoneForm.Description],
      'Color': [this.breedingZoneForm.Color],
      'CropID': [this.breedingZoneForm.CropID, Validators.required],
      'CropName': [this.breedingZoneForm.CropName],
      'CropDescription': [this.breedingZoneForm.CropDescription],
      'CRMUpper': [this.breedingZoneForm.CRMUpper],
      'CRMLower': [this.breedingZoneForm.CRMLower],
      'BreedingZoneTypeID': [this.breedingZoneForm.BreedingZoneTypeID],
      'BreedingZoneTypeCode': [this.breedingZoneForm.BreedingZoneTypeCode],
      'BreedingZoneTypeName': [this.breedingZoneForm.BreedingZoneTypeName],
      'RequestStatus': [{ value: this.breedingZoneForm.RequestStatus, disabled: true }],
      'PRISM_AOI_ID': [this.breedingZoneForm.PRISM_AOI_ID],
      'Comment': [this.breedingZoneForm.ApprovalComments]
    });
  }


  bindBreedingZoneDropdownLists() {
    this._breedingZoneService.bindBreedingZoneDropdownLists().subscribe({
      next: data => {
        if (data.BreedingZoneCodes && data.BreedingZoneCodes.length > 0) {
          this.breedingZoneCodes = [];

          data.BreedingZoneCodes.forEach((item: { Id: any; Code: string; Name: string; CropID: any; RequestStatus: any; }) => {
            this.breedingZoneCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              CropID: item.CropID,
              RequestStatus: item.RequestStatus,
              label: item.Code + ' - ' + item.Name,
              value: item.Id
            });
          });
          this.breedingZoneCodes.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }

        if (data.Crops && data.Crops.length > 0) {
          this.breedingZoneCropName = data.Crops;
          this.breedingZoneCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
          this.breedingZoneCropName.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }

        if (data.BreedingZoneTypes && data.BreedingZoneTypes.length > 0) {
          this.breedingZoneTypeName = data.BreedingZoneTypes;
          this.breedingZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
          this.breedingZoneTypeName.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  showBreedingZoneTypeDialog() {
    this.displayBreedingZoneTypeDialog = true;
  }

  public getBreedingZoneTypeLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPEBREEDINGZONETYPE, true)
      .subscribe({
        next: (response) => {
          this.breedingZoneTypeName = response;

          this.breedingZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }


  ShowPeoplePicker() {
    this.existingPeople = [];
    if (this.breedingZoneData.BreedingZoneLeads) {
      this.breedingZoneData.BreedingZoneLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayBreedingZoneLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayBreedingZoneLead = false;
      let breedingZoneLeadNames = '';

      this.breedingZoneData.BreedingZoneLeads = [];
      this.breedingZoneData.BreedingZoneLeadNames = '';
      this.existingPeople = [];
      this.bzLead = [];

      data.person.forEach(pr => {
        this.existingPeople.push(pr);

        if (!this.breedingZoneData.BreedingZoneLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.breedingZoneData.BreedingZoneLeads.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, BreedingZone: null, BreedingZoneID: this.breedingZoneData.BreedingZoneID
          });
          this.bzLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        breedingZoneLeadNames = breedingZoneLeadNames + pr.DisplayName + '; ';
      });

      this.breedingZoneData.BreedingZoneLeadNames = breedingZoneLeadNames;
      this.breedingZoneData.BreedingZoneLeadNames = this.breedingZoneData.BreedingZoneLeadNames.trim();

      if (this.breedingZoneData.BreedingZoneLeadNames.lastIndexOf(';')
        === this.breedingZoneData.BreedingZoneLeadNames.length - 1) {
        this.breedingZoneData.BreedingZoneLeadNames = this.breedingZoneData.BreedingZoneLeadNames
          .substring(0, this.breedingZoneData.BreedingZoneLeadNames.lastIndexOf(';'));
      }
    }
  }

  saveBreedingZone(frm: any) {
    const crop = this.breedingZoneCropName.find(s => s.value === this.breedingZoneData.CropID);
    if (crop) {
      this.breedingZoneData.CropDescription = crop.CropDescription;
      this.breedingZoneData.CropName = crop.CropName;
      this.breedingZoneData.CropID = crop.CropID;
    }

    const breedingZoneType = this.breedingZoneTypeName.find(s => s.value === this.breedingZoneData.BreedingZoneTypeID);
    if (breedingZoneType) {
      this.breedingZoneData.BreedingZoneTypeCode = breedingZoneType.Code;
      this.breedingZoneData.BreedingZoneTypeName = breedingZoneType.Name;
      this.breedingZoneData.BreedingZoneTypeID = breedingZoneType.Id;
    }

    if (this.validateForm(frm)) {
      if (this.comments
        && this.comments.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.comments.toString();
        this.breedingZoneData.ApprovalComments = [];
        this.breedingZoneData.ApprovalComments.push(comment);
      }

      this._breedingZoneService.saveBreedingZone(JSON.stringify(this.breedingZoneData))
        .subscribe({
          next: Result =>  {
            if(Result.Status == 'SUCCESS'){
            this.breedingZoneCodes.push({
              Id: '',
              Code: this.breedingZoneData.BreedingZoneCode,
              Name: this.breedingZoneData.BreedingZoneName,
              CropID: this.breedingZoneData.CropID,
              RequestStatus: this.breedingZoneData.RequestStatus,
              label: this.breedingZoneData.BreedingZoneCode + ' - ' + this.breedingZoneData.BreedingZoneName,
              value: ''
            });

            this._appService.sendPendingCount(1);

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset();
          }
          else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }
  validateForm(frm: NgForm) {
    if (!this.breedingZoneData.BreedingZoneName || this.breedingZoneData.BreedingZoneName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Name is required...');
      return false;
    }

    if ((!this.breedingZoneData.BreedingZoneCode
      || (this.breedingZoneData.BreedingZoneCode
        && this.breedingZoneData.BreedingZoneCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Code is required...');
      return false;
    }

    if ((this.breedingZoneData.BreedingZoneCode
      && (this.breedingZoneData.BreedingZoneCode.trim().length < 2
        || this.breedingZoneData.BreedingZoneCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Breeding Zone Code should be 2 to 4 characters long...');
      return false;
    }

    if (!this.breedingZoneData.CropName || this.breedingZoneData.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    const bzCode = this.breedingZoneCodes.find(s => s.Code.toString().toLowerCase()
      === this.breedingZoneData.BreedingZoneCode.toString().toLowerCase()
      && s.CropID === this.breedingZoneData.CropID && s.RequestStatus !== RequestStatus.RETIRED);

    if (bzCode) {
      this.DisplayWarningMessage('Warning', 'This Breeding Zone Code and Crop combo already exists. Please enter a new Code or Crop...');
      return false;
    }

    // if (!this.breedingZoneData.Description || this.breedingZoneData.Description.trim() === '') {
    //   this.DisplayWarningMessage('Warning', 'Description is required...');
    //   return false;
    // }

    if (this.breedingZoneData.Color
      && (this.breedingZoneData.Color.toString().indexOf('-') >= 0
        || this.breedingZoneData.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.breedingZoneData.Color
      && this.breedingZoneData.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 9 characters for Color...');
      return false;
    }

    if (this.breedingZoneData.CRMLower
      && (this.breedingZoneData.CRMLower.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Lower...');
      return false;
    }

    if (this.breedingZoneData.CRMLower
      && this.breedingZoneData.CRMLower === parseInt(this.breedingZoneData.CRMLower.toString(), 10)
      && (this.breedingZoneData.CRMLower < -255 || this.breedingZoneData.CRMLower > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Lower should be between -255 to 255 digits long...');
      return false;
    }

    if (this.breedingZoneData.CRMUpper
      && (this.breedingZoneData.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRMUpper...');
      return false;
    }

    if (this.breedingZoneData.CRMUpper
      && this.breedingZoneData.CRMUpper === parseInt(this.breedingZoneData.CRMUpper.toString(), 10)
      && (this.breedingZoneData.CRMUpper < -255 || this.breedingZoneData.CRMUpper > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be between -255 to 255 digits long...');
      return false;
    }

    if (this.breedingZoneData.CRMUpper
      && (this.breedingZoneData.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRMUpper...');
      return false;
    }

    if (this.breedingZoneData.CRMUpper && this.breedingZoneData.CRMLower
      && (this.breedingZoneData.CRMUpper === parseInt(this.breedingZoneData.CRMUpper.toString(), 10)
        && this.breedingZoneData.CRMLower === parseInt(this.breedingZoneData.CRMLower.toString(), 10)
        && parseInt(this.breedingZoneData.CRMUpper.toString(), 10) < parseInt(this.breedingZoneData.CRMLower.toString(), 10))) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be greater than CRM Lower...');
      return false;
    }

    if (!this.breedingZoneData.BreedingZoneLeadNames
      || this.breedingZoneData.BreedingZoneLeadNames.trim() === '') {
      this.DisplayWarningMessage('Warning', 'At least one Breeding Zone Lead is required...');
      return false;
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  clearCrop() {
    this.breedingZoneData.CropID = '';
  }

  clearBZT() {
    this.breedingZoneData.BreedingZoneTypeID = '';
  }

  reset() {
    this.breedingZoneData = new BreedingZoneResultModel();
    this.breedingZoneData.RequestStatus = RequestStatus.PENDING;
    this.bzLead = [];
    this.existingPeople = [];
    this.comments = '';
  }
}
