<form id="frmCreateBZ" #frm="ngForm" [formGroup]="createBreedingZoneFormGroup">
  <p-panel id="panelCBZ">
    <p-header id="pnlCBZHeader">
      <span>
        <b>Breeding Zone Details</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label id="lblBreedingZoneName">Breeding Zone Name</label>
          </div>
          <div class="col-9">
            <input id="txtCBZName" name="breedingZoneName" pInputText autofocus
              [(ngModel)]="breedingZoneData.BreedingZoneName" required formControlName="BreedingZoneName"
              minlength="1" maxlength="100" />
          </div>
          <div class="col-3 controlLabel">
            <label id="lblBreedingZoneCode">Breeding Zone Code </label>
          </div>
          <div class="col-9">
            <input id="txtCBZCode" name="breedingZoneCode" pInputText
              [(ngModel)]="breedingZoneData.BreedingZoneCode" required formControlName="BreedingZoneCode"
              minlength="2" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <label id="lblCrop">Crop</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCBZCrop" [style]="{'width':'100%'}" name="Crop" [filter]="true"
              [options]="breedingZoneCropName" [(ngModel)]="breedingZoneData.CropID" formControlName="CropID"
              placeholder="Select a Crop" [showClear]="true"></p-dropdown>
          </div>
          <!-- <div class="col-1">
            <button id="btnCBZClearCrop" (click)="clearCrop()" pButton icon="fa fa-close" title="Clear Selection"
              class="buttonStyle" style="border:none;background-color: white; color: black;"></button>
          </div> -->
          <div class="col-3 controlLabel">
            <label id="lblDescription">Description</label>
          </div>
          <div class="col-9">
            <textarea id="txtCBZDescription" [cols]="30" [rows]="4" maxlength="500" name="description"
              [(ngModel)]="breedingZoneData.Description" formControlName="Description" pInputTextarea
              class="p-inputtext"></textarea>
          </div>
          <div class="col-3 controlLabel">
            <label id="lblColor">Color</label>
          </div>
          <div class="col-9">
            <input id="txtCBZColor" type="number" name="color" pInputText [(ngModel)]="breedingZoneData.Color"
              formControlName="Color" maxlength="9" />
          </div>
          <div class="col-3 controlLabel">
            <label id="lblCRMUpper ">CRM Upper</label>
          </div>
          <div class="col-9">
            <input id="txtCBZCRMUpper" type="number" pInputText [(ngModel)]="breedingZoneData.CRMUpper"
              name="CRMUpper" formControlName="CRMUpper" minlength="0" maxlength="4" />
          </div>
          <div class="col-3 controlLabel">
            <label id="lblCRMLower ">CRM Lower</label>
          </div>
          <div class="col-9">
            <input id="txtCBZCRMLower" type="number" pInputText [(ngModel)]="breedingZoneData.CRMLower"
              name="CRMLower" formControlName="CRMLower" minlength="0" maxlength="4" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="col-3 controlLabel">
            <label id="lblBreedingZoneLead">Breeding Zone Lead</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCBZLead" [options]="bzLead" [(ngModel)]="breedingZoneData.BreedingZoneLeadNames"
                  formControlName="BreedingZoneLeadNames" [style]="{'min-height':'35px','width':'100%'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnAddCBZLead" type="button" pButton icon= "fa fa-user-plus" class="p-button-info" style="width: 30px; float: right; margin-right: 8px;" 
                title="Add Breeding Zone Leads" (click)="ShowPeoplePicker()"></button>
              </div>
            </div>
          </div>
          <div class="col-3 controlLabel">
            <label id="lblBreedingZoneTypeName">Breeding Zone Type</label>
          </div>
          <div class="col-9"> 
            <div class="grid">
              <div class="col-11">
            <p-dropdown id="ddCBZType" [style]="{'width':'100%'}" name="breedingZoneType" [filter]="true"
              [options]="breedingZoneTypeName" [(ngModel)]="breedingZoneData.BreedingZoneTypeID"
              formControlName="BreedingZoneTypeID" placeholder="Select a Breeding Zone Type" [showClear]="true"></p-dropdown>
          </div>  
          <div class="col-1">
            <button id="btnBreedingZoneTypeDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
              style="width: 30px; float: right; margin-right: 8px;" (click)="showBreedingZoneTypeDialog()"
              title="Add/Edit BreedingZoneType"></button>
          </div>
        </div> 
          </div>

          <!-- <div class="col-1">
            <button id="btnClearCBZT" (click)="clearBZT()" pButton icon="fa fa-close" id="btnBZT"
              title="Clear Selection" class="buttonStyle"
              style="border:none;background-color: white; color: black;"></button>
          </div> -->
          <div class="col-3 controlLabel">
            <br />
            <label id="lblComment">Comment</label>
          </div>
          <div class="col-9">
            <span>Please enter comments or notes regarding changes.</span>
            <textarea id="txtCBZComment" [cols]="30" [rows]="7" name="Comment" pInputTextarea
              [(ngModel)]="comments" formControlName="Comment" class="p-inputtext"></textarea>
          </div>
        </div>
      </div>
    </div>
    
    <p-footer>
      <div class="grid">
        <div class="col-8">
        </div>
        <div class="col-2">
          <button id="btnCBZReset" type="button" class="p-button-warning alignRight" pButton icon="fa fa-close"
            (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2">
          <button id="btnCBZSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save"
            (click)="saveBreedingZone(frm)" [disabled]="!frm.form.valid" label="Submit"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>
<br />

<p-dialog  header="Select Breeding Zone Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayBreedingZoneLead" showEffect="fade" [modal]="true">
  <app-peoplepicker id="appCBZPeoplePicker" [existingPeopleString]="existingPeopleString"
    (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
</p-dialog> 

<p-dialog id="dlgEditBreedingZone" header="Edit Breeding Zone Type" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayBreedingZoneTypeDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="BreedingZoneType" (getLookUpEvent)="getBreedingZoneTypeLookUp()"></manage-lookup>
</p-dialog>
