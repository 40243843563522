import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";

@Component({
  selector: 'view-comments',
  templateUrl: './view-comments.component.html'
})

export class ViewCommentsComponent implements OnInit {
  @Input() disableCommentButton: boolean;
  @Input() approvalCommentData: any;
  @Input() sourceIdentifier: string;

  @Output() saveCommentEvent: EventEmitter<any> = new EventEmitter<any>();
  
  commentsColumns: any;

  constructor(private _messagingService: MessageService){}
  
  ngOnInit(): void {
    this.commentsColumns = [
      { field: 'Comment', header: 'Comments' },
      { field: 'Commenter', header: 'Commented By', width: '200px' },
      { field: 'CommentDateString', header: 'Commented On', width: '150px' },
    ];
  }
  
  saveComment() {
    const comment = (document.querySelector('#txtApprovalComment') as HTMLTextAreaElement).value;

    if (!comment || comment.toString().trim() === '') {
      this._messagingService.add({severity: "error", detail: "Please enter the Comment first..."});
      (document.querySelector('#txtApprovalComment') as HTMLTextAreaElement).focus();
      return;
    }

    this.saveCommentEvent.emit({ comment: comment, sourceIdentifier: this.sourceIdentifier});
    (document.querySelector('#txtApprovalComment') as HTMLTextAreaElement).value = '';
  }
}