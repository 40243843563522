export class Constants {
    public static readonly localServicePath = 'http://localhost:62422/';
    public static readonly devServicePath = 'https://manager-ui-dev.se.research.corteva.com/api/';
    public static readonly qaServicePath = 'https://manager-ui-qa.se.research.corteva.com/api/';
    public static readonly trServicePath = 'https://manager-ui-stage.se.research.corteva.com/api/';
    public static readonly prodServicePath = 'https://manager-ui-prod.se.research.corteva.com/api/';
    public static readonly NOSERVICE = 'Unable to communicate to RAD Service or service might not be running...';
    public static readonly PENDINGCHANGES = 'You will lose these pending changes. Are you sure you want to do this?';
    public static readonly CONFIRMDECLINE = 'This request will be declined. Are you sure you want to do this?';
    public static readonly CONFIRMDELETE = 'Are you sure you want to delete selected comment(s)?';
    public static readonly CONFIRMDELETERULES = `Existing rule(s) will be deleted permanently and any unsaved
                                                  records will also be lost. Are you sure you want to do it?`;
    public static readonly CONFIRMSAVECATEGORIES = 'Are you sure you want to save these Category changes?';
    public static readonly CONFIRMSAVESCHEDULER = 'Are you sure you want to save these Scheduler changes?';
    public static readonly SCROLLERROR = 'Could not set scroll position. Error: ';
    public static readonly NOTHINGTOEXPORT = 'Records not available to export...';
    public static readonly CONFIRMDELETEALLOCATIONEZPROGRAM = 'Are you sure you want to delete this?';
    public static readonly CONFIRMDELETECALENDARCATEGORIES = 'Are you sure you want to delete this Calendar Category?';
    public static readonly CONFIRMDELETEDEMANDCALENDAR = 'Are you sure you want to delete this Research Calendar?';
    public static readonly USERALREADYEXISTS = 'User already exists in Research Area Database.';
    public static readonly MULTIPLEUSERS = 'Multiple Users found in PRISM with different LE Ids, please contact PRISM Support.';
    public static readonly CONFIRMRADUSER = 'Are you sure you want to add this user to Research Area Database?';
    public static readonly NODATA = 'Records not available to display...';  
    public static readonly CRAINFODATAEXISTS = 'The yearly records associated with Resource File are already replicated.'; 
    public static readonly CRAINFODATAINSERTED = 'The yearly records associated with Resource File are successfully replicated.'; 
    public static readonly LOOKUPITEMSSAVED = 'Lookup items saved successfully';

    public static readonly LOOKUPTYPETECHNOLOGYTIERTYPE = 'TechnologyTierType';
    public static readonly LOOKUPTYPESTATUS = 'Status';
    public static readonly LOOKUPTYPECPSUBFUNCTION = 'CPSubfunction';  
    public static readonly LOOKUPTYPECPREGION = 'CPRegion'; 
    public static readonly LOOKUPTYPERESPONSIBLESUBFUNCTION = 'PrimaryResponsibleSubFunction'; 
    public static readonly LOOKUPTYPEBREEDINGZONETYPE = 'BreedingZoneType';
    public static readonly LOOKUPTYPEEVALUATIONZONETYPE = 'EvaluationZoneType'; 
    public static readonly LOOKUPTYPESEEDSTORAGELOCATIONTYPE = 'SeedStorageLocationType'; 
    public static readonly LOOKUPTYPERANDDCENTERTYPE = 'StationType'; 
    public static readonly LOOKUPTYPEPROGRAMTYPE = 'ProgramType';

    public static readonly CGU = 'CGU';
  
    public static get UNAUTH_Header(): string {
        return "Authorization Failed";
    }
    
    public static get Name(): string {
        return "User Name :"
    }

    public static get ProfileId(): string {
        return ", ProfileId :"
    }

    public static get Audiance(): string {
        return " , Audiance :"
    }

    public static get Jobs_Not_Created(): string {
        return "Jobs not created - no SIIDs found with a status of InQueue"
    }

    public static get Issuer(): string {
        return ", Issuer :"
    }

    public static get At(): string {
        return " , At :"
    }

    public static get TenantId(): string {
        return "  , TenantId :"
    }

    public static get Environment(): string {
        return "  , Environment :"
    }

    public static get LogIn_Msg(): string {
        return "User Logged In :"
    }

    public static get Nav_Home(): string {
        return "/home";
    }

    public static get Nav_Audit_Results(): string {
        return "/audit-results";
    }

    public static get USERPROPERTYFILTER(): string {
        return "$select=id,givenName,surname,displayName,mail,jobTitle,businessPhones,mobilePhone,displayName,streetAddress,city,postalCode,state,country,department,officeLocation,onPremisesSamAccountName,companyName"
    }

    public static get Nav_unauthorized(): string {
        return "/unauthorized";
    }

    public static get Home(): string {
        return "home";
    }

    public static get URL(): string {
        return "/";
    }

    public static get UIUSERROLE(): string {
        return "UIROVUSERROLE";
    }
  }