import { BaseService } from './base.service';
import { Message, SelectItem, MessageService } from 'primeng/api';
import { Constants, CropModel, EvaluationModel, GeoPoliticalUnitModel, Program, ProgramType, Region, RegionModel, SeedStorageLocationCombo, Station, StationType } from '../shared';
import { UserClaim} from "../shared/userClaim";
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component } from '@angular/core';
import {SharedService} from '../services/shared.service';

@Component({
  template: ''
})

export abstract class BaseComponent implements OnInit, OnChanges {
  private static Crops: CropModel[] = [];
  get crops(): CropModel[] {
    return BaseComponent.Crops;
  }
  set crops(crops: CropModel[]) {
    BaseComponent.Crops = crops;
  }

  private static Regions: Region[] = [];
  get regions(): Region[] {
    return BaseComponent.Regions;
  }
  set regions(regions: Region[]) {
    BaseComponent.Regions = regions;
  }

  private static DistinctRegions: RegionModel[] = [];
  get distinctRegions(): Region[] {
    return BaseComponent.DistinctRegions;
  }
  set distinctRegions(distinctRegions: RegionModel[]) {
    BaseComponent.DistinctRegions = distinctRegions;
  }
  private static Countries: GeoPoliticalUnitModel[] = [];
  get countries(): GeoPoliticalUnitModel[] {
    return BaseComponent.Countries;
  }
  set countries(countries: GeoPoliticalUnitModel[]) {
    BaseComponent.Countries = countries;
  }

  private static StationTypes: StationType[] = [];
  get stationTypes(): StationType[] {
    return BaseComponent.StationTypes;
  }
  set stationTypes(stationTypes: StationType[]) {
    BaseComponent.StationTypes = stationTypes;
  }

  private static EvaluationZones: EvaluationModel[] = [];
  get evaluationZones(): EvaluationModel[] {
    return BaseComponent.EvaluationZones;
  }
  set evaluationZones(evaluationZones: EvaluationModel[]) {
    BaseComponent.EvaluationZones = evaluationZones;
  }

  private static DistinctEZS: EvaluationModel[] = [];
  get distinctEZs(): EvaluationModel[] {
    return BaseComponent.DistinctEZS;
  }
  set distinctEZs(distinctEZs: EvaluationModel[]) {
    BaseComponent.DistinctEZS = distinctEZs;
  }

  private static Programs: Program[] = [];
  get programs(): Program[] {
    return BaseComponent.Programs;
  }
  set programs(programs: Program[]) {
    BaseComponent.Programs = programs;
  }

  private static DistinctPrograms: Program[] = [];
  get distinctPrograms(): Program[] {
    return BaseComponent.DistinctPrograms;
  }
  set distinctPrograms(distinctPrograms: Program[]) {
    BaseComponent.DistinctPrograms = distinctPrograms;
  }

  private static StationCodes: Station[] = [];
  get stationCodes(): Station[] {
    return BaseComponent.StationCodes;
  }
  set stationCodes(stationCodes: Station[]) {
    BaseComponent.StationCodes = stationCodes;
  }

  private static ProgramTypes: ProgramType[] = [];
  get programTypes(): ProgramType[] {
    return BaseComponent.ProgramTypes;
  }
  set programTypes(programTypes: ProgramType[]) {
    BaseComponent.ProgramTypes = programTypes;
  }

  private static SeedStorageLocations: SeedStorageLocationCombo[] = [];
  get seedStorageLocations(): SeedStorageLocationCombo[] {
    return BaseComponent.SeedStorageLocations;
  }
  set seedStorageLocations(seedStorageLocations: SeedStorageLocationCombo[]) {
    BaseComponent.SeedStorageLocations = seedStorageLocations;
  }
  private static DistinctSeedStorageLocations: SeedStorageLocationCombo[] = [];
  get distinctSeedStorageLocations(): SeedStorageLocationCombo[] {
    return BaseComponent.DistinctSeedStorageLocations;
  }
  set distinctSeedStorageLocations(distinctSeedStorageLocations: SeedStorageLocationCombo[]) {
    BaseComponent.DistinctSeedStorageLocations = distinctSeedStorageLocations;
  }

  public msgs: Message[] = [];
  public loading: boolean;
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public isROVSuperUser = false;
  public isROVPublishedResourceFileUser = false;
  public sortedRegionCodes: SelectItem[];
  public sortedSeedStorageLocations: SelectItem[];
  public sortedTreatment: SelectItem[];
  public sortedNewVVS: SelectItem[];
  public sortedContainer: SelectItem[];
  public sortedEZCodes: SelectItem[];
  public sortedProgramCodes: SelectItem[];

  constructor(public _baseService: BaseService, public _messageService: MessageService,
    public sharedservice: SharedService ) {
  }

  ngOnInit() { let i = 0; } //fake code to fix warning

  // if not overridden in the control will re-lookup and set roles
  ngOnChanges(changes: SimpleChanges) {
    this.setupRoles();
  }

  setupRoles() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
      this.isROVSuperUser = userClaim.isROVSuperUser;
      this.isROVPublishedResourceFileUser = userClaim.isROVPublishedResourceFileUser;
    }
  }

  getDropdownLists() {
    if (this.crops.length === 0 ||
      this.regions.length === 0 ||
      this.countries.length === 0 ||
      this.stationTypes.length === 0 ||
      this.evaluationZones.length === 0 ||
      this.programs.length === 0 ||
      this.programTypes.length === 0 ||
      this.stationCodes.length === 0 ||
      this.seedStorageLocations.length === 0) {
      this._baseService.getDropdownLists().subscribe(
        data => {
          this.fillDropDownList(data);
        }
      );
    }
  }

  fillDropDownList(data: any) {
    if (data.RegionCodes && data.RegionCodes.length > 0) {
      this.regions = data.RegionCodes;
      this.regions.forEach(element => {
        element.value = element.Id;
        element.label = element.Code;
      });
      this.regions.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.Crops && data.Crops.length > 0) {
      this.crops = data.Crops;
      this.crops.forEach(element => {
        element.value = element.CropID;
        element.label = element.CropName + ' - ' + element.CropDescription;
      });
      this.crops.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.StationTypes && data.StationTypes.length > 0) {
      this.stationTypes = data.StationTypes;
      this.stationTypes.forEach(element => {
        element.value = element.Id;
        element.label = element.Code + ' - ' + element.Name;
      });
      this.stationTypes.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.Countries && data.Countries.length > 0) {
      this.countries = data.Countries;
      this.countries.forEach(element => {
        element.value = element.AoiId;
        element.label = element.Name;
      });
      this.countries.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.EvaluationZones && data.EvaluationZones.length > 0) {
      this.evaluationZones = data.EvaluationZones;
      this.evaluationZones.forEach(element => {
        element.value = element.Id;
        element.label = element.Code + ' - ' + element.Name;
      });
      this.evaluationZones.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.Programs && data.Programs.length > 0) {
      this.programs = data.Programs;
      this.programs.forEach(element => {
        element.value = element.ProgramID;
        element.label = element.ProgramCode;
      });
      this.programs.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.ProgramTypes && data.ProgramTypes.length > 0) {
      this.programTypes = data.ProgramTypes;
      this.programTypes.forEach(element => {
        element.value = element.Id;
        element.label = element.Code + ' - ' + element.Name;
      });
      this.programTypes.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.Stations && data.Stations.length > 0) {
      this.stationCodes = data.Stations;
      this.stationCodes.forEach(element => {
        element.value = element.StationID;
        element.label = element.StationCode + ' - ' + element.StationName;
      });
      this.stationCodes.sort((a,b)=>a.label.localeCompare(b.label));
    }

    if (data.SeedStorageLocations && data.SeedStorageLocations.length > 0) {
      this.seedStorageLocations = data.SeedStorageLocations;
      this.seedStorageLocations.forEach(element => {
        element.value = element.Id;
        element.label = element.Name;
      });
      this.seedStorageLocations.sort((a,b)=>a.label.localeCompare(b.label));
    }

    this.distinctDropDownValues();
    this.setValidValueSetFieldsData();
  }

  distinctDropDownValues() {
    const items = this.regions;
    let flags: { [code: string]: boolean } = {};
    this.distinctRegions = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (!flags[items[i].Code]) {
          flags[items[i].Code] = true;
          this.distinctRegions.push({
            Id: items[i].Id,
            Name: '',
            Description: '',
            Code: items[i].Code,
            CropID: '',
            RequestStatus: '',
            value: items[i].Id,
            label: items[i].Code
          });
        }
      }

      if (this.distinctRegions) {
        this.sortedRegionCodes = this.distinctRegions.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    const itemPrograms = this.programs;
    this.distinctPrograms = [];
    if (itemPrograms) {
      flags = {};
      for (let i = 0; i < itemPrograms.length; i++) {
        if (!flags[itemPrograms[i].ProgramCode]) {
          flags[itemPrograms[i].ProgramCode] = true;
          this.distinctPrograms.push({
            ProgramID: itemPrograms[i].ProgramID,
            ProgramName: '',
            ProgramCode: itemPrograms[i].ProgramCode,
            CropID: '',
            RequestStatus: '',
            EvaluationZoneID: '',
            value: itemPrograms[i].ProgramID,
            label: itemPrograms[i].ProgramCode
          });
        }
      }

      if (this.distinctPrograms) {
        this.sortedProgramCodes = this.distinctPrograms.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    const itemEvalZone = this.evaluationZones;
    this.distinctEZs = [];
    if (itemEvalZone) {
      flags = {};
      for (let i = 0; i < itemEvalZone.length; i++) {
        if (!flags[itemEvalZone[i].Code]) {
          flags[itemEvalZone[i].Code] = true;
          this.distinctEZs.push({
            Id: itemEvalZone[i].Id,
            Name: '',
            Code: itemEvalZone[i].Code,
            CropID: '',
            RegionId: '',
            value: itemEvalZone[i].Id,
            label: itemEvalZone[i].Code
          });
        }
      }

      if (this.distinctEZs) {
        this.sortedEZCodes = this.distinctEZs.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
          } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }

      const itemsSSL = this.seedStorageLocations;
      this.distinctSeedStorageLocations = [];
      if (itemsSSL) {
        for (let i = 0; i < itemsSSL.length; i++) {
          if (!flags[itemsSSL[i].Name]) {
            flags[itemsSSL[i].Name] = true;
            this.distinctSeedStorageLocations.push({
              Id: itemsSSL[i].Id,
              Name: itemsSSL[i].Name,
              Description: '',
              CropID: '',
              StationId: '',
              StationCode: '',
              StationName: '',
              RequestStatus: '',
              value: itemsSSL[i].Id,
              label: itemsSSL[i].Name
            });
          }
        }

        if (this.distinctSeedStorageLocations) {
          this.sortedSeedStorageLocations = this.distinctSeedStorageLocations.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            } else {
              return 0;
            };
          });
        }
      }
    }
  }

  setValidValueSetFieldsData() { }

  SelectMenuOption(Name: string) {
    setTimeout(function () {
      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Upload' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'History' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Create' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Home' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'UserUtility' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(4) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(4) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Calendar' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Scheduler' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'CategoryManager' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Allocate' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(4) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(4) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'RecentHistory' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(1) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Calendar' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(2) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'Demand' ? 'grey' : 'white';
      }

      if ((document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement) !== undefined) {
        (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(3) > li')
        [0] as HTMLElement).style.backgroundColor = Name === 'ViewRepLoc' ? 'grey' : 'white';
      }
    }, 100);
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._messageService.add({ severity: 'error', summary: title, detail: message });
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._messageService.add({ severity: 'warn', summary: title, detail: message });
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._messageService.add({ severity: 'info', summary: title, detail: message });
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._messageService.add({ severity: 'success', summary: title, detail: message });
  }
}
