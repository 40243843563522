import { Component, OnInit } from "@angular/core";
import { Constants } from "../shared";
import { Router } from "@angular/router";
import { SharedService } from "../services/shared.service";

@Component({
    selector: 'app-logout',
    template: '',
    styleUrls: []
})
export class LogoutComponent implements OnInit {
    constructor(private router: Router, private sharedservice: SharedService) {
    }

    ngOnInit(): void {
        sessionStorage.clear();
        localStorage.clear();
        this.sharedservice.setSessionStorageValue(Constants.UIUSERROLE,"");
        this.router.navigate([Constants.Nav_Home]);
    }
}