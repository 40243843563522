<div class="grid">
  <!-- <div class="col-1 controlLabelComment">
    <label id="lblComment">Comment: </label>
  </div> -->
  <div class="col-9">
    <textarea id="txtApprovalComment" required [cols]="30" [rows]="4" maxlength="1000" pInputTextarea
      style="width: 100%" class="p-inputtext" placeholder="Comments"></textarea>
  </div>
  <div class="col-3">
    <button type="button" id="btnSaveComment" pButton icon="fa fa-save" class="p-button-primary"
      [disabled]="disableCommentButton" (click)="saveComment()" label="Save Comment" title="Save Comment"
      style="float: right; width: 150px;"></button>
  </div>
  <div class="col-12">
    <p-table #dt id="dtComment" [columns]="commentsColumns" class="gridCommentHeight"
      [value]="approvalCommentData" sortMode="single" selectionMode="single" [resizableColumns]="true"
      columnResizeMode="fit" scrollHeight="250px">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" pResizableColumn
            [style.width]="col.width">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                No records found
            </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>