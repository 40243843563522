<div [hidden]="!isROVAdmin" #dvPendingChanges>
  <div class="grid">
    <div class="col-6">
      <p-panel id="pnlPendingChanges" appendTo="body">
        <p-header>
          <span>
            <b>Pending Changes</b>
          </span>
        </p-header>

        <p-table id="dtPendingChanges" [columns]="colsPendingChanges" [value]="pendingChanges" sortMode="single"
            [scrollable]="true" scrollHeight="500px"
            [reorderableColumns]="false" [resizableColumns]="true" [loading]="loadingRequests"
            showHeaderCheckBox="false">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <th [pSortableColumn]="col.field" [pTooltip]="col.header" [ngStyle]="{'width': col.width}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <td style="width: 50%">
                <div title="{{rowData.CreatedBy}}">
                  {{rowData.CreatedBy}}
                </div>
              </td>
              <td style="width: 20%">
                <div title="{{rowData.RequestType}}">
                  {{rowData.RequestType}}
                </div>
              </td>
              <td style="width: 10%">
                <div title="{{rowData.Code}}">
                  {{rowData.Code}}
                </div>
              </td>
              <td style="width: 20%">
                <div title="{{rowData.CreatedOn}}">
                  {{rowData.CreatedOn}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
    <div class="col-6">
      <p-panel id="pnlRecentComments" appendTo="body">
        <p-header>
          <span>
            <b>Recent Comments</b>
          </span>
        </p-header>
        <p-table id="dtRecentComments" [columns]="colsRecentComments" [value]="recentComments" sortMode="single"
            [scrollable]="true" scrollHeight="500px"
            [reorderableColumns]="false" [resizableColumns]="true" [loading]="loadingComments"
            [(selection)]="selectedComments" dataKey="ApprovalCommentID">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <th *ngIf="!col.hidden" [pSortableColumn]="col.field" [pTooltip]="col.header"
                  [ngStyle]="{'width': col.width}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>
              <th style="width: 20px;">
                <div title="Select / Deselect all Comments">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <td style="width: 25%">
                <div title="{{rowData.Commenter}}">
                  {{rowData.Commenter}}
                </div>
              </td>
              <td style="width: 20%">
                <div title="{{rowData.Comment}}">
                  {{rowData.Comment}}
                </div>
              </td>
              <td style="width: 25%">
                <div title="{{rowData.CommentType}}">
                  {{rowData.CommentType}}
                </div>
              </td>
              <td style="width: 10%">
                <div title="{{rowData.Code}}">
                  {{rowData.Code}}
                </div>
              </td>
              <td style="width: 20%">
                <div title="{{rowData.CommentDate}}">
                  {{rowData.CommentDate}}
                </div>
              </td>
              <td style="width: 20px;">
                <div title="Select / Deselect Comment">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
  <div class="cos-sm-11">
    <button id="btnRecentHistoryDelete" pButton type="button" label="Delete Selection" title="Delete Selection"
      [disabled]="this.selectedComments.length === 0" style="width: 150px; float: right; margin: 20px;"
      (click)="DeleteComments();"></button>
  </div>
</div>
<div class="container-fluid grid" [hidden]="isROVAdmin">
  <br />
  <br />
  <br />
  <div class="col-3"></div>
  <div class="col-6">
    <h3>
      <span [hidden]="hideMessage">
        You don't have access to the
        <b>manageR Overview</b> screen.
        <br />
        <a href="/">Click Here</a> to go to Home Screen.
      </span>
    </h3>
  </div>
  <div class="col-3"></div>
</div>