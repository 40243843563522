import { NgModule } from "@angular/core"; 
import { ViewCommentsComponent } from "./view-comments.component"; 
import { DialogModule } from 'primeng/dialog'; 
import { TableModule } from 'primeng/table';
import { BrowserModule } from "@angular/platform-browser";
import { ButtonModule } from "primeng/button";
import { MessageService } from "primeng/api";


@NgModule({
    declarations: [ ViewCommentsComponent ],  
    imports: [
        BrowserModule, 
        TableModule, 
        DialogModule, 
        ButtonModule
    ],
    exports: [ ViewCommentsComponent ],
    providers: [ MessageService ]

})
export class ViewCommentsComponentModule {

}
