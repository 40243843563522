<p-panel header="Add Failed" *ngIf="failedAdd.length !== 0">
  <p-table [value]="failedAdd">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 175px" pSortableColumn="userLoginName"> User
          <p-sortIcon field="userLoginName"/>
        </th>
        <th style="width: 175px" pSortableColumn="cropDescription"> Crop
          <p-sortIcon field="cropDescription"/>
        </th>
        <th style="width: 300px" pSortableColumn>Station</th>
        <th style="width: 300px">Program</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assoc>
      <tr>
        <td>{{ assoc.userLoginName }}</td>
        <td>{{ assoc.cropDescription }}</td>
        <td>{{ assoc.stationCode }} - {{ assoc.stationName }}</td>
        <td>{{ assoc.programCode }} - {{ assoc.programName }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<div style="height: 20px" *ngIf="failedAdd.length !== 0 && failedRemove.length !== 0"></div>
<p-panel header="Removal Failed" *ngIf="failedRemove.length !== 0">
  <p-table [value]="failedRemove">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 175px" pSortableColumn="userLoginName"> User
          <p-sortIcon field="userLoginName"/>
        </th>
        <th style="width: 175px" pSortableColumn="cropDescription"> Crop
          <p-sortIcon field="cropDescription"/>
        </th>
        <th style="width: 300px" pSortableColumn>Station</th>
        <th style="width: 300px">Program</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-assoc>
      <tr>
        <td>{{ assoc.userLoginName }}</td>
        <td>{{ assoc.cropDescription }}</td>
        <td>{{ assoc.stationCode }} - {{ assoc.stationName }}</td>
        <td>{{ assoc.programCode }} - {{ assoc.programName }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<p-footer>
  <div class="grid" style="margin-top: 10px">
    <div class="col-8"></div>
    <div class="col-2">
      <button id="btnCancel" type="button"
              class="p-button-warning alignRight"
              pButton icon="fa fa-close"
              (click)="cancel()" label="Close"></button>
    </div>
    <div class="col-2">
      <button id="btnConfirm" type="button"
              class="p-button-success alignRight"
              style="margin-left: 5px"
              pButton icon="fa fa-save"
              (click)="export()"
              label="Export"></button>
    </div>
  </div>
</p-footer>
