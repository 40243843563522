<form id="frmCreateEvalZone" #frm="ngForm" [formGroup]="createEvaluationZoneFormGroup">
    <p-panel id="panelEvalZone">
      <p-header id="pnlCEvalZoneHeader">
        <span>
          <b>Evaluation Zone Details</b>
        </span>
      </p-header>
      <div class="grid">
        <div class="col-6">
          <div class="grid">
            <div class="col-3 controlLabel">
              <label id="lblEvaluationZoneName">Evaluation Zone Name</label>
            </div>
            <div class="col-9">
              <input id="txtCEZName" name="evaluationZoneName" pInputText required autofocus [(ngModel)]="evaluationZoneData.EvaluationZoneName" formControlName="EvaluationZoneName"
                minlength="1" maxlength="100" />
            </div>
            <div class="col-3 controlLabel">
              <label id="lblEvaluationZoneCode">Evaluation Zone Code</label>
            </div>
            <div class="col-9">
              <input id ="txtCEZCode" name="evaluationZoneCode" pInputText required [(ngModel)]="evaluationZoneData.EvaluationZoneCode" formControlName="EvaluationZoneCode"
                minlength="2" maxlength="4" />
            </div>
            <div class="col-3 controlLabel">
              <label id="lblCrop">Crop</label>
            </div>
            <div class="col-9">
              <p-dropdown id ="ddCEZCrop" [style]="{'width':'100%'}" name="Crop"  [options]="evaluationZoneCropName" [(ngModel)]="evaluationZoneData.CropID"
                formControlName="CropID" placeholder="Select a Crop" (onChange)="clearRegion(); getRegionsByCropID()" [showClear]="true"></p-dropdown>
            </div>
            <!-- <div class="col-1">
              <button id="btnCEZClearCrop" (click)="clearCrop()" pButton icon= "fa fa-close" id="btnCrop" title="Clear Selection" class="buttonStyle" style="border:none;background-color: white; color: black;"></button>
            </div> -->
            <div class="col-3 controlLabel">
              <label id="lblDescription">Description</label>
            </div>
            <div class="col-9">
              <textarea id="txtCEZDescription" [cols]="30" [rows]="4" maxlength="500" name="description" [(ngModel)]="evaluationZoneData.Description" formControlName="Description"
                pInputTextarea class="p-inputtext"></textarea>
            </div>
            <div class="col-3 controlLabel">
              <label id="lblColor">Color</label>
            </div>
            <div class="col-9">
              <input id="txtCEZColor" type="number" name="color" pInputText [(ngModel)]="evaluationZoneData.Color" formControlName="Color" maxlength="9"
                />
            </div>
            <div class="col-3 controlLabel">
              <label id="lblRegion">Research Commercial Zone</label>
            </div>
            <div class="col-9">
              <p-dropdown id="ddCEZRegionId" [style]="{'width':'100%'}" name="region" [options]="allRegions" [(ngModel)]="evaluationZoneData.RegionID"
                formControlName="RegionID" placeholder="Select a Research Commercial Zone" [showClear]="true"></p-dropdown>
            </div>
            <!-- <div class="col-1">
              <button id="btnCEZClearRegion" (click)="clearRegion()" pButton icon= "fa fa-close" id="btnRegionp" title="Clear Selection" class="buttonStyle" style="border:none;background-color: white; color: black;"></button>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <div class="grid">
            <div class="col-3 controlLabel">
              <label id="lblEvaluationZoneLead">Evaluation Zone Lead</label>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-11">
                  <p-listbox id="lstCEZLead" [options]="ezLead" [(ngModel)]="evaluationZoneData.EvaluationZoneLeadNames" formControlName="EvaluationZoneLeadNames"
                  styleClass="width100" [style]="{'min-height':'35px'}">
                  </p-listbox>
                </div>
                <div class="col-1">
                  <button id="btnCEZLeads" type="button" pButton icon= "fa fa-user-plus" class="p-button-info" style="width: 30px; float: right; margin-right: 8px;" title="Add Evaluation Zone Leads"
                    (click)="ShowPeoplePicker()"></button>
                </div>
              </div>
            </div>
            <div class="col-3 controlLabel">
              <label id="lblEvaluationZoneTypeName">Evaluation Zone Type</label>
            </div>
            <div class="col-9"> 
              <div class="grid">
                <div class="col-11">
              <p-dropdown id="ddCEZType" [style]="{'width':'100%'}" name="evaluationZoneType" [options]="evaluationZoneTypeName" [(ngModel)]="evaluationZoneData.EvaluationZoneTypeID"
                formControlName="EvaluationZoneTypeID" placeholder="Select a Evaluation Zone Type" [showClear]="true"></p-dropdown>
            </div> 
            <div class="col-1">
              <button id="btnEvaluationZoneTypeDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                style="width: 30px; float: right; margin-right: 8px;" (click)="showEvaluationZoneTypeDialog()"
                title="Add/Edit EvaluationZoneType"></button>
            </div>
          </div>  
            </div>
            <div class="col-3 controlLabel"> 
              <br />
              <label id="lblCEZComment">Comment</label>
            </div>
            <div class="col-9">
              <span>Please enter comments or notes regarding changes.</span>
              <textarea id="txtCEZComment" [cols]="30" [rows]="5" name="Comment" pInputTextarea class="p-inputtext" [(ngModel)]="comments" formControlName="Comment"></textarea>
            </div>
          </div> 
        </div>
      </div>

      <p-footer>
        <div class="grid">
            <div class="col-8">
            </div>
            <div class="col-2 d-grid">
            <button id="btnCEZReset"  type="button" class="p-button-warning alignRight" pButton icon= "fa fa-close" (click)="reset()" label="Reset"></button>
          </div>
          <div class="col-2 d-grid">
            <button id="btnCEZSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save" (click)="saveEvaluationZone(frm)" [disabled]="!frm.form.valid"
              label="Submit"></button>
          </div>
        </div>
      </p-footer>
    </p-panel>
  </form>
  <br/>
  
  <p-dialog id="dlgCEZLead" header="Select EZ Leads" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayEvaluationZoneLead" showEffect="fade"
    [modal]="true">
    <app-peoplepicker id="appCEZPeoplePicker" [existingPeopleString]="existingPeopleString" 
      (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
  </p-dialog>
  
  <p-dialog id="dlgEditEvaluationZone" header="Edit Evaluation Zone Type" [style]="{'width': '1000px', 'height': '580px'}"
    [(visible)]="displayEvaluationZoneTypeDialog" showEffect="fade" [modal]="true">
    <manage-lookup [lkpType]="EvaluationZoneType" (getLookUpEvent)="getEvaluationZoneTypeLookUp()"></manage-lookup>
</p-dialog>