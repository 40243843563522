import { LERequestedData } from './../shared/lERequstedData';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { RegionResultModel } from './../shared/regionResultModel';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { UserRequestedData } from './../shared/userRequestedData';
import { Constants } from './../shared/constants';
import { RequestStatus } from '../shared';
import { UserClaim } from "../shared/userClaim";
import { AppService } from '../app.service';
import { RegionService } from './region.service';
import { CropModel } from './../shared/cropModel';
import { RegionModel } from './../shared/regionModel';
import { CommonService } from './../shared/common.service';
import { Message } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Person } from './../shared/person';
import { ApprovalComments } from '.././shared/approvalComments';
import { RegionForm } from './../shared/regionFormControls';
import { ExperimentProjectCodeResult } from './../shared/experimentProjectCodeResult';
import { LocationProjectCodeResult } from './../shared/locationProjectCodeResult';
import { ProjectLEResult } from './../shared/projectLEResult';
import { SSLLEResult } from './../shared/sSLLEResult';
import { StationLEResult } from './../shared/stationLEResult';
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})

export class RegionComponent implements OnInit, OnChanges {
  expandedItems: any[] = [];
  msgs: Message[] = [];
  status: SelectItem[] = [];
  displayComments: boolean;
  displayRegionLead: boolean;
  dialogVisible: boolean;
  selectedStatus: string;
  sourceIdentifier: string;
  loading: boolean;

  public filteredRegionCode: string;
  public filteredRegionName: string;
  public filteredRegionCropDescription: string;
  public filteredRegionDescription: string;
  public filteredRegionLeadNames: string;
  public filteredRegionRequestStatus: string;
  public _approvalComments: ApprovalComments = new ApprovalComments();
  public RegionID: string;
  public disableCommentButton = false;
  public regionLead: SelectItem[] = [];
  public regionAllData: RegionResultModel[] = [];
  public filteredData: RegionResultModel[] = [];
  public regionCropName: CropModel[] = [];
  public regionFormGroup: FormGroup;
  public regionData: RegionResultModel = new RegionResultModel();
  public approvalCommentData: any[] = [];
  public existingPeopleString = '';
  public existingPeople: Person[] = [];
  public regionName: RegionModel[] = [];
  public regionCodes: RegionModel[] = [];
  public parentComponent = 'Region';
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public distinctCodes: SelectItem[];
  public sortedCodes: SelectItem[];
  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';

  public displayLEIDWindow = false;
  public existingLEIDString = '';
  public aoiIDs: number[] = [];
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = [];
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public panelHeight = '410px';
  public resizeIcon = 'fa fa-expand';
  public height = 580;
  public width = 1200;
  public positionLeft = '50px';
  public positionTop = '40px';
  public top = 0;
  public left = 0;
  cols: any[];
  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  public regionForm = new RegionForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;

  constructor(private route: ActivatedRoute, private _appService: AppService,
    private _regionService: RegionService, private _commonService: CommonService,
    private _confirmation: ConfirmationService, private _fb: FormBuilder,
    private sharedservice: SharedService) {
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.regionFormGroup = this._fb.group({
        'RegionName': [this.regionForm.RegionName, Validators.required],
        'RegionCode': [this.regionForm.RegionCode, Validators.required],
        'RegionLeadNames': [this.regionForm.RegionLeadNames],
        'Description': [this.regionForm.Description],
        'Color': [this.regionForm.Color],
        'CropID': [this.regionForm.CropID, Validators.required],
        'CropName': [this.regionForm.CropName],
        'CropDescription': [this.regionForm.CropDescription],
        'RequestStatus': [this.regionForm.RequestStatus],
        'PRISM_AOI_ID': [this.regionForm.PRISM_AOI_ID, this.regionForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.regionForm.ApprovalComments]
      });
    } else {
      this.regionFormGroup = this._fb.group({
        'RegionName': [this.regionForm.RegionName, Validators.required],
        'RegionCode': [this.regionForm.RegionCode, Validators.required],
        'RegionLeadNames': [this.regionForm.RegionLeadNames],
        'Description': [this.regionForm.Description],
        'Color': [this.regionForm.Color],
        'CropID': [this.regionForm.CropID, Validators.required],
        'CropName': [this.regionForm.CropName],
        'CropDescription': [this.regionForm.CropDescription],
        'RequestStatus': [this.regionForm.RequestStatus],
        'PRISM_AOI_ID': [this.regionForm.PRISM_AOI_ID, this.regionForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.regionForm.ApprovalComments]
      });
    }
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  ngOnInit() {
    this.getLoginInfo();
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 4) {
      this.getLoginInfo();
      this.buildForm();

      if( (this.cleanCache || (this.regionAllData == null || this.regionAllData.length === 0))
          && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 4)) {
        this.route.queryParams.subscribe(p=>{
          const regionID: string = p['RegionID'];
          this.selectedRequestID = regionID ?? '';
        })

        this.cols = [
          { field: 'RegionID', header: 'RCZ ID', hidden: 'true', title: 'Research Commercial Zone ID' },
          { field: 'RegionCode', header: 'RCZ Code', title: 'Research Commercial Zone Code' },
          { field: 'RegionName', header: 'RCZ Name', title: 'Research Commercial Zone Name' },
          { field: 'CropDescription', header: 'Crop' },
          { field: 'Description', header: 'Description' },
          { field: 'RegionLeadNames', header: 'RCZ Leads', title: 'Research Commercial Name Leads' },
          { field: 'RequestStatus', header: 'Status' }
        ];

        this.bindRegionDropdownLists();
        this.getRegions();
        this.cleanUpCache.emit(false);
      }
    }
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
      this.userName = userClaim.username;
    }
  }

  bindRegionDropdownLists() {
    this._regionService.bindRegionDropdownLists().subscribe(
      data => {
        if (data.regionCodes && data.regionCodes.length > 0) {
          this.regionCodes = [];
          data.regionCodes.forEach((item: { Id: any; Code: string; Name: string; Description: any; RequestStatus: any; CropID: any; }) => {
            this.regionCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              Description: item.Description,
              RequestStatus: item.RequestStatus,
              CropID: item.CropID,
              label: item.Name + ' - ' + item.Code,
              value: item.Id
            });
          });
        }

        if (data.Crops && data.Crops.length > 0) {
          this.regionCropName = data.Crops;
          this.regionCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }
      }
    );
  }

  getRegions() {
    this.loading = true;
    this._regionService.getRegionResult().subscribe({
      next: response => {
        this.filteredData = this.regionAllData = response;
        if (response != null && this.selectedRequestID !== '') {
          const selectedItem = response.find(element => element.RegionID.toString());
          this.first = 0;
          if(!selectedItem){
            this.DisplayInfoMessage('Info', `The Region
            requested in the URL either doesn't exist or is no more in Pending State.`);
          }
          else{
            let index = response.indexOf(selectedItem);
            const pageNumber = Math.ceil(++index / 10)
            this.first = (pageNumber - 1) * 10;
            this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
            this.editRegions(selectedItem);
          }
        }
        this.distinctRegionCodes();
        this.loading = false;
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  distinctRegionCodes() {
    const items = this.regionAllData;
    const l = items.length;
    let flags: { [code: string]: boolean } = {};
    this.distinctCodes = [];
    for (let i = 0; i < items.length; i++) {
      if (!flags[items[i].RegionCode]) {
        flags[items[i].RegionCode] = true;
        this.distinctCodes.push({
          label: items[i].RegionCode,
          value: items[i].RegionCode
        });
      }
    }
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value?.toLowerCase() < b.value?.toLowerCase()) {
          return -1;
        } else if (a.value?.toLowerCase() > b.value?.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
  }

  expandRow(item: number) {
    let exists = false;
    this.expandedItems.forEach(x => {
      if (x.RegionID === this.regionAllData[item].RegionID) {
        exists = true;
      }
    });

    if (exists) {
      this.expandedItems.pop();
    } else {
      this.expandedItems.push(this.regionAllData[item]);

      if (this.isROVAdmin
        || (this.regionAllData[item].CreatedBy.Email.toLocaleLowerCase() === this.userName.toLocaleLowerCase())) {
        try {
          setTimeout(function () {
            document.getElementById('dtRegions')?.querySelector('p-rowexpansionloader')?.scrollIntoView();
          }, 100);
        } catch (err:any) { console.log(Constants.SCROLLERROR + err.toString()); }

        this.editRegions(this.regionAllData[item]);
      }
    }
  }

  editRegions(region: RegionResultModel) {
    if (this.isROVAdmin) {
      this.regionFormGroup.controls['RequestStatus'].enable();
    } else {
      this.regionFormGroup.controls['RequestStatus'].disable();
    }

    this.buildForm();
    this.regionForm = Object.assign({}, region);
    this.dialogVisible = true;

    this.status = [];
    this.regionLead = [];
    if (region.RegionLeads != null) {
      region.RegionLeads.forEach(pr => {
        this.regionLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
      });
    }

    if (this.isROVAdmin) {
      this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
      this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
    }
    this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

    if (region.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }
      this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
      this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
    }

    this.selectedStatus = this.status.find(s => s.label === region.RequestStatus)?.value;
    this.regionForm.RequestStatus = this.selectedStatus;

    this.regionForm.RegionID = region.RegionID;
    this.regionForm.BaseRegionID = region.BaseRegionID;

    this.regionForm.ApprovalComments = [];
    this.regionFormGroup.controls['Comment'].setValue('');

    this.statusChange(this.regionForm.RequestStatus,false);

    if (this.isROVAdmin) {
      this.getLEData(this.regionForm.RegionCode);
    }
  }

  getLEData(code: any) {
    if (code) {
      const queryString = `code=${code}&type=${this.parentComponent}`;

      this.projectLEResult = [];
      this.stationLEResult = [];
      this.sSLLEResult = [];
      this.locationProjectCodeResult = [];
      this.experimentProjectCodeResult = [];
      this.aoiIDs = [];

      this._commonService.getLEAndAOIReportData(queryString)
        .subscribe({
          next: Result => {
            this.projectLEResult = Result.ProjectLE;
            this.projectLEResult = this.projectLEResult.slice();

            this.stationLEResult = Result.StationLE;
            this.stationLEResult = this.stationLEResult.slice();

            this.sSLLEResult = Result.SSLLE;
            this.sSLLEResult = this.sSLLEResult.slice();

            this.locationProjectCodeResult = Result.LocationProjectCode;
            this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

            if (this.sSLLEResult && this.sSLLEResult.length > 0) {
              this.sSLLEResult.forEach(s => {
                this.aoiIDs.push(s.AOIId);
              });
            }

            this.experimentProjectCodeResult = Result.ExperimentProjectCode;
            this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string, isChange:boolean) {
    if(this.regionForm.PRISM_AOI_ID=="0")
    {
      this.regionForm.PRISM_AOI_ID="";
    }
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.regionForm.RequestStatus === RequestStatus.RETIRED || this.regionForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.regionFormGroup = this._fb.group({
        'RegionName': [this.regionForm.RegionName, Validators.required],
        'RegionCode': [this.regionForm.RegionCode, Validators.required],
        'RegionLeadNames': [this.regionForm.RegionLeadNames],
        'Description': [this.regionForm.Description],
        'Color': [this.regionForm.Color],
        'CropID': [this.regionForm.CropID],
        'CropName': [this.regionForm.CropName],
        'CropDescription': [this.regionForm.CropDescription],
        'RequestStatus': [this.regionForm.RequestStatus],
        'PRISM_AOI_ID': [this.regionForm.PRISM_AOI_ID, Validators.required],
        'Comment': [this.regionForm.ApprovalComments]
      });
    } else {
      this.buildForm();
    }
    if(isChange)
    this.regionFormGroup.markAsDirty();
  }

  saveRegion(frm: any, status: any) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }

  save(frm: any, status: any) {
    const crop = this.regionCropName.find(s => s.value === this.regionForm.CropID);

    if (crop) {
      this.regionForm.CropID = crop.CropID;
      this.regionForm.CropName = crop.CropName;
      this.regionForm.CropDescription = crop.CropDescription;
    } else {
      this.regionForm.CropID = '';
      this.regionForm.CropName = '';
      this.regionForm.CropDescription = '';
    }

    this.existingPeople = [];
    if (this.regionForm.RegionLeads) {
      this.regionForm.RegionLeads.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    this.regionData = Object.assign({}, this.regionForm);

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.regionData.RequestStatus = status;

      this._regionService.saveRegion(JSON.stringify(this.regionData))
        .subscribe({
          next: Result => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.regionData.RegionID;
              this.regionData = Result.Region;

              const i = this.regionAllData.findIndex(s => s.RegionID === primaryID);
              const j = this.expandedItems.findIndex(s => s.RegionID === primaryID);
              const k = this.regionCodes.findIndex(s => s.Id === primaryID);

              if (Result.Region.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.regionAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.regionCodes.splice(k, 1);
                }
              } else {
                this.regionAllData[i] = this.regionData;
                this.expandedItems[j] = this.regionData;

                if (k !== -1) {
                  this.regionCodes.splice(k, 1,
                    ({
                      Id: this.regionData.RegionID,
                      Code: this.regionData.RegionCode,
                      Name: this.regionData.RegionName,
                      Description: this.regionData.Description,
                      RequestStatus: this.regionData.RequestStatus,
                      CropID: this.regionData.CropID,
                      label: this.regionData.RegionName + ' - ' + this.regionData.RegionCode,
                      value: this.regionData.RegionID
                    }));
                }

                this.cleanUpCache.emit(true);
              }

              this.regionCodes = this.regionCodes.slice();
              this.expandedItems = this.expandedItems.slice();
              this.regionAllData = this.regionAllData.slice();
              this.distinctRegionCodes();

              if (this.selectedStatus === RequestStatus.PENDING && Result.Region.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);
              this.buildForm();
            } else {
              this.regionData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('Error', Result.Message);
            }

            this.dialogVisible = false;
          },
          error: error => {
            this.regionData.RequestStatus = this.selectedStatus;
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(isApprove: any) {
    if ((!this.regionForm.CropName || this.regionForm.CropName === '') && this.regionForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (!this.regionForm.RegionName || this.regionForm.RegionName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'RCZ Name is required...');
      return false;
    }

    if ((!this.regionForm.RegionCode
      || (this.regionForm.RegionCode
        && this.regionForm.RegionCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'RCZ Code is required...');
      return false;
    }

    if ((this.regionForm.RegionCode
      && (this.regionForm.RegionCode.trim().length < 2
        || this.regionForm.RegionCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'RCZ Code should be between 2 to 4 characters long...');
      return false;
    }

    if (this.regionForm.RegionCode && this.regionForm.RegionCode.toString().trim() !== '') {
      const sCode = this.regionCodes.find(s => s.Code.toString().toLowerCase()
        === this.regionForm.RegionCode.toString().toLowerCase() && s.CropID === this.regionForm.CropID
        && s.Id !== this.regionForm.RegionID && s.RequestStatus !== RequestStatus.RETIRED);
      if (sCode && this.regionForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning', 'This Region Code and Crop combo already exists. Please enter a new Code or Crop...');
        return false;
      }
    }

    if (this.regionForm.Color
      && (this.regionForm.Color.toString().indexOf('-') >= 0
        || this.regionForm.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.regionForm.Color
      && this.regionForm.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 9 characters for Color...');
      return false;
    }

    if (this.regionForm.RequestStatus === RequestStatus.PENDING
      && this.selectedStatus !== RequestStatus.PENDING) {
      this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
      return false;
    }

    if (this.regionForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
      if (isApprove && (!this.regionForm.PRISM_AOI_ID
        || (this.regionForm.PRISM_AOI_ID
          && this.regionForm.PRISM_AOI_ID.toString().trim() === ''))) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is required for Request Status other than Pending and Retired...');
        return false;
      }

      const idx = this.aoiIDs.findIndex(s => s.toString() === this.regionForm.PRISM_AOI_ID.toString());

      if (isApprove && (this.regionForm.PRISM_AOI_ID === '0' || idx === -1)) {
        this.DisplayWarningMessage('Warning', 'PRISM AOI ID is not a valid AOI ID...');
        return false;
      }
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  onCloseEditRegion(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: any) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
    }
  }

  viewComments(region: RegionResultModel) {
    this.approvalCommentData = [];

    if (region.ApprovalComments != null) {
      region.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;

    this.sourceIdentifier = region.RegionID;
  }

  onSaveComment(data: any){
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const regionID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = regionID;

    this._regionService.saveRegionComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const regionIndex = this.regionAllData
              .findIndex(s => s.RegionID.toString() === regionID.toString());

            if (regionIndex !== -1) {
              this.regionAllData[regionIndex] = Result.Region;
              this.regionAllData[regionIndex].ApprovalComments = Result.Region.ApprovalComments.slice();
              this.regionAllData = this.regionAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.RegionID = Result.Region.RegionID);

              this.expandedItems[expandedIndex] = Result.Region;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.Region.ApprovalComments != null) {
                Result.Region.ApprovalComments.forEach((element: any) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
       error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
  });
  }

  filterRegionDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  exportCSVForRegions() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._regionService.exportCSVForRegions(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel' });
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'RCZData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
       error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
  });
  }

  GetAOIID(region: RegionResultModel) {
    if (region.RegionCode) {
      if (!region.RegionCode) {
        this.DisplayErrorMessage('Error', 'Please enter the Region Code first...');
        return;
      }

      if (!region.RegionName) {
        this.DisplayErrorMessage('Error', 'Please enter the Region Name first...');
        return;
      }

      if (!region.Description) {
        this.DisplayErrorMessage('Error', 'Please enter the Description first...');
        return;
      }

      const obj = new LERequestedData();
      obj.ParentComponent = this.parentComponent;
      obj.LEAOIID = 0;

      if (!this.regionFormGroup.controls['RegionCode'].dirty) {
        obj.Code = '';
        obj.ProjectLE = this.projectLEResult;
        obj.StationLE = this.stationLEResult;
        obj.SSLLE = this.sSLLEResult;
        obj.LocationProjectCode = this.locationProjectCodeResult;
        obj.ExperimentProjectCode = this.experimentProjectCodeResult;
      } else {
        obj.Code = region.RegionCode;
        obj.ProjectLE = [];
        obj.StationLE = [];
        obj.SSLLE = [];
        obj.LocationProjectCode = [];
        obj.ExperimentProjectCode = [];
      }

      obj.OtherObject = JSON.stringify(region);

      this.existingLEIDString = JSON.stringify(obj);
      this.displayLEIDWindow = true;
    } else {
      this.DisplayErrorMessage('Error', 'Please enter the Code first...');
    }
  }

  onaddLEDataClick(data: LERequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayLEIDWindow = false;

      this.regionForm.PRISM_AOI_ID = data.LEAOIID.toString();

      this.projectLEResult = data.ProjectLE;
      this.stationLEResult = data.StationLE;
      this.sSLLEResult = data.SSLLE;

      this.aoiIDs = [];

      if (this.sSLLEResult && this.sSLLEResult.length > 0) {
        this.sSLLEResult.forEach(s => {
          this.aoiIDs.push(s.AOIId);
        });
      }

      this.locationProjectCodeResult = data.LocationProjectCode;
      this.experimentProjectCodeResult = data.ExperimentProjectCode;

      this.existingLEIDString = '';
    }
  }

  addLeads() {
    this.existingPeople = [];

    if (this.regionForm.RegionLeads) {
      this.regionForm.RegionLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = true;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayRegionLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayRegionLead = false;
      let leadNames = '';

      this.regionForm.RegionLeads = [];
      this.regionForm.RegionLeadNames = '';
      this.regionLead = [];

      data.person.forEach(pr => {
        if (!this.regionForm.RegionLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.regionForm.RegionLeads.push({
            ID: '', PersonID: pr.PersonID, Person: pr, Region: null,
            RegionID: this.regionForm.RegionID
          });
          this.regionLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        leadNames = leadNames + pr.DisplayName + '; ';
      });

      leadNames = leadNames.trim();

      if (leadNames.lastIndexOf(';') === leadNames.length - 1) {
        leadNames = leadNames.substring(0, leadNames.lastIndexOf(';'));
      }

      this.regionForm.RegionLeadNames = leadNames;
      this.existingPeopleString = '';
    }
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      this.panelHeight = '410px';
      return 10;
    }
  }

  clearCrop() {
    this.regionForm.CropID = '';
  }
}