<form #frm="ngForm" [formGroup]="stationFormGroup">
  <p-panel id="pnlCStations" [toggleable]="false">
    <p-header id="pnlCStationHeader">
      <span>
        <b>R&D Center Parameters</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div class="controlLabel col-3">
            <label id="lblCStationName">R&D Center Name</label>
          </div>
          <div class="col-9">
            <input id="txtCStationName" name="stationName" formControlName="StationName" pInputText required
            autofocus minlength="1" maxlength="25" [(ngModel)]="stationData.StationName" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblStationCode">R&D Center Code</label>
          </div>
          <div class="col-9">
            <input id="txtCStationCode" name="stationCode" pInputText minlength="2" maxlength="4" [(ngModel)]="stationData.StationCode"
            formControlName="stationCode" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblCStationCrops">Crop(s)</label>
          </div>
          <div class="col-9">
            <p-multiSelect id="msCStationCrops" panelStyleClass="long-width" [options]="crops" name="crops"
              [(ngModel)]="selectedCrops" appendTo="body" formControlName="crop" [style]="{'border-bottom': '3px solid #a94442', 'width': '100%'}"
              placeholder="Select a Crop(s)" (onChange)="changeCrops($event)">
            </p-multiSelect>
          </div>
          <div class="controlLabel col-3">
            <label id="lblConsolidationCrop">Consolidation Crop(s)</label>
          </div>
          <div class="col-9">
            <p-multiSelect id="msCStationConsolidationCrops" panelStyleClass="long-width" [options]="consolidationCrops" name="ConsolidationCrops"
              appendTo="body" formControlName="consolidationCrop" [style]="{'width': '100%'}"
              placeholder="Select a Crop(s)" (onChange)="changeConsolidationCrops($event)">
            </p-multiSelect>
          </div>
          <div class="controlLabel col-3">
            <label id="lblDescription">Description</label>
          </div>
          <div class="col-9">
            <textarea id="txtCStationDescription" [cols]="30" [rows]="3" maxlength="500" name="description"
            pInputTextarea [(ngModel)]="stationData.Description" formControlName="description" class="p-inputtext"></textarea>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="controlLabel col-3">
            <label id="lblStationTypes">R&D Center Type</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-dropdown id="ddCStationTypes" name="stationTypes" [options]="stationTypes" [filter]=true
                  [(ngModel)]="stationData.StationTypeID" formControlName="StationTypeID" appendTo="body" [style]="{'width':'100%'}"
                  placeholder="Select a R&D Center Type" [showClear]="true">
                </p-dropdown>
              </div>
              <div class="col-1">
                <button id="btnR&DCenterTypeDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="showRDCenterTypeDialog()"
                  title="Add/Edit R&D Center Type"></button>
              </div>
            </div>
          </div>
          <div class="controlLabel col-3">
            <label id="lblStationLeads">R&D Center Operations Lead</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCStationLeads" [options]="stationOperationLead" [(ngModel)]="stationData.UserResponsibilityStationAssocNames"
                  formControlName="UserResponsibilityStationAssocNames" styleClass="width100" [style]="{'min-height':'35px'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnCStationAddLead" type="button" pButton icon= "fa fa-user-plus" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="ShowPeoplePicker('OPLD')" title="Add R&D Center Operation Lead"></button>
              </div>
            </div>
          </div>
          <div class="controlLabel col-3">
            <label id="lblCShiptoStationContact">Ship to R&D Center Contact</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCShiptoStationContact" [options]="shiptoStationContact" [(ngModel)]="stationData.UserResponsibilityShiptoStationAssocNames"
                  formControlName="UserResponsibilityShiptoStationAssocNames" styleClass="width100" [style]="{'min-height':'35px'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnCShiptoStationContact" type="button" pButton icon= "fa fa-user-plus" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="ShowPeoplePicker('SSHP')" title="Add Ship to R&D Center Contact"></button>
              </div>
            </div>
          </div>
          <div class="controlLabel col-3">
            <label id="lblStationSafetyContact">R&D Center Safety Contact</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-listbox id="lstCStationSafetyContacts" name="UserResponsibilityStationSafetyContactAssocNames" [options]="stationSafetyContact"
                  [(ngModel)]="stationData.UserResponsibilityStationSafetyContactAssocNames"
                  formControlName="UserResponsibilityStationSafetyContactAssocNames" styleClass="width100" [style]="{'min-height':'35px'}">
                </p-listbox>
              </div>
              <div class="col-1">
                <button id="btnCStationAddSafetyContact" type="button" pButton icon= "fa fa-user-plus" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="ShowPeoplePicker('SAFE')" title="Add R&D Center Safety Contact"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel [toggleable]="false" id="pnlLocation">
    <p-header id="locationHeader">
      <span>
        <b>Location Details</b>
      </span>
    </p-header>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div class="controlLabel col-3">
            <label id="lblAddress">Address</label>
          </div>
          <div class="col-9">
            <textarea id="txtCStationAddres" [cols]="30" [rows]="2" name="Address" minlength="1" maxlength="150"
              pInputTextarea [(ngModel)]="stationData.Address" formControlName="Address" class="p-inputtext"></textarea>
          </div>

          <div class="controlLabel col-3">
            <label id="lblRegionCodes">Research Commercial Zone</label>
          </div>
          <div class="col-9">
            <p-dropdown id="ddCStationRegionCode" name="regionCodes" [options]="distinctRegions" [filter]=true
              [(ngModel)]="stationData.RegionID" formControlName="RegionID" appendTo="body" [style]="{'width':'100%'}"
              placeholder="Select a Research Commercial Zone" [showClear]="true">
            </p-dropdown>
          </div>
          <div class="controlLabel col-3">
            <label id="lblR3SiteCode">R3 Site Code</label>
          </div>
          <div class="col-9">
            <input id="txtCStationR3SiteCode" type="text" pInputText name="R3SiteCode" [(ngModel)]="stationData.R3SiteCode"
            formControlName="R3SiteCode" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblCStationR3ShipTo">R3 Ship To</label>
          </div>
          <div class="col-9">
            <input id="txtCStationR3ShipTo" type="text" pInputText name="R3ShipTo" [(ngModel)]="stationData.R3ShipTo"
                   formControlName="R3ShipTo" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblR3SoldTo">R3 Sold To</label>
          </div>
          <div class="col-9">
            <input id="txtCStationR3SoldTo" type="text" pInputText name="R3SoldTo" [(ngModel)]="stationData.R3SoldTo"
                   formControlName="R3SoldTo" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblStationR3DeliveryPlant">R3 Delivery Plant</label>
          </div>
          <div class="col-9">
            <input id="txtCStationR3DeliveryPlant" type="text" pInputText name="R3DeliveryPlant" [(ngModel)]="stationData.R3DeliveryPlant"
                   formControlName="R3DeliveryPlant" />
          </div>

          <div class="controlLabel col-3">
            <label id="lbltechnologytier">IT Infrastructure Technology Tier</label>
          </div>
          <div class="col-9">
            <div class="grid">
              <div class="col-11">
                <p-dropdown id="ddCStationTechnologyTier" panelStyleClass="long-width" [options]="technologyTiers"
                  name="technologyTiers" [(ngModel)]="stationData.TechnologyTierTypeId" appendTo="body"
                  formControlName="TechnologyTierTypeId" [style]="{'width':'100%'}" placeholder="Select a Technology Tier"
                  [showClear]="true">
                </p-dropdown>
              </div>
              <div class="col-1">
                <button id="btnTechTierDialog" type="button" pButton icon="fa fa-edit" class="p-button-info"
                  style="width: 30px; float: right; margin-right: 8px;" (click)="showTechnologyTierDialog()"
                  title="Add/Edit IT Infrastructure Technology Tier"></button>
              </div>
            </div>
          </div>

          <div class="controlLabel col-3">
            <label id="lblCorporateITCode">Corporate IT Code</label>
          </div>
          <div class="col-9">
            <input id="txtCorpITCode" name="corporateITCode" formControlName="CorporateITCode" pInputText
            autofocus [(ngModel)]="stationData.CorporateITCode" />
          </div>

        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="controlLabel col-3">
            <label id="lblLatitude">Latitude</label>
          </div>
          <div class="col-9">
            <input id="txtCStationLatitude" type="text" pInputText name="Latitude" maxlength="15" step="any"
                   [(ngModel)]="stationData.Latitude" formControlName="Latitude" />
          </div>
          <div class="col-3 controlLabel">
            <label id="lblLongitude">Longitude</label>
          </div>
          <div class="col-9">
            <input id="txtCStationLongitude" type="text" pInputText name="Longitude" (keyup)="GetGAAData($event)" maxlength="15" step="any"
                   [(ngModel)]="stationData.Longitude" formControlName="Longitude" />
          </div>
          <div class="controlLabel col-3">
            <label id="lblCStationGeographicLocale">Geographic Locale</label>
          </div>
          <div class=" removeSpacing col-9">
            <input id="txtCStationGeographicLocale" type="text" pInputText name="GeographicLocale" readonly
                   [(ngModel)]="stationData.GeographicLocale" formControlName="GeographicLocale" />
          </div>
          <div [hidden]="true">
            <label id="lblState">{{stationData.GAAResponseJson}}</label>
          </div>
          <div class="col-3 controlLabel">
            <br />
            <label id="lblComment">Comment</label>
          </div>
          <div class="col-9">
            Please enter comments or notes regarding changes.
            <textarea id="txtCStationComment" [cols]="30" [rows]="5" name="Comment" pInputTextarea class="p-inputtext"
            [(ngModel)]="stationData.Comment" formControlName="Comment"></textarea>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="grid">
        <div class="col-8">
        </div>
        <div class="col-2 d-grid">
          <button id="btnCStationReset" type="button" class="p-button-warning alignRight" pButton icon= "fa fa-close"
            (click)="reset()" label="Reset"></button>
        </div>
        <div class="col-2 d-grid">
          <button id="btnCStationSave" type="submit" class="p-button-success alignRight" pButton icon="fa fa-save"
            (click)="saveStation(frm)" [disabled]="!frm.form.valid" label="Submit"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>
<br />

<p-dialog id="dlgCStationLeads" [header]="peoplePickerHeader" [style]="{'width': '1000px', 'height': '580px'}" [(visible)]="displayStationLead"
showEffect="fade" [modal]="true">
  <app-peoplepicker id="appCStationPeoplePicker" [existingPeopleString]="existingPeopleString" (addUserDataClick)='onaddUserDataClick($event)'></app-peoplepicker>
</p-dialog>

<p-dialog id="dlgEditLookupTechTier" [header]="lookupDialogHeader" [style]="{'width': '1000px', 'height': '580px'}"
  [(visible)]="displayLookupDialog" (onHide)="closeTechnologyTierDialog()" showEffect="fade" [modal]="true">
  <manage-lookup [lkpType]="lookupType" (getLookUpEvent)="getLookupTypes()"></manage-lookup>
</p-dialog>
<p-dialog id="dlgEditLookupRDCenter" [header]="lookupDialogHeader" [style]="{'width': '1000px', 'height': '580px'}"
  [(visible)]="displayRDCenterTypeDialog" showEffect="fade" [modal]="true">
  <manage-lookup [lkpType]="StationType" (getLookUpEvent)="getRDCenterTypeLookup()"></manage-lookup>
</p-dialog>
