import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button'
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip"
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from "primeng/panel";
import { AccordionModule } from "primeng/accordion"
import { TableModule } from "primeng/table"
import { FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import { RadUser, RadUserRoles, RadUserRolesSave} from "../shared/radUser";
import { CommonService } from "../shared/common.service";
import { SharedService } from "../services/shared.service";
import { Constants } from "../shared";
import { UserClaim } from "../shared/userClaim";
import { CreateUserRoleService } from "./create-user-role.service";
import { Option } from "../shared/option";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ConfirmRoleChangesComponent } from './confirm-role-changes/confirm-role-changes.component';

@Component({
  selector: 'app-create-user-role',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PanelModule,
    ChipsModule,
    ChipModule,
    MultiSelectModule,
    NgClass,
    ButtonModule,
    DialogModule,
    NgIf,
    NgFor,
    AccordionModule,
    TableModule,
    ConfirmRoleChangesComponent
  ],
  templateUrl: './create-user-role.component.html',
  styleUrl: './create-user-role.component.scss'
})

export class CreateUserRoleComponent implements OnInit {
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public isROVSuperUser = false;

  public accordionIndex: number[] = [0];

  public loadingUsers = false;
  public findRolesDisabled = true;
  public saving = false;
  public submitEnabled = false;
  
  public showNewRoleDialog: boolean = false;
  public confirmChangesDialogVisible: boolean = false;

  public users: RadUser[] = [];
  public existingUserRoles: RadUserRoles[] = [];
  public filteredExistingUserRoles: RadUserRoles[] = [];
  public removedUserRoles: RadUserRoles[] = [];

  public newUserRoles: RadUserRoles[] = [];
  public filteredNewUserRoles: RadUserRoles[] = [];

  public roles: Option[] = [];

  public showFilter: boolean = false;
  public filter: string = "";
  public searchSubject$ = new Subject<string>();

  public userRoleForm = this.fb.nonNullable.group({
    selectedUsers: new FormControl(),
    selectedRoles: new FormControl(),
    filter: new FormControl()
  })

  public newRoleForm = this.fb.nonNullable.group({
    newRole: new FormControl()
  })

  constructor(private fb: FormBuilder, private _commonService: CommonService,
              private _shareService: SharedService, private _createUserRoleService: CreateUserRoleService) {
  }

  ngOnInit() {
    this.searchSubject$
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
    )
    .subscribe(search => {
      this.filterUserRoles(search);
    });
    
    this.getLoginInfo();
    this.loadUsers();
  }

  onChange() {
    let userLen = this.userRoleForm.controls.selectedUsers.value?.length;
    let roleLen = this.userRoleForm.controls.selectedRoles.value?.length;

    this.findRolesDisabled = (!userLen || userLen == 0)
      && (!roleLen || roleLen == 0);
  }

  onExistingRoleChipRemoved(id: string, role: Option) {
    this.removeRole(this.existingUserRoles, id, role);

    let found = this.users.find(u=>u.Id === id);
    if(found) {
      const idx = this.removedUserRoles.findIndex(u=>u.Id === id);
      let opt: Option = {label: role.label, value: role.value};

      if(idx > 0)
        this.removedUserRoles[idx].RolesArray.push(opt);
      else {
        let user: RadUserRoles = { Id: found.Id, LoginName: found.LoginName, RolesArray: [] };
        user.RolesArray.push(opt);

        this.removedUserRoles.push(user);
      }

      this.submitEnabled = true;
    }
  }

  onNewRoleChipRemoved(id: string, role: Option) {
    this.removeRole(this.newUserRoles, id, role);
  }

  onFilterChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchSubject$.next(target.value);
  }

  onUserFilter(event: any) {
    const inputValue = event.filter;

    if (inputValue.includes(',')) {
      let pastedValues = inputValue.split(',').map((value: string) => value.trim().toUpperCase());
      this.applyPastedValues(pastedValues);
    }
    else if (inputValue.includes(' ')) {
      let pastedValues = inputValue.split(' ').map((value: string) => value.trim().toUpperCase());
      this.applyPastedValues(pastedValues);
    }
  }

  applyPastedValues(pastedValues: any) {
    if(pastedValues) {
      const matches = this.users.filter(u => pastedValues.includes(u.LoginName.toUpperCase()));

      let current = this.userRoleForm.controls.selectedUsers.value;
      if(current) {
        this.userRoleForm.controls.selectedUsers.setValue( [...current, ...matches]);
      }
      else {
        this.userRoleForm.controls.selectedUsers.setValue( [...matches]);
      }

      this.onChange();
    }
  }

  createNewRole() {
    this.newRoleForm.controls.newRole.setValue(undefined);
    this.showNewRoleDialog = true;
  }

  findRoles() {
    this.accordionIndex = [0];
    this.showFilter = false;

    let searchUsers: RadUser[] = this.userRoleForm.controls.selectedUsers.value;
    let searchRoles: Option[] = this.userRoleForm.controls.selectedRoles.value;

    this.findExistingUserRoles(searchUsers, searchRoles);
    this.findNewUserRoles(searchUsers, searchRoles);

    const idx: number[] = [];
    if(this.existingUserRoles.length > 0)
      idx.push(1);
    if(this.newUserRoles.length > 0) {
      idx.push(2);
      this.submitEnabled = true;
    }

    this.showFilter = idx.length > 0;

    if(this.showFilter) {
      this.accordionIndex = idx;
    }

    const filter: string | null | undefined = this.userRoleForm.controls.filter.value;
    if (filter && filter.length > 0)
      this.filterUserRoles(filter);
  }

  findExistingUserRoles(searchUsers: RadUser[], searchRoles: Option[]) {
    this.resetExisting();

    if(searchUsers) {
      searchUsers.forEach(user => {  
        let existingUser: RadUserRoles = { Id: user.Id, LoginName: user.LoginName, RolesArray: [] };
  
        if (user.Roles !== '') {
          let roles: string[] = user.Roles.split(",");
          roles.forEach(role => {
            if(!searchRoles || searchRoles.findIndex(s=>s.value === role) >= 0) {
              let optionToAdd: Option = { label: role, value: role };
              existingUser.RolesArray.push(optionToAdd);
            }
          })
        }
  
        if(existingUser.RolesArray.length > 0) {
          this.existingUserRoles.push(existingUser);
          this.filteredExistingUserRoles.push(existingUser);
        }
      });
    }
    else if (searchRoles) {
      this.users.forEach(user => {  
        let existingUser: RadUserRoles = { Id: user.Id, LoginName: user.LoginName, RolesArray: [] };
  
        if (user.Roles !== '') {
          let roles: string[] = user.Roles.split(",");
          roles.forEach(role => {
            if(searchRoles.findIndex(s=>s.value === role) >= 0) {
              let optionToAdd: Option = { label: role, value: role };
              existingUser.RolesArray.push(optionToAdd);
            }
          })
        }
  
        if(existingUser.RolesArray.length > 0) {
          this.existingUserRoles.push(existingUser);
          this.filteredExistingUserRoles.push(existingUser);
        }
      });
    }
  }

  findNewUserRoles(searchUsers: RadUser[], searchRoles: Option[]) {
    this.resetNew();

    if(!searchUsers || !searchRoles)
      return;

    searchUsers.forEach(user => {  
      let newUser: RadUserRoles = { Id: user.Id, LoginName: user.LoginName, RolesArray: [] };

      if (user.Roles !== '') {
        let roles: string[] = user.Roles.split(",");
        searchRoles.forEach(role => {
          if(roles.findIndex(s=>s === role.value) < 0) {
            let optionToAdd: Option = { label: role.label, value: role.value };
            newUser.RolesArray.push(optionToAdd);
          }
        })
      }
      else {
        searchRoles.forEach(role => {
          let optionToAdd: Option = { label: role.label, value: role.value };
          newUser.RolesArray.push(optionToAdd);
        })
      }

      if(newUser.RolesArray.length > 0) {
        this.newUserRoles.push(newUser);
        this.filteredNewUserRoles.push(newUser);
      }
    });
  }

  filterUserRoles(argument: string): void {
    const filterByUserRoles = (userRoles: RadUserRoles, searchText: string): boolean => {
      const lowercasedSearchText = searchText.toLowerCase();
      const matchesUser: boolean =
        userRoles.LoginName.toLowerCase().includes(lowercasedSearchText);

      const matchesRoles = userRoles.RolesArray.some((role: Option) => role.label.toLowerCase().includes(lowercasedSearchText));

      return matchesUser || matchesRoles;
    };

    this.filteredNewUserRoles = this.newUserRoles.filter(userRoles => filterByUserRoles(userRoles, argument));
    this.filteredExistingUserRoles = this.existingUserRoles.filter(userRoles => filterByUserRoles(userRoles, argument));
  }

  saveNewRole() {
    let roleToAdd = this.newRoleForm.controls.newRole.value;
    if(!roleToAdd)
      return;

    roleToAdd = this.removeSpecialChars(roleToAdd).toUpperCase();

    if(roleToAdd && this.roles.some(x=>x.label.toUpperCase() == roleToAdd)) {
      this.newRoleForm.controls.newRole.setErrors( () => {
        return { existingName: true };
      });

      return;
    }

    let optionToAdd: Option = { label: roleToAdd, value: roleToAdd };
    this.roles.push(optionToAdd);
    this.sortRoles();

    this.cancelNewRole();
  }

  cancelNewRole() {
    this.showNewRoleDialog = false;
  }

  getLoginInfo() {
    let localRoles = this._shareService.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles != '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
      this.isROVSuperUser = userClaim.isROVSuperUser;
    }
  }

  loadUsers() {
    this.loadingUsers = true;

    this._createUserRoleService.loadUsers().subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.setUserData(data)
          this.populateExistingRoles()
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }
        this.loadingUsers = false;
        this.submitEnabled = false;
      },
      complete: () => {
        this.loadingUsers = false;
        this.submitEnabled = false;
      }
    })
  }

  setUserData(input: RadUser[]) {
    this.users = []

    input.forEach(user => {
      user.label = user.LoginName;
      user.value = user.Id;
    })

    input.sort((a,b)=>a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}))
    this.users = input;
  }

  populateExistingRoles() {
    this.roles = [];
    this.users.forEach(user => {
      if (user.Roles !== '') {
        let roles: string[] = user.Roles.split(",");
        roles.forEach(item => {
          if (this.roles.some(s => s.value === item)) {
            return;
          }

            let role: Option = {label: item, value: item};
            this.roles.push(role);
        })
      }
    })

    this.sortRoles();
  }

  removeRole(userRoles: RadUserRoles[], id: string, role: Option) {
    const found = userRoles.find(u => u.Id === id);
    if(found) {
      let editingItem = {...found};
      let filtered = editingItem.RolesArray.filter(r=>r.value !== role.value);
      editingItem.RolesArray = [...filtered];
      
      const index = userRoles.findIndex(x => x.Id === id);
      if (index !== -1) {
        userRoles[index] = editingItem;
      }
    }
  }

  removeSpecialChars(str: string) {
    return str.replace(/[^a-zA-Z0-9_-]/g, '');
  }

  reset() {
    this.accordionIndex = [0];
    this.resetExisting();
    this.resetNew();
    this.userRoleForm.controls.filter.setValue(undefined);
    this.searchSubject$.next("");
    this.showFilter = false;
    this.submitEnabled = false;
  }

  resetExisting() {
    this.existingUserRoles = [];
    this.filteredExistingUserRoles = [];
    this.removedUserRoles = [];
  }

  resetNew() {
    this.newUserRoles = [];
    this.filteredNewUserRoles = [];
  }

  findValidExisting(): RadUserRolesSave[] {
    let validExisting: RadUserRolesSave[] = [];

    if(this.removedUserRoles.length === 0)
      return validExisting;

    this.removedUserRoles.forEach(rur => {
      let found = this.users.find(u=>u.Id === rur.Id);
      if(found) {
        let user: RadUserRolesSave = { Id: found.Id, AddedRoles: [], RemovedRoles: [] };

        rur.RolesArray.forEach(role => {
            user.RemovedRoles.push(role.value);
          }
        );

        validExisting.push(user);
      }
    });

    return validExisting;
  }

  findValidUserRolesToSave(): RadUserRolesSave[] {
    let validExisting = this.findValidExisting();
    let validNew = this.newUserRoles.filter(n=>n.RolesArray.length > 0);    

    let combined: RadUserRolesSave[] = [];
    let skip: string[] = [];

    validExisting.forEach(e=> {
      let found = validNew.find(n=>n.Id === e.Id);
      if(found) {
        found.RolesArray.forEach(role=>{
          e.AddedRoles.push(role.value);
        });

        skip.push(e.Id);
      }

      combined.push(e);
    });
    
    validNew.forEach(n=> {
      if(skip.findIndex(s=>s === n.Id) < 0) {
        let user: RadUserRolesSave = { Id: n.Id, AddedRoles: [], RemovedRoles: [] };
        
        n.RolesArray.forEach(role=>{
          user.AddedRoles.push(role.value);
        });

        combined.push(user);
      }
    });

    return combined;
  }

  showConfirmation() {
    this.confirmChangesDialogVisible = true;
  }

  submit() {
    let toSave = this.findValidUserRolesToSave();

    if(!toSave || toSave.length === 0) {
      this.displayWarningMessage('Invalid', 'There are no users or no users with roles');
      return;
    }

    this.saving = true;

    this._createUserRoleService.saveUserRoles(toSave)
      .subscribe({
       next: () => {
            this.displaySuccessMessage('Success', 'User roles saved');
            this.removedUserRoles = [];
            this.updateUsers();
            this.confirmChangesDialogVisible = false;
        },
       error: error => {
          if (error === '') {
            this.displayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.displayErrorMessage('Error', error);
          }
          this.saving = false;
        },
        complete: () => { 
          this.saving = false;
        }
    });
  }

  updateUsers() {
    this._createUserRoleService.loadUsers().subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.users.forEach(u => {
            let found = data.find(n => n.Id === u.Id);
            if(found)
              u.Roles = found.Roles;
          });

          this.findRoles();
        }
      },
      error: error => {
          this.displayErrorMessage('Error', 'Could not update roles after saving');
        }
      });
  }

  sortRoles() {
    this.roles = [...this.roles.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}))];
  }

  displayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  displayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  displayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  displaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}