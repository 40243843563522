import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ConfirmationService, Message, SelectItem } from "primeng/api";
import { ApprovalComments, Constants, CropModel, ExperimentProjectCodeResult, LocationProjectCodeResult, Person, ProjectLEResult, Region, RegionModel, RequestStatus, SSLLEResult, StationLEResult, UserRequestedData } from "../shared";
import { UserClaim} from "../shared/userClaim";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { CommonService } from "../shared/common.service";
import { LERequestedData } from "../shared/lERequstedData";
import { CommercialMarketService } from "./commercialmarket.service";
import { SeedCommercialMarketCombo } from "../shared/seedCommercialMarketCombo";
import { CommercialMarketZoneForm } from "../shared/commercialMarketZoneFormControls";
import { CommercialMarketZoneResultModel } from "../shared/commercialMarketZoneResultModel";
import {SharedService} from './../services/shared.service';

@Component({
    selector: 'app-commercialmarket',
    templateUrl: './commercialmarket.component.html',
    styleUrls: ['./commercialmarket.component.scss']
  })
  export class CommercialMarketComponent implements OnInit, OnChanges {

    msgs: Message[] = [];
    status: SelectItem[] = [];
    displayComments: boolean;
    dialogVisible: boolean;
    displayCommercialMarketLead: boolean;
    selectedStatus: string;
    sourceIdentifier: string;
    expandedItems: any[] = [];
    public cmLead: SelectItem[] = [];
    public userName: string;
    public isROVAdmin = false;
    public isROVUser = false;
    public commercialMarketAllData: CommercialMarketZoneResultModel[] = [];
    public filteredData: CommercialMarketZoneResultModel[] = [];
    public _approvalComments: ApprovalComments = new ApprovalComments();
    public commercialMarketZoneFormGroup: FormGroup;
    public existingPeopleString = '';
    public existingPeople: Person[] = [];
    public approvalCommentData: any[] = [];
    public commercialMarketCodes: SeedCommercialMarketCombo[] = [];
    public commercialMarketZoneCropName: CropModel[] = [];
    public regionName: RegionModel[] = [];
    public allRegions: RegionModel[] = [];
    public commercialMarketData: CommercialMarketZoneResultModel = new CommercialMarketZoneResultModel();
    public parentComponent = 'CommercialMarketZone';
    loading: boolean;
    public filteredCMCodes: string;
    public filteredCMName: string;
    public filteredCMCrops: string;
    public filteredCMRegionCodes: string;
    public filteredCMDEscription: string;
    public filteredCMLeadNames: string;
    public filteredCMRequestStatus: string;
    public CommercialMarketID: string;
    public disableCommentButton = false;
    public distinctCodes: SelectItem[];
    public distinctRegions: SelectItem[];
    public sortedCodes: SelectItem[];
    public sortedRegions: SelectItem[];
    public saveLabelText = 'Save';
    public saveIcon = 'fa fa-save';
    public saveIconClass = 'p-button-success';
    public panelHeight = '350px';
    public resizeIcon = 'fa fa-expand';
    public height = 580;
    public width = 1200;
    public positionLeft = '50px';
    public positionTop = '40px';
    public displayLEIDWindow = false;
    public existingLEIDString = '';
    public aoiIDs: number[] = [];
    public projectLEResult: ProjectLEResult[] = [];
    public stationLEResult: StationLEResult[] = [];
    public sSLLEResult: SSLLEResult[] = [];
    public locationProjectCodeResult: LocationProjectCodeResult[] = [];
    public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
    public top = 0;
    public left = 0;
    cols: any[];

    @Input()
    public selectedTabIndex = 0;

    @Input()
    public selectedRequestID: string;

    @Input()
    public cleanCache: boolean;

    @Output()
    public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

    public commercialMarketZoneForm = new CommercialMarketZoneForm(
      '',
      '',
      '',
      '',
      '',
      new Region(),
      '',
      '',
      [],
      new Person(),
      '',
      new Date(),
      '',
      new Person(),
      new Date(),
      '',
      new Person(),
      new Date(),
      '',
      new Person(),
      new Date(),
      '',
    );
    expandedRows: { [id: string]: boolean } = {};
    first: number = 0;
    constructor(private route: ActivatedRoute, private _appService: AppService,
      private _commercialMarketService: CommercialMarketService, private _commonService: CommonService,
      private _confirmation: ConfirmationService,
      private _fb: FormBuilder, private sharedservice: SharedService) {
    }

    buildForm(): void {
      if (this.isROVAdmin) {
        this.commercialMarketZoneFormGroup = this._fb.group({
          'CommercialMarketZoneName': [this.commercialMarketZoneForm.CommercialMarketZoneName, Validators.required],
          'CommercialMarketZoneCode': [this.commercialMarketZoneForm.CommercialMarketZoneCode, Validators.required],
          'Description': [this.commercialMarketZoneForm.Description, Validators.required],
          'RegionID': [this.commercialMarketZoneForm.RegionID, Validators.required],
          'RequestStatus': [this.commercialMarketZoneForm.RequestStatus],
          'PRISM_AOI_ID': [this.commercialMarketZoneForm.PRISM_AOI_ID, this.commercialMarketZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
          'Comment': [this.commercialMarketZoneForm.ApprovalComments],
        });
      } else {
        this.commercialMarketZoneFormGroup = this._fb.group({
          'CommercialMarketZoneName': [this.commercialMarketZoneForm.CommercialMarketZoneName, Validators.required],
          'CommercialMarketZoneCode': [this.commercialMarketZoneForm.CommercialMarketZoneCode, Validators.required],
          'Description': [this.commercialMarketZoneForm.Description, Validators.required],
          'RegionID': [this.commercialMarketZoneForm.RegionID, Validators.required],
          'RequestStatus': [this.commercialMarketZoneForm.RequestStatus],
          'PRISM_AOI_ID': [this.commercialMarketZoneForm.PRISM_AOI_ID, this.commercialMarketZoneForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
          'Comment': [this.commercialMarketZoneForm.ApprovalComments],
        });
      }
    }

    ConvertDateToString(dt: Date): string {
      return this._commonService.ConvertDateToString(dt);
    }

    ngOnInit() {
      this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges) {
      if (this.selectedTabIndex === 7) {
        this.getLoginInfo();
        this.buildForm();

        if( (this.cleanCache || (this.commercialMarketAllData == null || this.commercialMarketAllData.length === 0))
            && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 7)) {
          this.route.queryParams.subscribe(p=>{
            const commercialMarketID: string = p['CommercialMarketID'];
            this.selectedRequestID = commercialMarketID ?? '';
          })

          this.cols = [
            { field: 'CommercialMarketID', header: 'CMZ ID', hidden: 'true', title: 'Commercial Market Zone ID' },
            { field: 'CommercialMarketZoneCode', header: 'CMZ Code', title: 'CMZ Code' },
            { field: 'CommercialMarketZoneName', header: 'CMZ Name', title: 'CMZ Name' },
            { field: 'RegionCode', header: 'RCZ', title: 'RCZ Code' },
            { field: 'Description', header: 'Description' },
            { field: 'RequestStatus', header: 'Status' }
          ];

          this.bindCommercialMarketDropdownLists();
          this.getCommercialMarkets();
        }
      }
    }

    bindCommercialMarketDropdownLists() {
      this._commercialMarketService.bindCommercialMarketDropdownLists().subscribe({
       next: data => {
          if (data.CommercialMarketCodes && data.CommercialMarketCodes.length > 0) {
            this.commercialMarketCodes = [];

            data.CommercialMarketCodes.forEach((item: { Id: any; Code: string; Name: string; CropID: any; RequestStatus: any; }) => {
              this.commercialMarketCodes.push({
                Id: item.Id,
                Code: item.Code, Name: item.Name,
                RequestStatus: item.RequestStatus,
                label: item.Code + ' - ' + item.Name,
                value: item.Id
              });
            });
          }

          if (data.RegionCodes && data.RegionCodes.length > 0) {
            this.allRegions = data.RegionCodes;
            this.allRegions.forEach(element => {
              element.value = element.Id;
              element.label = element.Code;
            });
            this.allRegions.forEach(reg => {
              if(!this.regionName.some(r => r.Code == reg.Code))
                this.regionName.push(reg);
            });
          }
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }
    });
    }

    getLoginInfo() {
      let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
      if (localRoles !== '' && localRoles !== null) {
        const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
        this.isROVAdmin = userClaim.isROVAdmin;
        this.isROVUser = userClaim.isROVUser;
        this.userName = userClaim.username;
      }
    }

    clearRegion() {
      this.commercialMarketZoneForm.RegionID = '';
    }

    getCommercialMarkets() {
      if(this.commercialMarketAllData.length === 0) {
        this.loading = true;

        this._commercialMarketService.getCommercialMarketResult().subscribe({
          next: response => {
            this.filteredData = this.commercialMarketAllData = response;
            if (response != null && this.selectedRequestID !== '') {
              const selectedItem = response.find(element => element.CommercialMarketZoneID.toString());
              this.first = 0;
              if(!selectedItem){
                this.DisplayInfoMessage('Info', `The Commercial Zone
                requested in the URL either doesn't exist or is no more in Pending State.`);
              }
              else{
                let index = response.indexOf(selectedItem);
                const pageNumber = Math.ceil(++index / 10)
                this.first = (pageNumber - 1) * 10;
                this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
                this.editCommercialMarkets(selectedItem);
              }
            }
            this.distinctAllDropdownCodes();
            this.loading = false;
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          }
        });
      }
    }

    distinctAllDropdownCodes() {
      const items = this.commercialMarketAllData;
      let flags: { [code: string]: boolean } = {};
      const l = items.length;

      this.distinctCodes = [];
      for (let i = 0; i < items.length; i++) {
        if (!flags[items[i].CommercialMarketZoneCode]) {
          flags[items[i].CommercialMarketZoneCode] = true;
          this.distinctCodes.push({
            label: items[i].CommercialMarketZoneCode,
            value: items[i].CommercialMarketZoneCode
          });
        }
      }
      if (this.distinctCodes) {
        this.sortedCodes = this.distinctCodes.sort((a, b) => {
          if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
          } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }

      flags = {};
      this.distinctRegions = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].Region && items[i].Region.Code) {
          if (!flags[items[i].Region.Code]) {
            flags[items[i].Region.Code] = true;
            this.distinctRegions.push({
              label: items[i].Region.Code,
              value: items[i].Region.Code
            });
          }
        }
      }
      if (this.distinctRegions) {
        this.sortedRegions = this.distinctRegions.sort((a, b) => {
          if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
          } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
          } else {
            return 0;
          };
        });
      }
    }

    expandRow(item: number) {
      let exists = false;
      this.expandedItems.forEach(x => {
        if (x.CommercialMarketID === this.commercialMarketAllData[item].CommercialMarketZoneID) {
          exists = true;
        }
      });

      if (exists) {
        this.expandedItems.pop();
      } 
      else {
        this.expandedItems.push(this.commercialMarketAllData[item]);

        if (this.isROVAdmin || (this.commercialMarketAllData[item].CreatedBy.Email.toLocaleLowerCase() === this.userName.toLocaleLowerCase())) {
          this.editCommercialMarkets(this.commercialMarketAllData[item]);
        }
      }
    }

    editCommercialMarkets(commercialMarket: CommercialMarketZoneResultModel) {
      if (this.isROVAdmin) {
        this.commercialMarketZoneFormGroup.controls['RequestStatus'].enable();
      }
      else {
        this.commercialMarketZoneFormGroup.controls['RequestStatus'].disable();
      }

      this.buildForm();
      this.commercialMarketZoneForm = Object.assign({}, commercialMarket);
      this.dialogVisible = true;

      if (!this.commercialMarketZoneForm.RegionID || !this.allRegions.find(s => s.Id.toString()
        === this.commercialMarketZoneForm.RegionID.toString())) {
        this.commercialMarketZoneForm.RegionID = '';
      }

      this.status = [];

      this.cmLead = [];


      if (this.isROVAdmin) {
        this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
        this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
      }
      this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

      if (commercialMarket.RequestStatus !== RequestStatus.PENDING) {
        let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

        if (index !== -1) {
          this.status.splice(index, 1);
        }

        index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

        if (index !== -1) {
          this.status.splice(index, 1);
        }
        this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
        this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
      }

      this.selectedStatus = this.status.find(s => s.label === commercialMarket.RequestStatus)?.value;
      this.commercialMarketZoneForm.RequestStatus = this.selectedStatus;

      this.commercialMarketZoneForm.CommercialMarketZoneID = commercialMarket.CommercialMarketZoneID;
      this.commercialMarketZoneForm.BaseCommercialMarketZoneID = commercialMarket.BaseCommercialMarketZoneID;

      this.commercialMarketZoneForm.ApprovalComments = [];
      this.commercialMarketZoneFormGroup.controls['Comment'].setValue('');

      this.statusChange(this.commercialMarketZoneForm.RequestStatus, false);

      if (this.isROVAdmin) {
        this.getLEData(this.commercialMarketZoneForm.CommercialMarketZoneCode);
      }
    }

    getLEData(code: string) {
      if (code) {
        const queryString = `code=${code}&type=${this.parentComponent}`;

        this.projectLEResult = [];
        this.stationLEResult = [];
        this.sSLLEResult = [];
        this.locationProjectCodeResult = [];
        this.experimentProjectCodeResult = [];
        this.aoiIDs = [];

        this._commonService.getLEAndAOIReportData(queryString)
          .subscribe({
            next: Result => {
              this.projectLEResult = Result.ProjectLE;
              this.projectLEResult = this.projectLEResult.slice();

              this.stationLEResult = Result.StationLE;
              this.stationLEResult = this.stationLEResult.slice();

              this.sSLLEResult = Result.SSLLE;
              this.sSLLEResult = this.sSLLEResult.slice();

              this.locationProjectCodeResult = Result.LocationProjectCode;
              this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

              if (this.sSLLEResult && this.sSLLEResult.length > 0) {
                this.sSLLEResult.forEach(s => {
                  this.aoiIDs.push(s.AOIId);
                });
              }

              this.experimentProjectCodeResult = Result.ExperimentProjectCode;
              this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
            },
            error:(error: any) => {
              if (error === '') {
                this.DisplayErrorMessage('Error', Constants.NOSERVICE);
              } else {
                this.DisplayErrorMessage('Error', error);
              }
            }
           } );
      }
    }

    setSaveButtonStyle(status: string) {
      if (status === RequestStatus.PENDING) {
        this.saveIcon = 'fa fa-save';
        this.saveIconClass = 'p-button-primary';
        this.saveLabelText = 'Save';
      } else if (status === RequestStatus.ACTIVE) {
        this.saveIcon = 'fa fa-check';
        this.saveIconClass = 'p-button-secondary';
        this.saveLabelText = 'Mark as Active';
      } else if (status === RequestStatus.DECLINED) {
        this.saveIcon = 'fa fa-trash';
        this.saveIconClass = 'p-button-danger';
        this.saveLabelText = 'Mark as Declined';
      } else if (status === RequestStatus.RETIRED) {
        this.saveIcon = 'fa fa-ban';
        this.saveIconClass = 'p-button-danger';
        this.saveLabelText = 'Mark as Retired';
      } else if (status === RequestStatus.TRANSITIONING) {
        this.saveIcon = 'fa fa-save';
        this.saveIconClass = 'p-button-info';
        this.saveLabelText = 'Mark as Transitioning';
      }

      this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
    }

    statusChange(status: string, isChange: boolean) {
      if(this.commercialMarketZoneForm.PRISM_AOI_ID=="0")
      {
        this.commercialMarketZoneForm.PRISM_AOI_ID="";
      }
      this.setSaveButtonStyle(status);
      if (status === this.selectedStatus) {
        this.saveLabelText = 'Save';
      }
      if (this.commercialMarketZoneForm.RequestStatus === RequestStatus.RETIRED ||
            this.commercialMarketZoneForm.RequestStatus === RequestStatus.TRANSITIONING) {
        this.commercialMarketZoneFormGroup = this._fb.group({
          'CommercialMarketZoneName': [this.commercialMarketZoneForm.CommercialMarketZoneName, Validators.required],
          'CommercialMarketZoneCode': [this.commercialMarketZoneForm.CommercialMarketZoneCode, Validators.required],
          'Description': [this.commercialMarketZoneForm.Description],
          'RegionID': [this.commercialMarketZoneForm.RegionID],
          'RequestStatus': [this.commercialMarketZoneForm.RequestStatus],
          'PRISM_AOI_ID': [this.commercialMarketZoneForm.PRISM_AOI_ID, Validators.required],
          'Comment': [this.commercialMarketZoneForm.ApprovalComments]
        });
      } else {
        this.buildForm();
      }
      if(isChange)
    this.commercialMarketZoneFormGroup.markAsDirty();
    }



    saveCommercialMarket(frm: any, status: string) {
      if (status === RequestStatus.DECLINED) {
        this._confirmation.confirm({
          message: Constants.CONFIRMDECLINE,
          accept: () => {
            this.save(frm, status);
          }
        });
      } else {
        this.save(frm, status);
      }
    }


    save(frm: any, status: string) {
      const region = this.regionName.find(s => s.value === this.commercialMarketZoneForm.RegionID);

      if (region) {
        this.commercialMarketZoneForm.Region = region;
      } else {
        const rgn = new Region();
        rgn.Code = '';
        rgn.Description = '';
        rgn.Id = '';
        rgn.Name = '';
        this.commercialMarketZoneForm.Region = rgn;
      }

      this.commercialMarketData = { ...Object.assign({}, this.commercialMarketZoneForm), RegionCode: '' };

      if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
        this.commercialMarketData.RequestStatus = status;

        this._commercialMarketService.saveCommercialMarket(JSON.stringify(this.commercialMarketData))
          .subscribe({
            next: (Result: { Status: string; CommercialMarketZone: CommercialMarketZoneResultModel; Message: string; }) => {
              if (Result.Status === 'SUCCESS') {
                const primaryID = this.commercialMarketData.CommercialMarketZoneID;
                this.commercialMarketData = Result.CommercialMarketZone;
                this.commercialMarketData.RegionCode = Result.CommercialMarketZone.Region?.Code;

                const i = this.commercialMarketAllData.findIndex(s => s.CommercialMarketZoneID === primaryID);
                const j = this.expandedItems.findIndex(s => s.CommercialMarketID === primaryID);
                const k = this.commercialMarketCodes.findIndex(s => s.Id === primaryID);

                if (Result.CommercialMarketZone.RequestStatus === RequestStatus.DECLINED) {
                  if (i !== -1) {
                    this.commercialMarketAllData.splice(i, 1);
                  }
                  if (j !== -1) {
                    this.expandedItems.splice(j, 1);
                  }
                  if (k !== -1) {
                    this.commercialMarketCodes.splice(k, 1);
                  }
                } else {
                  this.commercialMarketAllData[i] = this.commercialMarketData;
                  this.expandedItems[j] = this.commercialMarketData;

                  if (k !== -1) {
                    this.commercialMarketCodes.splice(k, 1,
                      ({
                        Id: this.commercialMarketData.CommercialMarketZoneID,
                        Code: this.commercialMarketData.CommercialMarketZoneCode,
                        Name: this.commercialMarketData.CommercialMarketZoneName,
                        RequestStatus: this.commercialMarketData.RequestStatus,
                        label: this.commercialMarketData.CommercialMarketZoneCode + ' - ' + this.commercialMarketData.CommercialMarketZoneName,
                        value: this.commercialMarketData.CommercialMarketZoneID
                      }));
                  }

                  this.cleanUpCache.emit(true);
                }

                this.commercialMarketCodes = this.commercialMarketCodes.slice();
                this.expandedItems = this.expandedItems.slice();
                this.commercialMarketAllData = this.commercialMarketAllData.slice();
                this.distinctAllDropdownCodes();

                if (this.selectedStatus === RequestStatus.PENDING && Result.CommercialMarketZone.RequestStatus !== RequestStatus.PENDING) {
                  this._appService.sendPendingCount(-1);
                }

                this.selectedStatus = status;
                this.DisplaySuccessMessage('Success', Result.Message);
                this.buildForm();
              } else {
                this.commercialMarketData.RequestStatus = this.selectedStatus;
                this.DisplayErrorMessage('Error', Result.Message);
              }
              this.dialogVisible = false;
            },
            error: (error: string) => {
              this.commercialMarketData.RequestStatus = this.selectedStatus;
              if (error === '') {
                this.DisplayErrorMessage('Error', Constants.NOSERVICE);
              } else {
                this.DisplayErrorMessage('Error', error);
              }
            }
      });
      }
    }

    validateForm(isApprove: boolean) {

      if (!this.commercialMarketZoneForm.CommercialMarketZoneName || this.commercialMarketZoneForm.CommercialMarketZoneName.trim() === '') {
        this.DisplayWarningMessage('Warning', 'Commercial Zone Name is required...');
        return false;
      }

      if ((!this.commercialMarketZoneForm.CommercialMarketZoneCode
        || (this.commercialMarketZoneForm.CommercialMarketZoneCode
          && this.commercialMarketZoneForm.CommercialMarketZoneCode.trim() === ''))) {
        this.DisplayWarningMessage('Warning', 'Commercial Zone Code is required...');
        return false;
      }

      if ((this.commercialMarketZoneForm.CommercialMarketZoneCode
        && (this.commercialMarketZoneForm.CommercialMarketZoneCode.trim().length < 2
          || this.commercialMarketZoneForm.CommercialMarketZoneCode.trim().length > 4))) {
        this.DisplayWarningMessage('Warning', 'Commercial Zone Code should be between 2 and 4 characters long...');
        return false;
      }

      const cmCode = this.commercialMarketCodes.find(s => s.Code.toString().toLowerCase()
        === this.commercialMarketZoneForm.CommercialMarketZoneCode.toString().toLowerCase()
        && s.Id !== this.commercialMarketZoneForm.CommercialMarketZoneID && s.RequestStatus !== RequestStatus.RETIRED);

      if (cmCode && this.commercialMarketZoneForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning',
          'This Commercial Zone Code and Crop combo already exists. Please enter a new Code or Crop..');
        return false;
      }

      if ((!this.commercialMarketZoneForm.Description || this.commercialMarketZoneForm.Description.trim() === '')
        && this.commercialMarketZoneForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning', 'Description is required...');
        return false;
      }

      if (this.commercialMarketZoneForm.RequestStatus === RequestStatus.PENDING
        && this.selectedStatus !== RequestStatus.PENDING) {
        this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
        return false;
      }

      // if ((!this.commercialMarketZoneForm.Region
      //   || (this.commercialMarketZoneForm.Region && this.commercialMarketZoneForm.Region.Name === ''))
      //   && this.commercialMarketZoneForm.RequestStatus !== RequestStatus.RETIRED) {
      //   this.DisplayWarningMessage('Warning', 'RCZ is required...');
      //   return false;
      // }


      // if (!this.commercialMarketZoneForm.CommercialMarketZoneLeadNames
      //     || this.commercialMarketZoneForm.CommercialMarketZoneLeadNames.trim() === '') {
      //     this.DisplayErrorMessage('Error', 'At least one Commercial Zone Lead is required...');
      //     return false;
      // }this.programForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)

      if (this.commercialMarketZoneForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
        if (isApprove && (!this.commercialMarketZoneForm.PRISM_AOI_ID
          || (this.commercialMarketZoneForm.PRISM_AOI_ID
            && this.commercialMarketZoneForm.PRISM_AOI_ID.toString().trim() === ''))) {
          this.DisplayWarningMessage('Warning', 'PRISM AOI ID is required for Request Status other than Pending and Retired...');
          return false;
        }

        const idx = this.aoiIDs.findIndex(s => s.toString() === this.commercialMarketZoneForm.PRISM_AOI_ID.toString());

        if (isApprove && (this.commercialMarketZoneForm.PRISM_AOI_ID === '0' || idx === -1)) {
          this.DisplayWarningMessage('Warning', 'PRISM AOI ID is not a valid AOI ID...');
          return false;
        }
      }

      return true;
    }

    public DisplayErrorMessage(title: string, message: string) {
      this._commonService.DisplayErrorMessage(title, message);
    }

    public DisplayWarningMessage(title: string, message: string) {
      this._commonService.DisplayWarningMessage(title, message);
    }

    public DisplayInfoMessage(title: string, message: string) {
      this._commonService.DisplayInfoMessage(title, message);
    }

    public DisplaySuccessMessage(title: string, message: string) {
      this._commonService.DisplaySuccessMessage(title, message);
    }

    // clearCrop() {
    //   this.commercialMarketZoneForm.CropID = '';
    //   this.commercialMarketZoneForm.RegionID = '';
    // }


    clearCMRegion(){}

    onCloseEditCommercialMarket(event: any, frm: any) {
      setTimeout(() => {
        this.close(frm);
      }, 100);
    }

    close(frm: any) {
      if (frm.dirty) {
        this._confirmation.confirm({
          message: Constants.PENDINGCHANGES,
          accept: () => {
            this.dialogVisible = false;
            this.existingPeople = [];
            this.existingPeopleString = '';
            this.buildForm();
          }
        });
      } else {
        this.dialogVisible = false;
        this.existingPeople = [];
        this.existingPeopleString = '';
      }
    }

    viewComments(commercialMarket: CommercialMarketZoneResultModel) {
      this.approvalCommentData = [];

      if (commercialMarket.ApprovalComments != null) {
        commercialMarket.ApprovalComments.forEach(element => {
          this.approvalCommentData.push({
            CommentDateString: this.ConvertDateToString(element.CommentDate),
            CommentDate: element.CommentDate,
            Commenter: element.Commenter.DisplayName,
            Comment: element.Comment
          });
        });
      }

      this.approvalCommentData = this.approvalCommentData.slice();
      this.displayComments = true;

      this.sourceIdentifier = commercialMarket.CommercialMarketZoneID;
    }

    onSaveComment(data: any){
      this.saveComment(data.comment, data.sourceIdentifier);
    }

    saveComment(comment: string, identifier: string) {
      const commercialMarketID = identifier;

      this.disableCommentButton = true;

      const approvalComments = new ApprovalComments();
      approvalComments.Comment = comment;
      approvalComments.RequestTypeID = commercialMarketID;

      this._commercialMarketService.saveCommercialMarketComment(JSON.stringify(approvalComments))
        .subscribe({
          next: Result => {
            if (Result.Status === 'SUCCESS') {
              const commercialMarketIndex = this.commercialMarketAllData
                .findIndex(s => s.CommercialMarketZoneID.toString() === commercialMarketID.toString());

              if (commercialMarketIndex !== -1) {
                this.commercialMarketAllData[commercialMarketIndex] = Result.CommercialMarketZone;
                this.commercialMarketAllData[commercialMarketIndex].ApprovalComments = Result.CommercialMarketZone.ApprovalComments.slice();
                this.commercialMarketAllData = this.commercialMarketAllData.slice();

                const expandedIndex = this.expandedItems
                  .findIndex(s => s.CommercialMarketID = Result.CommercialMarketZone.CommercialMarketID);

                this.expandedItems[expandedIndex] = Result.CommercialMarketZone;
                this.expandedItems = this.expandedItems.slice();

                this.approvalCommentData = [];

                if (Result.CommercialMarketZone.ApprovalComments != null) {
                  Result.CommercialMarketZone.ApprovalComments.forEach((element : any) => {
                    this.approvalCommentData.push({
                      CommentDateString: this.ConvertDateToString(element.CommentDate),
                      CommentDate: element.CommentDate,
                      Commenter: element.Commenter.DisplayName,
                      Comment: element.Comment
                    });
                  });
                }
              }

              this.approvalCommentData = this.approvalCommentData.slice();
              this.DisplaySuccessMessage('Success', Result.Message);
              this.displayComments = false;
            } else {
              this.DisplayErrorMessage('Error', Result.Message);
            }
            this.disableCommentButton = false;
          },
          error:error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
            this.disableCommentButton = false;
          }
    });
    }

    filterCommercialMarketDataTable(event: { filteredValue?: CommercialMarketZoneResultModel[]; }) {
      if(event?.filteredValue)
        this.filteredData = event.filteredValue;
    }

    exportCSVForCommercialMarketZones() {
      if (this.filteredData == null || this.filteredData.length === 0) {
        this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
        return;
      }

      const exportData = this.filteredData;
      this._commercialMarketService.exportCSVForCommercialMarketZones(JSON.stringify(exportData))
        .subscribe({
          next: data => {
            const blob = new Blob([data], { type: 'application/ms-excel'});
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = window.URL.createObjectURL(blob);
            link.download = 'CommercialMarketData.xlsx';
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
              document.body.removeChild(link);
              window.URL.revokeObjectURL(link.href);
            }, 100);
          },
          error: error => {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          }
    });
    }

    GetAOIID(commercialMarket: CommercialMarketZoneForm) {
      if (commercialMarket.CommercialMarketZoneCode) {
        if (!commercialMarket.CommercialMarketZoneCode) {
          this.DisplayErrorMessage('Error', 'Please enter the Commercial Zone Code first...');
          return;
        }

        if (!commercialMarket.CommercialMarketZoneName) {
          this.DisplayErrorMessage('Error', 'Please enter the Commercial Zone Name first...');
          return;
        }

        if (!commercialMarket.Description) {
          this.DisplayErrorMessage('Error', 'Please enter the Description first...');
          return;
        }

        const obj = new LERequestedData();
        obj.ParentComponent = this.parentComponent;
        obj.LEAOIID = 0;

        if (!this.commercialMarketZoneFormGroup.controls['CommercialMarketZoneCode'].dirty) {
          obj.Code = '';
          obj.ProjectLE = this.projectLEResult;
          obj.StationLE = this.stationLEResult;
          obj.SSLLE = this.sSLLEResult;
          obj.LocationProjectCode = this.locationProjectCodeResult;
          obj.ExperimentProjectCode = this.experimentProjectCodeResult;
        } else {
          obj.Code = commercialMarket.CommercialMarketZoneCode;
          obj.ProjectLE = [];
          obj.StationLE = [];
          obj.SSLLE = [];
          obj.LocationProjectCode = [];
          obj.ExperimentProjectCode = [];
        }

        obj.OtherObject = JSON.stringify(commercialMarket);

        this.existingLEIDString = JSON.stringify(obj);
        this.displayLEIDWindow = true;
      } else {
        this.DisplayErrorMessage('Error', 'Please enter the Code first...');
      }
    }

    onaddLEDataClick(data: LERequestedData): void {
      if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
        this.displayLEIDWindow = false;

        this.commercialMarketZoneForm.PRISM_AOI_ID = data.LEAOIID.toString();

        this.projectLEResult = data.ProjectLE;
        this.stationLEResult = data.StationLE;
        this.sSLLEResult = data.SSLLE;

        this.aoiIDs = [];

        if (this.sSLLEResult && this.sSLLEResult.length > 0) {
          this.sSLLEResult.forEach(s => {
            this.aoiIDs.push(s.AOIId);
          });
        }

        this.locationProjectCodeResult = data.LocationProjectCode;
        this.experimentProjectCodeResult = data.ExperimentProjectCode;

        this.existingLEIDString = '';
      }
    }

    public setHeight() {
      const viewPortHeight = document.documentElement.clientHeight;
      if (viewPortHeight <= 650) {
        return 10;
      } else {
        this.panelHeight = '500px';
        return 10;
      }
      return 0;
    }
  }